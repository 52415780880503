import { errorToComponent } from 'client/js/util/rest_utils';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';

import { Line as LineChart } from "react-chartjs-2";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, TimeScale);


var ChartColorBlue = '#3859a7';
var ChartColorGreen = '#4cd235';

const VirtualMachineGraph = () => (<div className="chart-container"><div className="chart-dummy"></div></div>)

var RrdCpuGraph = function(props) {
  if(!props.data)
    return <VirtualMachineGraph />;

  const mangled = props.data.map((p) => {
    return {x: dayjs(p.time).format(), y: p.cpu}
  })

  const datasets = [{
    label: 'CPU',
    backgroundColor: ChartColorBlue,
    borderColor: ChartColorBlue,
    data: mangled,
    pointRadius: 0,
    borderWidth: 2,
    fill: false,
    spanGaps: false
  }]

  const cb = (value) => (format_percent(value))

  return <RrdGraph datasets={datasets} yFormatCb={cb} />
}

var RrdDiskGraph = function(props) {
  if(!props.data)
    return <VirtualMachineGraph />;

  const cb = (value) => (value.fileSize() + '/s')

  const datasets = [{
    label: 'read',
    backgroundColor: ChartColorBlue,
    borderColor: ChartColorBlue,
    data: props.data.map((p) => ({x: dayjs(p.time).format(), y: p.disk_read})),
    pointRadius: 0,
    borderWidth: 2,
    fill: false,
    spanGaps: false
  }, {
    label: 'write',
    backgroundColor: ChartColorGreen,
    borderColor: ChartColorGreen,
    data: props.data.map((p) => ({x: dayjs(p.time).format(), y: p.disk_write})),
    pointRadius: 0,
    borderWidth: 2,
    fill: false,
    spanGaps: false
  }]

  return <RrdGraph datasets={datasets} yFormatCb={cb} redraw />
}

var RrdMemoryGraph = function(props) {
  if(!props.data)
    return <VirtualMachineGraph />;

  const datasets = [{
    label: 'belegt',
    backgroundColor: "#3859a7",
    borderColor: "#3859a7",
    data: props.data.map((p) => ({x: dayjs(p.time).format(), y: p.memory_used})),
    pointRadius: 0,
    borderWidth: 2,
    fill: true,
    spanGaps: false
  }]

  const cb = (value) => (value.fileSize())

  return <RrdGraph datasets={datasets} yFormatCb={cb} redraw />
}

var RrdNetworkGraph = function(props) {
  if(!props.data)
    return <VirtualMachineGraph />;

  const datasets = [{
    label: 'ausgehend (TX)',
    backgroundColor: ChartColorBlue,
    borderColor: ChartColorBlue,
    data: props.data.map((p) => ({x: dayjs(p.time).format(), y: p.bytes_out})),
    pointRadius: 0,
    borderWidth: 2,
    fill: false,
    spanGaps: false
  }, {
    label: 'eingehend (RX)',
    backgroundColor: ChartColorGreen,
    borderColor: ChartColorGreen,
    data: props.data.map((p) => ({x: dayjs(p.time).format(), y: p.bytes_in})),
    pointRadius: 0,
    borderWidth: 2,
    fill: false,
    spanGaps: false
  }]

  const cb = (value) => (value.fileSize() + '/s')

  return <RrdGraph datasets={datasets} yFormatCb={cb} redraw />
}

var RrdGraph = function(props) {
  const o = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0, // general animation time
      },
      plugins: {
        legend: {
          position: 'bottom'
        },
      },
      title: {
        display:false
      },
      interaction: {
        mode: 'index',
        axis: 'x',
        intersect: false
      },
      scales: {
          x: {
            type: "time",
            display: true,
            scaleLabel: {
              display: false
            }
          },
          y: {
              display: true,
              scaleLabel: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                callback: props.yFormatCb
              }
          }
      }
  }

  return <div className="chart-container"><LineChart data={{datasets: props.datasets}} options={o} width={"600"} height={320} /></div>;
}

var ClampedProgress = function(props) {
  const v = Math.max(Math.min(props.percent, 100), 0);

  return <div className="progress inline"><div className="progress-bar" style={{width: v + '%'}}></div></div>;
}

var CpuHeader = function(props) {
  if(typeof(props.percent) !== "undefined")
    return <h4>CPU ({format_percent(parseFloat(props.percent))}) <ClampedProgress percent={props.percent} /></h4>;
  else
    return <h4>CPU</h4>;
}

const fetchData = async (vm_id, timeframe) => {
  return await $.ajax({url: `/usercp/virtual_machines/${vm_id}/graph`, dataType: 'json', data: { timeframe }});
}

const Graphs = (props) => {
  const [timeframe, setTimeframe] = useState('day');

  const { data, isError, error } = useQuery(['virtual_machines', props.id, 'graphs', {timeframe: timeframe}], () => fetchData(props.id, timeframe), {
    keepPreviousData: true,
    refetchInterval: 60000,
  });

  if(isError)
    return errorToComponent(error);

  const p = 0; //fixme

  return (
    <>
        <form className="form-inline float-right mb-3">
          Zeitraum: <select className="form-control" value={timeframe} onChange={(e) => setTimeframe(e.target.value)}>
            <option value="hour">Stunde</option>
            <option value="day">Tag</option>
            <option value="week">Woche</option>
            <option value="month">Monat</option>
            <option value="year">Jahr</option>
          </select>
        </form>

      <div className="vm-graph-row">
        <CpuHeader percent={props.cpu_percent} />

        <RrdCpuGraph data={data?.graph} />

        <hr/>
      </div>

      <div className="vm-graph-row">
        <h4>
          RAM *
        </h4>

        <RrdMemoryGraph data={data?.graph} />

        <hr/>
      </div>

      <div className="vm-graph-row">
        <h4>
          Netzwerk
        </h4>

        <RrdNetworkGraph data={data?.graph} />

        <hr/>
      </div>

      <div className="vm-graph-row">
        <h4>
          SSD/Disk
        </h4>

        <RrdDiskGraph data={data?.graph} />
      </div>

      <p><small>*Bitte beachte, dass die RAM-Belegung extrospektiv ist: die tatsächliche Nutzung des RAM innerhalb von Linux/Windows wird sich zwangsläufig vom hier genannten Wert unterscheiden, da wir nicht auf die Daten des Betriebssystems zugreifen (können).</small></p>
    </>
  );
}

export default Graphs;
