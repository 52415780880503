import { useQuery } from '@tanstack/react-query';
import Callout from 'client/js/util/callout';
import ExternalLink from 'client/js/util/external_link';
import { FaIcon, PageHeader, SkeletonLoading } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

const fetchStatus = async (id) => {
  const response = await $.ajax({
    url: `/usercp/installations/${id}`,
    dataType: 'json',
  });

  return response;
};

// Show a callout with a list of steps, and each step has an icon and a description.
// The icon is either an hourglass, a spinner or a checkmark.
const MultiStepProgress = ({ steps, currentStep, children }) => {
  let calloutClass = 'info';
  if(currentStep === steps.length - 1) {
    calloutClass = 'success';
  }

  const stepIndex = Object.keys(steps).indexOf(currentStep);

  return (
    <Callout calloutClass={calloutClass}>
      {children}

      <ul className="steps fa-ul mb-0 ml-1">
        {Object.keys(steps).map((key, index) => {
          let icon = 'square-o';
          if (index < stepIndex) {
            icon = 'check-square-o';
          }

          return (
            <li key={index}>
              <FaIcon name={icon} loading={index == stepIndex} />
              <span className={index == stepIndex ? "font-weight-bold" : null}>{steps[key]}</span>
            </li>
          );
        })}
      </ul>
    </Callout>
  )
}

const Status = ({ installation }) => {
  const steps = installation.configuration_step ? (
    {
      waiting: "Warten auf den Start der Installation...",
      running: "Die Software wird entpackt...",
      installed: installation.configuration_step ? `Richte "${installation.software_name}" erstmalig ein...` : "Warte, bis die Änderungen übernommen wurden...",
      configured: "Abschließen der Installation..."
    }
  ) : (
    {
      waiting: "Warten auf den Start der Installation...",
      running: "Die Software wird entpackt...",
      configured: "Abschließen der Installation..."
    }
  );

  const state = installation?.state;

  if (state === 'success') {
    if (!installation.configuration_step) {
      return (
        <Callout calloutClass="success">
          <p className="font-weight-bold">&quot;{installation.software_name}&quot; ist nun zur Einrichtung bereit!</p>

          <p>Die Software ist nun entpackt. Bitte fahre mit der Einrichtung fort.</p>

          <p>Der Assistent wird Dich nach Zugangsdaten für eine MySQL-Datenbank fragen. Die Angaben findest Du in der Verwaltung unter <ExternalLink href="/usercp/databases">MySQL-Zugangsdaten</ExternalLink>.</p>

          <a href={installation.setup_url} target="_blank" rel="noopener noreferrer" className="btn btn-light btn-lg">Einrichtungs-Assistenten öffnen <FaIcon name="external-link" /></a>
        </Callout>
      );
    }

    return (
      <Callout calloutClass="success">
        <p className="font-weight-bold">Die Installation von {installation.software_name} war erfolgreich!</p>

        <p>Die Software ist nun einsatzbereit. Du kannst die Webseite öffnen und mit der Benutzung beginnen.</p>

        <a href={installation.url} target="_blank" rel="noopener noreferrer" className="btn btn-light btn-lg">Webseite öffnen <FaIcon name="external-link" /></a>
      </Callout>
    );
  }

  if (state === 'failure') {
    return (
      <Callout calloutClass="danger">
        <p className="font-weight-bold">Die Installation von {installation.software_name} ist fehlgeschlagen. Bitte probiere es erneut oder installiere die Software manuell.</p>

        {installation.configuration_step && (
          <>
            <p><strong>Du kannst einen Screenshot des Fehlers ansehen, um zu prüfen ob Du eine Eingabe gemacht hast, die zu dem Fehler führt. Bitte versuche es in solch einem Fall noch einmal mit korrigierten Angaben!</strong></p>

            <a href={installation.screenshot_url} className="screenshot-link" target="_blank" rel="noreferrer">
              <img src={installation.screenshot_url} className="img-polaroid" />
            </a>
          </>
        )}

        <p>Sollte der Fehler dauerhaft bestehen wende Dich bitte unter Angabe der Installations-ID &quot;{installation.id}&quot; an den <Link to="/tickets/new">Support</Link>.</p>
      </Callout>
    );
  }

  return (
    <>
      <MultiStepProgress steps={steps} currentStep={installation?.state}>
        <p>Installation von {installation.software_name} läuft...</p>
      </MultiStepProgress>
    </>
  );
}

const Show = (props) => {
  const { id } = props.match.params;
  const { data, isLoading } = useQuery(['installations', id], () => fetchStatus(id), {
    refetchInterval: (data) => (data?.installation?.state !== 'success' && data?.installation?.state !== 'failure') ? 1000 : false
  });

  const installation = data?.installation;

  if (isLoading) {
    return (
      <>
        <PageHeader text="Installation ...">Installation <SkeletonLoading /></PageHeader>

        <LoadingTableSpinner text="Laden..." />
      </>
    );
  }

  return (
    <>
      <PageHeader text={`Installation ${installation.id}`} />

      <Status installation={installation} />
    </>
  );
}

export { MultiStepProgress };
export default Show;
