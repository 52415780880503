import ExternalLink from 'client/js/util/external_link';
import PropTypes from "prop-types";
import {PasswordInput, BooleanInput} from 'client/js/util/form_utils';
import DomainPrefixInput from "client/js/util/inputs/domain_prefix_input";
import GenericCreate from 'client/js/resellercp/generic_resource/create';
import {withLoading} from 'client/js/util/rest_utils';

const Form = (props) => {
  return <div>
      <DomainPrefixInput prefix_name="login" required label="Postfach-Name" separator="@"
                         domains={props.data.domains.map((i) => ([i.label, i.id]))} />

      <PasswordInput required name="password" label="Passwort" />
    </div>;
}

Form.propTypes = {
  data: PropTypes.shape({
    domains: PropTypes.array.isRequired
  }).isRequired
};

const FormContainer = withLoading(Form);

const Create = () => (<GenericCreate namespace="usercp" back_url="/email"
                                    resource_name="mailboxes"
                                    display_resource_name="mailboxes"
                                    form_component={() => (<FormContainer url="/usercp/mailboxes/new.json" key_name="new_mailbox" />)} />);

export default Create;
