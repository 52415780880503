import {Link} from 'react-router-dom';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import {RadioCollectionInput} from 'client/js/util/form_utils';
import {FaIcon, PageHeader} from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';
import {withLoading} from 'client/js/util/rest_utils';

class SuccessCallout extends React.Component {
  componentDidMount() {
    _paq.push(['trackEvent', 'subscription', 'start_trial']);
  }

  render() {
    return (
      <React.Fragment>
        <PageHeader text="Webhosting-Test gestartet" />

        <div className="text-center">
          <h4 className="text-success">
            <video className="success-video mb-3" id="video-manager" muted preload autoPlay>
              <source src="/assets/ani/success.mp4" type="video/mp4" />
            </video>

            <br/>
            Test gestartet!
          </h4>

          <p>
            <strong>Willkommen bei lima-city!</strong>
            <br/>
            Solltest Du Fragen haben oder Hilfe brauchen, ist unser freundlicher <Link to="/tickets/new">Support</Link> gerne für Dich da!
          </p>

          <p>Die Inklusiv-Domains im Paket kannst Du erst bestellen, wenn das Paket bestellt ist. Du kannst vorher eine kostenlose Webseite anlegen.</p>

          <a href="/usercp/websites/lima-subdomain/new" className="btn btn-primary"><FaIcon name="plus" /> erste Webseite anlegen</a>
        </div>
      </React.Fragment>
    );
  }
}

const Form = (props) => {
  if(!props.data.can_test)
    return <Callout calloutClass="error" title="Test nicht verfügbar">
        <p>Ein Test des Webhosting-Pakets ist derzeit nicht verfügbar.</p>
        <p>Du kannst stattdessen ein Webhosting-Paket <Link to="/webhosting/order">direkt bestellen</Link>.</p>
      </Callout>


  const subscriptions = props.data.test_subscriptions.map((item) => ([item.offer_code, <div key={item.offer_code}><h5>{item.name} ({i18n_c("month", item.contract_period)} Laufzeit) <small className="text-muted">{item.offer_code}</small></h5><p>{item.disk_space_gb} GB SSD, WordPress-Tools, SSH, tägl. Backup (90 Tage Aufbewahrung)</p></div>]))

  return (
    <React.Fragment>
      <RadioCollectionInput required name="offer_code" label="Webhosting-Paket" options={subscriptions} />
    </React.Fragment>
  )
}

const FormContainer = withLoading(Form);

const Page = () => (<GenericInteraction namespace="usercp"
                                             resource_name="webhosting"
                                             form_namespace="test_subscription"
                                             form_url="/usercp/webhosting/create_test"
                                             action="create_test"
                                             method="post"
                                             success_splash={() => (<SuccessCallout />)}
                                             form_component={() => (<FormContainer url="/usercp/webhosting/create_test.json" />)} />);

export default Page;
