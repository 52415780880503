import Modal from 'react-modal';
import {BooleanInput, GenericReactForm, SubmitButton} from 'client/js/util/form_utils';
import {Link} from 'react-router-dom';

class TransferLockBadge extends React.Component {
  state = {open: false}

  toggleModal = (e) => {
    e.preventDefault();
    e.target.blur();
    this.setState({open: !this.state.open});
  }

  dispatch = (action) => {
    if(action != "form:success")
      return;

    this.setState({open: false});
    this.props.refreshCb();
  }

  render() {
    return <React.Fragment>
      <a href="#" onClick={this.toggleModal} className="badge badge-warning">Transfer Lock gesetzt</a>

      <Modal isOpen={this.state.open} className="modal fade in show d-block" contentLabel="Transfer-Lock gesetzt">
                    <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                      <h3>Transfer-Lock gesetzt</h3>
                      <button type="button" className="close" onClick={this.toggleModal} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <GenericReactForm namespace="transfer_lock" url={`/usercp/domains/${this.props.id}/restart_transfer`} dispatch={this.dispatch}>
                      <div className="modal-body">
                        <p>Für diese Domain ist ein &quot;Transfer Lock&quot;, eine Transfer-Sperre gesetzt. Die Domain kann derzeit nicht umgezogen werden.</p>

                        <p>Eine solches Transfer-Lock kann aus zwei Gründen bestehen:</p>

                        <ul>
                          <li>es wurde manuell eine Transfer-Sperre gesetzt, um versehentliche Transfers zu verhindern</li>
                          <li>die Registry (nicht der Provider) hat eine automatische Transfer-Sperre gesetzt</li>
                        </ul>

                        <p>Manuelle Transfer-Locks können in der Regel im Kundenmenü oder über den Support des Domain-Anbieters aufgehoben werden. Sofern es sich um ein automatisches Transfer-Lock handelt wird dies von der Registry gesetzt. Dies ist bei ICANN-Domains der Fall, die innerhalb der letzten 60 Tage registriert oder umgezogen werden. Die Transfer-Sperre wird automatisch 60 Tage nach dem Umzug bzw. der Registrierung aufgehoben und kann nicht vorher manuell entfernt werden.</p>

                        <p><strong>Bei Fragen zu einem bestehenden Transfer Lock wende Dich bitte an den derzeitigen Domain-Provider.</strong></p>

                        <p>Nach dem Aufheben des Transfer-Locks kannst Du unten den Domain-Transfer erneut starten.</p>

                        <BooleanInput name="transfer_lock_removed" label="Ich habe das Transfer-Lock entfernt und möchte den Transfer erneut starten" required />
                      </div>

                      <div className="modal-footer">
                        <SubmitButton icon="refresh" text="Domain-Transfer erneut versuchen" />
                      </div>
                    </GenericReactForm>
                    </div>
                    </div>
                  </Modal>
                </React.Fragment>;
}
}

const Status = (props) => {
  const status = props.item.status;

  if(status == 'ok')
    return <span className="badge badge-success">{status}</span>;

  if(status == 'auth_info_required')
    return <Link to={`/domains/${props.item.id}/auth_info`} className="badge badge-warning">AuthCode erforderlich</Link>;

  if(status == 'transfer_lock_error')
    return <TransferLockBadge id={props.item.id} refreshCb={props.refreshCb} />;

  if(status == 'authcode_invalid')
    return <Link to={`/domains/${props.item.id}/auth_info`} className="badge badge-warning">AuthCode inkorrekt</Link>;

  // if(status == 'deleted' && props.item.restorable)
  //   return <a href={`/usercp/domains/${props.item.id}/auth_info`} className="badge badge-danger">Gelöscht, wiederherstellbar</a>;

  if(status == 'deleted')
    return <span className="badge badge-danger">Gelöscht</span>;

  return <span className="badge badge-secondary">{status}</span>
}

const AutoRenewStatus = (props) => {
  if (props.in_subscription) {
    if (props.auto_renew)
      return <span className="badge badge-success">Inklusiv-Domain</span>
    else
      return <span className="badge badge-warning">Inklusiv-Domain (gekündigt)</span>
  }

  if (props.auto_renew)
    return <span className="badge badge-success">automatische Verlängerung</span>

  return <span className="badge badge-secondary">Prepaid</span>
}

export {Status, AutoRenewStatus};

export default Status;
