import {Route, Switch} from 'react-router-dom';
import New from './new';
import Progress from './progress';

const Router = () => (
    <Switch>
      <Route path="/mailboxes/:mailbox_id/imports/new" component={New} />
      <Route path="/mailboxes/:mailbox_id/imports/:import_id" component={Progress} />
    </Switch>);

export default Router;
