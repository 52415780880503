import VirtualMachineWizard from "client/js/usercp/cloud/new";
import {withLoading} from 'client/js/util/rest_utils';
import {PageHeader, FaIcon} from 'client/js/util/layout_utils';
import CreateAccount from 'client/js/usercp/cloud/create_account';
import Callout from 'client/js/util/callout';
import VerifyAccount from 'client/js/usercp/cloud/verify_account';

const PaymentRequiredSplash = () => {
  return <Callout calloutClass="warning" title="Vorauszahlung nötig">
      <p>Du hast die Zahlung per Kreditkarte für die Cloud-VPS ausgewählt. Für Neukunden ist eine einmalige Vorauszahlung nötig, bevor Du ein Cloud-VPS erstellen kannst. Bitte lade Guthaben auf, um eine Cloud-VPS zu erstellen:</p>

      <form className="form-inline" action="/usercp/webhosting/add_funds" method="post">
        <div className="input-group mr-1">
          <input className="form-control" type="number" defaultValue="5" min="5" max="100" name="amount" />
          <div className="input-group-append"><span className="input-group-text">&euro;</span></div>
        </div>
        <button className="btn btn-primary" type="submit"><FaIcon name="euro" /> Guthaben aufladen</button>
      </form>
    </Callout>;
}

const VerifyPaymentMethod = () => {
  return <Callout calloutClass="warning" title="Zahlungsmethode unbestätigt">
      <p>Die eingegebene SEPA-Bankverbindung ist noch nicht bestätigt. Wir haben eine Überweisung mit einem Code im Verwendungszweck auf Dein Konto getätigt. Den Code aus der Überweisung musst Du wieder bei uns eingeben um die Bankverbindung zu bestätigen. Die Bestätigung der Zahlungsmethode muss abgeschlossen sein, bevor Du die Cloud-VPS nutzen kannst.</p>

      <a className="btn btn-primary" href="/usercp/bankcode"><FaIcon name="ellipsis-h" /> Bestätigungscode eingeben</a>
    </Callout>;
}

const Form = (props) => {
  return <VirtualMachineWizard models={props.data.models}
                                templates={props.data.templates}
                                ssh_keys={props.data.ssh_keys} />;
}

const FormContainer = withLoading(Form, { url: "/usercp/virtual_machines/new.json", key_name: "options" });

const PageWrapper = (props) => (<div>
      <PageHeader text="Cloud-VPS erstellen" back_url="/virtual_machines" />

      <ContentArea {...props} />
    </div>);

const Container = (props) => {
  if (!props.data || !props.data.enabled)
    return <CreateAccount />;

  if (props.data && props.data.sms_verification_required)
    return <VerifyAccount />;

  if(props.data && props.data.sms_verification_required)
    return <VerifyAccount />;

  if (props.data && props.data.payment_required)
    return <PaymentRequiredSplash />;

  if(props.data && props.data.payment_method_verification_required)
    return <VerifyPaymentMethod />;

  return (
      <React.Fragment>
        <PageHeader text="Cloud-VPS erstellen" back_url="/virtual_machines" />

        <FormContainer />;
      </React.Fragment>
    )
}

export default withLoading(Container, { url: "/usercp/cloud_account.json", key_name: "cloud_account" });
