import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import Callout from 'client/js/util/callout';
import { StringInput, TextInput } from 'client/js/util/form_utils';
import { PageHeader } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';

const SuccessCallout = () => (<div>
  <PageHeader text="Account gelöscht" />

  <Callout calloutClass="success">
    <p>Der Account ist nun gelöscht. Auf Wiedersehen &amp; alles Gute!</p>
  </Callout>
</div>)

const Form = (props) => (<div>
  <Callout calloutClass="danger">
    <p>Dies wird Deinen Account unwiderruflich löschen. Wir können den Account nicht wiederherstellen!</p>
    <p>Der Benutzername {props.username} kann nicht noch einmal registriert werden!</p>

    {!!props.has_domains && <p>Es ist keine Verwaltung Deiner Domains mehr möglich, nachdem der Account gelöscht wurde. Wenn Du Deine Domains zu einem anderen Provider transferieren oder sie löschen möchtest wende Dich bitte vor der Löschung an den Support!</p>}

    {!!props.has_domains && <p>Bitte beachte, dass mit der Account-Löschung alle Restlaufzeiten für Domains verfallen, Vorauszahlungen werden nicht erstattet. Sobald der Account gelöscht wird, werden auch alle mit den Accounts verbundenen Services bei lima-city (DNS, Webhosting, E-Mail, vServer) <u>sofort</u> deaktiviert.</p>}
  </Callout>

  <StringInput required type="password" name="password" autoComplete="off" label="Passwort" hint="wird zur Bestätigung benötigt" />

  <TextInput name="comment" label="Feedback" hint="Warum löschst Du Deinen Account? Unzufrieden? Woanders bessere Konditionen erhalten? Was können wir verbessern? Sag uns, was Dich stört, wir möchten unser Angebot verbessern!" />
</div>)


const Page = withLoading((props) => (<GenericInteraction namespace="usercp"
                                                  resource_name="usercp_delete_account_interaction"
                                                  form_url="/usercp/delete-account.json"
                                                  method="post"
                                                  action="update"
                                                  back_url="/settings"
                                                  success_splash={() => (<SuccessCallout />)}
                                                  form_component={() => (<Form username={props.data.username} has_domains={props.data.has_domains} />)} />));

const PageWrapper = () => (<Page url="/usercp/delete-account.json" />);

export default PageWrapper;
