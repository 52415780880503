import GenericUpdate from 'client/js/resellercp/generic_resource/update';
import WebspacePathInput from 'client/js/util/inputs/webspace_path_input';
import { PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';

const Form = () => (<>
  <WebspacePathInput name="directory" label="Webspace-Verzeichnis" required rootChoosable />
</>);

const Page = (props) => {
  const id = props.match.params.id;

  const { data, component } = useResource(['ftp_accounts', id], `/usercp/ftp_accounts/${id}`, {
    header: () => <PageHeader title={`FTP-Account ${data?.ftp_account?.username}`} />,
  });

  if (component) {
    return component;
  }

  return (
    <GenericUpdate namespace="usercp"
      resource_name="ftp_accounts"
      url={`/usercp/ftp_accounts/${id}/directory`}
      method="PATCH"
      transform_data={(data) => ({ path: data?.ftp_account?.directory})}
      id={id}
      form_component={() => <Form id={id} />}
    />
  );
}

export default Page;
