import { DetailsRow, DetailsSection, FaIcon } from "client/js/util/layout_utils";

const TrafficCard = (props) => {
  const traffic = props.traffic;
  const used_percent = traffic.prorated_limit ? (traffic.net_external_in + traffic.net_external_out) / traffic.prorated_limit * 100 : null;

  return (
    <DetailsSection title="Traffic">
      {!!used_percent && (
        <DetailsRow title="Traffic-Verbrauch">
          <div className="progress">
            <div className="progress-bar" style={{ "width": `${used_percent}%` }}>
              {(traffic.net_external_in + traffic.net_external_out).fileSize()}/{traffic.prorated_limit.fileSize()} ({used_percent.toFixed(2)}%)
            </div>
          </div>
        </DetailsRow>
      )}

      <DetailsRow title="Limit">
          {traffic.limit ? traffic.limit.fileSize() : 'kein Limit'}
          {traffic.prorated_limit && traffic.prorated_limit != traffic.limit ? ` (${traffic.prorated_limit.fileSize()} anteilig)` : null}
      </DetailsRow>

      <DetailsRow title="Externer Traffic">
        {traffic.net_external_in.fileSize()} <FaIcon name="download" title="eingehender Traffic" /> / {traffic.net_external_out.fileSize()} <FaIcon name="upload" title="ausgehender Traffic" />
      </DetailsRow>

      <DetailsRow title="Interner Traffic">
        {traffic.net_internal_in.fileSize()} <FaIcon name="download" title="eingehender Traffic" /> / {traffic.net_internal_out.fileSize()} <FaIcon name="upload" title="ausgehender Traffic" />
      </DetailsRow>
    </DetailsSection>
  )
};

const ContractCard = (props) => {
  const contract = props.contract;

  return (
    <DetailsSection title="Vertragsinformationen">
      {/* Basic contract information */}
      <DetailsRow title="Tarif" text={contract.model_name} />
      <DetailsRow title="Status">
        {contract.cancelled_at ?
          <span className="badge badge-danger ml-auto">Gekündigt</span> :
          <span className="badge badge-success ml-auto">Aktiv</span>
        }
      </DetailsRow>
      <DetailsRow title="Vertragsbeginn" text={dayjs(contract.created_at).format('L')} />
      <DetailsRow title="Vertragslaufzeit" text={i18n_c("month", contract.contract_period)} />
      
      {/* Financial information */}
      <DetailsRow title="Preis pro Monat" text={format_fractional_price(contract.price_per_month)} />
      <DetailsRow title="Abrechnungszeitraum" text={i18n_c("month", contract.billing_cycle)} />
      
      {/* Renewal/termination information */}
      <DetailsRow title="Abgerechnet bis" text={dayjs(contract.paid_through_date).format('L')} />
      {!contract.cancelled_at && contract.next_renewal_date && (
        <DetailsRow title="Nächste Verlängerung" text={dayjs(contract.next_renewal_date).format('L')} />
      )}
      {!contract.cancelled_at && contract.next_renewal_date && (
        <DetailsRow title="Preis der nächsten Verlängerung" text={format_fractional_price(contract.next_renewal_price)} />
      )}
      {contract.cancelled_at ?
        <DetailsRow title="Gekündigt zum" text={dayjs(contract.cancelled_at).format('L')} /> :
        <DetailsRow title="Nächster Kündigungszeitpunkt" text={dayjs(contract.next_cancellation_date).format('L')} />
      }
    </DetailsSection>
  );
};

const Overview = (props) => {
  const traffic = props.virtual_machine.traffic;
  const isVserver = props.virtual_machine.type === 'vserver';
  const contract = props.virtual_machine.contract;

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 col-md-6">
          <DetailsSection title="Hardware-Konfiguration">
            <DetailsRow title="vCores" text={props.virtual_machine.vcores} />
            <DetailsRow title="RAM" text={`${props.virtual_machine.memory_gb} GB`} />
            <DetailsRow title="SSD" text={`${props.virtual_machine.ssd_storage_gb} GB`} />
          </DetailsSection>
        </div>

        <div className="col-12 col-md-6">
          <TrafficCard traffic={traffic} />
        </div>
      </div>

      {isVserver && contract && (
        <div className="row mb-3">
          <div className="col-12">
            <ContractCard contract={contract} />
          </div>
        </div>
      )}
    </>
  );
}

export default Overview;
