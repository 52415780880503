import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import { StringInput } from 'client/js/util/form_utils';
import { PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';

const Form = () => (
  <>
    <StringInput name="comment" label="Kommentar (optional)" />
  </>
);

const UpdatePassword = (props) => {
  const id = props.match.params.id;

  const { data, component } = useResource(['ftp_accounts', id], `/usercp/ftp_accounts/${id}`, {
    header: <PageHeader title={`Kommentar bearbeiten`} />,
  });

  if (component) {
    return component;
  }

  return (
    <GenericInteraction
      namespace="usercp"
      resource_name="ftp_accounts"
      action="comment"
      method="patch"
      id={id}
      redirectAfter="index"
      defaults={{comment: data.ftp_account.comment}}
      form_component={() => (<Form />)}
    />
  );
};

export default UpdatePassword;
