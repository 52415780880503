const state_to_badge = (db_state) => {
  if(db_state == 'operational')
    return <span className="badge badge-success">aktiv</span>;

  if(db_state == 'destroying')
    return <span className="badge badge-danger">wird gelöscht</span>;

  if(db_state == 'destroyed')
    return <span className="badge badge-danger">gelöscht</span>;

  if(db_state == 'creating')
    return <span className="badge badge-secondary">wird erstellt</span>;

  return <span className="badge badge-secondary">unbekannt</span>;
}

export {state_to_badge};
