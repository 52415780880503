import { useQuery } from '@tanstack/react-query';
import Callout from 'client/js/util/callout';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';
import VirtualMachineRow from './virtual_machine_row';


const EmptyCallout = () => (
  <Callout calloutClass="primary" title="Noch keine Cloud-VPS">
    <p>Bisher sind noch keine Cloud-VPS vorhanden. Erstelle doch ein neues...</p>

    <Link to="/virtual_machines/new" className="btn btn-secondary"><FaIcon name="plus" /> Cloud-VPS erstellen</Link>
  </Callout>
  );

const buttons = [
  <a key="1" href="/vserver" className="btn btn-light"><FaIcon name="plus" /> vServer erstellen</a>,
  <Link key="0" to="/virtual_machines/new" className="btn btn-light"><FaIcon name="plus" /> Cloud-VPS erstellen</Link>
];

const Header = () => (<PageHeader text="Cloud-VPS" buttons={buttons} />)

const fetchData = async () => {
  const response = await $.ajax({url: '/usercp/virtual_machines', dataType: 'json'});

  return response;
}

const PageView = () => {
  const {data, isLoading, isError, error} = useQuery(['virtual_machines'], () => fetchData(), {
    keepPreviousData: true,
    refetchInterval: 5000,
  });

  if(isLoading)
    return (<><Header /> <LoadingTableSpinner /></>);

  if(isError)
    return <><Header /> {errorToComponent(error)}</>;

  const virtual_machines = data.virtual_machines;

  return (
    <>
      <Header />

      {virtual_machines.length == 0 ? <EmptyCallout /> :
        <table className="table table-striped table-wide vms-table">
          <thead><tr><th></th><th>Name</th><th>OS</th><th>IP-Adresse</th><th>Zuletzt gestartet</th><th></th></tr></thead>
          <tbody>
            {virtual_machines.map((item) => {
              return <VirtualMachineRow key={item.id} item={item} />;
            })}
          </tbody>
        </table>}
    </>);
}

export { PageView as default, PageView };

