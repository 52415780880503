import Redirect from 'client/js/util/hard_redirect';
import {GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';
import DomainPrefixInput from "client/js/util/inputs/domain_prefix_input";
import WebsiteContentSection from './website_content_section';
import {PageHeader} from 'client/js/util/layout_utils';
import {useState} from 'react';

var SubdomainInput = function() {
  let domains = [
          ["4lima.de", "4lima.de"],
          ["12hp.de", "12hp.de"],
          ["2ix.de", "2ix.de"],
          ["lima-city.at", "lima-city.at"],
          ["4lima.at", "4lima.at"],
          ["12hp.at", "12hp.at"],
          ["2ix.at", "2ix.at"],
          ["lima-city.ch", "lima-city.ch"],
          ["4lima.ch", "4lima.ch"],
          ["12hp.ch", "12hp.ch"],
          ["2ix.ch", "2ix.ch"],
          ["1337.pictures", "1337.pictures"],
          ["clan.rip", "clan.rip"],
          ["lima-city.rocks", "lima-city.rocks"],
          ["webspace.rocks", "webspace.rocks"],
          ["lima.zone", "lima.zone"],
          ["de.cool", "de.cool"]];

  return <DomainPrefixInput domains={domains} />;
}

const Page = () => {
  const [success, setSuccess] = useState(false);

  const onSuccess = (_data, submittedData) => {
    if(submittedData.content_strategy == 'install') {
      return setSuccess('install');
    }
    setSuccess(true);
  }

  if(success)
    return <Redirect to={success == 'install' ? '/softwares' : '/websites'} />;

  return <>
    <PageHeader text="lima-Subdomain erstellen" back_url="/websites" />

    <GenericReactForm namespace="usercp_create_free_subdomain_interaction" defaults={{content_strategy: "same_path"}} url="/usercp/websites/lima-subdomain" onSuccess={onSuccess}>
      <SubdomainInput />

      <WebsiteContentSection name="name" label="Webseiten-Inhalt" installerRedirect required />

      <FormActions>
        <SubmitButton icon="plus" text="kostenlose Subdomain erstellen" />
      </FormActions>
    </GenericReactForm>
    </>;
}

export default Page;
