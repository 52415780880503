import 'react-toastify/dist/ReactToastify.css';
import 'react-credit-cards/lib/styles-compiled.css';

import Login from "client/js/login";
import NewMfaSession from "client/js/new_mfa_session";
import StandaloneUsercpNav from "client/js/usercp/navigation";
import UsercpApp from "client/js/usercp_app";
// can be removed after usercp is fully reactified
import PayButton from "client/js/usercp/lib/payment";
import ReactOnRails from 'react-on-rails';

ReactOnRails.register({ UsercpApp, Login, NewMfaSession, StandaloneUsercpNav, PayButton });
