import PropTypes from "prop-types";
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

class HardRedirect extends React.Component {
  componentDidMount() {
    location.href = "/usercp" + this.props.to;
  }

  render() {
    return <LoadingTableSpinner />;
  }
}

HardRedirect.contextTypes = {
  to: PropTypes.string.isRequired
};

export default HardRedirect;
