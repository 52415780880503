import {ControlGroup} from 'client/js/util/form_utils';
import InlineSpinnner from 'client/js/util/inline_spinner';

class TopupBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return null;
  }
}

class PrepaidStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.ajax = $.get('/usercp/invoices/balance.json')

    this.ajax.done((data, xhr) => {
      this.setState({currentBalance: data.balance.current_balance});
    });
  }

  render() {
    return <ControlGroup type={'change_selection'} label="aktuelles Guthaben">
          {this.state.currentBalance ? <strong>{format_price(this.state.currentBalance * 100)}</strong> : <InlineSpinnner text="Lade Kontostand..." />} <a href="#">Guthaben aufladen</a>

          <TopupBalance shown={true || this.state.showTopup} />
        </ControlGroup>
  }
}

export default PrepaidStep;
