import { useQuery } from '@tanstack/react-query';
import { BasicDropdown, Dropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent } from 'client/js/util/rest_utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSTransition } from "react-transition-group";
import { Tooltip } from 'reactstrap';

const fetchData = async () => {
  const response = await $.ajax({ url: `/usercp/websites`, dataType: 'json' });

  return response;
}

const fetchSubscriptionData = async () => {
  const response = await $.ajax({ url: `/usercp/webhosting`, dataType: 'json' });

  return response;
}

// return if the root website matches _or_ any of the children match
const filterData = (data, filterQuery) => {
  if(!filterQuery || filterQuery.length === 0) {
    return data;
  }

  let filter_fn;
  try {
    const rex = new RegExp(filterQuery, 'i');

    filter_fn = (item) => (rex.test([item.hostname, item.document_root].join(' ')));
  } catch (err) {
    filter_fn = (item) => ([item.hostname, item.document_root].join(' ').indexOf(filterQuery) !== -1);
  }

  return data.filter(filter_fn);
}

// Show a Screenshot of the site. When the hostname contains //*., replace it with www. and use thum.io to get a screenshot
const Screenshot = ({hostname}) => (
  <div className="img-thumbnail">
    <img
      src={`https://image.thum.io/get/width/341/crop/500/maxAge/24/?url=${encodeURIComponent(hostname.replace(/\/\/\*\./, '//anywildcardsubdomain.'))}`}
      alt="Screenshot"
      style={{ minWidth: '341px', minHeight: '142px' }}
    />
  </div>
)

Screenshot.propTypes = {
  hostname: PropTypes.string.isRequired,
}

const Details = ({ website, hasWebhosting }) => {
  if(website.redirect) {
    return (
      <>
        <strong>{website.redirect_status == 301 ? 'Permanente' : 'Temporäre'} Weiterleitung</strong> zu {website.redirect_url}
      </>
    )
  }

  if(hasWebhosting === false) {
    return (
      <div className="alert alert-warning">
        Diese Webseite ist nicht aktiv, da kein Webhosting-Paket gefunden wurde. <Link to="/webhosting">Webhosting-Paket bestellen oder kostenlos testen</Link>.
      </div>
    )
  }

  if (website.app && website.app.software == 'wordpress') {
    return (
      <>
        <strong>WordPress-Webseite</strong> <br/>
        Webspace-Verzeichnis: {website.document_root}
        <br/>
        Version: {website.app.version} <br />
      </>
    )
  }

  return (
    <>
      Webspace-Verzeichnis: <strong>{website.document_root}</strong>< br />

      PHP-Version: {
        website.php_version ? (
          <strong>{website.php_version}</strong>
        ) : (
        <><strong>{website.default_php_version}</strong> <small>(Account-Standard)</small></>
      )
    } <br />

    {
      website.app && (
        <>
          Software: <strong>{website.app.software}</strong> <br />
          Version: <strong>{website.app.version}</strong> <br />
        </>
      )
    }
    </>
  )
}

const SiteType = ({ type, domain, hostname, website_root }) => {
  if (type == 'free_subdomain')
    return `Kostenlose Subdomain ${hostname}`

  if (type == 'external')
    return `Aufgeschaltete Domain ${hostname}`

  if (domain?.id || website_root)
    return `Domain ${hostname}`;

  return `Subdomain ${hostname}`;
}

const WebsiteDetails = ({ website, hasWebhosting }) => {
  return (<>
        <StatusAlert website={website} />

        <div className="d-flex">
          <div className="mr-3">
            <Screenshot hostname={website.url} />
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="details-header">
              <p><SiteType type={website.type} domain={website.domain} hostname={website.hostname} website_root={website.website_root} /></p>


              <Details website={website} hasWebhosting={hasWebhosting} />
            </div>

            <div className="details-buttons bnt-group">
              <Link to={`/websites/${website.id}/edit`} className="btn btn-sm btn-light"><FaIcon name="pencil" /> Inhalt ändern</Link>
              {website?.app?.software == 'wordpress' && (
                <Link to={`/wordpress/${website?.app?.id}`} className="btn btn-sm btn-light"><FaIcon name="wordpress" /> WordPress verwalten</Link>
              )}
              {!!website?.domain?.id && (
                <>
                  <Link to={`/websites/subdomain/new?usercp_create_subdomain_form%5Bdomain%5D=${website?.domain?.unicode_fqdn}`} className="btn btn-sm btn-light"><FaIcon name="plus" /> Subdomain erstellen</Link>
                  <Link to={`/domains/${website?.domain?.id}`} className="btn btn-sm btn-light"><FaIcon name="eye" /> Domain verwalten</Link>
                  {!website?.domain?.custom_dns_servers && <Link to={`/domains/${website?.domain?.id}/records`} className="btn btn-sm btn-light"><FaIcon name="globe" /> DNS verwalten</Link>}
                </>
              )}
            </div>
          </div>
        </div>
  </>
  )
}

const StatusAlert = ({ website }) => {
  if (website?.domain?.expiring_soon) {
    return <div className="alert alert-warning">Domain läuft am {dayjs(website.domain.registered_until).format('L')} aus und muss vorher verlängert werden.</div>;
  }

  if (!website?.active) {
    return <div className="alert alert-danger">Die Webseite wurde deaktiviert.</div>;
  }

  if (website?.domain?.status == 'deleted')
    return <div className="alert alert-danger">Die Domain wurde gelöscht.</div>;

  if (website?.domain?.status == 'transferred_out')
    return <div className="alert alert-danger">Die Domain wurde zu einem anderen Domain-Anbieter umgezogen</div>;

  if (website.resolve_failures !== null && website.resolve_failures > 0) {
    return <div className="alert alert-warning">Die DNS-Einträge für diese Domain zeigen nicht auf unser Hosting.</div>;
  }

  return null;
}

const StatusDot = ({ status, title }) => {
  // generate a random ID for the tooltip that does not change on re-renders
  const [tooltipId] = useState(`tooltip${Math.floor(Math.random() * 1000000000)}`);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <span id={tooltipId} className={`status-dot status-${status}`} title={title}></span>

      <Tooltip
        isOpen={tooltipOpen}
        target={tooltipId}
        toggle={toggle}
      >
        {title}
      </Tooltip>
    </>
  )
}

const StatusBadge = ({ website }) => {
  let contentIcon = null;
  let statusIcon = <StatusDot status="success" title="aktiv" />;

  if(website?.app?.software == 'wordpress') {
    contentIcon = <Link to={`/wordpress/${website?.app?.id}`} title="WordPress-Verwaltung öffnen"><FaIcon name="wordpress ml-2 " /></Link>;
  }

  if (website?.domain?.expiring_soon) {
    statusIcon = <StatusDot status="warning" title="Domain läuft bald aus" />;
  }

  if(!website?.active) {
    statusIcon = <StatusDot status="danger" title="inaktiv" />;
  }

  if (website?.domain?.status == 'deleted')
    statusIcon = <StatusDot status="danger" title="Domain wurde gelöscht" />;

  if(website?.domain?.status == 'transferred_out')
    statusIcon = <StatusDot status="danger" title="Domain wurde umgezogen" />;

  if (website.resolve_failures !== null && website.resolve_failures > 0) {
    statusIcon = <StatusDot status="warning" title="Domain läuft nicht auf unser Hosting auf" />;
  }

  return (
    <div className="status-icons">
      {statusIcon} {contentIcon}
    </div>
  );
}

const RootWebsite = ({ website, refetch, hasWebhosting, open, onToggle }) => {
  const [hovered, setHovered] = React.useState(false);
  const [hidden, setHidden] = React.useState(true);

  const toggleHandler = (e) => {
    e.preventDefault();

    onToggle(website.id);
  }

  return (
    <>
      <tr className={website.website_root ? "website-parent" : "website-parent website-subdomain" } onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <td>
          <button className="btn btn-hoverable" onClick={toggleHandler}><FaIcon name={open ? "chevron-right fa-fw chevron rotate" : "chevron-right fa-fw chevron"} /></button>
        </td>
        <td>
          {!website.website_root && <span className="subdomain-icon">&#8627;</span>} <a href="#" className="text-muted" onClick={toggleHandler}><strong>{website.hostname}</strong></a>

          {(hovered || open) && <a href={website.url} className="ml-2 text-muted" title="Webseite öffnen" target="_blank" rel="noopener noreferrer"><FaIcon name="external-link" /></a>}
        </td>
        <td><StatusBadge website={website} /></td>
        <td>
          <Dropdown direction="right">
            <Link to={`/websites/${website.id}/edit`} className="dropdown-item"><FaIcon name="pencil" />{' '} Inhalt ändern</Link>
            {website?.app?.software == 'wordpress' && (
              <Link to={`/wordpress/${website?.app?.id}`} className="dropdown-item"><FaIcon name="wordpress" />{' '} WordPress verwalten</Link>
            )}
            {website.deleteable && (
              <ConfirmedDelete
                namespace="usercp"
                resource="websites"
                id={website.id}
                link
                button_class="dropdown-item text-danger"
                button_text={<><FaIcon name="trash" />{' '} löschen</>}
                redirect_back={false}
                onSuccess={() => {
                  toast.success('Webseite gelöscht!');
                  refetch()
                }}
              />
            )}
          </Dropdown>
        </td>
      </tr>

      <CSSTransition
          in={open}
          timeout={400}
          classNames="slide"
          onEnter={() => setHidden(!open)}
          onExited={() => setHidden(!open)}
          unmountOnExit
          appear
      >
        <tr style={{display: hidden ? 'none' : 'table-row'}} className="details-row">
          <td colSpan={4}>
            <div className="website-details-outer">
              <div className="website-details-inner">
                <WebsiteDetails website={website} refetch={refetch} hasWebhosting={hasWebhosting} />
              </div>
            </div>
          </td>
        </tr>
      </CSSTransition>
    </>
  )
}

function reverseHostname(hostname) {
  return hostname.split('.').reverse().join('.');
}

const Index = () => {
  const [filterQuery, setFilterQuery] = React.useState('');

  const [expandedWebsites, setExpandedWebsites] = React.useState({});

  const onToggle = (id) => {
    setExpandedWebsites((prev) => {
      const newExpanded = { ...prev };
      newExpanded[id] = !newExpanded[id];

      return newExpanded;
    });
  }

  const { data, isLoading, isError, error, refetch } = useQuery(['websites'], fetchData, {
    keepPreviousData: true,
  });

  const { data: subscriptionData } = useQuery(['webhosting_status'], fetchSubscriptionData, {
    keepPreviousData: true,
  });

  const isAnyOpen = Object.values(expandedWebsites).some((v) => v);

  const toggleAllHandler = (e) => {
    e.preventDefault();

    if (isAnyOpen) {
      setExpandedWebsites({});
    } else {
      setExpandedWebsites(Object.fromEntries(data.vhosts.map((website) => [website.id, true])));
    }
  }

  const buttons = (
    <>
      <input className="search-query form-control" type="search" placeholder='Schnellsuche' onChange={(e) => setFilterQuery(e.target.value)} />

      <BasicDropdown button={<a href="/domain-check" className="btn btn-light"><FaIcon name="plus" /> Domain registrieren oder umziehen</a>}>
        <Link to="/websites/subdomain/new" className="dropdown-item">Subdomain erstellen</Link>
        <Link to="/websites/external-domain/new" className="dropdown-item">externe Domain aufschalten</Link>
        <Link to="/websites/lima-subdomain/new" className="dropdown-item">kostenlose Subdomain erstellen</Link>
      </BasicDropdown>
    </>
  )

  if (isLoading) {
    return (
      <>
        <PageHeader text="Webseiten" buttons={buttons} />

        <LoadingTableSpinner />
      </>
    )
  }

  if (isError) {
    return (
      <>
        <PageHeader text="Webseiten" buttons={buttons} />

        {errorToComponent(error)}
      </>
    )
  }

  const root_websites = filterData(data.vhosts, filterQuery).sort((a, b) => {
    const reversedA = reverseHostname(a.hostname);
    const reversedB = reverseHostname(b.hostname);

    if (reversedA < reversedB) return -1;
    if (reversedA > reversedB) return 1;
    return 0;
  });

  return (
    <>
      <PageHeader text="Webseiten" buttons={buttons} />

      <table className="table table-wide table-websites">
        <thead>
          <tr>
            <th><button className="btn btn-hoverable" onClick={toggleAllHandler}><FaIcon name={isAnyOpen ? "chevron-right fa-fw chevron rotate" : "chevron-right fa-fw chevron"} /></button></th>
            <th>Webseite</th>
            <th>Status</th>
            <th>Optionen</th>
          </tr>
        </thead>
        <tbody>
          {root_websites.map((website, index) => (
            <RootWebsite key={website.id} website={website} refetch={refetch} index={index} hasWebhosting={subscriptionData?.subscription} open={expandedWebsites[website.id]} onToggle={onToggle} />
          ))}

          {root_websites.length === 0 && (
            <tr>
              <td colSpan="4" className="text-center">Keine Webseiten gefunden</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export { RootWebsite, WebsiteDetails };

export default Index;
