import { Route, Switch } from 'react-router-dom';
import Create from './create';
import Edit from './edit';
import Index from './index';
import Show from './show';

const Router = () => (
    <Switch>
      <Route path="/cronjobs/new" component={Create} />
      <Route path="/cronjobs/:id/edit" component={Edit} />
      <Route path="/cronjobs/:id" component={Show} />
      <Route path="/cronjobs" component={Index} />
    </Switch>);

export default Router;
