import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import Callout from 'client/js/util/callout';
import ExternalLink from 'client/js/util/external_link';
import {PageHeader} from 'client/js/util/layout_utils';
import AddFundsButton from 'client/js/usercp/lib/add_funds_button';

const Footer = function(props) {
  if(props.credits.length) {
    return (
      <tfoot>
        <tr><th colSpan="2">{props.charge_count} Position(en)</th><th>Summe (Leistungen):</th><th>{format_price(props.total_amount)}</th></tr>
        {props.credits.map((credit, index) => {
          return (
            <tr key={index}>
              <td>{credit.text} (Urspungsbetrag: {format_price(credit.original_amount)})</td>
              <td>1</td>
              <td></td>
              <td>{format_price(credit.remaining_amount)}</td>
            </tr>);
        })}
        <tr><th></th><th colSpan="2">Summe (angewendete Gutscheine):</th><th>{format_price(props.applied_credit)}</th></tr>
        <tr><th></th><th colSpan="2">Zahlbetrag:</th><th>{format_price(props.payable)}</th></tr>
      </tfoot>);
  } else {
    return <tfoot>
        <tr><th colSpan="3">Zahlbetrag</th><th>{format_price(props.total_amount)}</th></tr>
      </tfoot>;
  }
}

const PaymentMethod = function(props) {
  if(props.payment_method)
    return <div className="float-right">{props.payment_method.friendly_name}</div>;

  return <div className="float-right">Vorkasse/Guthaben</div>;
}

const BillingHandle = function(props) {
  if(props.billing_handle)
    return <div className="float-right">{props.billing_handle.friendly_name}</div>;

  return <div className="float-right">Keine Rechnungsadresse angegeben</div>;
}

class PreviewContainer extends React.Component {
  state = {summary: true}

  handleCheck = () => {
    this.setState((prevState) => ({summary: !prevState.summary}));
  }

  charges = () => {
    if(this.state.summary)
      return this.props.summarized_charges;
    else
      return this.props.charges;
  }

  render() {
    return <div>
        <form className="form-inline mb-3">
          <div className="custom-control custom-checkbox">
            <input type="checkbox"
                   id="cloud_charges_summarize"
                   className="large_boolean custom-control-input"
                   onChange={this.handleCheck}
                   checked={this.state.summary} />
            <label className="checkbox custom-control-label" htmlFor="cloud_charges_summarize">Zusammenfassung anzeigen</label>
          </div>
        </form>

        <table className="table table-striped table-wide table-charges">
          <thead><tr><th>Position</th><th>Anzahl</th><th>Preis/Einheit</th><th>Summe</th></tr></thead>
            <tbody>
             {this.charges().map((charge, index) => {
                return (
                  <tr key={index}>
                    <td>{charge.description}</td>
                    <td>{number_with_delimiter(charge.quantity)} {charge.unit}</td>
                    <td>{format_price(charge.unit_price, 8)}</td>
                    <td>{format_price(charge.total_amount)}</td>
                  </tr>);
              })}
            </tbody>
            <Footer credits={this.props.credits} charge_count={this.props.charge_count}
                    total_amount={this.props.total_amount} applied_credit={this.props.applied_credit}
                    payable={this.props.payable} />
        </table>
        </div>;
  }
}

class Charges extends React.Component {
  state = {loadingInProgress: false}

  componentDidMount() {
    window.document.title = "Cloud-Abrechnung";
    this.fetchData();
  }

  fetchData = () => {
    this.setState({loadingInProgress: true});

    $.get("/usercp/virtual_machines/charges.json")
      .done((data) => this.setState({charges: data.charges,
                                     summarized_charges: data.summarized_charges,
                                     credits: data.credits,
                                     applied_credit: data.applied_credit,
                                     payable: data.payable,
                                     total_amount: data.total_amount,
                                     charge_count: data.charge_count,
                                     payment_method: data.payment_method,
                                     billing_handle: data.billing_handle,
                                     loadingInProgress: false}))
      .fail(() => this.setState({loadingInProgress: false}));
  }

  render() {
    if(!this.state.charges)
      return (<LoadingTableSpinner />);

    return <div>
        <div className="card mb-3">
          <h5 className="card-header">Abrechnung</h5>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">Zahlungsmethode: <PaymentMethod payment_method={this.state.payment_method}/></li>
            <li className="list-group-item">Rechnungsadresse: <BillingHandle billing_handle={this.state.billing_handle}/></li>
            <li className="list-group-item">Abrechnungsdatum: <div className="float-right">jeweils zum Monatsersten</div></li>
          </ul>
        </div>

        {!this.state.payment_method && <AddFundsButton btnClass="btn-primary btn mb-3" />}

        {this.state.charges.length ?
          <Callout title="Vorschau">
            <p>Dies ist eine Vorschau der Positionen auf der nächsten Rechnung. Änderungen am Abrechnungsmodell behalten wir uns vor. Genaue Informationen zur Abrechnung haben wir <ExternalLink href="https://www.lima-city.de/hilfe/cloud-abrechnung" text="in der Hilfe" /> zusammengestellt.</p>
          </Callout> : null}

        {this.state.charges.length ?
          <PreviewContainer {...this.state} /> : null}
      </div>
  }
}

const ChargesPage = () => (<div>
  <PageHeader text="Abrechnung" />

  <Charges />
</div>)

export default ChargesPage;
