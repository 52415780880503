import { BasicDropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import CopyableField from 'client/js/util/copyable_field';
import { DetailsRow, DetailsSection, FaIcon, PageHeader, SkeletonLoading } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import RevealableField from 'client/js/util/revealable_field';
import { Link } from 'react-router-dom';

const Show = (props) => {
  const id = props.match.params.id;

  const { data, component } = useResource(['ftp_accounts', id], `/usercp/ftp_accounts/${id}`, {
    header: <PageHeader text="FTP-Account ..." back_url="/ftp_accounts">FTP-Account <SkeletonLoading /></PageHeader>,
  });

  if (component) {
    return component;
  }

  const buttons = (
    <BasicDropdown button={
      <Link to={`/ftp_accounts/${id}/password`} className="btn btn-light"><FaIcon name="key" /> Passwort ändern</Link>
      }
    >
      <Link to={`/ftp_accounts/${id}/edit`} className="dropdown-item"><FaIcon name="pencil" /> Verzeichnis bearbeiten</Link>
      <Link to={`/ftp_accounts/${id}/comment`} className="dropdown-item"><FaIcon name="comment" /> Kommentar bearbeiten</Link>

      {data.ftp_account.deleteable && (
        <ConfirmedDelete namespace="usercp" resource="ftp_accounts" id={id} button_text={<><FaIcon name="trash" /> löschen</>}
                            button_class="dropdown-item text-danger" text="Dies wird den FTP-Zugang sofort löschen, der Zugriff ist nicht mehr möglich." />
      )}
    </BasicDropdown>
  );

  const { ftp_account } = data;

  return (
    <>
      <PageHeader text={`FTP-Account ${data?.ftp_account?.username}`} back_url="/ftp_accounts" buttons={buttons} />

      <DetailsSection title="FTP-Zugangsdaten" className="auto-sized">
        <DetailsRow title="Protokoll:">FTPS</DetailsRow>
        <DetailsRow title="FTP-Servername:"><CopyableField value={ftp_account?.host} /></DetailsRow>
        <DetailsRow title="SSL-Zertifikat-Fingerprint(s):">{ftp_account?.ssl_certificate_fingerprints.map(
          (fingerprint, i) => <><code key={i}>{fingerprint}</code><br/></>
        )}</DetailsRow>
        <DetailsRow title="Port-Nummer:">21</DetailsRow>
        <DetailsRow title="Benutzername:"><CopyableField value={ftp_account?.username} /></DetailsRow>

        <DetailsRow title="Passwort:">
          <RevealableField value={ftp_account?.password} />
        </DetailsRow>

        <DetailsRow title="Webspace-Verzeichnis:">{ftp_account?.directory || 'gesamter Webspace'}</DetailsRow>

        <DetailsRow title="Kommentar:">{ftp_account?.comment || <>&mdash;</>}</DetailsRow>
      </DetailsSection>

      <div className="btn-group">

        <form action="https://filemanager.lima-city.de" method="post" target="_blank">
          <input type="hidden" name="ftpserver" value={ftp_account?.host} />
          <input type="hidden" name="ftpserverport" value="21" />
          <input type="hidden" name="username" value={ftp_account?.username} />
          <input type="hidden" name="password" value={ftp_account?.password} />
          <input type="hidden" name="passivemode" value="yes" />
          <input type="hidden" name="state" value="browse" />
          <input type="hidden" name="state2" value="main" />

          <button type="submit" className="btn btn-light" >
            <FaIcon name="sign-in" /> mit diesem Account im Filemanager einloggen
          </button>
        </form>
        <a href={`/usercp/ftp_accounts/${id}/filezilla_config.xml`} className="btn btn-light">
          <FaIcon name="download" />

          FileZilla-Konfigurationsdatei herunterladen
        </a>
      </div>
    </>
  );
}

export default Show;
