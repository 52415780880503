
const SwitchInput = (props) => (
  <label className="input-switch">
    <input type="checkbox" {...props} />
    <span className="slider"></span>
  </label>
)

const FlexMenuItem = (props) => {
  const classes = ['list-group-item d-flex align-items-center']

  if(props.disabled)
    classes.push('disabled');

  if(props.link)
    classes.push('list-group-item-action');

  let title = [props.title];
  if(props.subtitle)
    title.push(<div key="st" className="text-muted">{props.subtitle}</div>);

  if(props.link)
    return <Link to={props.link} className={classes.join(' ')}>
        <div className="mr-auto">{title}</div>
        {props.text || props.children}
      </Link>

  return <div className={classes.join(' ')}>
    <div className="mr-auto">{title}</div>
    {props.text || props.children}
  </div>
}

export {FlexMenuItem, SwitchInput};
