import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BasicDropdown, Dropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent, errorToToast } from 'client/js/util/rest_utils';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CopyableField from 'client/js/util/copyable_field';

const fetchDomainData = async (id) => {
  const response = await $.ajax({url: `/usercp/domains/${id}`, dataType: 'json'});

  return response;
}

const fetchData = async (id) => {
  const response = await $.ajax({ url: `/usercp/domains/${id}/records`, dataType: 'json' });

  return response;
}

const ResetDnsEntriesButton = ({domainId}) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return $.ajax({ url: `/usercp/domains/${domainId}/records/reset_dns`, type: 'POST', dataType: 'json' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', domainId, 'dns_records'] })
      toast.success('DNS-Einträge wurden zurückgesetzt');
      setModalOpen(false);
    },
    onError: (error) => {
      errorToToast(error);
      setModalOpen(false);
    }
  })

  const handleConfirmClick = (e) => {
    e.preventDefault();

    mutate();
  }

  const handleResetClick = () => {
    setModalOpen(true);
  }

  const handleCloseClick = (e) => {
    e.preventDefault();
    setModalOpen(false);
  }

  return (
    <>
      <button onClick={handleResetClick} className="dropdown-item"><FaIcon name="repeat" /> DNS-Einträge zurücksetzen</button>

      <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} className="modal fade in show d-block" >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">DNS-Einträge zurücksetzen</h5>
              <button type="button" onClick={handleCloseClick} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {isLoading ?
              <div className="modal-body"><LoadingTableSpinner /></div> :
              <div className="modal-body">
                <p>
                  Dies wird alle DNS-Einträge auf die Standard-Konfiguration zurücksetzen. Dieser Vorgang kann nicht rückgängig gemacht werden.
                </p>

                <p>
                  <strong>Bitte bestätige, dass Du fortfahren möchtest.</strong>
                </p>
              </div>}

            <div className="modal-footer">
              <button disabled={isLoading} className="btn btn-lg btn-secondary" onClick={handleCloseClick}><FaIcon name="times" loading={isLoading} /> abbrechen</button>
              <button disabled={isLoading} className="btn btn-lg btn-danger" onClick={handleConfirmClick}><FaIcon name="repeat" loading={isLoading} /> zurücksetzen</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const Index = (props) => {
  const domainId = props.match.params.domain_id;
  const [filterQuery, setFilterQuery] = React.useState('');

  const { data: domainData } = useQuery(['domain', domainId], () => fetchDomainData(domainId), {
    keepPreviousData: true
  });

  const { data, isLoading, isError, error, refetch, isPreviousData } = useQuery(['domain', domainId, 'dns_records'], () => fetchData(domainId), {
    keepPreviousData: true
  });

  const buttons = (
    <>
      <input className="search-query form-control" type="search" placeholder='Schnellsuche' onChange={(e) => setFilterQuery(e.target.value)} value={filterQuery} />

      <BasicDropdown direction="right" button={<Link to={`/domains/${domainId}/records/new`} className="btn btn-light"><FaIcon name="plus" /> Eintrag hinzufügen</Link>}>
        <Link to={`/domains/${domainId}/dns_servers`} className="dropdown-item"><FaIcon name="globe" /> DNS-Server ändern</Link>
        <ResetDnsEntriesButton domainId={domainId} />
      </BasicDropdown>
    </>
  );

  let pageTitle = "DNS-Records";

  if(domainData?.domain?.unicode_fqdn)
    pageTitle = `DNS-Records für ${domainData.domain.unicode_fqdn}`;

  if (isLoading) {
    return (
      <>
        <PageHeader back_url={`/domains/${domainId}`} text={pageTitle} buttons={buttons} />

        <LoadingTableSpinner />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PageHeader back_url={`/domains/${domainId}`} text={pageTitle} buttons={buttons} />

        {errorToComponent(error)}
      </>
    );
  }

  const records = filterQuery == '' ? data.records : data.records.filter((item) => (
    [item.name, item.type, item.content].join(' ').toLowerCase().indexOf(filterQuery.toLowerCase()) !== -1
  ));

  return (
    <>
      <PageHeader back_url={`/domains/${domainId}`} text={pageTitle} buttons={buttons} />

      <div className="table-responsive">
        <table className={isPreviousData ? "table table-striped table-wide skeleton-loading" : "table table-striped table-wide"}>
          <thead>
            <tr><th>Name</th><th>Typ</th><th>Inhalt</th><th>Priorität</th><th>TTL</th><th></th></tr>
          </thead>

          <tbody>
            {records.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>
                    <div className="dns-record-content">
                      <CopyableField value={item.content} />
                    </div>
                  </td>
                  <td>{item.priority}</td>
                  <td>{item.ttl}</td>
                  <td>
                    {(item.updateable || item.destroyable) && (
                      <Dropdown>
                        {item.updateable && (
                          <Link to={`/domains/${domainId}/records/${item.id}/edit`} className="dropdown-item"><FaIcon name="pencil" /> bearbeiten</Link>
                        )}
                        {item.destroyable && (
                          <ConfirmedDelete namespace="usercp" resource={`domains/${domainId}/records`} display_resource="dns_record" id={item.id}
                          link button_class="dropdown-item" button_text={<React.Fragment><FaIcon name="trash" /> löschen</React.Fragment>}
                          redirect_back={false} success_cb={refetch} />
                        )}
                      </Dropdown>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Index;
