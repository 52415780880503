import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent } from 'client/js/util/rest_utils';
import { HashRouter, Link, Switch } from 'react-router-dom';

const FolderIcon = (props) => {
  if(props.special && props.special.software == 'wordpress') {
    return <FaIcon name="wordpress fa-fw" />;
  }
  return <FaIcon name="folder-o fa-fw" />;
}

const FolderLink = (props) => {
  if(props.special && props.special.software == 'wordpress') {
    return <span><Link to={`/webspace#${props.id}`}>{props.text}</Link> v{props.special.version} <Link to={`/wordpress/${btoa(props.id)}`}>verwalten</Link></span>
  }

  return <Link to={`/webspace#${props.id}`}>{props.text}</Link>
}

const FileIcon = (props) => {
  if(props.name.endsWith('.php'))
    return <FaIcon name="file-code-o fa-fw" />;

  if(props.name.endsWith('.jpg') || props.name.endsWith('.jpeg') || props.name.endsWith('.gif') || props.name.endsWith('.png'))
    return <FaIcon name="file-image-o fa-fw" />;

  if(props.name.endsWith('.zip') || props.name.endsWith('.rar') || props.name.endsWith('.tar.gz') || props.name.endsWith('.tgz') || props.name.endsWith('.tar.xz'))
    return <FaIcon name="file-archive-o fa-fw" />;

  return <FaIcon name="file-o fa-fw" />;
}

const fetchData = async (url) => {
  const response = await $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
  });

  return response;
}

const FlatListing = (props) => {
  const {data, isLoading, isError, error} = useQuery(['webspace_browser', props.path], () => fetchData(props.url));

  if(isLoading) {
    return <LoadingTableSpinner />;
  }

  if(isError) {
    return errorToComponent(error);
  }

  if(data.length == 0) {
    return <div className="browser">Keine Dateien</div>
  }

  return <table className="table table-striped table-wide">
            <thead><tr><th>Name</th><th>Größe</th><th>Änderungsdatum</th><th></th></tr></thead>
            <tbody>
             {data.map((item) => {
                if(item.children)
                  return <tr key={item.id}><td><FolderIcon {...item}/> <FolderLink {...item} /></td><td>-</td><td>{dayjs(item.last_modified_at).format('l')}</td><td><FaIcon name="trash" /></td></tr>;
                else
                  return <tr key={item.id}><td><FileIcon name={item.text} /> {item.text}</td><td>{item.size && item.size.fileSize()}</td><td>{dayjs(item.last_modified_at).format('l')}</td><td><FaIcon name="trash" /></td></tr>;
              })}
            </tbody>
        </table>
}

const Breadcrumbs = (props) => (
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link to="#">Home</Link></li>

    {props.items.map((item, index) => {
      var long_id = props.items.slice(0, (index+1)).join('/')

      if(props.items.length > (index + 1))
        return <li className="breadcrumb-item" key={long_id}><Link to={`#${long_id}`}>{item}</Link></li>
      else
        return <li className="breadcrumb-item" key={long_id}>{item}</li>
    })}
  </ol>
)

const Browser = (props) => {
  var sanitizedFolder = props.location.hash.replace(/^\#/, '')
  var parts = sanitizedFolder.length > 0 ? sanitizedFolder.split('/') : []

  return (<>
    <PageHeader text="Webspace-Browser" buttons={[<a key="0" href="#" className="btn btn-light"><FaIcon name="upload" /> Dateien hochladen</a>]} />

    <Breadcrumbs items={parts} />

    <FlatListing path={sanitizedFolder} url={`${props.url}?folder=${encodeURIComponent(sanitizedFolder)}`} key_name='contents' />
  </>)
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // do not retry if the error is a client error
        if (error.status >= 400 && error.status < 500) {
          return false;
        }

        return failureCount < 3;
      },
      refetchOnWindowFocus: false,
    },
  }
});

const AdminWebspaceBrowser = ({url}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <Switch>
          <Browser url={url} />
        </Switch>
      </HashRouter>
    </QueryClientProvider>
  );
}

export { AdminWebspaceBrowser };

export default Browser;
