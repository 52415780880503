import { PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {AnimatedTabs} from 'client/js/util/nav_tabs';

function truncate(text, maxLength = 30, omission = '...') {
  if(!text) {
    return '';
  }

  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength - omission.length) + omission;
}

const SoftwareCard = ({software}) => {
  return (
    <div className="card">
      {software.logo_name && <div className="logo-header"><img className="card-img-top" src={software.logo_name} alt={`Logo für ${software.name}`} /></div>}
      <div className="card-body">
        <h5 className="card-title">{software.name}</h5>
        <p className="card-text">{truncate(software.description, 150)}</p>
        <Link to={`/softwares/${software.id}/install`} className="btn btn-sm btn-secondary">Installieren</Link>
      </div>
      <div className="card-footer">
        <div className="d-flex align-items-center justify-content-between">
          <small>Version: {software.version}</small> {software.premium_only && <span className="badge badge-primary premium-only">Premium</span>}
        </div>
      </div>
    </div>
  )
}

const Tab = React.forwardRef(({active, category, changeTab}, ref) => {
  return (
    <li className="nav-item">
      <a ref={ref} className={"nav-link" + (active === category.id ? ' active' : '')} href={"#" + category.id} onClick={(e) => changeTab(e, category.id)}>
        {category.name}
      </a>
    </li>
  );
});

Tab.displayName = 'Tab';

const Index = () => {
  const [category, setCategory] = useState('recommendations');

  const { data, component } = useResource(['softwares'], '/usercp/softwares', {
    keepPreviousData: true
  });

  if(component) {
    return component
  }

  const filter = category === 'recommendations' ? (s) => (s.recommendation) : (s) => (s.category === category);

  let premiumListed = false;

  return (
    <>
      <PageHeader text="Software-Installer" />

      <AnimatedTabs>
        <Tab category={{id: 'recommendations', name: 'Empfehlungen'}} active={category} changeTab={() => setCategory('recommendations')} />

        {data.categories.map((c) => (
          <Tab key={c.id} category={c} active={category} changeTab={() => setCategory(c.id)} />
        ))}
      </AnimatedTabs>

      <div className="card-columns software-installer-card-columns">
        {data.softwares.filter(filter).map((software) => {
          premiumListed ||= software.premium_only;
          return <SoftwareCard key={software.id} software={software} />;
        })}
      </div>

      {premiumListed && <p>
        <span className="badge badge-primary">Premium</span> Softwares mit dieser Markierung sind nur für Kunden mit Webhosting-Paket verfügbar und funktionieren nicht auf dem kostenlosen Webspace.
      </p>}
    </>
  )
}

export default Index;
