import PropTypes from "prop-types";
import { PageHeader } from 'client/js/util/layout_utils';
import { Redirect } from 'react-router-dom';
import { withLoading } from 'client/js/util/rest_utils';
import { GenericReactForm, SelectCollectionInput, SubmitButton, FormActions, TextInput, RadioCollectionInput } from 'client/js/util/form_utils';

const LimitVisibility = (props, context) => {
  if (context.formData && context.formData['type'] == props.visible_value)
    return props.children;

  return null;
}

LimitVisibility.propTypes = {
  visible_value: PropTypes.string.isRequired
};

LimitVisibility.contextTypes = {
  formData: PropTypes.object
};

class EditMailboxAlias extends React.Component {
  state = { updated: false }

  dispatch = (action) => {
    if (action == 'form:success') {
      this.setState({ updated: true })
    }
  }

  render() {
    if (this.state.updated)
      return <Redirect to="/email" />;

    const { data } = this.props;
    // this.props.data.mailbox_alias

    let form_defaults = {type: 'external', email_address: data.mailbox_alias.destinations.join("\n")};
    if(data.mailbox_alias.destinations.length == 1) {
      let dest = data.mailbox_alias.destinations[0];
      let mailbox = data.mailboxes.find((i) => i.label == dest);

      if(mailbox) {
        form_defaults = {
          type: 'internal',
          mailbox: mailbox.id,
          email_address: ''
        }
      }
    }

    return (
      <React.Fragment>
        <PageHeader text="Weiterleitung ändern" back_url="/email" />

        <GenericReactForm namespace="mailbox_alias"
          verb="put"
          dispatch={this.dispatch}
          url={`/usercp/aliases/${data.mailbox_alias.id}.json`}
          defaults={form_defaults}>

          <RadioCollectionInput required name="type"
            label="Ziel"
            options={[['internal', `Postfach`], ['external', 'E-Mail-Adresse(n)']]} />

          <LimitVisibility visible_value='internal'>
            <SelectCollectionInput required name="mailbox" label="Postfach" options={data.mailboxes.map((i) => ([i.id, i.label]))} />
          </LimitVisibility>

          <LimitVisibility visible_value='external'>
            <TextInput required name="email_address" label="E-Mail-Adressen" hint="Empfänger-Adresse(n), eine je Zeile oder getrennt mit Komma, max. 20 E-Mail-Adressen" />
          </LimitVisibility>

          <FormActions>
            <SubmitButton icon="pencil" text="Adresse aktualisieren" />
          </FormActions>
        </GenericReactForm>
      </React.Fragment>
    )
  }
}

EditMailboxAlias.propTypes = {
  data: PropTypes.shape({
    handle: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      street: PropTypes.string,
      house_number: PropTypes.string,
      postal: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      fax: PropTypes.string
    }).isRequired
  }).isRequired
};

const Form = withLoading(EditMailboxAlias);
const Page = (props) => (<Form url={`/usercp/aliases/${props.match.params.id}.json`} />)

Page.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default Page;
