import {GenericReactForm, SubmitButton, StringInput, FormActions} from 'client/js/util/form_utils';
import Callout from 'client/js/util/callout';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

const SuccessMessage = function(props) {
  return <Callout calloutClass="success" title="Cloud-VPS gekündigt">
      <p>Das Cloud-VPS wurde erfolgreich gekündigt und wird zum Kündigungstermin gelöscht.</p>

      {!!props.cancellation_received_date && <p>Die Kündigung wurde von uns am {dayjs(props.cancellation_received_date).format('L LT')} erhalten und wird zum {dayjs(props.cancelled_at).format('L')} aktiv.</p>}
    </Callout>;
}

class CancelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dispatch = this.dispatch.bind(this);
  }

  componentDidMount() {
    this.ajax = $.get(`/usercp/virtual_machines/${this.props.id}/cancel.json`)

    this.ajax.done((data) => {
      this.setState({data: data})
    })
  }

  dispatch(action) {
    switch (action) {
      case "form:success":
      this.setState({redirecting: true})
      break;
    }
  }

  render() {
    if(!this.state.data)
      return <LoadingTableSpinner />;

    if (this.state.data.cancelled_at)
      return <SuccessMessage cancelled_at={this.state.data.cancelled_at} cancellation_received_date={this.state.data.cancellation_received_date} />;

    if(this.state.redirecting)
      return <SuccessMessage />;

    return (
      <GenericReactForm url={`/usercp/virtual_machines/${this.props.id}/cancel`} dispatch={this.dispatch}>
        <Callout title="Kündigungsdatum">
          <p>Das nächstmögliche Kündigungsdatum ist der {dayjs(this.state.data.next_cancellation_date).format('L')}.</p>
        </Callout>

        <StringInput required type="password" label="Passwort" name="password" hint="Bitte gib zur Bestätigung der Kündigung Dein Account-Passwort ein." />

        <FormActions>
          <SubmitButton icon="times-circle" text="Cloud-VPS kündigen" />
        </FormActions>
      </GenericReactForm>
      );
  }
}

export default CancelForm;
