import ExternalLink from 'client/js/util/external_link';
import { BooleanInput, FormActions, GenericReactForm, SubmitButton } from 'client/js/util/form_utils';
import { PageHeader, Progress } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AddFundsButton from '../lib/add_funds_button';
import { PaymentMethodConfig, PaymentMethodSelect } from './payment_lib';

class TrackEvent extends React.Component {
  componentDidMount() {
    _paq.push(['trackEvent', this.props.category, this.props.action, this.props.name]);
  }

  render() {
    return null;
  }
}

const InfoRow = (props) => {
  return <tr><td>{props.title}:</td><td>{props.children}</td></tr>;
}

const StepOne = (props) => {
  let subscription = props.data;

  let tos_label = <span>Ich akzeptiere die <ExternalLink text='AGB' href='/AGB.pdf' /> und die <ExternalLink text='Leistungsbeschreibung für das Webhosting' href='/Leistungsbeschreibung Webhosting.pdf' /></span>;
  let rev_label = <>Ich habe weiterhin zur Kenntnis genommen (PDF, öffnet in neuem Fenster):<br/>
                    {'- '}<ExternalLink text='Widerrufsbelehrung' href='/widerrufsbelehrung.pdf' /><br/>
                    {'- '}<ExternalLink text='Muster-Widerrufsformular' href='/muster_widerruf.pdf' /></>;

  return <>
      <PageHeader text="Webhosting-Paket buchen" back_url="/webhosting" />

      <TrackEvent category='subscription' action='subscription_form' />

      <Progress steps={['Preis und Abrechnungszeitraum', 'Zahlungsmethode']} current={0} />

      <table className="table table-striped">
        <tbody>
          <InfoRow title="Einrichtungsgebühr">{format_fractional_price(subscription.setup_price)} einmalig</InfoRow>
          {subscription.discount ? [
            <InfoRow key={0} title="Neukundenrabatt">{format_fractional_price(subscription.discount_price)} pro Monat für {i18n_c("month", subscription.discount_months)}</InfoRow>,
            <InfoRow key={1} title="danach">{format_fractional_price(subscription.price_per_month)} pro Monat</InfoRow>]
            : <InfoRow title="Preis pro Monat">{format_fractional_price(subscription.price_per_month)} pro Monat</InfoRow>}
          <InfoRow title="Erste Rechnung">{format_fractional_price(subscription.next_amount)} am {dayjs(subscription.first_billing_date).format('L')}</InfoRow>
          <InfoRow title="Abrechnung">{i18n_c("month", subscription.billing_cycle)} im Voraus</InfoRow>
          <InfoRow title="Laufzeit">{i18n_c("month", subscription.contract_period)}</InfoRow>
          {props.inclusive_domains && props.inclusive_tlds.length > 0 ?
          <InfoRow title="Inklusiv-Domains">
            {i18n_c("shared_hosting_offer.inclusive_domains", props.inclusive_domains)} ({props.inclusive_tlds.map((item, index) => (`.${item}`)).join(', ')})
          </InfoRow> : null}
          <InfoRow title="Zahlung je Abrechnungszeitraum">{format_fractional_price(subscription.price_per_billing_cycle)}</InfoRow>
          <InfoRow title="wesentliche Produkteigenschaften">Webhosting-Paket mit {subscription.disk_space_gb} GB Speicherplatz, {subscription.mysql_space_gb} GB MySQL-Speicher und {subscription.email_space_gb} GB E-Mail-Speicher </InfoRow>
          <InfoRow title="Garantierte Verfügbarkeit">99,9%</InfoRow>
          <InfoRow title="Bereitstellung">ab {dayjs(subscription.created_at).format('L')}</InfoRow>
          <InfoRow title="Zahlungsmöglichkeiten">(siehe Abbildung, kann im nächsten Schritt ausgewählt werden)</InfoRow>
          </tbody>
      </table>

      <GenericReactForm namespace="usercp_subscribe_to_premium"
                        url="/usercp/webhosting/subscribe"
                        dispatch={props.dispatch}>
        <BooleanInput required name="tos_accepted" value="1" label={tos_label} />
        <BooleanInput required name="revoc_accepted" value="1" label={rev_label} hint="(Diese Dokumente werden Dir zusätzlich per E-Mail zugesendet)" />

        <FormActions>
          <SubmitButton className="btn btn-primary btn-lg" text="zahlungspflichtig bestellen" />
          <em className="text-muted">Im nächsten Schritt wird die Zahlungsmethode ausgewählt.</em>
        </FormActions>
      </GenericReactForm>
  </>;
}

class SelectPaymentMethod extends React.Component {
  state = {}

  handleClick = (e, payment_method_type) => {
    e.preventDefault();
    this.setState({payment_method_type: payment_method_type});
  }

  handleReturn = (e) => {
    e.preventDefault();
    this.setState({payment_method_type: null});
  }

  render() {
    return <>
      <PageHeader text="Webhosting-Paket buchen" back_url="/webhosting" />

      <TrackEvent category='subscription' action='select_payment_method' />

      <Progress steps={['Preis und Abrechnungszeitraum', 'Zahlungsmethode']} current={1} />

      {this.state.payment_method_type ?
        <PaymentMethodConfig next={this.props.next} payment_method={this.state.payment_method_type} handle_success={this.props.next} handle_return={this.handleReturn} /> :
        <PaymentMethodSelect next={this.props.next} with_subscription handleClick={this.handleClick} />}
    </>;
  }
}

const AddFundsBanner = (props) => {
  if(props.type == 'direct_debit' && props.payment_method && props.payment_method.verification_required)
    return <p className="mb-3">Du hast eine neue Bankverbindung hinzugefügt. Diese muss vor der ersten Abrechnung am {dayjs(props.first_billing_date).format('L')} bestätigt werden.<br/>Bitte gib dazu den Code, den wir Dir per Überweisung mitgeteilt haben, bei uns wieder ein.</p>;

  if(props.type != 'prepaid')
    return null;

  return <form className="mb-3">
        <p>Du hast die Prepaid-Zahlung per Guthaben ausgewählt.<br/>Bitte lade <strong>vor dem {dayjs(props.first_billing_date).format('L')}</strong> einen Betrag von {format_fractional_price(props.next_amount)} auf.</p>

        <AddFundsButton amount={props.next_amount} />
      </form>;
}

const SuccessBanner = (props) => {
  const subscription = props.subscription;

  return <>
      <PageHeader text="Webhosting-Paket gebucht!" back_url="/webhosting" />

      <Progress steps={['Preis und Abrechnungszeitraum', 'Zahlungsmethode']} current={2} />

      <div className="text-center">
        <h4 className="text-success">
          <video className="success-video mb-3" id="video-manager" muted preload autoPlay>
            <source src="/assets/ani/success.mp4" type="video/mp4" />
          </video>

          <br/>
          Vielen Dank für Deine Buchung!
        </h4>

        <p className="mb-3"><strong>Willkommen bei lima-city!</strong><br/>Solltest Du Fragen haben oder Hilfe brauchen, ist unser freundlicher <a href="/usercp/tickets/new">Support</a> gerne für Dich da!</p>

        <AddFundsBanner type={props.payment_method_type}
                        payment_method={props.payment_method}
                        next_amount={subscription.next_amount}
                        first_billing_date={subscription.first_billing_date} />

        <>
          <Link to="/webhosting" className="btn">zurück zum Webhosting-Paket</Link>
        </>
      </div>
    </>
}

SuccessBanner.propTypes = {
  subscription: PropTypes.object.isRequired,
  payment_method_type: PropTypes.string.isRequired,
  payment_method: PropTypes.object
};

class ShowContainer extends React.Component {
  state = {step: 0}

  stepOneDispatch = (action) => {
    if(action == 'form:success') {
      this.setState({step: 1});
      _paq.push(['trackEvent', 'subscription', 'subscribed', this.props.data.offer_name]);
    }
  }

  handleNext = (payment_method_type, payment_method) => {
    this.setState({step: 2, payment_method_type: payment_method_type, payment_method: payment_method})
  }

  render() {
    if(this.state.step == 0)
      return <StepOne data={this.props.data} dispatch={this.stepOneDispatch} />

    if(this.state.step == 1)
      return <SelectPaymentMethod next={this.handleNext} />

    if(this.state.step == 2) {
      return <SuccessBanner subscription={this.props.data}
                            payment_method_type={this.state.payment_method_type}
                            payment_method={this.state.payment_method} />
    }
  }
}

const ShowLoading = withLoading(ShowContainer);
const Subscribe = () => (<ShowLoading url={`/usercp/webhosting/subscribe.json`} key_name="subscription" />);

export default Subscribe;
