import {PageHeader} from 'client/js/util/layout_utils';
import {useResource} from 'client/js/util/rest_utils';
import {PaymentMethodSelect, PaymentMethodConfig} from './payment_lib';
import {toast} from "react-toastify";
import {Redirect} from 'react-router-dom';
import { GenericReactForm, SelectCollectionInput, SubmitButton, FormActions, RadioCollectionInput } from 'client/js/util/form_utils';
import PropTypes from 'prop-types';

class Update extends React.Component<object, {payment_method_type?: string|null, success: boolean}> {
  state = { success: false }

  handleClick = (e: React.MouseEvent<HTMLButtonElement>, payment_method_type: string) => {
    e.preventDefault();
    this.setState({payment_method_type: payment_method_type});
  }

  handleReturn = (e) => {
    e.preventDefault();
    this.setState({payment_method_type: null});
  }

  handlePaymentSuccess = () => {
    toast.success("Zahlungsmethode erfolgreich geändert");
    this.setState({success: true});
  }

  render() {
    if(this.state.success)
      return <Redirect to="/webhosting" />

    return <>
      <PageHeader text="Zahlungsmethode ändern" back_url="/webhosting" />

      {this.state.payment_method_type ?
        <PaymentMethodConfig next={this.handlePaymentSuccess} payment_method={this.state.payment_method_type} handle_return={this.handleReturn} /> :
        <PaymentMethodSelect with_subscription next={this.handlePaymentSuccess} handleClick={this.handleClick} />}
    </>;
  }
}

const PartiallyDisabledInput = ({payment_methods}, {formData}) => {
  return (
    <SelectCollectionInput
      name="payment_method_id"
      label="Zahlungsmethode"
      raw_options={payment_methods.filter((p) => !p.default).map((p) => ({value: p.id, label: p.friendly_name}))}
      clearable
      required={formData['use_default_payment_method'] === "other"}
      disabled={formData['use_default_payment_method'] === "use_default"}
    />
  )
}

interface PaymentMethodType {
  friendly_name: string;
  id: number;
  default: boolean;
}

PartiallyDisabledInput.contextTypes = {
  formData: PropTypes.object
}

const Form = ({payment_methods, subscriptionData}: {payment_methods: Array<PaymentMethodType>, subscriptionData: {payment_method_id: number}}) => {
  const [success, setSuccess] = React.useState(false);

  const onSuccess = () => {
    toast.success("Zahlungsmethode erfolgreich geändert");

    setSuccess(true);
  }

  if(success)
    return <Redirect to="/webhosting" />

  const options: Array<{value: number | null, label: string}> = payment_methods.map((p) => ({value: p.id, label: p.friendly_name}));

  const default_payment_method = payment_methods.find((o) => o.default);

  return (
    <>
      <PageHeader text="Zahlungsmethode ändern" back_url="/webhosting" />

      <GenericReactForm defaults={{payment_method_id: subscriptionData.payment_method_id, use_default_payment_method: (!subscriptionData.payment_method_id ? 'use_default' : 'other')}} verb='POST' url='/usercp/webhosting/payment_method' onSuccess={onSuccess}>
        <RadioCollectionInput
          onChange={(e, formDispatch) => {
            if(e.target.value === "use_default")
              formDispatch('value_changed', e, {name: "payment_method_id", value: null});
          }}
          name="use_default_payment_method"
          options={[["use_default", `Standard-Zahlungsmethode verwenden (${default_payment_method?.friendly_name})`], ["other", "Eigene Zahlungsmethode verwenden"]]}
        />

        <PartiallyDisabledInput payment_methods={payment_methods} />

        <FormActions>
          <SubmitButton text="Zahlungsmethode ändern" icon="floppy-o" />
        </FormActions>
      </GenericReactForm>
    </>
  )
}

const UpdatePaymentMethod = () => {
  const { data, component } = useResource(['payment_methods'], "/usercp/payment_methods", {
    keepPreviousData: true,
    header: <PageHeader text="Zahlungsmethode ändern" back_url="/webhosting" />
  });

  const { component: anotherComponent, data: subscriptionData } = useResource(['webhosting_subscription'], "/usercp/webhosting", {
    keepPreviousData: true,
    header: <PageHeader text="Zahlungsmethode ändern" />
  });

  if(component) {
    return component;
  }

  if(anotherComponent) {
    return anotherComponent;
  }

  if(data?.payment_methods?.length <= 1)
    return <Update />;


  return <Form payment_methods={data.payment_methods} subscriptionData={subscriptionData?.subscription} />;
}

export default UpdatePaymentMethod;
