import PropTypes from 'prop-types';
import Callout from 'client/js/util/callout';
import {withLoading} from 'client/js/util/rest_utils';
import {FaIcon, Card} from 'client/js/util/layout_utils';
import {GenericReactForm, SimpleSubmitButton, IbanInput,
        StringInput, SubmitButton, FormActions} from 'client/js/util/form_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import {CardElement, StripeProvider, injectStripe, Elements} from 'react-stripe-elements';
import {xhrToError} from 'client/js/usercp/lib/payment';
import NewPayPal from 'client/js/payment_methods/new_paypal';

class CreditCardForm extends React.Component {
  state = {intent: null, submitting: false}

  componentDidMount() {
    this.createIntent();
  }

  createIntent = () => {
    $.post('/usercp/credit_cards/intent').then((data) => {
      this.setState({intent: data});
    }).fail((xhr) => {
      if(xhr.status == 429)
        return this.setState({intent: 'create_rate_limited'});

      this.setState({intent: 'create_failed'});
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({submitting: true});

    this.props.stripe.handleCardSetup(
      this.state.intent.secret
    ).then((result) => {
      if(result.error) {
        // TODO: handle error
        this.setState({submitting: false, form_error: result.error.message});
      } else {
        $.post(
          '/usercp/credit_cards', { payment_method_id: result.setupIntent.payment_method }
        ).done((response) => {
          this.props.next('credit_card', response.payment_method);
        }).fail((xhr) => {
          // handle xhr error
          this.setState({submitting: false, form_error: xhrToError(xhr)});
        })
      }
    })
  }

  render() {
    if(!this.state.intent)
      return <LoadingTableSpinner />;

    if(this.state.intent == 'create_failed')
      return <div className="alert alert-danger">Es ist ein Fehler aufgetreten, bitte versuche es erneut.</div>;

    if(this.state.intent == 'create_rate_limited')
      return <div className="alert alert-danger">Du hast zu viele Versuche ausgeführt, Kreditkartendaten einzugeben. Bitte warte einen Moment.</div>;

    return (
        <form id="new_usercp_payment_methods_create_credit_card" className="mb-4" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className={`offset-md-3 col-md-9 mb-2 ${this.state.form_error && 'error'}`}>
              <CardElement className="form-control stripe-credit-card-element"
                           classes={{invalid: 'is-invalid'}} hidePostalCode={true}
                           onReady={(el) => el.focus()} />

              <div className="help-block payment-errors text-danger mb-1">{this.state.form_error}</div>

              <p>
                <small>Kreditkartendaten werden an Stripe, Inc. übertragen und dort PCI-DSS-compliant gespeichert.</small>
              </p>
            </div>
          </div>


          <FormActions>
            <SimpleSubmitButton submitting={this.state.submitting} icon="plus" text="Kreditkarte anlegen" />
          </FormActions>
        </form>
      )
  }
}

const InjectedCreditCardForm = injectStripe(CreditCardForm);

class AddCreditCard extends React.Component {
  state = {stripe: null, submitted: false}

  componentDidMount() {
    if(typeof(window.Stripe) === 'undefined')
      $.getScript('https://js.stripe.com/v3/', () => {
        this.setState({stripe: window.Stripe(window.StripePublishableKey)});
      });
    else
      this.setState({stripe: window.Stripe(window.StripePublishableKey)});
  }

  render() {
    if(this.state.success)
      return <LoadingTableSpinner />;

    return (
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <InjectedCreditCardForm next={this.props.next} />
          </Elements>
        </StripeProvider>
      )
  }
}

AddCreditCard.propTypes = {
  next: PropTypes.func.isRequired
}

class PaymentMethodSelection extends React.Component {
  state = {}

  handleClick = (e, payment_method) => {
    e.preventDefault();
    this.setState({submitting: true});
    $.post('/usercp/webhosting/payment_method', {payment_method_id: payment_method.id}).done((data) => {
      this.setState({success: true, submitting: false})
      this.props.next(this.props.payment_method, this.props.payment_method);
    }).fail(() => {
      this.setState({submitting: false})
    })
  }

  render() {
    if(this.state.success)
      return <LoadingTableSpinner />;

    return <div className="list-group mb-3">
      {this.props.payment_methods.map((c) => (<a key={c.id} className="list-group-item list-group-item-action" href="#" disabled={this.state.submitting} onClick={(e) => this.handleClick(e, c)}>{c.name}</a>))}
    </div>
  }
}

PaymentMethodSelection.propTypes = {
  next: PropTypes.func.isRequired
}

class CreditCardConfig extends React.Component {
  state = {form_open: false}

  handleAdd = (e) => {
    e.preventDefault();
    this.setState({form_open: true});
  }

  render() {
    let credit_cards = this.props.data.payment_methods.filter((i) => (i.type == 'credit_card'))

    if(credit_cards.length == 0)
      return <>
          <h2>Kreditkarte hinzufügen</h2>

          <AddCreditCard next={this.props.next} />
        </>;
    else
      return <>
        <PaymentMethodSelection next={this.props.next} type="credit_card" payment_methods={credit_cards} />

        {this.state.form_open ? <AddCreditCard next={this.props.next} /> : <a href="#" className="btn btn-secondary mb-3" onClick={this.handleAdd}><FaIcon name="plus" /> Kreditkarte hinzufügen</a>}
    </>
  }
}

const CreditCardContainer = withLoading(CreditCardConfig);
const CreditCardWrapper = (props) => (<CreditCardContainer url={"/usercp/payment_methods.json"} next={props.next} />)

const AddDirectDebit = (props) => {
  return <>
      <Callout title="Hinweis" calloutClass="primary">Um von europäischen Bankkonten automatisch per SEPA-Lastschrift zahlen zu können muss die Bankverbindung verifiziert werden. Dazu überweisen wir einen Betrag von 1 Cent auf Dein Konto mit einem Code im Verwendungszweck. Das Konto ist bestätigt, sobald Du diesen Code hier eingibst.</Callout>

      <GenericReactForm defaults={{lang: (navigator.language || navigator.userLanguage), for: 'webhosting_subscription'}}
                        namespace="usercp_payment_methods_create_direct_debit"
                        url="/usercp/payment_methods"
                        dispatch={props.dispatch}>
        <StringInput required name="account_holder" label="Kontoinhaber" autoComplete='name'  />
        <IbanInput required name="iban" label='IBAN' autoComplete='iban' />

        <FormActions>
          <SubmitButton icon="plus" text="SEPA-Bankverbindung anlegen" />
        </FormActions>
      </GenericReactForm>
    </>
}

class DirectDebitConfig extends React.Component {
  state = {form_open: false}

  handleAdd = (e) => {
    e.preventDefault();
    this.setState({form_open: true});
  }

  dispatch = (action, e, data) => {
    if(action == 'form:success') {
      //window.location.href = '/usercp/webhosting';
      this.props.next('direct_debit', data.object);
      this.setState({success: true});
    }
  }

  render() {
    if(this.state.success)
      return <LoadingTableSpinner />;

    let direct_debit_accounts = this.props.data.payment_methods.filter((i) => (i.type == 'direct_debit'))

    if(direct_debit_accounts.length == 0)
      return <>
          <h2>Bankverbindung hinzufügen</h2>

          <AddDirectDebit next={this.props.next} dispatch={this.dispatch} />
        </>;
    else
      return <>
        <PaymentMethodSelection next={this.props.next} type="direct_debit" payment_methods={direct_debit_accounts} />

        {this.state.form_open ? <AddDirectDebit next={this.props.next} dispatch={this.dispatch} /> : <a href="#" className="btn btn-secondary mb-3" onClick={this.handleAdd}><FaIcon name="plus" /> Bankverbindung hinzufügen</a>}
    </>
  }
}

DirectDebitConfig.propTypes = {
  next: PropTypes.func.isRequired
}

const DirectDebitContainer = withLoading(DirectDebitConfig);
const DirectDebitWrapper = (props) => (<DirectDebitContainer next={props.next} url={"/usercp/payment_methods.json"} />)

const PaypalSubscriptionConfig = (props) => {
  return <Card title="PayPal-Abonnement">
    <div className="card-body">
      <p className="payment-text">
        <em>Es handelt sich hierbei um eine automatische Zahlung mit PayPal. Um auf Guthaben-Basis per PayPal zu zahlen benutze bitte "Vorauszahlung/Guthaben" als Zahlungsmethode!</em>
      </p>
      <p className="payment-text">
        Bitte schließe die Einrichtung der automatischen PayPal-Zahlung im Popup ab.
      </p>

      <NewPayPal />
    </div>
  </Card>;
}

const PaypalSubscriptionConfigContainer = withLoading(PaypalSubscriptionConfig);
const PaypalSubscriptionConfigWrapper = () => (<PaypalSubscriptionConfigContainer url={"/usercp/webhosting/payment_method/edit"} />)

class PrepaidBanner extends React.Component {
  state = {}

  handleClick = (e) => {
    e.preventDefault();
    this.setState({submitting: true});

    $.post('/usercp/webhosting/clear_payment_method').done(() => {
      //window.location.href = '/usercp/webhosting'
      this.props.next('prepaid');
      this.setState({submitting: false, success: true});
    }).fail(() => {
      this.setState({submitting: false});
    })
  }

  render() {
    if(this.state.success)
      return <LoadingTableSpinner />;

    return <Card title="Zahlung per Vorkasse / Guthaben-Zahlung">
      <div className="card-body">
        <div className="card-text">
          <p>Wenn keine automatische Zahlungsmethode vorhanden ist kann das Webhosting-Paket per Vorkasse gezahlt werden. Guthaben kann über folgende Zahlungsmethoden eingezahlt werden:</p>
          <ul>
            <li>PayPal</li>
            <li>Bitcoin</li>
            <li>paysafecard</li>
            <li>SOFORT Überweisung</li>
            <li>Kreditkarte</li>
            <li>Banküberweisung</li>
          </ul>

          <button className="btn btn-primary" disabled={!!this.state.submitting} onClick={this.handleClick}>Guthaben-Zahlung aktivieren</button>
        </div>
      </div>
    </Card>;
  }
}

PrepaidBanner.propTypes = {
  next: PropTypes.func.isRequired
}

const InvoiceBanner = () => {
  return <Card title="Zahlung auf Rechnung">
    <div className="card-body">
      <p className="card-text">
        Für Geschäftskunden bieten wir die Zahlung auf Rechnung an. Für eine Freischaltung dieses Kundenkontos für Zahlung auf Rechnung setze Dich bitte mit dem <a href="/usercp/tickets/new?ticket%5Btitle%5D=Zahlung+auf+Rechnung">Support in Verbindung</a>.
      </p>
    </div>
  </Card>;
}

const PaymentMethodConfig = (props) => {
  return <>
    <a href="#" className="btn mb-3" onClick={props.handle_return}><FaIcon name="caret-left" /> zurück zur Auswahl</a>

    {props.payment_method == 'credit_card' && <CreditCardWrapper next={props.next} />}
    {props.payment_method == 'direct_debit' && <DirectDebitWrapper next={props.next} />}
    {props.payment_method == 'paypal_subscription' && <PaypalSubscriptionConfigWrapper next={props.next} />}
    {props.payment_method == 'prepaid' && <PrepaidBanner next={props.next} />}
    {props.payment_method == 'invoice' && <InvoiceBanner next={props.next} />}
  </>;
}

const PaymentMethodSelect = (props) => {
  return <div className="list-group">
      <a className="list-group-item list-group-item-action" href="#" onClick={(e) => props.handleClick(e, 'direct_debit')}>SEPA-Lastschrift</a>
      <a className="list-group-item list-group-item-action" href="#" onClick={(e) => props.handleClick(e, 'credit_card')}>Kreditkarte</a>
      {props.with_subscription && <a className="list-group-item list-group-item-action" href="#" onClick={(e) => props.handleClick(e, 'paypal_subscription')}>PayPal-Abonnement</a>}
      {props.with_subscription && <a className="list-group-item list-group-item-action" href="#" onClick={(e) => props.handleClick(e, 'prepaid')}>Vorkasse/Guthaben</a>}
      {props.with_subscription && <a className="list-group-item list-group-item-action" href="#" onClick={(e) => props.handleClick(e, 'invoice')}>Zahlung auf Rechnung (für Geschäftskunden)</a>}
    </div>
}

PaymentMethodSelect.propTypes = {
  with_subscription: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
};

export {PaymentMethodConfig, PaymentMethodSelect, AddDirectDebit, AddCreditCard}
