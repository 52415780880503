import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import Callout from 'client/js/util/callout';
import { StringInput } from 'client/js/util/form_utils';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';
import { toast } from "react-toastify";

const TaskStatusNotification = (props) => {
  return <div><span>{props.text}</span> <span className="css-spinloader float-right mr-1"></span></div>;
}

const resendActivationEmail = (e) => {
  e.preventDefault();

  const toastId = toast.info(<TaskStatusNotification text="E-Mail wird gesendet..." />, {
    autoClose: false,
    closeButton: false
  });

  $.post(e.target.href).done((data) => {
    if(data.status == 'ok') {
      toast.update(toastId, { closeButton: true, render: "E-Mail wurde gesendet!", type: toast.TYPE.SUCCESS, autoClose: 10000 });
    } else {
      toast.update(toastId, { closeButton: true, render: data.error, type: toast.TYPE.ERROR });
    }
  }).fail(() => {
    toast.update(toastId, { closeButton: true, render: "Fehler beim Senden!", type: toast.TYPE.ERROR });
  })
}

const SuccessCallout = () => (
  <>
    <PageHeader text="E-Mail-Adresse ändern" />

    <Callout calloutClass="success" title="E-Mail-Adresse geändert">
      <p>Die E-Mail-Adresse wurde erfolgreich geändert.</p>

      <p>Wir haben einen E-Mail mit einem Bestätigungslink an die hinterlegte E-Mail-Adresse geschickt. Bitte klicke den Link an, um Deinen Account zu aktivieren.</p>

      <ul className='mb-0'>
        <li>Wenn Du keine E-Mail bekommen hast kannst Du Dir <a href="/usercp/resend-activation-email" onClick={resendActivationEmail}>eine neue E-Mail zusenden</a>.</li>
        <li>Wenn die E-Mail-Adresse nicht korrekt ist, ändere bitte die E-Mail-Adresse in dem folgenden Formular.</li>
      </ul>
    </Callout>
  </>
  );

const Form = (props) => (
  <>
    {!props.confirmed && <div className='alert alert-warning'>
      <h4 className='alert-heading'>Bestätigung erforderlich:</h4>

      <p>Wir haben einen E-Mail mit einem Bestätigungslink an <strong>{props.current_email}</strong> geschickt. Bitte klicke den Link an, um Deinen Account zu aktivieren.</p>
      <ul className='mb-0'>
        <li>Wenn Du keine E-Mail bekommen hast kannst Du Dir <a href="/usercp/resend-activation-email" onClick={resendActivationEmail}>eine neue E-Mail zusenden</a>.</li>
        <li>Wenn "<strong>{props.current_email}</strong>" nicht korrekt ist, ändere bitte die E-Mail-Adresse in dem folgenden Formular.</li>
      </ul>
    </div>}

    <div className='alert alert-info'>
      <FaIcon name="exclamation-circle" />
      {' '}
      Es wird eine E-Mail zur Bestätigung an die aktuelle E-Mail-Adresse (<strong>{props.current_email}</strong>) und die neue E-Mail-Adresse geschickt!
    </div>

    <StringInput required type="password" name="password" autoComplete='current-password' label="aktuelles Passwort" hint="das aktuelle Passwort ist zur Bestätigung notwendig" />
    <StringInput required type="email" name="email" label="neue E-Mail-Adresse" />
  </>
  );


const Page = withLoading((props) => (<GenericInteraction namespace="usercp"
                                             resource_name="change_email"
                                             form_url="/usercp/email-adresse"
                                             back_url="/settings"
                                             action="update"
                                             method="patch"
                                             success_splash={() => (<SuccessCallout />)}
                                             form_component={() => (<Form current_email={props.data.email} confirmed={props.data.confirmed} />)} />));

const PageWrapper = () => (<Page url="/usercp/email-adresse.json" />);

export default PageWrapper;
