import GenericUpdate from 'client/js/resellercp/generic_resource/update';
import Form from './form';

const Page = (props) => {
  const id = props.match.params.id;

  return (
    <GenericUpdate namespace="usercp"
      resource_name="cronjobs"
      transform_data={({ cronjob }) => ({ cronjob_type: cronjob.type, exec_times: cronjob.exec_times, command_or_url: cronjob.url || cronjob.command, description: cronjob.description })}
      id={id}
      form_component={() => <Form id={id} />}
    />
  );
}

export default Page;
