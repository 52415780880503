import PropTypes from 'prop-types';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import { StringInput, RadioCollectionInput } from 'client/js/util/form_utils';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent } from 'client/js/util/rest_utils';

const fetchData = async (id) => {
  const response = await $.ajax({ url: `/usercp/domains/${id}`, dataType: 'json' });

  return response;
}

const Form = (_props, context) => {
  const { formData } = context;

  return (
    <>
      <RadioCollectionInput required name="dns_mode" label="DNS-Modus" options={{ 'auto': 'Nameserver von lima-city verwenden', 'custom': 'Eigene Nameserver angeben'}} />

      <StringInput disabled={formData?.dns_mode == 'auto'} required name="nameserver1" label="Nameserver" />
      <StringInput disabled={formData?.dns_mode == 'auto'} required name="nameserver2" label="Nameserver" />
      <StringInput disabled={formData?.dns_mode == 'auto'} name="nameserver3" label="Nameserver" />
      <StringInput disabled={formData?.dns_mode == 'auto'} name="nameserver4" label="Nameserver" />
      <StringInput disabled={formData?.dns_mode == 'auto'} name="nameserver5" label="Nameserver" />
    </>
  )
}

Form.contextTypes = {
  formData: PropTypes.object,
};

const onSuccess = () => {
  toast.success("DNS-Server gespeichert")
}

const UpdateDnsServers = (props) => {
  const id = props.match.params.domain_id;

  const { data, isLoading, isError, error } = useQuery(['domain', id], () => fetchData(id), {
    keepPreviousData: true
  });

  if(isLoading) {
    return <>
      <PageHeader back_url={`/domains/${id}`} text={i18n_t(`resources.domains.interactions.update_dns_servers`)} />

      <LoadingTableSpinner />
    </>
  }

  if(isError) {
    return <>
      <PageHeader back_url={`/domains/${id}`} text={i18n_t(`resources.domains.interactions.update_dns_servers`)} />

      {errorToComponent(error)}
    </>
  }

  const defaults = {
    dns_mode: data?.domain?.custom_dns_servers ? 'custom' : 'auto',
    nameserver1: data?.domain?.custom_dns_servers ? data?.domain?.dns_servers[0] : null,
    nameserver2: data?.domain?.custom_dns_servers ? data?.domain?.dns_servers[1] : null,
    nameserver3: data?.domain?.custom_dns_servers ? data?.domain?.dns_servers[2] : null,
    nameserver4: data?.domain?.custom_dns_servers ? data?.domain?.dns_servers[3] : null,
    nameserver5: data?.domain?.custom_dns_servers ? data?.domain?.dns_servers[4] : null,
  }

  return (
    <GenericInteraction
      namespace="usercp"
      resource_name="domains"
      defaults={defaults}
      form_namespace="dns_servers"
      action="update_dns_servers"
      form_url={`/usercp/domains/${props.match.params.domain_id}/dns_servers`}
      method="post"
      button_text="DNS-Server ändern"
      icon_name="globe"
      onSuccess={onSuccess}
      id={props.match.params.domain_id}
      form_component={() => (<Form />)}
    />
  )
}

export default UpdateDnsServers;
