import {GenericReactForm, SubmitButton, RadioCollectionInput, FormActions} from 'client/js/util/form_utils';
import Callout from 'client/js/util/callout';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import {VmModelInput} from 'client/js/usercp/cloud/virtual_machines/model';

const SuccessMessage = function(props) {
  return <Callout calloutClass="success" title="Cloud-VPS skalieren">
      <p>Das Cloud-VPS wurde erfolgreich geändert. Bitte stoppe nun das Cloud-VPS und starte es erneut, um die geänderten CPU- und RAM-Ressourcen zu verwenden.</p>
    </Callout>;
}

class ResizeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dispatch = this.dispatch.bind(this);
  }

  componentDidMount() {
    this.ajax = $.get(`/usercp/virtual_machines/${this.props.id}/resize.json`)

    this.ajax.done((data) => {
      this.setState({models: data.models, current_model: data.current_model})
    })
  }

  dispatch(action, original_event, data) {
    console.log(`Got action ${action} with data`, data);

    switch (action) {
      case "form:success":
      this.setState({redirecting: true})
      break;
    }
  }

  render() {
    if(!this.state.models)
      return <LoadingTableSpinner />;

    if(this.state.redirecting)
      return <SuccessMessage />;

    return (<GenericReactForm defaults={{virtual_machine_model_id: this.state.current_model.model_id, resize_disk: '0'}} namespace="usercp_resize_virtual_machine" url={`/usercp/virtual_machines/${this.props.id}/resize`} dispatch={this.dispatch}>
              <Callout calloutClass="primary" title="Herunterfahren erforderlich">
                <p>Um CPU und RAM zum VPS hinzuzufügen ist ein Herunterfahren (Power-Off, kein einfacher Reboot) und erneutes Starten des VPS nötig. Bitte starte das VPs *nach* dem Ausführen des Resize neu.</p>
              </Callout>
              <p>Aktuell: {this.state.current_model.model_name}, {this.state.current_model.vcores} vCores, {this.state.current_model.memory_mb/1024} GB RAM, {this.state.current_model.ssd_storage_gb} GB SSD</p>

        <VmModelInput name="virtual_machine_model_id" currentModel={this.state.current_model.model_id} models={this.state.models} />
        <RadioCollectionInput required name="resize_disk" options={{'0': 'CPU und RAM ändern', '1': 'CPU, RAM und Festplatte vergrößern'}} hint="Wenn die Festplatte vergrößert wird, ist ein Wechsel auf ein kleineres VPS-Modell nicht mehr möglich." />

        <FormActions>
          <SubmitButton icon="edit" text="Cloud-VPS ändern" /> <small className="muted">Dies ist eine zahlungspflichtige Aktion!</small>
        </FormActions>
      </GenericReactForm>);
  }
}

export default ResizeForm;
