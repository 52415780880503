import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import Callout from 'client/js/util/callout';
import { PasswordInput, StringInput } from 'client/js/util/form_utils';
import { PageHeader } from 'client/js/util/layout_utils';

const SuccessCallout = () => (<div>
  <PageHeader text="Passwort ändern"  back_url="/settings" />

    <Callout calloutClass="success" title="Passwort geändert">
      <p>Das Passwort wurde erfolgreich geändert.</p>
    </Callout>
  </div>)

const Form = () => (<div>
    <Callout calloutClass="info" title="Passwort ändern">
      <p>Dieses Passwort gilt nur für den Zugang zur Verwaltung. Datenbanken, FTP-Accounts, E-Mail-Postfächer etc. haben eigene Passwörter!</p>
    </Callout>

    <StringInput required type="password" name="password" autoComplete='current-password' label="bisheriges Passwort" hint="ist zur Bestätigung notwendig" />
    <PasswordInput required type="password" name="new_password" label="neues Passwort" />
  </div>)


const Page = () => (<GenericInteraction namespace="usercp"
                                             resource_name="password"
                                             form_url="/usercp/password"
                                             back_url="/settings"
                                             action="update"
                                             method="put"
                                             success_splash={() => (<SuccessCallout />)}
                                             form_component={() => (<Form />)} />);

export default Page;
