import { Route, Switch } from 'react-router-dom';
import Create from './create';
import Edit from './edit';
import EditComment from './edit_comment';
import EditPassword from './edit_password';
import Index from './index';
import Show from './show';

const Router = () => (
    <Switch>
      <Route path="/ftp_accounts/new" component={Create} />
      <Route path="/ftp_accounts/:id/edit" component={Edit} />
      <Route path="/ftp_accounts/:id/password" component={EditPassword} />
      <Route path="/ftp_accounts/:id/comment" component={EditComment} />
      <Route path="/ftp_accounts/:id" component={Show} />
      <Route path="/ftp_accounts" component={Index} />
    </Switch>);

export default Router;
