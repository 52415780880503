import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { FaIcon } from 'client/js/util/layout_utils';
import { AnimatedTabs, AnimatedNavItem as NavItem } from 'client/js/util/nav_tabs';
import { useState } from 'react';
import { Tooltip } from 'reactstrap';

const Header = () => {
  return (
    <tr>
      <th>Backup-Typ</th>
      <th>Backup-Zeitpunkt</th>
      <th>Restore gestartet</th>
      <th>Status</th>
      <th>Optionen</th>
    </tr>
  )
}

const TooltipButton = ({children, tooltip}) => {
  // generate a random ID for the tooltip that does not change on re-renders
  const [tooltipId] = useState(`tooltip${Math.floor(Math.random() * 1000000000)}`);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div id={tooltipId} className="d-inline-block">
        {children}
      </div>

      <Tooltip
        isOpen={tooltipOpen}
        target={tooltipId}
        toggle={toggle}
      >
        {tooltip}
      </Tooltip>
    </>
  )
}

const Options = ({restore}) => {
  if(!restore.success)
    return null;

  if(restore.target && restore.target.database)
    return `Wiederhergestellt in Datenbank ${restore.target.database}`;

  if(restore.target && restore.target.webspace)
    return 'Wiederhergestellt auf den Webspace';

  if (restore.target && !restore.target.downloadable) {
    return (
      <TooltipButton tooltip="Der Download für diesen Restore ist nicht mehr verfügbar, bitte erstelle ein neuen Download-Restore.">
        <button disabled target="_blank" rel="noopener noreferrer" className="btn btn-light">
          <FaIcon name="download" /> herunterladen ({format_disk_space(restore.target.filesize)})
        </button>
      </TooltipButton>
    )
  }

  return (
    <a href={restore.target?.download_url} target="_blank" rel="noopener noreferrer" className="btn btn-light">
      <FaIcon name="download" /> herunterladen ({format_disk_space(restore.target.filesize)})
    </a>
  );
}

const Restorable = ({restore}) => {
  if(restore.restorable?.webspace)
    return "Webspace-Backup";

  return <>Datenbank {restore.restorable?.database || <>&mdash;</>}</>;
}

const ProgressDisplay = ({ restore }) => {
  const progress = restore?.progress;
  const hasProgress = progress && progress.files_total > 0 && restore.state === 'extracting';

  if (!hasProgress) return null;

  const percentFormatted = Math.round(progress.percent * 100);

  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${percentFormatted}%` }}
        aria-valuenow={percentFormatted}
        aria-valuemin="0"
        aria-valuemax="100">
        {percentFormatted}%
      </div>
    </div>
  );
}

const TableRow = (props) => {
  const restore = props.item;

  return (
    <tr key={restore.id}>
      <td><Restorable restore={restore} /></td>
      <td>{restore.restorable?.finished_at ? dayjs(restore.restorable?.finished_at).format('L LT') : '-'}</td>
      <td>{dayjs(restore.created_at).format('L LT')}</td>
      <td>
        {restore.state != 'success' && restore.state != 'failure' ? <div className="css-spinloader inline"></div> : null}

        {i18n_t(`resources.restores.state.${restore.state}`)}
        <ProgressDisplay restore={restore} />
      </td>
      <td><Options restore={restore} /></td>
    </tr>
  );
}

const tabs = (
    <AnimatedTabs>
      <NavItem to="/webspace_backups">Webspace-Backups</NavItem>
      <NavItem to="/database_backups">Datenbank-Backups</NavItem>
      <NavItem to="/restores">Restores</NavItem>
    </AnimatedTabs>
  )

const finalStates = ['success', 'failure'];

const Index = () => (<GenericIndex namespace="usercp"
  resource_name="restores"
  tabs={tabs}
  refetchInterval={(data) => data?.restores?.some((restore) => !finalStates.includes(restore.state)) ? 1000 : false}
  table_header={Header}
  row={TableRow}
  no_create />);

export default Index;
