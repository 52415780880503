import {GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';
import { PasswordInput } from 'client/js/util/form_utils';
import Callout from 'client/js/util/callout';
import {FaIcon} from 'client/js/util/layout_utils';

const SuccessMessage = function(props) {
  return <Callout calloutClass="success" title="root-Passwort geändert">
      <p>Das root-Passwort für das System wurde erfolgreich geändert!</p>
    </Callout>;
}

class CancelForm extends React.Component {
  state = {}

  dispatch = (action, original_event, data) => {
    switch (action) {
      case "form:success":
      this.setState({redirecting: true})
      break;
    }
  }

  render() {
    if(this.state.redirecting)
      return <SuccessMessage />;

    return (
      <GenericReactForm url={`/usercp/virtual_machines/${this.props.match.params.id}/root_password`} dispatch={this.dispatch}>
        <Callout>
          <p>Das root-Passwort für die virtuelle Maschine kann hier geändert werden. Es wird dabei der qemu-guest-agent verwendet. Die Änderung funktioniert nicht, wenn der qemu-guest-agent nicht installiert oder nicht gestartet ist.</p>

          <p>Bitte beachte, dass der Login als root per SSH auf dem System deaktiviert sein kann, je nach den individuellen Einstellungen auf dem System.</p>
        </Callout>

        <PasswordInput required type="password" label="neues Passwort" name="password" hint="Das neue root-Passwort für die virtuelle Maschine." />

        <FormActions>
          <SubmitButton icon="key" text="root-Passwort ändern" />
        </FormActions>
      </GenericReactForm>
      );
  }
}

export default CancelForm;
