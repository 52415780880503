import {Route, Switch} from 'react-router-dom';
import Index from './index';
import Show from './show';
import {ImportUpload, ImportRun} from './import';

const ResourceRouter = () => (
    <Switch>
      <Route path="/wordpress/import/:id" component={ImportRun} />
      <Route path="/wordpress/import" component={ImportUpload} />
      <Route path="/wordpress/:id" component={Show} />
      <Route path="/wordpress" exact component={Index} />
    </Switch>);

export default ResourceRouter;
