import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { FaIcon } from 'client/js/util/layout_utils';

const Body = (props) => {
  if( props.state == 'done' )
    return <div className="text-center">
      <h4 className="text-success">
        <FaIcon name="thumbs-o-up fa-3x mb-3" />
        <br />
        Zahlungsmethode erstellt!
      </h4>

      <p className="mb-3">
        Das PayPal Billing Agreement wurde erfolgreich erstellt und kann von uns belastet werden.
      </p>
    </div>

  if (props.state == 'error')
    return <div className="text-center">
      <h4 className="text-danger">
        <FaIcon name="times-circle-o fa-3x mb-3" />
        <br />
        Fehler
      </h4>

      <p className="mb-3">
        Das Erstellen des PayPal Billing Agreement ist ein Fehler aufgetreten. Bitte versuche es erneut.
      </p>
    </div>

  if (props.state == 'cancel')
    return <div className="text-center">
      <h4 className="text-danger">
        <FaIcon name="times-circle-o fa-3x mb-3" />
        <br />
        Abgebrochen
      </h4>

      <p className="mb-3">
        Das Erstellen des PayPal Billing Agreement wurde abgebrochen. Bitte starte den Vorgang ggf. erneut.
      </p>
    </div>

  if (props.state == 'blocked')
    return <div className="text-center">
      <h4 className="text-danger">
        <FaIcon name="times-circle-o fa-3x mb-3" />
        <br />
        Blockiert
      </h4>

      <p className="mb-3">
        Das Popup von PayPal wurde vom Browser blockiert. Bitte öffne das Popup über folgenden Link:

        <br /><br />

        <button className="btn btn-primary" onClick={(e) => { e.preventDefault() ; props.openPopup()}}>
          <FaIcon name="external-link" /> Popup öffnen
        </button>
      </p>
    </div>

  if (props.state == 'closed')
    return <div className="text-center">
      <h4 className="text-danger">
        <FaIcon name="times-circle-o fa-3x mb-3" />
        <br />
        Geschlossen
      </h4>

      <p className="mb-3">
        Das Popup von PayPal wurde geschlossen. Bitte öffne das Popup erneut über folgenden Link:

        <br /><br />

        <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); props.openPopup() }}>
          <FaIcon name="external-link" /> Popup öffnen
        </button>
      </p>
    </div>

  return <div className="css-spinloader"></div>;
}

class PaypalLink extends React.Component {
  state = {popupState: null}

  componentDidMount() {
    this.openPopup();
  }

  createBillingAgreement() {
    $.post('/usercp/payment_methods/paypal_ba_confirm', {token: this.props.token}).done(() => {
      this.setState({ popupState: 'done' });
    }).fail(() => {
      this.setState({ popupState: 'error' });
    });
  }

  openPopup = () => {
    const popup = open(
      this.props.url,
      'paypal_ec_popout',
      "scrollbars=yes,resizable=yes,status=no,location=yes,toolbar=no,menubar=no,width=600,height=600"
    );

    if (!popup || popup.closed)
      return this.setState({ popupState: 'blocked' });

    this.setState({ popupState: 'open', popup: popup });

    const timer = window.setInterval(
      () => {
        if (popup.closed) {
          this.setState({ popupState: 'closed' })
          clearInterval(this.state.timer);
        } else {
          try {
            const url = popup.location.href;
            if (url.indexOf('paypal_ba_landing') !== -1 && url.indexOf('success=true') !== -1) {
              this.setState({ popupState: 'loading' });
              this.createBillingAgreement();
              clearInterval(this.state.timer);
              popup.close();
            } else if (url.indexOf('paypal_ba_landing') !== -1 && url.indexOf('cancel=true') !== -1) {
              this.setState({ popupState: 'cancelled' });
              clearInterval(this.state.timer);
              popup.close();
            }
          } catch (e) {
            // it's okay if we dont have access...
          }
        }
      },
      100
    )
    this.setState({ timer: timer });
  }

  render() {
    return <Body state={this.state.popupState} openPopup={this.openPopup} />;
  }
}

class Page extends React.Component {
  state = {}

  componentDidMount() {
    $.post('/usercp/payment_methods/paypal_ba_ec_token').done((data) => {
      this.setState({token: data.token, paypal_url: data.url});
    });
  }

  render() {
    if(this.state.paypal_url)
      return <PaypalLink url={this.state.paypal_url} token={this.state.token} />;

    return <LoadingTableSpinner text="Lade PayPal-Link..." />;
  }
}

const Popup = () => (<div className="modal d-block" style={{ position: "relative" }} tabIndex="-1" role="dialog">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">PayPal Billing Agreement erstellen</h5>
      </div>
      <div className="modal-body">
        <Page />
      </div>
    </div>
  </div>
</div>)

export {Popup};
export default Page;
