import PropTypes from "prop-types";
import {ControlledErrors} from "client/js/util/form_tags";
import {ControlGroup} from "client/js/util/form_utils";
import {FaIcon} from 'client/js/util/layout_utils';
import {withLoading} from 'client/js/util/rest_utils';
import Select from 'react-select';

class Input extends React.Component {
  state = { new_priv: [], new_db: null}

  handleAdd = (e) => {
    e.preventDefault();

    if(!this.state.new_db || !this.state.new_priv.length)
      return;

    let grants = [ ...this.grants(), {database: this.state.new_db, privileges: this.state.new_priv.map((item) => (item.id))}];
    this.context.formDispatch('value_changed', e, {name: this.props.name, value: grants, label: null});
    this.setState({new_db: null, new_priv: []});
  }

  handleDbChange = (db) => {
    if(db && db.id)
      this.setState({new_db: db});
    else
      this.setState({new_db: null});
  }

  handlePrivilegesChange = (privilege) => {
    if(privilege && privilege.length)
      this.setState({new_priv: privilege});
    else
      this.setState({new_priv: []});
  }

  grants = () => {
    let grants = [];
    if(this.context.formData && this.context.formData['grants'])
      grants = this.context.formData['grants'];

    return grants;
  }

  removeGrants = (e, db_name) => {
    e.preventDefault();
    const grants = this.grants();

    const new_grants = grants.filter((item) => (item.database.name != db_name));
    this.context.formDispatch('value_changed', e, {name: this.props.name, value: new_grants, label: null});
  }

  render() {
    const n = [this.context.namespace, this.props.name].join('_');
    const grants = this.grants();

    const used_dbs = grants.map((i) => (i.database.name));
    const databases = this.props.data.map((item) => ({id: item.id, name: item.name})).filter((db) => (!used_dbs.includes(db.name)));

    const privileges = [{label: 'Select', id: 'Select'},
                        {label: 'Insert', id: 'Insert'},
                        {label: 'Update', id: 'Update'},
                        {label: 'Delete', id: 'Delete'},
                        {label: 'Create', id: 'Create'},
                        {label: 'Drop', id: 'Drop'},
                        {label: 'Grant', id: 'Grant'},
                        {label: 'References', id: 'References'},
                        {label: 'Index', id: 'Index'},
                        {label: 'Alter', id: 'Alter'},
                        {label: 'Lock_tables', id: 'Lock_tables'},
                        {label: 'Create_view', id: 'Create_view'},
                        {label: 'Show_view', id: 'Show_view'},
                        {label: 'Create_tmp_table', id: 'Create_tmp_table'},
                        {label: 'Event', id: 'Event'},
                        {label: 'Trigger', id: 'Trigger'},
                        {label: 'Create_routine', id: 'Create_routine'},
                        {label: 'Alter_routine', id: 'Alter_routine'},
                        {label: 'Execute', id: 'Execute'}];

    return <ControlGroup name={n} labelFor={n} required label={this.props.label} hint={this.props.hint}>
              <table className="table table-striped mb-3">
                <thead>
                  <tr><th>Datenbank</th><th>Rechte</th><th></th></tr>
                </thead>
                <tbody>
                 {grants.map((grant, index) => {
                    return <tr key={index}>
                            <td>{grant.database.name}</td>
                            <td>{grant.privileges && grant.privileges.length ? grant.privileges.join(', ') : '-'}</td>
                            <td><a href='#' className="float-right" onClick={(e) => this.removeGrants(e, grant.database.name)} title="löschen"><FaIcon name="trash" /></a></td>
                           </tr>;
                  })}
                </tbody>
              </table>

              <div className="row">
                <div className="col-md-4">
                  <Select name="new_db"
                      placeholder="Datenbank auswählen"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={this.state.new_db}
                      clearable={false}
                      options={databases}
                      onChange={this.handleDbChange} />
                </div>
                <div className="col-md-7">
                  <Select name="new_db"
                      placeholder="Rechte auswählen"
                      getOptionValue={(option) => option.id}
                      value={this.state.new_priv}
                      isMulti
                      closeMenuOnSelect={false}
                      options={privileges}
                      onChange={this.handlePrivilegesChange} />
                </div>
                <div className="col-md-1">
                  <button className="btn btn-secondary" onClick={this.handleAdd} title="hinzufügen"><FaIcon name="plus" /></button>
                </div>
              </div>
            <ControlledErrors names={[this.props.name]} />
           </ControlGroup>;
  }
}

Input.contextTypes = {
  formData: PropTypes.object,
  formDispatch: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

const LoadingInput = withLoading(Input);
const DatabaseLoadingInput = (props) => (<LoadingInput url="/usercp/databases.json" key_name="databases" {...props} />)

const DatabaseGrantsInput = (props, context) => {
  if(context.formData && (context.formData['simple_access'] == 'complex' || context.formData['access'] == 'complex'))
    return <DatabaseLoadingInput {...props} />;

  return null;
}

DatabaseGrantsInput.contextTypes = {
  formData: PropTypes.object
};

export default DatabaseGrantsInput;
