import PropTypes from "prop-types";
import {ErrorCallout} from 'client/js/util/form_utils';

const VirtualMachineModel = function(props) {
  const n = `usercp_create_vm_virtual_machine_model_id_${props.id}`;

  const selectModel = (e) => {
    props.dispatch('model_select', e, {id: props.id, disk_size: props.ssd_gb});
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div key={props.id} className={props.id == props.selectedModel ? "vm-model selected" : "vm-model"} onClick={selectModel}>
      <input
        onChange={selectModel}
        checked={props.id == props.selectedModel}
        className="css-checkbox"
        id={n}
        name={`${props.namespace}[${props.name}]`}
        type="radio"
        required={true}
        value={props.id} />

      <label className="css-label" htmlFor={n}>
        <h4>{props.title}</h4>
        {props.vcores == 1 ? `${props.vcores} vCore` : `${props.vcores} vCores` }
        <br />
        {props.memory_gb} GB RAM
        <br />
        {props.ssd_gb} GB SSD
        <br />
        {((props.traffic_gb || 0)*1024*1024*1024).fileSize().replace(',00', '')} Traffic/Monat*
        {props.currentHighlight ? (
          <>
            <span className="badge badge-primary">Aktuelle Auswahl</span>
            <br/>
          </>
         ) : (
          <>
            <br />
            <br />
          </>
        )}
        {format_price(props.price_per_hour, 6)}/Stunde
        <br />
        <small>maximal {format_price(props.price_per_month)}/Monat</small>
      </label>
    </div>
  );
}

var VmModelInput = function(props, context) {
  const errors = context.serverErrors[props.name];

  return <div className="virtual-machine-models">
          {props.models.map((model) => {
              return <VirtualMachineModel key={model.id} id={model.id}
                        selectedModel={context.formData[props.name]}
                        currentHighlight={props.currentModel == model.id}
                        namespace={context.namespace} name={props.name}
                        title={model.title}
                        vcores={model.vcores}
                        memory_gb={model.memory_mb / 1024}
                        ssd_gb={model.ssd_storage_gb}
                        traffic_gb={model.traffic_per_month_gb}
                        price_per_hour={model.price_per_hour_in_cents}
                        price_per_month={model.price_per_month_in_cents}
                        dispatch={(action, e) => context.formDispatch('value_changed', e, {name: props.name, value: model.id})} />
            })}
          <ErrorCallout errors={errors} />
        </div>;
}

VmModelInput.propTypes = {
  name: PropTypes.string.isRequired,
}

VmModelInput.contextTypes = {
  serverErrors: PropTypes.object,
  formData: PropTypes.object,
  namespace: PropTypes.string.isRequired,
  formDispatch: PropTypes.func.isRequired
};

export {VirtualMachineModel, VmModelInput};
export default VirtualMachineModel;
