import {withLoading} from 'client/js/util/rest_utils';
import Redirect from 'client/js/util/hard_redirect';
import {GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';
import DomainPrefixInput from "client/js/util/inputs/domain_prefix_input";
import WebsiteContentSection from './website_content_section';
import {PageHeader} from 'client/js/util/layout_utils';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action, original_event, data) {
    if(action == 'form:success')
      this.setState({newRecord: data.object});

    if(action == 'form:change' && data.changedField == 'content_strategy')
      this.setState({redirect_to_installer: data.formData['content_strategy'] == 'install'});
  }

  render() {
    let default_domain = null;
    let query = parse_query_string(location.search);

    if(this.props.data.domains && this.props.data.domains.length)
      default_domain = this.props.data.domains[0][0];

    if(query['usercp_create_subdomain_form[domain]'])
      default_domain = query['usercp_create_subdomain_form[domain]'];

    if(this.state.newRecord)
      return <Redirect to={this.state.redirect_to_installer ? '/softwares' : '/websites'} />;

    return <>
      <PageHeader text="Subdomain erstellen" back_url="/websites" />

      <GenericReactForm namespace="usercp_create_subdomain_form"
                        defaults={{content_strategy: "same_path", domain: default_domain}}
                        url="/usercp/websites/subdomain"
                        dispatch={this.dispatch}>
        <DomainPrefixInput prefix_name="subdomain" hint="Als Wildcard kann bei der Subdomain der Stern (*) verwendet werden" domains={this.props.data.domains} />

        <WebsiteContentSection name="name" label="Webseiten-Inhalt" installerRedirect required />

        <FormActions>
          <SubmitButton icon="plus" text="Subdomain erstellen" />
        </FormActions>
      </GenericReactForm>
      </>;
  }
}

const FormContainer = withLoading(Form);

const Page = () => (<FormContainer url={`/usercp/websites/subdomain/new`} />);

export default Page;
