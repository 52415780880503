
import PropTypes from "prop-types";
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PageHeader } from 'client/js/util/layout_utils';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import { ControlGroup, RadioCollectionInput } from 'client/js/util/form_utils';
import { ControlledErrors } from 'client/js/util/form_tags';
import { FaIcon } from 'client/js/util/layout_utils';

const ItemInfo = (props) => {
  if (props.item.special && props.item.special.type == 'app' && props.item.special.software == 'wordpress')
    return <span>{props.item.text} (WordPress v{props.item.special.version})</span>;

  return <span>{props.item.text}</span>;
}

const FolderIcon = (props) => {
  if (props.item.special && props.item.special.type == 'app' && props.item.special.software == 'wordpress')
    return <FaIcon name='wordpress fa-fw text-left' />

  if (props.open)
    return <FaIcon name="folder-open-o fa-fw text-left" />;

  return <FaIcon name="folder-o fa-fw text-left" />;
}

FolderIcon.propTypes = {
  open: PropTypes.bool.isRequired
}

FolderIcon.defaultProps = {
  open: false
}

const Entry = (props) => {
  const [open, setOpen] = React.useState(false);

  const item = props.item;
  const full_name = props.current_directory ? props.current_directory.concat('/').concat(item.text) : item.text;

  // generate a unique id for the item by hashing the full name
  const id = `node-${full_name.split('').reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a; }, 0)}`;
  const isChecked = props.checkedItems.includes(full_name);

  if (item.type == 'directory')
    return <li>
      <a href="#" className="toggle-handle" onClick={() => setOpen(!open)}>
        {open ? <FaIcon name="chevron-right fa-fw text-left chevron-90 rotate" /> : <FaIcon name="chevron-right chevron-90 fa-fw text-left" />}
      </a>
      <input type="checkbox" className="mr-2" id={id} disabled={props.parentOpen} checked={isChecked || props.parentOpen} onChange={(e) => props.checkItem(full_name, e.target.checked)} />

      <label className="toggle-handle" htmlFor={id} style={{cursor: 'pointer'}}>
        <FolderIcon open={open} item={item} /> <ItemInfo item={item} />
      </label>

      {open && <DirectoryList parentOpen={isChecked || props.parentOpen} checkItem={props.checkItem} checkedItems={props.checkedItems} backup_id={props.backup_id} current_directory={full_name} />}
    </li>;
  else
    return (
      <li className="node-file">
        <input type="checkbox" className="mr-2" id={id} disabled={props.parentOpen} checked={isChecked || props.parentOpen} onChange={(e) => props.checkItem(full_name, e.target.checked)} />

        <FaIcon name="file-o fa-fw" />

        <label className="toggle-handle" htmlFor={id} style={{cursor: 'pointer'}}>
          {item.text} ({format_disk_space(item.size)})
        </label>
      </li>
    );
}

Entry.propTypes = {
  current_directory: PropTypes.string,
  checkedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkItem: PropTypes.func.isRequired,
  item: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['directory', 'file']).isRequired,
  })
}

const fetchBackupContent = async (backup_id, directory) => {
  const response = await $.ajax({
    url: `/usercp/webspace_backups/${backup_id}/content.json`,
    data: { folder: directory },
    dataType: 'json',
  });

  return response;
}

const DirectoryList = (props) => {
  const folder = props.current_directory;
  const id = props.backup_id;

  const { data, isLoading, isError } = useQuery(['webspace_backup', 'content', id, folder], () => fetchBackupContent(id, folder));

  let items = data;

  if(isLoading) {
    return <div className="pl-4 my-2 mx-1"><div className="css-spinloader"></div></div>;
  }

  if(isError)
    return null;

  return <ul className="list-unstyled pl-4 my-1">
    {items.map((item) => (<Entry parentOpen={props.parentOpen} checkItem={props.checkItem} checkedItems={props.checkedItems} key={item.text} backup_id={id} item={item} selected_item={props.selected_item} select_item={props.select_item} current_directory={props.current_directory} />))}
  </ul>;
}

DirectoryList.defaultProps = {
  current_directory: ""
}

DirectoryList.propTypes = {
  item: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['directory', 'file']),
  })).isRequired
}

class WebspacePathInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { directory: "", checkedItems: [] };
  }

  checkItem = (item, checked) => {
    let nextCheckedItems = null;
    if (checked) {
      // we need to iterate over the list and remove all items that are children of the current item
      nextCheckedItems = this.state.checkedItems.filter((i) => !i.startsWith(item + '/'));
      nextCheckedItems = [...nextCheckedItems, item];
    } else {
      nextCheckedItems = this.state.checkedItems.filter((i) => i !== item);
    }

    this.setState({ checkedItems: nextCheckedItems});
    this.context.formDispatch('value_changed', null, { name: this.props.name, value: nextCheckedItems });
  }

  render() {
    const n = [this.context.namespace, this.props.name].filter((n) => (n != undefined)).join('_');

    return <ControlGroup name={n} labelFor={n} required label={this.props.label}>
      <p>Bitte wähle die wiederherzustellenden Dateien und Verzeichnisse:</p>

      <div className="file-browser mb-3">
        <DirectoryList checkedItems={this.state.checkedItems} checkItem={this.checkItem} backup_id={this.props.backup_id} />
      </div>
      <ControlledErrors names={[this.props.name]} />

      {this.context.formData && this.context.formData[this.props.name] && <p>Ausgewählt: {this.context.formData[this.props.name].join(', ')}</p>}
    </ControlGroup>;
  }
}

WebspacePathInput.contextTypes = {
  namespace: PropTypes.string,
  formData: PropTypes.object,
  formDispatch: PropTypes.func
};

const Form = ({backup_id}) => {
  return (
    <>
      <RadioCollectionInput required name="target" label="Restore-Methode" options={{ webspace: 'auf den Webspace (überschreibt bestehende Daten!)', download: 'eine ZIP-Datei zum Download' }} />
      <WebspacePathInput backup_id={backup_id} name="path_list" label="Inhalte" />
    </>
    );
}

const Show = (props) => {
  const { id } = props.match.params;

  return (
    <GenericInteraction
      namespace="usercp"
      resource_name="restore"
      form_url={`/usercp/webspace_backups/${id}/restore.json`}
      method="post"
      action="create"
      redirectAfter="url"
      redirect_url={(restore) => `/restores/${restore.restore_id}`}
      back_url={false}
      form_component={() => (<Form backup_id={id} />)}
    />
  );

}

export default Show;
