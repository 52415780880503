import GenericUpdate from 'client/js/resellercp/generic_resource/update';
import { CheckboxCollectionInput, StringInput } from 'client/js/util/form_utils';
import { useResource } from 'client/js/util/rest_utils';

const UpdateForm = () => {
  const { data, component } = useResource(['roles'], '/usercp/api_keys/roles.json');

  if (component)
    return component;

  return (
    <>
      <StringInput name="name" label="Name (optional)" />
      <CheckboxCollectionInput name="roles" label="Rollen" required options={data.roles.map((item) => [item.name, item.name])} />
    </>
  );
};

const Page = (props) => (
  <GenericUpdate
    namespace="usercp"
    resource_name="api_keys"
    id={props.match.params.id}
    transform_data={(data) => ({ name: data.api_key.name, roles: data.api_key.roles })}
    form_component={() => <UpdateForm />}
  />
);

export default Page;
