import {Route, Switch} from 'react-router-dom';
import New from './new';
import Show from './show';
import Index from './index';
import Update from './update';
import PaypalConfirm from './paypal_confirm';
import PaypalCancel from './paypal_cancel';

const Browser = () => {
  return <Switch>
           <Route path="/payment_methods/paypal_ba_confirm" component={PaypalConfirm} />
           <Route path="/payment_methods/paypal_ba_cancel" component={PaypalCancel} />
           <Route path="/payment_methods/new" component={New} />
           <Route path="/payment_methods/:id/edit" component={Update} />
           <Route path="/payment_methods/:id" component={Show} />
           <Route path="/payment_methods" component={Index} />
         </Switch>;
}

export default Browser;
