import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import React from 'react';
import { Link } from 'react-router-dom';
import { AcceptTos } from '../commissions/index';
import {AnimatedTabs, AnimatedNavItem as NavItem} from 'client/js/util/nav_tabs';

const Index = () => {
  const { data, component } = useResource(['affiliate_payouts'], '/usercp/affiliate_payouts', {
    keepPreviousData: true,
    header: <PageHeader text="Kunden werben" />
  });

  if(component) {
    return component;
  }

  if(!data.tos_accepted) {
    return <AcceptTos />;
  }

  return (
    <>
      <PageHeader text="Kunden werben" buttons={<Link to="/affiliate_payouts/new" className="btn btn-light" disabled={data.available_balance < 10}><FaIcon name="money" /> Auszahlung erstellen</Link>} />

      <AnimatedTabs>
        <NavItem to="/commissions" exact>Übersicht</NavItem>
        <NavItem to="/commissions/media">Werbemittel</NavItem>
        {data.has_promotions && <NavItem to="/commissions/promotions">Promotions</NavItem>}
        <NavItem to="/affiliate_payouts">Auszahlungen</NavItem>
      </AnimatedTabs>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Code</th>
            <th>Betrag</th>
          </tr>
        </thead>
        <tbody>
          {data.payouts.map((payout, i) => (
            <tr key={i}>
              <td>{dayjs(payout.created_at).format('L LT')}</td>
              <td>{payout.code}</td>
              <td>{format_fractional_price(payout.amount)}</td>
            </tr>
          ))}

          {data.payouts.length === 0 && (
            <tr>
              <td colSpan="3" className="text-center">Keine Auszahlungen vorhanden.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default Index;
