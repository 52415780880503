import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

const AnimatedNavItem = React.forwardRef(({ children, to, exact }, ref) => {
  return (
    <NavLink className="nav-link" to={to} exact={exact} ref={ref}>
      {children}
    </NavLink>
  );
});

AnimatedNavItem.displayName = 'AnimatedNavItem';

const AnimatedTabs = ({ children }) => {
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabsRef = useRef([]); // Refs for the <li> elements
  const childrenRef = useRef([]); // Refs for the child components (NavLink, buttons, etc.)

  const updateIndicator = () => {
    let activeIndex = 0;

    // Iterate over child refs to find the active one using refs
    childrenRef.current.forEach((child, index) => {
      if (child && child.classList.contains('active')) {
        activeIndex = index;
      }
    });

    // Update the indicator position based on the active tab
    const currentTab = tabsRef.current[activeIndex];
    if (currentTab) {
      setIndicatorStyle({
        left: `${currentTab.offsetLeft}px`,
        top: `${currentTab.offsetTop + currentTab.offsetHeight}px`,
        width: `${currentTab.offsetWidth}px`,
      });
    }
  }

  useEffect(() => {
    // Initial update of the indicator on mount or when children change
    updateIndicator();

    // Add window resize listener
    const handleResize = () => {
      updateIndicator();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [children]);

  return (
    <div className="nav-animated">
      <ul className="nav">
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            return null; // Skip null or undefined children
          }

          return (
            <li
              className="nav-item"
              role="presentation"
              ref={(el) => (tabsRef.current[index] = el)} // Assign ref to the <li> element
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {React.cloneElement(child, {
                ref: (el) => (childrenRef.current[index] = el), // Assign ref to the child element (NavLink, button, etc.)
                className: `${child.props.className || ''} nav-link`,
              })}
            </li>
          );
        })}
      </ul>

      {/* Background border */}
      <div className="background-border" />

      {/* Animated indicator */}
      <div className="indicator" style={indicatorStyle} />
    </div>
  );
};

export { AnimatedNavItem, AnimatedTabs };
export default AnimatedTabs;
