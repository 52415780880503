import {Route, Link,Switch} from 'react-router-dom';
import {PageHeader} from 'client/js/util/layout_utils';
import {AddDirectDebit, AddCreditCard} from 'client/js/usercp/webhosting/payment_lib';
import {Popup as NewPayPal} from './new_paypal';
import {Redirect} from 'react-router-dom';

const Select = () => {
  return <div className="list-group">
      <Link to="/payment_methods/new/credit_card" className="list-group-item list-group-item-action" href="#">Kreditkarte</Link>
      <Link to="/payment_methods/new/direct_debit" className="list-group-item list-group-item-action" href="#">SEPA-Bankverbindung</Link>
    </div>
}

class New extends React.Component {
  state = {}

  handleCreditCard = () => {
    this.setState({success: true});
  }

  handleDirectDebit = (action) => {
    if(action == 'form:success') {
      this.setState({success: true});
    }
  }

  render() {
    if(this.state.success)
      return <Redirect to="/payment_methods" />

    return <div>
      <PageHeader text="Zahlungsmethode erstellen" back_url="/payment_methods" />

      <Switch>
        <Route path="/payment_methods/new/credit_card" render={() => <AddCreditCard next={this.handleCreditCard} />} />
        <Route path="/payment_methods/new/direct_debit" render={() => <AddDirectDebit dispatch={this.handleDirectDebit} />} />
        <Route path="/payment_methods/new/paypal" render={() => <NewPayPal />} />
        <Route path="/payment_methods/new" render={() => <Select />} />
      </Switch>
    </div>;
  }
}

export default New;
