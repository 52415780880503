
import { useQuery } from '@tanstack/react-query';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

const fetchListingStatus = async (id) => {
  const response = await $.ajax({
    url: `/usercp/restores/${id}.json`,
    dataType: 'json',
  });

  return response;
};

const Status = ({restore}) => {
  const state = restore?.state;

  if(state === 'waiting') {
    return (
      <>
        <LoadingTableSpinner text="Warte auf Beginn des Vorgangs..." />

        <p className="text-muted text-center"><FaIcon name="lightbulb-o" /> Tipp: Der Restore läuft auch beim Verlassen dieser Seite weiter. Der Status kann auch unter Webhosting &rarr; Backups &rarr; Restores eingesehen werden.</p>
      </>
    );
  }

  if(state === 'running') {
    return (
      <>
        <LoadingTableSpinner text="Vorbereitung..." />

        <p className="text-muted text-center"><FaIcon name="lightbulb-o" /> Tipp: Der Restore läuft auch beim Verlassen dieser Seite weiter. Der Status kann auch unter Webhosting &rarr; Backups &rarr; Restores eingesehen werden.</p>
      </>
    );
  }

  if(state === 'extracting') {
    const progress = restore?.progress;
    const hasProgress = progress && progress.files_total > 0;
    const percentFormatted = hasProgress ? Math.round(progress.percent * 100) : 0;
    const filesProgress = hasProgress ? `${progress.files_restored.toLocaleString()} / ${progress.files_total.toLocaleString()} Dateien` : '';
    const bytesProgress = hasProgress ? `${format_disk_space(progress.bytes_restored)} / ${format_disk_space(progress.bytes_total)}` : '';
    
    return (
      <>
        <LoadingTableSpinner text={`Lade die Dateien aus dem Backup... ${hasProgress ? percentFormatted + '%' : ''}`} />
        
        {hasProgress && (
          <div className="progress mb-3 mt-2">
            <div 
              className="progress-bar" 
              role="progressbar" 
              style={{ width: `${percentFormatted}%` }} 
              aria-valuenow={percentFormatted} 
              aria-valuemin="0" 
              aria-valuemax="100">
              {percentFormatted}%
            </div>
          </div>
        )}
        
        {hasProgress && (
          <p className="text-center">
            <strong>{filesProgress}</strong><br/>
            <span className="text-muted">{bytesProgress}</span>
          </p>
        )}

        <p className="text-center">Der Server entpackt die Dateien aus dem Backup in ein temporäres Verzeichnis.</p>

        <p className="text-center">Je nach Größe des Backups kann dies mehrere Minuten dauern.</p>

        <p className="text-muted text-center"><FaIcon name="lightbulb-o" /> Tipp: Der Restore läuft auch beim Verlassen dieser Seite weiter. Der Status kann auch unter Webhosting &rarr; Backups &rarr; Restores eingesehen werden.</p>
      </>
    );
  }

  if(state === 'packing') {
    return (
      <>
        <LoadingTableSpinner text="Erstelle ein zip-Archiv aus den Dateien..." />

        <p className="text-center">Der Server erstellt aus den Dateien ein zip-Archiv zum Download.</p>

        <p className="text-muted text-center"><FaIcon name="lightbulb-o" /> Tipp: Der Restore läuft auch beim Verlassen dieser Seite weiter. Der Status kann auch unter Webhosting &rarr; Backups &rarr; Restores eingesehen werden.</p>
      </>
    );
  }

  if(state === 'handover') {
    return (
      <>
        <LoadingTableSpinner text="Übergebe Restore..." />

        <p className="text-center">Der Restore-Auftrag wird nun an den Webspace-Server gesendet, der die Daten importiert.</p>

        <p className="text-muted text-center"><FaIcon name="lightbulb-o" /> Tipp: Der Restore läuft auch beim Verlassen dieser Seite weiter. Der Status kann auch unter Webhosting &rarr; Backups &rarr; Restores eingesehen werden.</p>
      </>
    );
  }

  if(state === 'copying') {
    return (
      <>
        <LoadingTableSpinner text="Übertragen..." />

        <p className="text-center">Die Dateien für den Restore werden vom Backup-System auf den Webspace-Server übertragen.</p>

        <p className="text-muted text-center"><FaIcon name="lightbulb-o" /> Tipp: Der Restore läuft auch beim Verlassen dieser Seite weiter. Der Status kann auch unter Webhosting &rarr; Backups &rarr; Restores eingesehen werden.</p>
      </>
    );
  }

  if(state === 'restoring') {
    return (
      <>
        <LoadingTableSpinner text="Importiere Daten..." />

        <p className="text-center">Die Daten werden aus dem Backup auf das Zielsystem importiert.</p>

        <p className="text-muted text-center"><FaIcon name="lightbulb-o" /> Tipp: Der Restore läuft auch beim Verlassen dieser Seite weiter. Der Status kann auch unter Webhosting &rarr; Backups &rarr; Restores eingesehen werden.</p>
      </>
    );
  }

  if(state === 'success') {
    return (
      <>
        <div className="text-center">

        <h3>Fertig!</h3>

        <p>Der Restore wurde erfolgreich ausgeführt.</p>

        {restore?.target?.download_url && <a href={restore?.target?.download_url} target="_blank" rel="noopener noreferrer" className="btn btn-primary"><FaIcon name="download" /> herunterladen ({format_disk_space(restore?.target?.filesize)})</a>}
        </div>
      </>
    );
  }

  if(state === 'failure') {
    return (
      <div className="text-center">
        <h3>Fehler!</h3>

        <p>Leider gab es einen Fehler beim Ausführen des Restores. Bitte versuche es erneut, oder kontaktiere den Support, falls das Problem weiter besteht.</p>
      </div>
    );
  }

  return null;
}

const RestoreStatus = (props) => {
  const { id } = props.match.params;
  const { data, isLoading } = useQuery(['webspace_backup', 'listing_status', id], () => fetchListingStatus(id), {
    refetchInterval: (data) => (data?.restore?.state !== 'success' && data?.restore?.state !== 'failure') ? 1000 : false
  });

  const restore  = data?.restore;

  if(isLoading) {
    return (
    <>
      <PageHeader text="Restores" />
      <LoadingTableSpinner text="Laden..." />
    </>
    );
  }

  return (
    <>
      <PageHeader text="Restores" />

      <Status restore={restore} />
    </>
  );
}

export default RestoreStatus;
