import Callout from 'client/js/util/callout';
import { FormActions, GenericReactForm, SelectCollectionInput, StringInput, SubmitButton } from 'client/js/util/form_utils';
import { PageHeader, SkeletonLoading } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

const fieldToComponent = ({name, options}) => {
  return (
    <StringInput
      key={name}
      type={options.as ? options.as : 'text'}
      name={name}
      label={options.label}
      hint={options.hint}
      autoComplete={options.input_html?.autocomplete}
      required={options.required}
      placeholder={options.placeholder ? `z.B. ${options.placeholder}` : null}
    />
  );
}

const Install = (props) => {
  const id = props.match.params.id;
  const [installId, setInstallId] = useState(null);

  const { data, component } = useResource(['softwares', id], `/usercp/softwares/${id}/install`, {
    keepPreviousData: true,
    header: <PageHeader back_url="/softwares" text="Software installieren"><SkeletonLoading /> installieren</PageHeader>
  });

  if (component) {
    return component
  }

  const software = data.software;

  const onSuccess = (response) => {
    toast.success('Starte Installation...');
    setInstallId(response.object.install_id);
  }

  if(installId) {
    return <Redirect to={`/installations/${installId}`} />
  }

  return (
    <>
      <PageHeader back_url="/softwares" text={`${software.name} installieren`} />

      <GenericReactForm
        namespace="installation"
        defaults={{ database_id: 'new', path: software.suggested_path }}
        url={`/usercp/softwares/${id}/install`}
        method="POST"
        onSuccess={onSuccess}
      >

        <SelectCollectionInput name="vhost_id"
          hint="Unter dieser Domain ist die Installation später zu erreichen. Die jetzt auf der Haupt-Domain vorhandenen Inhalte werden nicht gelöscht sondern bleiben auf dem Webspace gespeichert."
          label="Domain"
          required
          options={data.vhosts.map((v) => [v.id, v.name])} />

        <StringInput required name="path" label="Pfad" hint="In dieses Verzeichnis wird die Software installiert. Bestehende Dateien werden überschrieben!" />

        {software.database_required && (
          <>
            <legend>MySQL-Datenbank</legend>

            <Callout calloutClass="info">
              <p>Diese Software erfordert eine MySQL-Datenbank. Bitte gib im Folgenden an, welche Datenbank für die Installation verwendet werden soll.</p>

              <p>Der Software-Installer wird automatisch einen neuen Datenbank-Benutzer anlegen, welcher Zugriff auf diese Datenbank hat. Du kannst diesem Datenbank-Benutzer später weitere Rechte zuweisen.</p>
            </Callout>

            <SelectCollectionInput name="database_id"
              label="Datenbank"
              required
              options={data.databases.map((db) => [db.name, db.id])} />
          </>
        )}

        {software.config_options.length > 0 && <legend>Software-Konfiguration</legend>}

        {software.config_options.map((field) => fieldToComponent(field))}

        <FormActions>
          <SubmitButton text="Software installieren" />
        </FormActions>
      </GenericReactForm>
    </>
  )
}

export default Install;
