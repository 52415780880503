import { useQuery } from '@tanstack/react-query';
import Usage from 'client/js/usercp/lib/usage';
import { Dropdown } from 'client/js/usercp/table_utils';
import Callout from 'client/js/util/callout';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';

// quota is a object and consits of usage and limit fields
const Quota = (props) => {
  if(!props.quota)
    return null;

  return <Usage percent={props.quota.usage_percent} limit={props.quota.limit_kb * 1024} />;
}

const ImportProgress = (props) => {
  const {messages_imported, messages_total} = props.import;
  const progress = messages_imported && messages_total ? Math.round((messages_imported / messages_total) * 100) : null;

  if(!props.import)
    return null;

  return (
    <>
      &mdash;
      <small className='text-muted'>E-Mail-Import läuft...</small>
      {!!progress && <div className="progress ml-2">
        <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{number_with_delimiter(messages_imported)} von {number_with_delimiter(messages_total)} E-Mails importiert...</div>
      </div>}
    </>
  );
}

const EmailRow = (props) => {
  if(props.item.type == 'mailbox')
    return <tr>
      <td>
        <Link to={`/mailboxes/${props.item.id}`}><strong>{props.item.local_part}@{props.item.domain}</strong></Link>
        {props.item.access_denied ? <span className="badge badge-danger">LOGIN GESPERRT!</span> : null}
        <br />
        <div className="d-flex align-items-baseline">
          <small className="text-muted">Postfach</small>

          {props.import ? <ImportProgress import={props.import} /> : null}
        </div>
      </td>
      <td><Quota quota={props.item.quota}/></td>
      <td>
        <Dropdown>
          <Link to={`/mailboxes/${props.item.id}/password`} href="#" className="dropdown-item"><FaIcon name="key" /> Passwort ändern</Link>

          <ConfirmedDelete
            namespace="usercp"
            resource="mailboxes"
            id={props.item.id}
            link
            button_class="dropdown-item"
            button_text={<><FaIcon name="trash" /> Postfach löschen</>}
            redirect_back={false}
            success_cb={props.refetch}
            text="Dies wird das Postfach und die darin vorhandenen E-Mails löschen. Bitte beachte die gesetzlichen Aufbewahrungsfristen, sofern es sich um E-Mails handelt, für welche Aufbewahrungspflichten gelten!" />
        </Dropdown>
      </td>
    </tr>
  else
    return <tr>
      <td>
        <strong>{props.item.local_part}@{props.item.domain}</strong>
        <br />
        <small className='text-muted'>Weiterleitung → {props.item.destinations.join(", ")}</small>
      </td>
      <td></td>
      <td>
        <Dropdown>
          <Link to={`/mailbox_aliases/${props.item.id}/edit`} href="#" className="dropdown-item"><FaIcon name="pencil" /> bearbeiten</Link>
          <ConfirmedDelete namespace="usercp" resource="aliases" display_resource="mail_aliases" id={props.item.id}
            link button_class="dropdown-item" button_text={<React.Fragment><FaIcon name="trash" /> löschen</React.Fragment>}
                          redirect_back={false} success_cb={props.refetch} />
        </Dropdown>
      </td>
    </tr>
}

const fetchData = async () => {
  const response = await $.ajax({
    url: '/usercp/email',
    dataType: 'json',
  });

  return response;
}

const NoEmailSplash = () => (
  <Callout calloutClass="info" title="E-Mail nicht verfügbar">
    <p>Hier kannst Du E-Mail-Postfächer und Weiterleitungen verwalten. Derzeit sind keine Domains verfügbar, für welche Postfächer oder Weiterleitungen verwaltet werden können.</p>
    <p>Bitte registriere oder transferiere eine Domain oder schalte sie mit aktivierter E-Mail-Funktion auf, sofern die Domain bei einem anderen Provider registriert ist.</p>
  </Callout>
)

const Index = () => {
  const [filterQuery, setFilterQuery] = React.useState('');

  const {data, isLoading, isError, isPreviousData, error, refetch} = useQuery(['email_addresses'], () => fetchData(), {
    keepPreviousData: true,
  });

  const buttons_disabled = isLoading || isError || !data.email_available;

  const header_buttons = <>
    <input disabled={buttons_disabled} className="search-query form-control" type="search" placeholder='Schnellsuche' onChange={(e) => setFilterQuery(e.target.value)} />
    <Link disabled={buttons_disabled} to="/mailboxes/new" className="btn btn-light"><FaIcon name="plus" /> Postfach <span className="d-none d-md-inline">erstellen</span></Link>
    <Link disabled={buttons_disabled} to="/aliases/new" className="btn btn-light"><FaIcon name="plus" /> Weiterleitung <span className="d-none d-md-inline">erstellen</span></Link>
  </>;

  if(isLoading) {
    return <>
      <PageHeader text="E-Mail-Adressen" buttons={header_buttons} />

      <LoadingTableSpinner />
    </>
  }

  if(isError) {
    return errorToComponent(error);
  }

  if(!data.email_available) {
    return <>
        <PageHeader text="E-Mail-Adressen" />

        <NoEmailSplash />
      </>
  }

  let filteredData = data.email_addresses;

  // build an object with the import for each mailbox_id
  const import_map = {};
  data.running_imports.forEach((item) => {
    import_map[item.mailbox_id] = item;
  });

  if(filterQuery && filterQuery.length !== '') {
    let filter_fn;
    let rex;
    try {
      rex = new RegExp(filterQuery, 'i');

      filter_fn = (item) => (rex.test(item.email));
    } catch {
      filter_fn = (item) => (item.email.indexOf(filterQuery) !== -1);
    }

    filteredData = filteredData.filter(filter_fn);
  }

  return <>
    <PageHeader text="E-Mail-Adressen" buttons={header_buttons} usage={<Usage percent={data.usage_percent} limit={data.limit_kb* 1024} />} />

    {/*<AnimatedTabs>
      <NavItem to="/email">E-Mail-Adressen</NavItem>
      <NavItem to="/mailbox_imports">E-Mail-Importe</NavItem>
    </AnimatedTabs>*/}

    <div className="table-responsive">
      <table className={isPreviousData ? "table table-striped table-wide table-email-manager skeleton-loading" : "table table-striped table-wide table-email-manager"}>
      <thead>
        <tr>
          <th>Adresse</th>
          <th style={{"max-width":'15rem'}}>Belegung</th>
          <th>Optionen</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item) => (<EmailRow key={`${item.type}:${item.id}`} item={item} import={import_map[item.id]} refetch={refetch} />))}

        {filteredData.length == 0 && <tr><td colSpan="3" className="text-center font-weight-bold">Keine E-Mail-Adressen {data.email_addresses.length == 0 ? 'vorhanden' : 'gefunden'}.</td></tr>}
      </tbody>
    </table>
    </div>
  </>;
}

export { Index };

export default Index;
