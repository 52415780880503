import { useQuery } from '@tanstack/react-query';
import CopyableField from 'client/js/util/copyable_field';
import ExternalLink from 'client/js/util/external_link';
import { ControlGroup, FormActions, GenericReactForm, SubmitButton } from 'client/js/util/form_utils';
import { FaIcon, PageHeader, Progress } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent } from 'client/js/util/rest_utils';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import WebsiteContentSection from './website_content_section';

const Action = ({expected, found}) => {
  if (expected == found) {
    return <span className="text-success">OK</span>;
  }

  if (!found && expected) {
    return "hinzufügen";
  }

  if (found && !expected) {
    return "entfernen";
  }

  return "ändern";
}

const CheckTable =({data}) => {
  if(!data || data.length == 0) {
    return null;
  }

  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Typ</th>
            <th>erwartet</th>
            <th>gefunden</th>
            <th>erforderliche Aktion</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <tr key={index}>
              <td>{entry.name}</td>
              <td>{entry.type}</td>
              <td>{entry.expected ? <CopyableField value={entry.expected} /> : <span className="text-danger">nicht vorhanden</span>}</td>
              <td>{entry.found ? entry.found : <span className="text-danger">nicht gefunden</span>}</td>
              <td><Action expected={entry.expected} found={entry.found} /></td>
            </tr>
          ))}
        </tbody>
      </table>
      </>
  )
}

const CheckResults = ({ isLoading, isError, error, data, domain}) => {
  if (isLoading) {
    return <LoadingTableSpinner />;
  }

  if (isError) {
    return errorToComponent(error);
  }

  return (
    <>
      <h3>Ergebnisse</h3>

      {data.dns_valid === true ? (
        <div className="alert alert-success">
          <strong>Die DNS-Einträge wurden erfolgreich eingerichtet. Du kannst mit dem nächsten Schritt fortfahren.</strong>
        </div>
      ) : (
        <div className="alert alert-danger">
          <strong>Es wurden Fehler bei der DNS-Konfiguration festgestellt. Bitte prüfe die DNS-Einträge und beachte, dass DNS-Server ggf. eine gewisse Zeit brauchen, um die neuen EInträge auszuliefern.</strong>
        </div>
      )}

      <CheckTable data={data.v4_results} domain={domain} />
      <CheckTable data={data.v6_results} domain={domain} />
      <CheckTable data={data.mx_results} domain={domain} />
    </>
  );
}

const DnsCheckForm = ({ onFinish }) => {
  const [domain, setDomain] = useState('');
  const [checkedDomain, setCheckedDomain] = useState(null);
  const [ignoreErrors, setIgnoreErrors] = useState(false);
  const [useIpv6, setUseIpv6] = useState(true);
  const [useEmail, setUseEmail] = useState(true);

  const checkDomain = () => {
    setCheckedDomain(domain);
    setIgnoreErrors(false);
  }

  const handleFinishClick = (e) => {
    e.preventDefault();
    onFinish({ hostname: checkedDomain, use_ipv6: useIpv6, use_email: useEmail });
  }

  const queryEnabled = !!checkedDomain && checkedDomain !== '';

  const { data, isLoading, isError, isRefetching, error, refetch } = useQuery(['checkDomain', checkedDomain], async () =>  {
    const response = await $.ajax({
      url: '/usercp/websites/external-domain/check',
      type: 'POST',
      data: {
        website: {
          hostname: checkedDomain,
          use_ipv6: useIpv6,
          use_email: useEmail
        }
      },
      dataType: 'json'
    });

    return response;
  }, {
    enabled: queryEnabled,
    keepPreviousData: false
  });

  const handleCheckAgainClick = (e) => {
    e.preventDefault();
    setCheckedDomain(domain);
    setIgnoreErrors(false);
    refetch();
  }

  const hostnameErrors = data?.errors?.hostname;

  return (
    <>
      <ControlGroup type="string" name="hostname" labelFor="hostnameInput" required label="Domain" errors={hostnameErrors}>
        <input type="text" id="hostnameInput" className="form-control" value={domain} onChange={(e) => setDomain(e.target.value)} />
      </ControlGroup>

      <ControlGroup type="boolean" name="useIpv6" hint={"Es werden zusätzlich zu den DNS-Records für IPv4 auch die DNS-Records zu IPv6 angezeigt. Wir empfehlen auf jeden Fall, auch IPv6 einzurichten."}>
        <div className="custom-control custom-checkbox">
          <input type="checkbox" id="useIpv6Input" className="form-control custom-control-input" checked={useIpv6} onChange={(e) => setUseIpv6(e.target.checked)} />

          <label className="custom-control-label" htmlFor="useIpv6Input">
            IPv6 einrichten
          </label>
        </div>
      </ControlGroup>

      <ControlGroup type="boolean" name="useEmail" hint="Mit einem Webhosting-Paket können auch für Domains, die nicht über lima-city registriert sind, die E-Mail-Dienste genutzt werden. Dies umfasst das Anlegen von unbegrenzt vielen Postfächern und Weiterleitungen für diese Domain.  Der Zugriff ist über IMAP/POP3/SMTP und Webmail möglich, natürlich auch vom Smartphone.">
      <div className="custom-control custom-checkbox">
          <input type="checkbox" id="useEmailInput" className="form-control custom-control-input" checked={useEmail} onChange={(e) => setUseEmail(e.target.checked)} />
          <label className="custom-control-label" htmlFor="useEmailInput">
            E-Mail für diese Domain einrichten
          </label>
      </div>
      </ControlGroup>

      {queryEnabled && <CheckResults queryEnabled={queryEnabled} isLoading={isLoading || isRefetching} isError={isError} error={error} data={data?.check_results} />}

      <div className="row">
        <div className="form-actions offset-md-3 col-md-9">
          {data?.check_results?.dns_valid === true || data?.check_results?.dns_valid === false ? (
            data.check_results.dns_valid ? (
              <button className="btn btn-primary" disabled={queryEnabled && isLoading} onClick={handleFinishClick}>&rarr; Weiter</button>
            ) : (
              <>
                <button className="btn btn-primary" disabled={queryEnabled && isLoading} onClick={handleCheckAgainClick}><FaIcon name="repeat" /> Erneut prüfen</button>
                <br/>
                <em>oder</em>
                <br/>

                <label htmlFor="ignore_errors">
                  <input type="checkbox" id="ignore_errors" checked={ignoreErrors} onChange={(e) => setIgnoreErrors(e.target.checked)} />
                  Ich möchte die DNS-Prüfung überspringen, mein Domain-Provider unterstützt nur einen DNS-Record oder es liegt derzeit ein anderes Problem vor. Ich akzeptiere, dass ich mit der Aufschaltung nur einer IP-Adresse die vertragliche vereinbarte Verfügbarkeitsgarantie für die jetzt aufgeschaltete Domain nicht in Anspruch nehmen kann. Ich möchte trotzdem fortfahren.
                </label>

                <button className="btn btn-secondary" disabled={(queryEnabled && isLoading) || !ignoreErrors} onClick={handleFinishClick}>&rarr; Probleme ignorieren und fortfahren</button>
              </>

            )
          ) : (
            <button className="btn btn-primary" disabled={queryEnabled && isLoading} onClick={checkDomain}><FaIcon name="magnifying-glass" />Prüfen</button>
          )}
        </div>
      </div>
    </>
  )
}

const CreateExternalDomain = () => {
  const [step, setStep] = useState(0);
  const [success, setSuccess] = useState(0);
  const [defaults, setDefaults] = useState({});

  if(success) {
    if(success == 'install') {
      return <Redirect to="/softwares" />;
    }

    return <Redirect to="/websites" />;
  }

  const onSuccess = (_data, submittedData) => {
    toast.success('Die Domain wurde erfolgreich aufgeschaltet.');
    if(submittedData.content_strategy == 'install') {
      return setSuccess('install');
    }

    setSuccess(true);
  }

  const onFinish = (data) => {
    setStep(1);
    setDefaults(data);
  }

  return (
    <>
      <PageHeader text="Externe Domain aufschalten" back_url="/websites" />
      <Progress steps={['DNS-Einrichtung', 'Webhosting-Einstellungen']} current={step} />

      <p>Um eine Domain aufzuschalten müssen zuerst die DNS-Einträge der Domain geändert werden. Bitte trage in dem folgenden Formular den Domain-Namen ein, den Du aufschalten möchtest. Es wird Dir angezeigt, welche Änderungen Du bei Deinem Domain-Anbieter eintragen musst. Wenn Du nicht sicher bist, wie die Einstellungen vorzunehmen sind, wende Dich bitte an den Support Deines Domain-Anbieters (da dieser Support weiß, wie das DNS-Menü zu benutzen ist).</p>

      <p><strong>Wir erlauben uns den Hinweis auf unsere <ExternalLink href="/domains/preisliste">Domain-Preisliste</ExternalLink>, da wir in der Regel günstigere Preise als andere Domain-Provider bieten.</strong></p>

      {step == 0 ? (
        <DnsCheckForm onFinish={onFinish} />
      ) : (
        <>
          <GenericReactForm url="/usercp/websites/external-domain" verb="POST" namespace="website" defaults={defaults} onSuccess={onSuccess}>
            <WebsiteContentSection name="name" label="Webseiten-Inhalt" installerRedirect required />

            <FormActions>
              <SubmitButton icon="plus" text="Domain aufschalten" />
            </FormActions>
          </GenericReactForm>
        </>
      )}

    </>
  );
}

export default CreateExternalDomain;
