import PropTypes from "prop-types";
import GenericCreate from 'client/js/resellercp/generic_resource/create';
import {StringInput, RadioCollectionInput, PasswordInput} from 'client/js/util/form_utils';
import PrependStringInput from 'client/js/util/inputs/prepend_string_input';
import DatabaseGrantsInput from 'client/js/util/inputs/database_grants_input';
import {transform_db_user} from './lib';

const HostInput = (props, context) => {
  if(context.formData && context.formData['host_type'] == 'hostname')
    return <StringInput {...props} />;

  return null;
}

HostInput.contextTypes = {
  formData: PropTypes.object
};

const Form = () => {
  const options = {"localhost": "localhost (vom eigenen Webspace)", "%": "% (vom eigenen Webspace und Internet)"}
  const access_options = {"admin": "Admin-Zugriff auf alle Datenbanken", "readonly": "Nur-Lesen (alle Datenbanken)", "readwrite": "Lese- und Schreibzugriff, keine Schema-Änderungen (alle Datenbanken)", "": 'kein Zugriff', complex: 'benutzerdefinierte Rechte'}

  return <div>
      <PrependStringInput prepend_text={`USER${document.lima_id}_`} name="suffix" label="Benutzername" required maxLength={6} />
      <RadioCollectionInput name="hostname" label="Zugriff" options={options} required />

      <PasswordInput name="password" label="Passwort" required />

      <RadioCollectionInput name="access" label="Rechte" options={access_options} required />

      <DatabaseGrantsInput name="grants" label="benutzerdefinierte Rechte" required />
    </div>;
}

const Create = () => (<GenericCreate namespace="usercp"
                                    resource_name="database_users"
                                    defaults={{host: 'localhost'}}
                                    redirectAfter="collection"
                                    transform_data={transform_db_user}
                                    form_component={() => (<Form />)} />);

export default Create;
