import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import Callout from 'client/js/util/callout';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { AnimatedTabs } from 'client/js/util/nav_tabs';
import { useResource } from "client/js/util/rest_utils";
import { useState } from 'react';
import { Line as LineChart } from "react-chartjs-2";
import Select from 'react-select';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, TimeScale);

const Graph = function ({ data, vhosts, colors, metricName, metricKey, selectedWebsites, sumUp }) {
  const processedData = {};
  const distinctWebsites = {};
  const processedVhosts = {};

  const vhostMap = vhosts.reduce((acc, {id, name}) => {
    acc[id] = name;
    return acc;
  }, {});

  const formatAxis = (metricKey === 'traffic' || metricKey === 'robot_traffic') ? (
    (value) => (value.fileSize())
  ) : (value) => (number_with_delimiter(value));

  // Organize data by vhost_id and dates for each metric
  data.forEach((entry) => {
    if (selectedWebsites.length > 0 && !selectedWebsites.includes(entry.vhost_id))
      return;

    const { vhost_id, date } = entry;
    const vhostName = sumUp ? 'sum' : vhostMap[vhost_id];

    distinctWebsites[vhostMap[vhost_id]] = true;
    processedVhosts[vhostName] = true;

    if (!processedData[date]) {
      processedData[date] = {};
    }

    if (!processedData[date][vhostName]) {
      processedData[date][vhostName] = 0
    }

    processedData[date][vhostName] += entry[metricKey];
  });

  // Create a dataset for each vhost
  const datasets = Object.keys(processedVhosts).map((vhostName) => {
    const finalData = Object.keys(processedData).map((date) => {
      return { x: date, y: processedData[date][vhostName] || 0 };
    });

    // Calculate stats for this vhost
    const total = finalData.reduce((acc, { y }) => acc + y, 0);
    const avg = Math.round(total / finalData.length);
    const min = Math.min(...finalData.map(({ y }) => y));
    const max = Math.max(...finalData.map(({ y }) => y));

    return {
      label: `${sumUp ? `Summe über ${Object.keys(distinctWebsites).length} Webseite(n)` : vhostName} - Gesamt: ${formatAxis(total)} | Durchschnitt: ${formatAxis(avg)} | Min: ${formatAxis(min)} | Max: ${formatAxis(max)}`,
      data: finalData,
      borderColor: sumUp ? '#669DE8' : colors[vhostName],
      fill: false,
      tension: 0.1
    };
  });

  const chartData = {
    datasets: datasets
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Webseiten-Statistiken nach Datum'
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            if(sumUp) {
              return `${metricName}: ${context.formattedValue}`;
            } else {
              return `${context.dataset.label.split(' ')[0]}: ${context.formattedValue}`;
            }
          }
        }
      }
    },
    interaction: {
      mode: 'index',
      axis: 'x',
      intersect: false
    },
    scales: {
      x: {
        type: 'time',
        // format it to show the day and not the time
        time: {
          unit: 'day'
        },
        title: {
          display: true,
          text: 'Datum'
        }
      },
      y: {
        title: {
          display: true,
          text: metricName, // Change this label to the metric you are tracking
        },
        // if the metric is "traffic" or "robot_traffic", we want to display the y-axis in MB
        ticks: {
          callback: formatAxis
        }
      }
    }
  }

  return (<div className="chart-container">
    <LineChart data={chartData} options={options} />
    </div>);
}

const tabMap = {
  'requests': 'Anfragen',
  'bytes': 'Traffic',
  'page_views': 'Seitenaufrufe',

  'robot_requests': 'Anfragen durch Bots',
  'robot_bytes': 'Traffic durch Bots',
  'robot_page_views': 'Seitenaufrufe durch Bots'

}

const Tab = React.forwardRef(({active, tab, changeTab}, ref) => {
  return (
    <li className="nav-item">
      <a ref={ref} className={"nav-link" + (active === tab ? ' active' : '')} href={"#" + tab} onClick={(e) => changeTab(e, tab)}>{tabMap[tab]}</a>
    </li>
  );
});

Tab.displayName = 'Tab';

const Statistics = () => {
  const { data, component } = useResource(['statistics'], "/usercp/websites/statistics", {
    keepPreviousData: true,
    header: <PageHeader text="Statistiken" />
  });

  const [activeTab, setActiveTab] = useState('requests');
  const [sumUp, setSumUp] = useState(true);

  const [selectedWebsites, setSelectedWebsites] = useState([]);

  const changeHandler = (selected) => {
    setSelectedWebsites(selected);
  }

  const changeTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  }

  if(component) {
    return component;
  }

  if(data.stats.length === 0) {
    return (
      <>
        <PageHeader text="Statistiken" />

        <Callout calloutClass="primary" title="Keine Statistiken vorhanden">
          <p>Es sind derzeit keine Statistiken vorhanden. Bitte warten etwas, bis Daten verfügbar sind.</p>
        </Callout>
      </>
    )
  }

  const options = data.vhosts.map(({id, name}) => ({value: id, label: name}));

  // iterate over all data and create a object where the key is the vhost_id and the value is a random color
  // we will use this object to assign a color to each vhost
  const vhostColors = data.vhosts.reduce((acc, { name }) => {
    acc[name] ||= `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`;
    return acc;
  });

  return (
    <>
      <PageHeader text="Statistiken" />

      <div className="d-flex justify-content-between mb-3">
        <Select name="websites"
          className="Select mr-md-3 w-100"
          classNamePrefix="Select"
          noOptionsMessage={() => "Keine Optionen gefunden"}
          placeholder="(Webseiten auswählen zum Filtern)"
          value={selectedWebsites}
          options={options}
          required
          closeMenuOnSelect={false}
          isClearable
          isMulti
          onChange={changeHandler}
        />

        {/* form control to toggle if sites should be summed up or no */}
        <div className="d-flex align-items-center">
          <div className="custom-control custom-checkbox">
            <input className="form-control custom-control-input" type="checkbox" id="sumUp" name="sumUp" checked={sumUp} onChange={(e) => setSumUp(e.target.checked)} />

            <label className="custom-control-label" htmlFor="sumUp">
              Summieren
            </label>
          </div>
        </div>
      </div>

      <AnimatedTabs>
        <Tab active={activeTab} tab="requests" changeTab={changeTab} />
        <Tab active={activeTab} tab="bytes" changeTab={changeTab} />
        <Tab active={activeTab} tab="page_views" changeTab={changeTab} />

        <Tab active={activeTab} tab="robot_requests" changeTab={changeTab} />
        <Tab active={activeTab} tab="robot_bytes" changeTab={changeTab} />
        <Tab active={activeTab} tab="robot_page_views" changeTab={changeTab} />
      </AnimatedTabs>

      <div className="chart-container mb-3">
        <Graph data={data.stats} sumUp={sumUp} selectedWebsites={selectedWebsites.map(({value}) => (value))} colors={vhostColors} vhosts={data.vhosts} metricName={tabMap[activeTab]} metricKey={activeTab} />
      </div>

      <p>
        <a href="/usercp/websites/statistics.csv" className="btn btn-light"><FaIcon name="download" /> Download als .csv</a>
      </p>
    </>
  );
}

export default Statistics;
