import Callout from 'client/js/util/callout';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import { useState } from 'react';

const log_level_to_badge_color = (level) => {
  if(level == 'deprecated')
    return 'badge-secondary';
  if(level == 'warning')
    return 'badge-warning';
  if(level == 'fatal_error' || level == 'parse_error')
    return 'badge-danger';
  if(level == 'error')
    return 'badge-danger';

  return 'badge-info';
}

const ItemView = (props) => {
  const same_message = props.errors.filter((item) => (item.message == props.item.message));
  const same_script = props.errors.filter((item) => (item.script == props.item.script));

  return <>
    <div className="mb-3">
      <strong><code>{props.item.message}</code></strong>
      <br />
      {props.item.script && props.item.script_line ? <small className="log-script">{props.item.script.replace(/\/home\/webpages\/lima-city\/[^\/]+\//, '')} Zeile {props.item.script_line}</small> : null}
    </div>
    {props.item.backtrace && props.item.backtrace.length ? [
      <h2 key={0}>Backtrace</h2>,
      <div className="mb-3" key={1} style={{'overflow-x': 'scroll', 'width': '100%'}}>
        <code style={{'white-space': 'pre'}}>
          {props.item.backtrace.map((item) => item.replace(/#([0-9]+) \/home\/webpages\/lima-city\/[^\/]+\//, '#$1 ')).join("\n")}
        </code>
      </div>] : null}

    {false && same_message && same_message.length ? [
      <h2 key={0}>{same_message.length} ähnliche Fehler (gleiche Meldung)</h2>,
      <div className="mb-3" key={1}>
        FOO
      </div>] : null}

    {false && same_script && same_script.length ? [
      <h2 key={0}>{same_script.length} ähnliche Fehler (gleiches Script)</h2>,
      <div className="mb-3" key={1}>
        FOO
      </div>] : null}

  </>
}

const Index = () => {
  const [item, setItem] = useState(null);

  const handleClick = (e, item) => {
    e.preventDefault();
    setItem(item);
  }

  const handleBack = (e) => {
    e.preventDefault();
    setItem(null);
  }

  const { data, component } = useResource(['php_error_log'], '/usercp/php_error_log', {header: () => <PageHeader text="PHP Error-Log" />, keepPreviousData: true});

  if(component)
    return component;

  if(item)
    return <>
              <PageHeader text="PHP Error-Details">
                <a href="#" onClick={handleBack}><FaIcon name="chevron-left" /></a> PHP Error-Details
              </PageHeader>

              <ItemView item={item} errors={data.error_log} />
            </>;

  if(!data.error_log.length)
    return (
      <>
        <PageHeader text="PHP Error-Log" />

        <Callout calloutClass="success" title="Alles in Ordnung!">
          <p>Es wurden keine Fehler oder Warnungen aufgezeichnet!</p>
        </Callout>
      </>
    );

  return (
    <>
      <PageHeader text="PHP Error-Log" />

      <table className="table table-striped table-wide">
        <thead>
          <tr><th>Uhrzeit</th><th>Meldung</th></tr>
        </thead>

        <tbody>
          {data.error_log.reverse().map((item, index) => {
            return <tr key={index}>
                    <td>{dayjs(item.time).format('l LTS')}</td>
                    <td>
                      {item.level ? <span className={`float-right badge ${log_level_to_badge_color(item.level)}`}>{item.level.replace('_', ' ')}</span> : null}
                      <div className="log-message"><a href="#" onClick={(e) => handleClick(e, item)}>{item.message}</a></div>
                      <br />
                      {item.script && item.script_line ? <small className="log-script">{item.script.replace(/\/home\/webpages\/lima-city\/[^\/]+\//, '')} Zeile {item.script_line}</small> : null}
                    </td>
                  </tr>;
          })}
        </tbody>
      </table>
    </>
  );
}

export default Index;
