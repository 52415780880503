import { BasicDropdown } from 'client/js/usercp/table_utils';
import Callout from 'client/js/util/callout';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import ExternalLink from 'client/js/util/external_link';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { Pagination, usePages } from 'client/js/util/pagination';
import { useResource } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


const LogSection = ({cronjob, changePage}) => {
  const pagination_data = cronjob && cronjob.runs_pagination;

  let pagination = null;
  if (pagination_data && pagination_data.total_pages > 1) {
    pagination = <Pagination currentPage={pagination_data.current_page} totalPages={pagination_data.total_pages} onPageChange={changePage} />;
  }

  return (
    <>
      {pagination}

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Zeitpunkt</th>
            <th>Laufzeit</th>
            <th>Status-Code</th>
            <th>Protokoll</th>
          </tr>
        </thead>
        <tbody>
          {cronjob.runs.map(run => (
            <tr key={run.id}>
              <td>{dayjs(run.start_time).format('L LTS')}</td>
              <td>{run.runtime && run.runtime > 0 ? `${run.runtime} s` : '-'}</td>
              <td>
                {run.response_code == 200 ? (
                  <span className="text-success"><FaIcon name="check-square-o" /> {run.response_code}</span>
                ) : (
                  <span className="text-danger"><FaIcon name="times-circle-o" /> {run.response_code}</span>
                )}
              </td>
              <td>
                <ExternalLink href={`/usercp/cronjobs/${cronjob.id}/runs/${run.id}`} target="_blank">Protokoll zeigen</ExternalLink>
              </td>
            </tr>
          ))}

          {cronjob.runs.length == 0 && (
            <tr>
              <td colSpan="4" className="text-center">Es wurden noch keine Läufe für diesen Cronjob aufgezeichnet.</td>
            </tr>
          )}
        </tbody>
      </table>

      {pagination}
    </>
  )
}

const Show = (props) => {
  const id = props.match.params.id;

  const [page, changePage] = usePages();

  const { data, component } = useResource(['cronjobs', id, {page: page}], `/usercp/cronjobs/${id}?page=${page}`, {
    header: () => <PageHeader text="Cronjob anzeigen" back_url="/cronjobs" />,
    keepPreviousData: true,
  });

  if (component) {
    return component;
  }

  const { cronjob } = data;

  const buttons = (
    <BasicDropdown button={
      <Link to={`/cronjobs/${id}/edit`} className="btn btn-light"><FaIcon name="pencil" /> bearbeiten</Link>
      }
    >
      <ConfirmedDelete namespace="usercp" resource="cronjobs" id={id} button_text={<><FaIcon name="trash" /> löschen</>}
        onSuccess={() => toast.success("Cronjob wurde gelöscht.")}
        button_class="dropdown-item text-danger" text="Dies wird den Cronjob sofort löschen. Aktuell laufende Instanzen dieses Cronjobs werden nicht beendet." />
    </BasicDropdown>
  );

  return (
    <>
      <PageHeader text="Cronjob anzeigen" back_url="/cronjobs" buttons={buttons} />

      {cronjob.type === 'url' ? (
        <LogSection cronjob={cronjob} changePage={changePage} />
      ) : (
        <Callout title="Log nicht verfügbar" calloutClass="info">
            <p>Für Cronjobs des Typs &quot;Shell&quot; sind Logs derzeit nicht verfügbar.</p>

          <p>Du kannst den Cronjob durch einen Shell-Redirect selber ein Log-File erzeugen lassen. Hänge dazu z.B. den folgenden String an das Kommando:<br/>
          <code> &gt;&gt; ~/logs/my_cron.log</code>.</p>
        </Callout>
      )}
    </>
  );
}

export default Show;
