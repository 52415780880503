import Cancel from 'client/js/usercp/cloud/virtual_machines/tabs/cancel';
import ChangeIso from 'client/js/usercp/cloud/virtual_machines/tabs/change_iso';
import ChangeRootPassword from 'client/js/usercp/cloud/virtual_machines/tabs/change_root_password';
import VirtualMachineDeleteForm from 'client/js/usercp/cloud/virtual_machines/tabs/delete';
import Graphs from 'client/js/usercp/cloud/virtual_machines/tabs/graphs';
import VirtualMachineNetwork from 'client/js/usercp/cloud/virtual_machines/tabs/network';
import VirtualMachineOverview from 'client/js/usercp/cloud/virtual_machines/tabs/overview';
import RebuildImage from 'client/js/usercp/cloud/virtual_machines/tabs/rebuild_image';
import VirtualMachineResize from 'client/js/usercp/cloud/virtual_machines/tabs/resize';
import SnapshotsContainer from 'client/js/usercp/cloud/virtual_machines/tabs/snapshots';
import VirtualMachineHeader from 'client/js/usercp/cloud/virtual_machines/virtual_machine_header';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { AnimatedTabs, AnimatedNavItem as NavItem } from 'client/js/util/nav_tabs';
import { useResource } from 'client/js/util/rest_utils';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Settings from './tabs/settings';

const VpsNav = ({id}) => (
  <AnimatedTabs>
    <NavItem to={`/virtual_machines/${id}`} exact>Übersicht</NavItem>
    <NavItem to={`/virtual_machines/${id}/graphs`}>Graphen</NavItem>
    <NavItem to={`/virtual_machines/${id}/network`}>Netzwerk</NavItem>
    <NavItem to={`/virtual_machines/${id}/change_root_password`}>root-Passwort</NavItem>
    <NavItem to={`/virtual_machines/${id}/rebuild`}>Rebuild</NavItem>
    <NavItem to={`/virtual_machines/${id}/iso`}>CD-ISO</NavItem>
    <NavItem to={`/virtual_machines/${id}/snapshots`}>Snapshots</NavItem>
    <NavItem to={`/virtual_machines/${id}/resize`}>skalieren</NavItem>
    <NavItem to={`/virtual_machines/${id}/settings`}>Einstellungen</NavItem>
    <NavItem to={`/virtual_machines/${id}/destroy`}>löschen</NavItem>
  </AnimatedTabs>
);

VpsNav.propTypes = {
  id: PropTypes.number.isRequired
}

const VserverNav = ({id}) => (
  <AnimatedTabs>
    <NavItem to={`/virtual_machines/${id}`} exact>Übersicht</NavItem>
    <NavItem to={`/virtual_machines/${id}/graphs`}>Graphen</NavItem>
    <NavItem to={`/virtual_machines/${id}/network`}>Netzwerk</NavItem>
    <NavItem to={`/virtual_machines/${id}/change_root_password`}>root-Passwort</NavItem>
    <NavItem to={`/virtual_machines/${id}/rebuild`}>Rebuild</NavItem>
    <NavItem to={`/virtual_machines/${id}/iso`}>CD-ISO</NavItem>
    <NavItem to={`/virtual_machines/${id}/snapshots`}>Snapshots</NavItem>
    <NavItem to={`/virtual_machines/${id}/settings`}>Einstellungen</NavItem>
    <NavItem to={`/virtual_machines/${id}/cancel`}>kündigen</NavItem>
  </AnimatedTabs>
);

const DetailsView = ({vm_id, virtual_machine, snapshots}) => {
  if (!virtual_machine)
    return <LoadingTableSpinner />;

  const can_make_snapshots = ['running', 'stopped'].includes(virtual_machine.status);

  return (
    <>
      <VirtualMachineHeader virtual_machine={virtual_machine} />

      {virtual_machine.type == 'vserver' ? <VserverNav id={vm_id} /> : <VpsNav id={vm_id} />}

      <Switch>
        <Route path="/virtual_machines/:id/network" render={() => (<VirtualMachineNetwork id={vm_id} ip_addresses={virtual_machine.ip_addresses} />)} />
        <Route path="/virtual_machines/:id/graphs" render={() => (<Graphs id={vm_id} />)} />
        <Route path="/virtual_machines/:id/snapshots" render={() => (<SnapshotsContainer id={vm_id} virtual_machine={virtual_machine} snapshotsDisabled={!can_make_snapshots} />)} />
        <Route path="/virtual_machines/:id/rebuild" render={() => (<RebuildImage id={vm_id} status={virtual_machine.status} restore_progress={virtual_machine.restore_progress} />)} />
        <Route path="/virtual_machines/:id/resize" render={() => (<VirtualMachineResize id={vm_id} />)} />
        <Route path="/virtual_machines/:id/iso" component={ChangeIso} />
        <Route path="/virtual_machines/:id/change_root_password" component={ChangeRootPassword} />
        <Route path="/virtual_machines/:id/destroy" render={() => (<VirtualMachineDeleteForm hasSnapshots={!snapshots || !!snapshots.length}
          hostname={virtual_machine.hostname}
          delete_url={`/usercp/virtual_machines/${vm_id}.json`} />)} />
        <Route path="/virtual_machines/:id/cancel" render={() => (<Cancel id={vm_id} />)} />
        <Route path="/virtual_machines/:id/settings" render={() => (<Settings id={vm_id} />)} />
        <Route path="/virtual_machines/:id" render={() => (<VirtualMachineOverview graph_url={`/usercp/virtual_machines/${vm_id}/graph.json`} virtual_machine={virtual_machine} />)} />
      </Switch>
    </>
  );
}

const Details = ({id}) => {
  const { data, component } = useResource(['virtual_machines', id], `/usercp/virtual_machines/${id}`, {
    keepPreviousData: true,
    refetchInterval: 5000
  });

  if(component)
    return component;

  return <DetailsView vm_id={id} virtual_machine={data?.virtual_machine} />
}

Details.propTypes = {
  id: PropTypes.string.isRequired,
};

const PageWrapper = (props) => (<Details id={props.match.params.id} />);

export { PageWrapper as default, DetailsView };

