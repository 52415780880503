import CloudCharges from 'client/js/usercp/cloud/charges';
import { MenuPage as StandaloneSnapshotMenu } from 'client/js/usercp/cloud/snapshots/standalone_snapshot_menu';
import Index from 'client/js/usercp/cloud/virtual_machines/index';
import New from "client/js/usercp/cloud/virtual_machines/new";
import Show from "client/js/usercp/cloud/virtual_machines/show";
import { Route, Switch } from 'react-router-dom';

const CloudRouter = () => (
  <Switch>
    <Route path="/virtual_machines/new" component={New} />
    <Route path="/virtual_machines/charges" component={CloudCharges} />
    <Route path="/virtual_machines/snapshots" component={StandaloneSnapshotMenu} />
    <Route path="/virtual_machines/:id" component={Show} />
    <Route path="/virtual_machines" component={Index} />
  </Switch>
);

export default CloudRouter;
