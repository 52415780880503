import PropTypes from "prop-types";
import {PageHeader} from 'client/js/util/layout_utils';
import {Redirect} from 'react-router-dom';
import {withLoading} from 'client/js/util/rest_utils';
import {GenericReactForm, RadioCollectionInput, SubmitButton, FormActions} from 'client/js/util/form_utils';
import DatabaseGrantsInput from 'client/js/util/inputs/database_grants_input';
import {transform_db_user} from './lib';

class EditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {updated: false};

    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action) {
    if(action == 'form:success') {
      this.setState({updated: true})
    }
  }

  render() {
    if(this.state.updated)
      return <Redirect to={`/database_users/${this.props.data.id}`} />;

    const options = {"localhost": "localhost (vom eigenen Webspace)", "%": "% (vom eigenen Webspace und Internet)"}
    const access_options = {"admin": "Admin-Zugriff auf alle Datenbanken", "readonly": "Nur-Lesen (alle Datenbanken)", "readwrite": "Lese- und Schreibzugriff, keine Schema-Änderungen", usage: 'kein Zugriff', complex: 'benutzerdefinierte Rechte'}

    let defaults = {...this.props.data};

    if(this.props.data.access == 'simple')
      defaults.access = this.props.data.simple_access;

    return <div>
      <PageHeader text={`Datenbank-Benutzer ${this.props.data.username} ändern`} back_url={`/database_users/${this.props.data.id}/grants`} />

      <GenericReactForm namespace="database_user"
                        verb="put"
                        dispatch={this.dispatch}
                        transform_data={transform_db_user}
                        url={`/usercp/database_users/${this.props.data.id}.json`}
                        defaults={defaults}>

        <RadioCollectionInput name="hostname" label="Zugriff" options={options} required />
        <RadioCollectionInput name="access" label="Zugriff" options={access_options} required />

        <DatabaseGrantsInput name="grants" label="benutzerdefinierte Rechte" required />

        <FormActions>
          <SubmitButton icon="pencil" text="Benutzer aktualisieren" />
        </FormActions>
      </GenericReactForm>
    </div>;
  }
}

EditPage.propTypes = {
  data: PropTypes.object.isRequired
};

const EditHandle = withLoading(EditPage);
const Page = (props) => (<EditHandle url={`/usercp/database_users/${props.match.params.id}.json`} key_name="database_user" />)

Page.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default Page;
