import VirtualMachineActionButton from './virtual_machine_action_button';
import {FaIcon} from 'client/js/util/layout_utils';
import {Link} from 'react-router-dom';
import CopyableField from 'client/js/util/copyable_field';

const stateNames = I18n_usercp.vms.state;

const PrimaryIP = ({ ipAddresses }) => {
  const ip = ipAddresses
    ?.filter((el) => el.version === 4)
    .map((el) => el.address)[0];

  return <CopyableField value={ip} /> || '-';
};

const Row = ({ item }) => {
  let clampedCpuUsage = {
    width: `${Math.max(Math.min(item.cpu_percent, 100), 0)}%`,
  };

  let stateIcon = item.status;

  if (
    ['provisioned', 'created', 'installed', 'rebuilding'].includes(item.status)
  ) {
    stateIcon = 'installing';

    if (item.restore_progress) {
      clampedCpuUsage = { width: `${item.restore_progress}%` };
    }
  }

  if (!['destroying', 'installing', 'running', 'stopped'].includes(stateIcon)) {
    stateIcon = 'unknown';
  }

  return (
    <tr>
      <td>
        <img src={`/assets/icons/vms/${stateIcon}.svg`} alt={stateIcon} />
      </td>
      <td>
        <h4>
          <Link to={`/virtual_machines/${item.id}`}>{item.hostname}</Link>{' '}
          <small>{stateNames[item.status] || '??'}</small>
        </h4>
        <span className="subtitle">
          {item.vcores} vCores, {item.memory_gb} GB RAM, {item.ssd_storage_gb} GB SSD
        </span>
        <div className="progress tooltipped">
          <div className="progress-bar" style={clampedCpuUsage}></div>
        </div>
      </td>
      <td>
        {item.os?.startsWith('ubuntu') ? (
          <img alt="Ubuntu" src="/assets/icons/distro/ubuntu.png" />
        ) : (
          <FaIcon name="question-sign" />
        )}
      </td>
      <td>
        <PrimaryIP ipAddresses={item.ip_addresses} />
      </td>
      <td>
        {item.uptime ? dayjs().subtract(item.uptime, 'seconds').calendar() : '-'}
      </td>
      <td>
        <VirtualMachineActionButton
          key={item.id}
          status={item.status}
          id={item.id}
        />
      </td>
    </tr>
  );
};

export default Row;
