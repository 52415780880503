import {Route, Switch} from 'react-router-dom';
import Index from './index';
import Show from './show';
import Create from './create';
import Update from './update';

const ResourceRouter = () => (
    <Switch>
      <Route path="/handles/new" exact component={Create} />
      <Route path="/handles/:id/edit" exact component={Update} />
      <Route path="/handles/:id" component={Show} />
      <Route path="/handles" exact component={Index} />
    </Switch>);

export default ResourceRouter;
