import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import {StringInput} from 'client/js/util/form_utils';
import Callout from 'client/js/util/callout';
import { toast } from 'react-toastify';

const Form = () => (
  <>
    <Callout calloutClass="info">
      <p>Die AuthInfo ist ein Code mit dem wir gegenüber der Registrierungsstelle nachweisen, dass wir autorisiert wurden den Domain-Umzug durchzuführen. Die AuthInfo wird von deinem bisherigen Provider erstellt und dir sowie der Registrierungsstelle mitgeteilt. Bei dem Umzug der Domain geben wir die AuthInfo ebenfalls an und weisen so nach, dass wir von dir autorisiert sind den Umzug durchzuführen.</p>

      <p>
        Für den Umzug der Domain muss eine AuthInfo eingegeben werden. Bitte trage hier die AuthInfo ein, damit wir die Domain umziehen können.
      </p>

      <p><strong>Mit dem Absenden dieses Formulars wird der Umzug der Domain gestartet. Möchtest Du die Domain erst später umziehen, beispielsweise um noch E-Mails vom alten Provider umzuziehen, trage die AuthInfo bitte erst später ein.</strong></p>

    </Callout>

    <StringInput required name="auth_info" label="AuthInfo" autoComplete="off" autoFocus />
  </>
)

const onSuccess = () => {
  toast.success("Domain-Transfer gestartet")
}

const Page = (props) => (<GenericInteraction namespace="usercp"
                                             resource_name="domains"
                                             form_namespace="auth_info"
                                             action="update_auth_info"
                                             form_url={`/usercp/domains/${props.match.params.id}/auth_info`}
                                             method="post"
                                             button_text="Umzug starten"
                                             icon_name="exchange"
                                             onSuccess={onSuccess}
                                             redirectAfter="show"
                                             id={props.match.params.id}
                                             form_component={() => (<Form />)} />);

export default Page;
