import GenericCreate from 'client/js/resellercp/generic_resource/create';
import { StringInput } from 'client/js/util/form_utils';
import { FaIcon } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';

const Splash = () => (
  <div className="text-center">
    <h5 className="text-success">
      <FaIcon name="check-circle fa-4x mb-2" />
      <br />
      Zweiter Faktor erfolgreich hinzugefügt
    </h5>

    <p>Der zweite Faktor wurde erfolgreich hinzugefügt und wird in Zukunft beim Login und zur Bestätigung verwendet.</p>

    <p><Link to="/security">zurück zu Account-Sicherheit</Link></p>
  </div>
)

const Form = (props) => {
  return <div>
        <p>Installiere eine Authenticator-App (z.B. Aegis, 2FAS, etc.) auf Deinem Handy und scanne den folgenden QR-Code ein:</p>

        <img src={props.provisioning_data.svg_image} className="mb-3" />

        <p>Wenn Du den QR-Code nicht einscannen kannst, gib den folgenden Code manuell ein: <code>{props.provisioning_data.secret_key}</code>.</p>
        <p>Nach dem Scannen des Codes gib bitte einen Code aus der Authenticator-App ein, um den Faktor zu bestätigen:</p>

      <StringInput name="display_name" label="Name" hint="Name des Geräts" />

      <StringInput extraClass="form-control-lg" required name="code" label="Code" />
    </div>;
}

const Create = (props) => (<GenericCreate resource_name="authentication_factors" form_url="/usercp/authentication_factors/otp"
                                          create_splash={Splash}
                                          back_url="/security"
                                          defaults={{secret: props.data.secret_key}}
                                          form_component={() => (<Form provisioning_data={props.data} />)} />);

const LoadingCreate = withLoading(Create)

const Page = () => (<LoadingCreate url="/usercp/authentication_factors/provision_otp.json" />)

export default Page;
