import VirtualMachineActionButton from 'client/js/usercp/cloud/virtual_machines/virtual_machine_action_button';
import VirtualMachineHostname from 'client/js/usercp/cloud/virtual_machines/virtual_machine_hostname';
import CopyableField from 'client/js/util/copyable_field';
import { BackLink } from 'client/js/util/layout_utils';

export default class Header extends React.Component {
  renderState = () => {
    const state = this.props.virtual_machine.status;

    if(state == 'running' && this.props.virtual_machine.uptime >= 0)
      return `Gestartet ${dayjs().subtract(this.props.virtual_machine.uptime, 'seconds').fromNow()}`;

    if((state == 'created' || state == 'rebuilding') && this.props.virtual_machine.restore_progress)
      return `Wird erstellt...`;

    return I18n_usercp.vms.state[state];
  }

  ipv = (v) => {
    const ips = this.props.virtual_machine.ip_addresses;

    if(ips && ips.length && ips.filter(el => el.version == v).length)
      return ips.filter(el => el.version == v).slice(0, 1).map(el => el.address).join(', ');
    else
      return '-';
  }

  render() {
    return (
      <>
        <div className="page-header header-vms">
          <h1>
            <BackLink back_url="/virtual_machines" />
            <VirtualMachineHostname hostname={this.props.virtual_machine.hostname} id={this.props.virtual_machine.id}>
              <small>{this.renderState()}</small>

              {!!((this.props.virtual_machine.status == 'provisioned' || this.props.virtual_machine.status == 'rebuilding') && this.props.virtual_machine.restore_progress) && <div className="progress" style={{ display: 'inline-flex', width: '10rem', 'margin-left': '1rem' }}><div className="progress-bar" style={{ width: `${this.props.virtual_machine.restore_progress}%` }}>{this.props.virtual_machine.restore_progress} %</div></div>}
            </VirtualMachineHostname>
          </h1>

          <div className="resource-btns">
            <VirtualMachineActionButton status={this.props.virtual_machine.status} id={this.props.virtual_machine.id} />
          </div>
        </div>

        <div className="vm-info-bar">
          <strong>IPv4-Adresse</strong>: <CopyableField value={this.ipv(4)} />, <strong>IPv6-Adresse</strong>: <CopyableField value={this.ipv(6)} />
        </div>
      </>);
  }
}
