import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { FaIcon } from 'client/js/util/layout_utils';
import { AnimatedTabs, AnimatedNavItem as NavItem } from 'client/js/util/nav_tabs';
import { Link } from 'react-router-dom';
import { DetailsRow, DetailsSection } from '../../util/layout_utils';
import AddFundsButton from '../lib/add_funds_button';

const Status = ({ paid }) => {
  if (paid) {
    return <span className="badge badge-success">Bezahlt</span>
  } else {
    return <span className="badge badge-danger">Offen</span>
  }
}

const formatFractionPriceWithColor = (f) => {
  if(f < 0) {
    return <span className="text-danger">{format_fractional_price(f)}</span>
  } else if(f > 0) {
    return <span className="text-success">{format_fractional_price(f)}</span>
  }

  return format_fractional_price(f);
}


const Header = () => {
  return (
    <tr>
      <th>Nr.</th>
      <th>Status</th>
      <th>Datum</th>
      <th>Betrag</th>
      <th>Bestellung</th>
      <th></th>
    </tr>
  )
}

const TableRow = ({item: invoice}) => {
  return (
    <tr key={invoice.id}>
      <td>{invoice.id}</td>
      <td><Status paid={invoice.paid} /></td>
      <td>{dayjs(invoice.created_at).format('L')}</td>
      <td>{format_fractional_price(invoice.gross)}</td>
      <td>
        {invoice.order ? (
          <Link to={`/orders/${invoice.order.reference_code}`}>{invoice.order.reference_code}</Link>
        ) : <>&mdash;</>}
      </td>
      <td>
        <div className="btn-group">
          <a href={`/usercp/invoices/${invoice.id}.pdf`} className="btn btn-light"><FaIcon name="download" /> PDF</a>
          {invoice.xrechnung_available && <a href={`/usercp/invoices/${invoice.id}.xml`} className="btn btn-light"><FaIcon name="download" /> XRechnung</a>}
        </div>
      </td>
    </tr>
  );
}

const tabs = (data) => {
  return (
    <>
      <AnimatedTabs>
        <NavItem to="/invoices">Rechnungen</NavItem>
        <NavItem to="/orders">Bestellungen</NavItem>
      </AnimatedTabs>

      {data && <DetailsSection title="Kontostand" className="account-balance">
        <DetailsRow title="derzeitiger Saldo">{formatFractionPriceWithColor(data.balance)}</DetailsRow>
        {!!data.overdue_balance && <DetailsRow title="davon fällig" >{formatFractionPriceWithColor(data.overdue_balance * -1)}</DetailsRow>}
        {!!data.credit_limit && (
          <>
            <DetailsRow title="Kreditlimit">{format_fractional_price(data.credit_limit)}</DetailsRow>
            <DetailsRow title="Verfügbar">{formatFractionPriceWithColor(data.credit_limit_available)}</DetailsRow>
          </>
        )}
      </DetailsSection>}
    </>
  )
}

const Index = () => (
  <GenericIndex
    namespace="usercp"
    resource_name="invoices"
    tabs={tabs}
    table_header={Header}
    row={TableRow}
    back_url="/settings"
    buttons={<AddFundsButton btnClass="btn-light btn" />}
    no_create
  />
);

export default Index;
