import { PageHeader, Card, DetailsRow, HelpTooltip, DetailsSection, Alert } from 'client/js/util/layout_utils';
import {useResource} from 'client/js/util/rest_utils';
import {Route, Switch} from 'react-router-dom';
import Staging from './staging';
import Issues from './issues';
import Components from './components';
import SingleSignIn from './single_sign_in';
import ExternalLink from 'client/js/util/external_link';
import {ScreenshotContainer} from './lib';
import CopyableField from '../../util/copyable_field';
import {AnimatedTabs, AnimatedNavItem as NavItem} from 'client/js/util/nav_tabs';

const InfoCardBody = ({data}) => {
  const vhost = data.websites[0];

  return <div className="card-body">
      <p className="card-text">
        <span className="float-right text-muted">v{data.wordpress.version}</span>
        {' '}
        {vhost ? <ExternalLink href={vhost.url} text={vhost.domain} /> : 'WordPress ohne Domain'}
      </p>
    </div>;
}

// Show alerts for optimization, security etc.
const AlertsSection = ({data}) => {
  if(!data.database_exists)
    return <Alert alert_class="danger" text={`Die Datenbank '${data.wordpress.config.db_name}' aus der Konfigurationsdatei wp-config.php existiert nicht!`} />;

  return (
    <>
      {data.latest_version != data.wordpress.version && (
        <Alert alert_class="warning" text={`Die WordPress-Version ist nicht aktuell und sollte aktualisiert werden. Aktuelle Version: ${data.latest_version} - installiert: ${data.wordpress.version}`} />
      )}

      {data.wordpress.config.db_host != 'localhost' && (
        <Alert alert_class="info" text={`Der Datenbank-Hostname sollte für optimale Performance auf 'localhost' gesetzt werden. Ist: '${data.wordpress.config.db_host}'`} />
      )}

    </>
  );
}

const Overview = ({app_id}) => {
  const { data, component } = useResource(['wordpress', app_id], `/usercp/wordpress/${app_id}`, {
    keepPreviousData: true
  });

  if(component) {
    return component;
  }

  const wordpress = data.wordpress;

  return <>
    <AlertsSection app_id={app_id} data={data} />

    <div className="row mb-3">
      <div className="col-md-6">
        <Card title={false}>
          {!!data.websites.length && <ScreenshotContainer app_id={app_id} url={data.websites[0].url} />}


          <InfoCardBody data={data} />
        </Card>
      </div>
      <div className="col-md-6">
        <DetailsSection title="Einstellungen">
          <DetailsRow title={<>Webspace-Verzeichnis: <HelpTooltip content="In diesem Verzeichnis auf dem Webspace ist das WordPress installiert." /></>} text={<CopyableField value={data.path} />} />
          <DetailsRow title={<>Debug-Modus: <HelpTooltip content="Mit aktiviertem Debug-Modus werden kritische Fehlermeldungen angezeigt oder in eine Log-Datei geschrieben, mit deaktiviertem Debug-Modus wird eine WordPress-Fehlerseite angezeigt." /></>} text={wordpress.config.debug ? <span className="badge badge-success">deaktiviert</span> : <span className="badge badge-warning">aktiviert</span>} />
          <DetailsRow title={<>Cronjob-Modus: <HelpTooltip content="Der Cronjob-Modus gibt Auskunft darüber, wie WordPress Hintergrundaufgaben ausführt. Der optimale Modus ist Cronjob" /></>} text={wordpress.config.disable_wp_cron ? <span className="badge badge-success">Cronjob</span> : <span className="badge badge-warning">Hintergrund</span>} />

            {/*{!data.config.disable_wp_cron || data.config.disable_wp_cron == 'false' ?
              <DetailsRow title={<span>Virtueller Cronjob deaktiviert <HelpTooltip content="Der virtuelle Cronjob wp-cron.php sollte für optimale Performance deaktiviert sein. Ein Cronjob sollte stattdessen regelmäßig die wp-cron.php aufrufen." /></span>}>
                <FaIcon name="times text-danger" />
              </DetailsRow> :
              <DetailsRow title="Virtueller Cronjob" text={<FaIcon name="check text-success" />} />
            }

            {/*{!data.config.disable_wp_cron || data.config.disable_wp_cron == 'false' ?
              <DetailsRow title={<span>Cache aktiviert <HelpTooltip content="Für optimale Performance wird ein Cache-Plugin empfohlen" /></span>}>
                <FaIcon name="times text-danger" />
              </DetailsRow> :
              <DetailsRow title="Cache aktiviert" text={<FaIcon name="check text-success" />} />
            }*/}
        </DetailsSection>

        <DetailsSection title="Datenbank">
          <DetailsRow title="Datenbank-Host:" text={<CopyableField value={wordpress.config.db_host} />} />
          <DetailsRow title="Datenbank-Benutzer:" text={<CopyableField value={wordpress.config.db_user} />} />
          <DetailsRow title="Datenbank:" text={<CopyableField value={wordpress.config.db_name} />} />
          <DetailsRow title="Tabellen-Prefix:" text={<CopyableField value={wordpress.config.table_prefix} />} />
        </DetailsSection>
      </div>
      </div>
    </>;
}

const Show = (props) => {
  return <>
      <PageHeader text="WordPress" back_url="/wordpress" buttons={[<SingleSignIn key="0" app_id={props.match.params.id} />]} />

      <AnimatedTabs>
        <NavItem to={`/wordpress/${props.match.params.id}`} exact>Übersicht</NavItem>
        <NavItem to={`/wordpress/${props.match.params.id}/components`}>Plugins & Themes</NavItem>
        <NavItem to={`/wordpress/${props.match.params.id}/issues`}>Sicherheitsprobleme</NavItem>
        <NavItem to={`/wordpress/${props.match.params.id}/staging`}>Staging</NavItem>
      </AnimatedTabs>

      <Switch>
        <Route path="/wordpress/:id/issues" render={() => <Issues app_id={props.match.params.id} />} />
        <Route path="/wordpress/:id/components" render={() => <Components app_id={props.match.params.id} />} />
        <Route path="/wordpress/:id/staging" render={() => <Staging url={`/usercp/wordpress/${props.match.params.id}/staging.json`} app_id={props.match.params.id} />} />
        <Route path="/wordpress/:id" exact render={() => <Overview app_id={props.match.params.id} />} />
      </Switch>
    </>
}

export default Show;
