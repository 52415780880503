import SnapshotMenu from 'client/js/usercp/cloud/snapshots/snapshot_menu';
import { usePages } from 'client/js/util/pagination';
import { useResource } from 'client/js/util/rest_utils';

const SnapshotsContainer = (props) => {
  const [page, changePage] = usePages();
  const { data, component, refetch } = useResource(['virtual_machine', props.id, 'snapshots', {page}], `/usercp/virtual_machines/${props.id}/snapshots?page=${page}`, {
    keepPreviousData: true,
    refetchInterval: (data) => data?.snapshots.some(s => (s.state == 'queued' || s.state == 'running')) ? 3000 : false,
  });

  if(component)
    return component;

  return <SnapshotMenu
          refreshCb={refetch}
          changePage={changePage}
          snapshots={data.snapshots}
          pagination_data={data.pagination}
          restore_enabled {...props}
         />;
}

export default SnapshotsContainer;
