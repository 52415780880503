import Callout from 'client/js/util/callout';
import { Alert, DetailsRow, DetailsSection, FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { useResource, errorToToast } from 'client/js/util/rest_utils';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import AddFundsButton from '../lib/add_funds_button';
import SubscriptionMigrationBanner from './subscription_migration_banner';
import { Tooltip } from 'reactstrap';

const StatusBadge = (props) => {
  if(props.cancelled)
    return <span className="badge badge-danger ml-auto">Gekündigt</span>;

  if(props.subscribed)
    return <span className="badge badge-success ml-auto">Aktiv</span>;

  if(props.in_trial)
    return <span className="badge badge-secondary ml-auto">Testzeitraum</span>;

  return null;
}

const DestroyModal = (props) => {
  return (
    <Modal isOpen={props.open} className="modal fade in show d-block" contentLabel="Zusatzpaket kündigen">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Zusatzpaket kündigen</h5>
            <button type="button" className="close" onClick={props.abort} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {props.loading ?
              <span className="css-spinloader fullpage-spinner"></span> :
              <React.Fragment>
                <p>Das Zusatzpaket &quot;{props.addon.title}&quot; wird zum {dayjs(props.paid_through_date).format('L')} gekündigt. Bis zu diesem Datum (einschließlich) bleibt das Zusatzpaket aktiv.</p>
                <p>Möchtest Du fortfahren?</p>
              </React.Fragment>}
          </div>

          <div className="modal-footer">
            <button className="btn btn-danger" onClick={props.confirm} disabled={props.loading}><FaIcon name={props.loading ? 'spinner fa-spin' : 'trash-o'} /> kündigen</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

class CancelAddonLink extends React.Component {
  state = { working: false, open: false }

  startDestroy = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  }

  abort = (e) => {
    e.preventDefault();

    if (this.state.working)
      return
    this.setState({ open: false });
  }

  destroy = (e) => {
    e.preventDefault();
    this.setState({ working: true });

    $.ajax({ url: `/usercp/webhosting/addons/${this.props.addon.id}`, type: 'DELETE' })
      .done(() => {
        this.setState({ working: false });
        this.props.refreshCb();
      })
      .fail((xhr) => {
        this.setState({ failed: true, working: false });
        errorToToast(xhr, "beim Kündigen des Zusatzpakets");
      });
  }

  render() {
    return (
      <React.Fragment>
        <a href="#delete" className="ml-auto text-small" onClick={this.startDestroy}>kündigen</a>

        <DestroyModal open={this.state.open}
          paid_through_date={this.props.paid_through_date}
          addon={this.props.addon}
          loading={this.state.working}
          confirm={this.destroy}
          abort={this.abort} />
      </React.Fragment>
    );
  }
}

const AddonCard = ({addon, subscription, refreshCb}) => (
  <>
    <DetailsSection key={addon.id} title={`Zusatzpaket: ${addon.title}`}>
      <DetailsRow title="Status:">
        {addon.cancellation_logged_at ? <span className="badge badge-danger ml-auto">Gekündigt</span> : <CancelAddonLink addon={addon} paid_through_date={subscription.paid_through_date} refreshCb={refreshCb} />}
      </DetailsRow>
      <DetailsRow title="Gebucht am:">{dayjs(addon.created_at).format('L')}</DetailsRow>
      {!!addon.cancellation_logged_at && <DetailsRow title="Gekündigt zum:">{dayjs(addon.cancellation_effective_at).format('L')}</DetailsRow>}
      <DetailsRow title="Preis:">{format_fractional_price(addon.price_per_month)}/Monat</DetailsRow>
    </DetailsSection>
  </>
)

const AddonsList = ({subscription, refreshCb}) => {
  if(!subscription.addons || !subscription.addons.length)
    return null;

  return subscription.addons.map((addon) => (<AddonCard key={addon.id} addon={addon} subscription={subscription} refreshCb={refreshCb} />));
}

const ContractCard = (props) => {
  const has_inclusive_domains = !!(props.subscription.inclusive_domains && props.subscription.inclusive_domains > 0)
  const subscription = props.subscription;
  let fields = [];

  fields.push(<DetailsRow title="Einrichtungsdatum:">{dayjs(subscription.created_at).format('L')}</DetailsRow>)

  if(!subscription.cloud_hosting) {
    if(subscription.upgraded_at) {
      if(subscription.in_trial)
      fields.push(<DetailsRow title="Testzeitraum bis:">{dayjs(subscription.trial_until).format('L')}</DetailsRow>);

      if(subscription.subscribed) {
        fields.push(<DetailsRow title="Preis pro Monat:">{format_fractional_price(subscription.price_per_month)}</DetailsRow>)
        fields.push(<DetailsRow title="Abgerechnet bis:">{dayjs(subscription.paid_through_date).format('L')}</DetailsRow>)
        fields.push(<DetailsRow title="Abrechungszeitraum:">{i18n_c("month", subscription.billing_cycle)}</DetailsRow>)
      }

      if(subscription.cancelled)
        fields.push(<DetailsRow title="Gekündigt zum:">{dayjs(subscription.cancelled_at).format('L')}</DetailsRow>);
      else
        fields.push(<DetailsRow title="Nächster Kündigungszeitpunkt:">{dayjs(subscription.cancellation_date).format('L')}</DetailsRow>);

      if(has_inclusive_domains) {
        fields.push(<DetailsRow title="Inklusiv-Domains:">
              {subscription.inclusive_domains} ({subscription.inclusive_domains_used} genutzt)
              {subscription.in_trial && <sup>1</sup>}
            </DetailsRow>);
        fields.push(<DetailsRow title="Domain-Endungen inklusive:">{subscription.inclusive_tlds.map((i) => `.${i}`).join(', ')}</DetailsRow>);
      }
    }
  }

  return <React.Fragment>
    <DetailsSection title="Übersicht">
      <DetailsRow title="Status:">
        <StatusBadge {...subscription} />
      </DetailsRow>
      <DetailsRow title="Paket:">
        {subscription.offer_name}
      </DetailsRow>
      <DetailsRow title="Preis:">
        {format_fractional_price(subscription.price_per_month)} pro Monat
      </DetailsRow>
      {!(subscription.tier == 'freespace' && !subscription.disk_space_gb) && <DetailsRow title="Speicherplatz:">
        {subscription.disk_space_gb} GB ({i18n_t(`resources.webhosting.tier.${subscription.tier}`)})
      </DetailsRow>}
      {!!subscription.mysql_space_gb && <DetailsRow title="MySQL-Speicherplatz:">
        {subscription.mysql_space_gb} GB
      </DetailsRow>}
      {!!subscription.email_space_gb && <DetailsRow title="E-Mail-Speicherplatz:">
        {subscription.email_space_gb} GB
      </DetailsRow>}
      {fields.map((item) => (item))}
    </DetailsSection>

    <AddonsList {...props} />

    {has_inclusive_domains && subscription.in_trial && <p><sup>1</sup> Die Inklusiv-Domains können während des kostenlosen Test-Zeitraums nicht verwendet werden.</p>}
  </React.Fragment>
}

const PaymentSpecifics = (props) => {
  let nextPayment = <DetailsRow title="Nächste Zahlung" text={`${format_fractional_price(props.next_billing_amount)} am ${dayjs(props.next_billing_date).format('l')}`} />;

  if(props.type == 'direct_debit')
    return <>
        <DetailsRow title="IBAN:" text={props.payment_method.iban} />
        <DetailsRow title="Mandat:" text={props.payment_method.mandate_id} />
        {nextPayment}
      </>
  if(props.type == 'credit_card')
    return <>
        <DetailsRow title="Kartennummer:" text={props.payment_method.credit_card_number} />
        <DetailsRow title="Gültig bis:" text={props.payment_method.valid_through} />
        {nextPayment}
      </>
  if(props.type == 'paypal')
    return <>
        <DetailsRow title="Abo-ID:" text={props.payment_method.paypal_subscription_id} />
        {nextPayment}
      </>
  if(props.type == 'prepaid')
    return <>
        <DetailsRow title="Guthaben:" text={format_fractional_price(props.current_balance)} />
        {nextPayment}
      </>

  return <>{nextPayment}</>;
}

const PaymentMethodStatus = (props) => {
  if(props?.payment_method?.blocked)
    return <span className="badge badge-danger">Gesperrt</span>;

  if(props.type == 'direct_debit' && !props?.payment_method?.verified)
    return <span className="badge badge-warning">nicht bestätigt</span>;

  if(props?.payment_method?.default_payment_method)
    return <span className="badge badge-primary">Standard-Zahlungsmethode</span>;

  return <span className="badge badge-success">Aktiv</span>;
}

const PaymentMethodCard = (props) => {
  if(!props.type)
    return null;

  return <>
      <DetailsSection title="Zahlungsmethode">
        <DetailsRow title="Zahlungsmethode:">
          {i18n_t(`resources.webhosting.payment_method.${props.type}`)}
        </DetailsRow>
        {props.type != 'prepaid' && (
          <DetailsRow title="Status:">
            <PaymentMethodStatus {...props} />
          </DetailsRow>
        )}
        <PaymentSpecifics {...props} />
    </DetailsSection>
      {props.type == 'prepaid' && <AddFundsButton btnClass="btn btn-light mb-3" wrapperClass="d-inline-block" />}
      {props.type == 'direct_debit' && !props.payment_method.verified && <Link to={`/payment_methods/${props.payment_method.id}`} className="btn btn-light mb-3"><FaIcon name="check" /> Konto bestätigen</Link>}
    </>;
}

const ApplicationLink = () => (<Link to="/webhosting/apply_for_freespace" className="list-group-item list-group-item-action">für kostenloses Webhosting anmelden →</Link>);

const FreespaceApplicationCallout = (props) => {
  if(props.status == 'rejected')
    return <Callout calloutClass="warning" title="Kostenloser Webspace abgelehnt">
        <p>Leider wurde Deine Anmeldung zum kostenlosen Webspace abgelehnt.</p>
        <p>Begründung: {props.reason || '-'}</p>
      </Callout>;

  return <Callout calloutClass="info" title="Anmeldung wird geprüft" text="Wir prüfen derzeit die Anmeldung zum kostenlosen Webspace und benachrichtigen Dich per E-Mail, sobald eine Entscheidung getroffen wurde. Alternativ kannst Du jederzeit ein Webhosting-Paket buchen oder 14 Tage kostenlos testen." />;
}

const TestSubscription = (props) => {
  return <React.Fragment>
          {!props.data.freespace_application && <Callout calloutClass="info" title="Kein Webhosting-Paket vorhanden">
            <p>Auf Deinem Account ist derzeit kein Webhosting-Paket vorhanden. Du kannst ein Webhosting-Paket kostenlos und unverbindlich für 14 Tage testen, direkt buchen oder für nicht-produktive Webseiten unseren kostenlosen Webspace benutzen. Auf dem kostenlosen Webspace werden u.a. keine Backups erstellt und wir garantieren keine Verfügbarkeit der Webseiten.</p>

            <p>Um Zugriff auf die Webhosting-Funktionen zu erhalten musst Du zuerst ein Webhosting-Paket anlegen.</p>
          </Callout>}

          {!!props.data.freespace_application && <FreespaceApplicationCallout {...props.data.freespace_application} />}

          <div className="list-group list-group-flush">
            <Link to="/webhosting/create_test" disabled={!props.data.can_test} className="list-group-item list-group-item-action">
              Webhosting-Paket 14 Tage kostenlos und unverbindlich testen  →
              {!props.data.can_test && <React.Fragment><br /><strong>Der kostenlose Test wurde schon einmal auf diesem Account in Anspruch genommen und ist nicht erneut verfügbar.</strong></React.Fragment>}
            </Link>

            <Link to="/webhosting/order" className="list-group-item list-group-item-action">
              Webhosting-Paket direkt buchen →
            </Link>

            {!!props.data.freespace_application || <ApplicationLink />}
          </div>
        </React.Fragment>;
}

const TestAlreadyUsed = () => {
  return <Callout calloutClass="warning" title="Kein Webhosting-Paket vorhanden">
    <p>Auf Deinem Webhosting-Paket ist derzeit kein Webhosting-Paket aktiv. Den kostenlosen Testzeitraum für ein Webhosting-Paket hast Du bereits in Anspruch genommen und ein weiterer Test ist nicht möglich.</p>

    <p>Wenn Du ein Webhosting-Paket buchen möchtest kannst Du dieses nur <Link to="/webhosting/order">direkt buchen</Link>.</p>

    <p>Alternativ kannst Du Dich für den <Link to="/webhosting/apply_for_freespace">kostenlosen Webspace anmelden</Link>.</p>
  </Callout>
}

const AlertBanner = (props) => {
  const data = props.data.subscription;

  if(data.cloud_hosting)
    return null;

  if(!data.upgraded_at)
    return <SubscriptionMigrationBanner />

  if(!data.cancelled && !data.subscribed)
    return <Alert text="Das Webhosting-Paket ist derzeit im Testzeitraum und läuft am Ende des Testzeitraums ab, sofern es nicht gebucht wird!" />;

  if(data.tier == 'freespace')
    return <React.Fragment>
      <Callout calloutClass="warning" title="Hinweis zum kostenlosen Webspace">
        <p>
          Du nutzt derzeit das kostenlose Webspace-Angebot.
          &nbsp;
          <strong>Der kostenlose Webspace ist nicht dafür gedacht, darauf produktive oder professionelle Webseiten zu hosten!</strong>
          &nbsp;
          Insbesondere erzeugen wir keine Backups, garantieren keine Mindest-Verfügbarkeit und es ist kein ADV-Vertrag für DSGVO-compliance inklusive!
          <br/>
          Sofern Du eine professionelle Webseite auf unseren Servern hosten möchtest oder Daten nach EU-DSGVO auf dem Webspace verarbeitest (Kontaktformular, Kommentar-Funktion, Registrierung, ...) solltest Du dringend auf eins unserer Webhosting-Angebote wechseln!
        </p>
      </Callout>
      <div className="list-group list-group-flush mb-3">
        <Link to="/webhosting/create_test" disabled={!data.can_test} className="list-group-item list-group-item-action">
          Webhosting-Paket 14 Tage kostenlos und unverbindlich testen  →
          {!data.can_test && <strong>Der kostenlose Test wurde schon einmal auf diesem Account in Anspruch genommen und ist nicht erneut verfügbar.</strong>}
        </Link>

        <Link to="/webhosting/order" className="list-group-item list-group-item-action">
          Webhosting-Paket direkt buchen →
        </Link>
      </div>
    </React.Fragment>;

  return null;
}

const DisabledPaymentMethodButton = () => {
  const [tooltipId] = useState(`tooltip${Math.floor(Math.random() * 1000000000)}`);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <button id={tooltipId} className="btn btn-light" disabled><FaIcon name="pencil-square-o" /> Zahlungsmethode ändern </button>

      <Tooltip
        isOpen={tooltipOpen}
        target={tooltipId}
        toggle={toggle}
      >
        Es ist keine weitere Zahlungsmethode vorhanden, daher kann die Zahlungsmethode nicht geändert werden. Bitte füge eine weitere Zahlungsmethode unter Einstellungen &rarr; Zahlungsmethoden hinzu.
      </Tooltip>
    </>
  );
}

const Index = (props) => {
  const { component, data, refetch } = useResource(['webhosting_subscription'], "/usercp/webhosting", {
    keepPreviousData: true,
    header: <PageHeader text="Webhosting-Paket" />
  });

  const { data: paymentMethodData } = useResource(['payment_methods'], "/usercp/payment_methods", {
    keepPreviousData: true
  });

  if(component)
    return component;

  const subscription = data.subscription;

  if(subscription === false) {
    return <React.Fragment>
        <PageHeader text="Webhosting-Paket" />

        {data.can_test ? <TestSubscription data={data} /> : <TestAlreadyUsed data={data} />}
      </React.Fragment>;
  }

  let buttons = [];
  // cancellable means that it's a paid subscription and not a freespace subscription
  if(subscription.cancellable) {
    if(!subscription.cancelled) {
      if(subscription.subscribed) {
        if(paymentMethodData?.payment_methods?.length <= 1) { // we don't have any other payment methods, so the user cannot choose
          buttons.push(<DisabledPaymentMethodButton />);
        } else {
          buttons.push(<Link to="/webhosting/payment_method/edit" className="btn btn-light"><FaIcon name="pencil-square-o" /> Zahlungsmethode ändern</Link>);
        }
      } else {
        buttons.push(<Link to="/webhosting/subscribe" className="btn btn-primary"><FaIcon name="handshake-o" /> zur Buchung</Link>);
      }

      buttons.push(<Link to="/webhosting/addons/new" className="btn btn-light"><FaIcon name="plus" /> Zusatzpaket buchen</Link>);

      buttons.push(<Link to="/webhosting/cancel" className="btn btn-light"><FaIcon name="ban" /> {subscription.in_trial ? 'Test abbrechen' : 'kündigen'}</Link>);
    }
  }

  if(subscription.tier == 'freespace') {
    //buttons.push(<Link to="/webhosting/upgrade" className="btn btn-secondary"><FaIcon name="level-up" /> Paket wechseln</Link>);
  }

  if (subscription.cloud_hosting)
    buttons = [];

  return <>
    <PageHeader text="Webhosting-Paket" buttons={buttons} />

    <AlertBanner data={data} />

    <div className="row">
      <div className="col-md-6">
        <ContractCard subscription={subscription} refreshCb={refetch} />
      </div>
      {subscription.subscribed && <div className="col-md-6">
        <PaymentMethodCard type={subscription.payment_method_type}
                           current_balance={subscription.current_balance}
                           next_billing_date={subscription.next_billing_date}
                           next_billing_amount={subscription.next_billing_amount}
                           payment_method={subscription.payment_method} />
      </div>}
    </div>
  </>
}

export default Index;
