import GenericCreate from 'client/js/resellercp/generic_resource/create';
import { RadioCollectionInput, StringInput } from 'client/js/util/form_utils';
import { PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const LimitVisibility = ({ children, visibleValue, visibleField }, { formData }) => {
  if (formData && formData[visibleField] == visibleValue)
    return children;

  return null;
}

LimitVisibility.propTypes = {
  visibleField: PropTypes.string.isRequired,
  visibleValue: PropTypes.string.isRequired
};

LimitVisibility.contextTypes = {
  formData: PropTypes.object
};

const Form = ({ available_balance }) => {
  return (
    <>
      <RadioCollectionInput label="Typ" name="destination" options={{ 'account': 'Account-Guthaben', 'sepa': 'SEPA-Überweisung' }} />

      <LimitVisibility visibleField="destination" visibleValue="sepa">
        <StringInput required label="Kontoinhaber" name="account_holder" placeholder="z.B. Max Mustermann" />
        <StringInput required label="IBAN" name="iban" placeholder="z.B. DE02120300000000202051" />
      </LimitVisibility>

      <StringInput required label="Betrag" name="amount" type="number" hint={`Mindestbetrag 10,00 € - verfügbarer Betrag: ${format_fractional_price(available_balance)}`} />
    </>
  )
}

const Page = () => {
  const onSuccess = () => {
    toast.success("Auszahlung erstellt - Überweisungen werden täglich um 16:00 ausgeführt.");
  }

  const { data, component } = useResource(['affiliate_payouts', 'new'], '/usercp/affiliate_payouts/new', {
    keepPreviousData: true,
    header: <PageHeader text="Auszahlung erstellen" />
  });

  if (component) {
    return component;
  }

  return (<GenericCreate namespace="usercp" onSuccess={onSuccess} resource_name="affiliate_payouts" defaults={{destination: 'sepa'}} redirectAfter="index" form_component={() => (<Form available_balance={data.available_balance} />)} />);
};

export default Page;
