import {PageHeader, FaIcon} from 'client/js/util/layout_utils';
import {errorToToast} from 'client/js/util/rest_utils';
import Callout from 'client/js/util/callout';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

class Page extends React.Component {
  state = {sso_step: 0}

  startSso = (e) => {
    e.preventDefault();
    this.setState({sso_step: 1, sso_failed: false})
    $.post('/usercp/phpmyadmin/sso_url', {dataType: 'json'}).done((data) => {
      this.setState({sso_step: 2, sso_url: data.url, expires_at: data.expires_at});
    }).fail((xhr) => {
      this.setState({sso_step: 0});
      errorToToast(xhr);
    });
  }

  render() {
    return <>
      <PageHeader text="phpMyAdmin" />

      {this.state.sso_failed && <div className="alert alert-danger"><strong>SSO fehlgeschlagen</strong> Beim Abruf der Single-Sign-In-URL ist ein Fehler aufgetreten. Bitte versuche es noch einmal!</div>}

      {this.state.sso_step == 0 &&
        <Callout title="phpMyAdmin" calloutClass="info">
          <p>Für den Login in den phpMyAdmin gibt es zwei Möglichkeiten:</p>
          <ul>
            <li>Du verwendest einen MySQL-Benutzer, dessen Paswort Dir bekannt ist</li>
            <li>Du benutzt den Single-Sign-In. Dabei wird ein temporärer MySQL-Benutzer mit Admin-Rechten erstellt. Dieser MySQL-Account wird drei Stunden nach dem Single-Sign-In gelöscht.</li>
          </ul>

          <div className="btn-group">
            <a href="https://pma.lima-city.de" className="btn btn-secondary" target="_blank" rel="noopener noreferrer"><FaIcon name="external-link" /> phpMyAdmin öffnen</a>
            <a href="https://pma.lima-city.de/sso.php" onClick={this.startSso} className="btn btn-secondary" target="_blank" rel="noopener noreferrer"><FaIcon name="sign-in" /> Single-Sign-In</a>
          </div>
        </Callout>}

      {this.state.sso_step == 1 && <LoadingTableSpinner text="Erstelle Single-Sign-In..." />}
      {this.state.sso_step == 2 && <div className="text-center">
          <h2>Single-Sign-In erstellt</h2>
          <p className="mb-3">Es wurde ein temporärer MySQL-Zugang erstellt, mit dem Du Dich nun in den phpMyAdmin einloggen kannst. <br/> Der Button sollte Dich direkt in den phpMyAdmin einloggen:</p>
          <a href={this.state.sso_url} className="btn btn-primary mb-3" target="_blank" rel="noopener noreferrer"><FaIcon name="external-link" /> phpMyAdmin öffnen</a>
          <p><small className="text-muted">Der Zugriff auf den phpMyAdmin läuft {dayjs(this.state.expires_at).format('l')} ab.</small></p>
        </div>}
    </>
  }
}

export default Page;
