import {FaIcon} from 'client/js/util/layout_utils';
import Modal from 'react-modal';
import { Dropdown } from 'client/js/usercp/table_utils';
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from "react-toastify";

var NameCol = function(props) {
  if(props.renderOrigin) {
    const label_name = `snapshot_select_${props.snapshot.id}`;

    if(props.selectSnapshot) {
      return (
        <td>
          <input id={label_name} type="radio" name="usercp_create_vm[snapshot_id]" className="large_checkbox" onChange={e => props.dispatch && props.dispatch("snapshot_selected", e, props.snapshot)} value={props.snapshot.id} defaultChecked={false} required={true} />
          <label htmlFor={label_name} className={props.selectSnapshot ? 'css-label' : null}>
            {props.snapshot.name} <br/>
            <small className="muted">Snapshot von {props.snapshot.origin_name}</small>
          </label>
        </td>
        );
    }

    return (
      <td>
        {props.snapshot.name}<br/>
        <small className="muted">Snapshot von {props.snapshot.origin_name}</small>
      </td>
      );
  }

  return <td>{props.snapshot.name}</td>;
}

const stateNames = {
    queued: 'eingereiht',
    running: 'wird erstellt',
    completed: 'fertig',
    destroying: 'wird gelöscht',
    destroyed: 'gelöscht',
    failed: 'fehlgeschlagen'
  };


const ConfirmationModal = (props) => {
  return <Modal isOpen={props.open} className="modal fade in show d-block" contentLabel={props.modal_title}>
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{props.modal_title}</h5>
          <button type="button" className="close" onClick={props.abort} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {props.loading ?
            <span className="css-spinloader fullpage-spinner"></span> :
            <p>{props.modal_text}</p>}
        </div>

        <div className="modal-footer">
          <button className={props.button_class} onClick={props.confirm} disabled={props.loading}>
            <FaIcon name={props.loading ? 'spinner fa-spin' : props.button_icon} /> {props.button_text}
          </button>
        </div>
      </div>
    </div>
  </Modal>
}

ConfirmationModal.defaultProps = {
  button_class: 'btn btn-danger',
  button_text: 'löschen',
  button_icon: 'trash-o',
}

ConfirmationModal.propTypes = {
  button_class: PropTypes.string,
  button_text: PropTypes.string,
  button_icon: PropTypes.string,
  modal_title: PropTypes.string,
  modal_text: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}

class DestroyDropdownItem extends React.Component {
  state = { working: false, open: false }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  }

  abort = (e) => {
    e.preventDefault();

    if (this.state.working)
      return
    this.setState({ open: false });
  }

  destroy = (e) => {
    e.preventDefault();
    this.setState({ open: false });
    const toast_id = toast(<React.Fragment><span>Lösche Snapshot...</span> <span className="css-spinloader float-right mr-1"></span></React.Fragment>, { closeButton: false, autoClose: false })

    $.ajax({ url: this.props.url, type: this.props.method, data: this.props.data })
      .done(() => {
        this.setState({ working: false });
        toast.update(toast_id, { render: this.props.success_toast_text, type: toast.TYPE.SUCCESS, autoClose: 5000, closeButton: true });
        this.props.refreshCb();
      })
      .fail(() => {
        this.setState({ working: false });
        toast.update(toast_id, { render: this.props.failure_toast_text, type: toast.TYPE.ERROR, autoClose: 5000, closeButton: true });
      });
  }

  render() {
    return <React.Fragment>
      <a href="#" role="button" className={this.props.link_class} onClick={this.openModal}>{this.props.link_text}</a>

      <ConfirmationModal open={this.state.open}
        loading={this.state.working}
        confirm={this.destroy}
        abort={this.abort}
        modal_text={this.props.modal_text}
        modal_title={this.props.modal_title}
        button_text={this.props.button_text}
        button_icon={this.props.button_icon}
        button_class={this.props.button_class}
         />

      </React.Fragment>;
  }
}

DestroyDropdownItem.defaultProps = {
  method: 'DELETE',
  link_class: 'dropdown-item text-danger',
  link_text: 'löschen',
}

DestroyDropdownItem.propTypes = {
  url: PropTypes.string.isRequired,
  refreshCb: PropTypes.func.isRequired,
  modal_text: PropTypes.string.isRequired,
  modal_title: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  link_class: PropTypes.string.isRequired,
  success_toast_text: PropTypes.string.isRequired,
  failure_toast_text: PropTypes.string.isRequired,
  data: PropTypes.object,
}

const ActionColumn = (props) => {
  const {item, refreshCb} = props;

  return (
    <td>
      <Dropdown running={item.state == 'running' || item.state == 'queued'} disabled={item.state != 'completed'} direction="right">
        {!!props.restore_enabled &&
          <DestroyDropdownItem item={item} refreshCb={refreshCb}
            modal_text="Soll der Snapshot wirklich wiederhergestellt werden? Alle Daten auf der virtuellen Maschine werden auf den Zustand zum Zeitpunkt des Snapshots zurückgesetzt."
            modal_title="Snapshot wiederherstellen"
            button_text="wiederherstellen"
            button_icon="undo"
            button_class="btn btn-light"
            success_toast_text="Wiederherstellung wurde gestartet."
            failure_toast_text="Fehler beim Starten der Wiederherstellung."
            url={`/usercp/virtual_machines/${props.virtual_machine_id}/restore`}
            data={{snapshot_id: item.id}}
            method="POST"
            link_class='dropdown-item'
            link_text='wiederherstellen'
        />}

        <DestroyDropdownItem item={item} refreshCb={refreshCb}
          modal_text="Soll der Snapshot wirklich gelöscht werden?"
          modal_title="Snapshot löschen"
          success_toast_text="Snapshot wurde gelöscht."
          failure_toast_text="Fehler beim Löschen des Snapshots."
          url={item.delete_url}
           />
      </Dropdown>
    </td>
  )
}

class Row extends React.Component {
  renderSize = () => {
    if(this.props.selectSnapshot)
      return `${this.props.snapshot.disk_size_gb} GiB`;

    if(this.props.snapshot.state == "completed" && this.props.snapshot.size)
      return `${this.props.snapshot.size.fileSize()}/${this.props.snapshot.disk_size_gb} GiB`;

    return "-";
  }

  render() {
    return (
      <tr>
        <NameCol renderOrigin={this.props.renderOrigin} snapshot={this.props.snapshot} selectSnapshot={this.props.selectSnapshot} dispatch={this.props.dispatch} />
        <td className="text-nowrap">{stateNames[this.props.snapshot.state]} {this.props.snapshot.progress ? ` (${this.props.snapshot.progress}%)` : null}</td>
        <td className="text-nowrap">{format_date(this.props.snapshot.created_at)}</td>
        <td className="text-nowrap">{this.renderSize()}</td>
        {!this.props.selectSnapshot && <ActionColumn virtual_machine_id={this.props.virtual_machine_id} item={this.props.snapshot} refreshCb={this.props.fetchSnapshots} restore_enabled={this.props.restore_enabled} />}
      </tr>);
  }
}

export default Row;
