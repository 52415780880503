import PropTypes from "prop-types";
import ExternalLink from 'client/js/util/external_link';
import {StringInput, TextInput, SubmitButton, CheckboxCollectionInput, GenericReactForm} from "client/js/util/form_utils";
import {Modal, ModalHeader} from 'client/js/util/modal';
import {useState} from 'react';
import {withLoading} from 'client/js/util/rest_utils';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';

var AuthorizedKeysInput = function(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const onSuccess = () => {
    props.refreshCb();
    setModalOpen(false);
    toast.success("SSH-Key erfolgreich hinzugefügt");
  }

  const ssh_keys_map = props.data ? props.data.map((item) => ([item.id, item.friendly_name])) : [];

  return (<>
      <CheckboxCollectionInput name={props.name} label="SSH-Keys" required options={ssh_keys_map} hint={props.hint} />

      <Modal isOpen={modalOpen} large contentLabel="SSH-Key anlegen" onRequestClose={() => setModalOpen(false)}>
        <ModalHeader title="SSH-Key anlegen" onRequestClose={() => setModalOpen(false)} />

        <GenericReactForm namespace="ssh_key" url="/usercp/ssh_keys" onSuccess={onSuccess}>
          <div className="modal-body">
            <StringInput name="name" label="Name (optional)" />
            <TextInput name="text_key" label="SSH-Key in Textform" rows={8} extraClass="input-xxlarge"
                      required={true}
                        placeholder="Beginnt mit 'ssh-rsa', 'ssh-dss', 'ssh-ed25519', 'ecdsa-sha2-nistp256', 'ecdsa-sha2-nistp384' oder 'ecdsa-sha2-nistp521'"
                        hint={<span>Rufe unsere <ExternalLink href="/hilfe/ssh-keys-erzeugen">Hilfe für die Generierung von SSH-Keys</ExternalLink> auf.</span>} />
          </div>

          <div className="modal-footer">
            <SubmitButton icon="plus" text="SSH-Key erstellen" />
          </div>
        </GenericReactForm>
      </Modal>

      <div className="row form-group">
        <div className="col-md-9 offset-md-3">
          <a href="/usercp/ssh_keys/new" onClick={(e) => { e.preventDefault(); setModalOpen(true) }}>Neuen SSH-Key hinzufügen</a>
          <br/>
          <Link to="/ssh_keys">Einstellungen &rarr; SSH-Keys verwalten</Link>
        </div>
      </div>
    </>);
}

AuthorizedKeysInput.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string.required
};

AuthorizedKeysInput.contextTypes = {
  namespace: PropTypes.string
};

const AuthorizedKeysInputLoading = withLoading(AuthorizedKeysInput);

const AuthorizedKeysInputContainer = (props) => {
  return <AuthorizedKeysInputLoading url="/usercp/ssh_keys.json" key_name="ssh_keys" {...props} />
}

export default AuthorizedKeysInputContainer;
