import GenericCreate from 'client/js/resellercp/generic_resource/create';
import { StringInput, RadioCollectionInput, SelectCollectionInput } from 'client/js/util/form_utils';

const types = {
  "A": "A (IPv4-Adresse)",
  "AAAA": "AAAA (IPv6-Adresse)",
  "CNAME": "CNAME (kanonischer Name, Verweis)",
  "SPF": "SPF (veraltet, seit 2014 durch TXT ersetzt)",
  "MX": "MX (Mail Exchange)",
  "SRV": "SRV (IP-basierter Service-Eintrag)",
  "TXT": "TXT (Text)",
  "PTR": "PTR (Pointer)",
  "NS": "NS (Nameserver-Record)",
  "CAA": "CAA (Certification Authority Authorization)",
  "TLSA": "TLSA (TLSA certificate association)",
  "DS": "Delegation Signer (DS)",
  "DNSKEY": "DNSKEY"
}

const ttls = [ [ 120, "2 Minuten" ], [ 300, "5 Minuten" ], [ 600, "10 Minuten" ], [ 900, "15 Minuten" ], [ 1800, "30 Minuten" ], [ 3600, "1 Stunden" ], [ 7200, "2 Stunden" ], [ 18000, "5 Stunden" ], [ 43200, "12 Stunden" ], [ 86400, "24 Stunden" ] ]

const Form = () => (<div>
  <SelectCollectionInput name="type" label="Typ" required options={types} />
  <StringInput name="name" label="Name" placeholder="z.B. www" />
  <StringInput name="content" label="Inhalt" required className="input-xxlarge" />

  <SelectCollectionInput name="ttl" label="TTL" options={ttls} />

  <StringInput type="number" name="priority" label="Priorität (für MX/SRV)" hint="Standard: 0" />
</div>);


const New = (props) => (
  <GenericCreate
    namespace="usercp"
    defaults={{ type: 'A', ttl: 1800 }}
    resource_name="record"
    display_resource_name="dns_records"
    redirectAfter="url"
    back_url={`/domains/${props.match.params.domain_id}/records`}
    redirect_url={`/domains/${props.match.params.domain_id}/records`}
    form_url={`/usercp/domains/${props.match.params.domain_id}/records`}
    form_component={() => (<Form />)}
  />
);

export default New;
