import Callout from 'client/js/util/callout';
import CopyableField from 'client/js/util/copyable_field';
import { FormActions, GenericReactForm, RadioCollectionInput, SubmitButton } from 'client/js/util/form_utils';
import AuthorizedKeysInput from 'client/js/util/inputs/authorized_keys_input';
import SshUsernameInput from 'client/js/util/inputs/ssh_username_input';
import { Card, CardList, CardListItem, DetailsRow, DetailsSection, FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';

const SetupSsh = ({ onSuccess }) => (
  <>
    <PageHeader text="SSH-Zugang erstellen" />

    <Callout title="Hinweise zum SSH-Zugang" text="Der SSH-Benutzername ist potenziell für andere Kunden sichtbar. Soll aus diesem Grund nicht der aktuelle Benutzername, sondern ein abweichender Name verwendet werden, ist das möglich. Die Verbindung ist aus Sicherheitsgründen nicht per Passwort, sondern nur per SSH-Key möglich!" />

    <GenericReactForm namespace="ssh_account"
                      defaults={{username_schema: 'login'}}
                      url={`/usercp/ssh_accounts`}
                      onSuccess={onSuccess}>

      <RadioCollectionInput required name="username_schema" options={[['login', `Benutzernamen verwenden (${document.lima_username})`], ['own', 'anderen SSH-Benutzernamen wählen']]} label="Benutzername" />
      <SshUsernameInput required name="username" label="SSH-Benutzername" hint="Kann später nicht mehr geändert werden" />
      <AuthorizedKeysInput required name="authorized_key_ids" label="SSH-Keys" hint={<span>Für die Authentifizierung erlauben wir aus Sicherheitsgründen ausschließlich SSH-Keys und keine Passwörter. Bitte beachte auch unsere Hilfe zu SSH-Keys.</span>} />

      <FormActions>
        <SubmitButton icon="plus" text="SSH-Account anlegen" />
      </FormActions>
    </GenericReactForm>
  </>
)

const SshKeysCard = ({authorized_keys}) => {
  return <>
      <h5 className="d-flex justify-content-between align-items-center mb-3">
        <div>
          SSH-Keys <small className="text-muted">(.ssh/authorized_keys)</small>
        </div>
        <Link to="/ssh_accounts/authorized_keys/edit" className="btn btn-light"><FaIcon name="pencil" /> SSH-Keys bearbeiten</Link>
      </h5>

      {!authorized_keys || authorized_keys.length == 0 ? (
        <Callout title={false}>
          <p>
            Derzeit sind keine SSH-Keys aktiviert.
          </p>
        </Callout>
      ) : (
      <Card title={false}>
          <CardList>
            {authorized_keys.map((item) => (
              <CardListItem key={item.id} title={item.friendly_name} />
            ))}
          </CardList>
        </Card>
      )}
    </>
}

const Show = () => {
  const {data, component, refetch} = useResource(["ssh_accounts"], '/usercp/ssh_accounts', {
    keepPreviousData: true,
    header: <PageHeader text="SSH-Zugang" />
  });

  if(component)
    return component;

  const ssh_account = data.ssh_account;

  if(ssh_account == null)
    return <SetupSsh onSuccess={refetch} />;

  return <>
    <PageHeader text="SSH-Zugang" />

    <DetailsSection title="Zugangsdaten">
      <DetailsRow title="Host:" ><CopyableField value={ssh_account.host.name} /></DetailsRow>
      <DetailsRow title="Port:" text={ssh_account.host.port} />
      <DetailsRow title="Benutzername:"><CopyableField value={ssh_account.username} /></DetailsRow>
      <DetailsRow title="SSH-Kommando:">
        <CopyableField>
          <pre className="d-inline-block my-0">ssh {ssh_account.username}@{ssh_account.host.name}{ssh_account.host.port == 22 ? null : ` -p ${ssh_account.host.port}`}</pre>
        </CopyableField>
      </DetailsRow>
    </DetailsSection>

    <hr />

    <SshKeysCard authorized_keys={ssh_account.authorized_keys} />

    {ssh_account.host.key_fingerprints &&
      <>
        <hr/>
        <h5>Host-Keys</h5>
        <p>Bei dem Aufbau einer Verbindung identifiziert sich unser Server mit den folgenden Host-Keys:</p>
        <pre className="code-block">{ssh_account.host.key_fingerprints}</pre>
      </>}
  </>
}

export default Show;
