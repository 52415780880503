import { ControlledErrors } from 'client/js/util/form_tags';
import { ControlGroup } from 'client/js/util/form_utils';
import { FaIcon } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';
import PropTypes from "prop-types";

const ItemInfo = ({item}) => {
  if(item.special && item.special.type == 'app' && item.special.software == 'wordpress')
    return <span>{item.text} (WordPress v{item.special.version})</span>;

  return <span>{item.text}</span>;
}

const FolderIcon = (props) => {
  if(props.item.special && props.item.special.type == 'app' && props.item.special.software == 'wordpress')
    return <FaIcon name='wordpress fa-fw text-left' />

  if(props.open)
    return <FaIcon name="folder-open-o fa-fw text-left"/>;

  return <FaIcon name="folder-o fa-fw text-left"/>;
}

FolderIcon.propTypes = {
  open: PropTypes.bool.isRequired
}

FolderIcon.defaultProps = {
  open: false
}

class Entry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: false};
    this.toggleState = this.toggleState.bind(this);
  }

  toggleState(e) {
    this.setState({open: !this.state.open});
    e.preventDefault();
  }

  render() {
    let item = this.props.item;
    let full_name = this.props.current_directory ? this.props.current_directory.concat('/').concat(item.text) : item.text;
    let selected = full_name == this.props.selected_item;

    if(item.type == 'root')
      return (
        <li className={selected ? 'node-dir selected' : 'node-dir'}>
          <a href="#" className="toggle-handle" onClick={this.toggleState}>
            {this.state.open ? <FaIcon name="minus-square-o fa-fw text-left"/> : <FaIcon name="plus-square-o fa-fw text-left"/>}
          </a>
          <a href="#" className="selection-wrapper toggle-handle" onClick={(e) => this.props.select_item(e, full_name)}>
            <FolderIcon open={this.state.open} item={item} /> /
          </a>
          {this.state.open && <LoadingDirectoryList selected_item={this.props.selected_item} select_item={this.props.select_item} current_directory={full_name} />}
        </li>
      );
    else if(item.type == 'directory' || (item.type == 'link' && item.target.type == 'directory'))
      return (
        <li className={selected ? 'node-dir selected' : 'node-dir'}>
          <a href="#" className="toggle-handle" onClick={this.toggleState}>
            {this.state.open ? <FaIcon name="minus-square-o fa-fw text-left"/> : <FaIcon name="plus-square-o fa-fw text-left"/>}
          </a>
          <a href="#" className="selection-wrapper toggle-handle" onClick={(e) => this.props.select_item(e, full_name)}>
            <FolderIcon open={this.state.open} item={item} /> <ItemInfo item={item} />
          </a> {item.type == 'link' && <span className="text-muted font-italic" title={`symbolischer Link auf ${item.target.name}`}>&rarr; {item.target.name}</span>}
          {this.state.open && <LoadingDirectoryList selected_item={this.props.selected_item} select_item={this.props.select_item} current_directory={full_name} />}
        </li>
      );
    else if(item.type == 'link')
      return <li className={selected ? 'node-file selected' : 'node-file'}>
        <FaIcon name="link fa-fw"/> <a href="#" className="selection-wrapper" onClick={(e) => this.props.select_item(e, full_name)}>{item.text}</a> {item.type == 'link' && <span className="text-muted font-italic" title={`symbolischer Link auf ${item.target.name}`}>&rarr; {item.target.name}</span>}
      </li>;
    else
      return null;
  }
}

Entry.propTypes = {
  current_directory: PropTypes.string,
  selected_item: PropTypes.string,
  select_item: PropTypes.func.isRequired,
  item: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['directory', 'file']).isRequired,
  })
}

const DirectoryList = (props) => {
  let items = props.data;

  return <ul className="list-unstyled pl-4 my-1">
      {items.map((item) => (<Entry key={item.text} item={item} selected_item={props.selected_item} select_item={props.select_item} current_directory={props.current_directory} />))}
    </ul>;
}

DirectoryList.propTypes = {
  item: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['directory', 'file']),
  })).isRequired
}

const DirectoryListContainer = withLoading(DirectoryList)
const LoadingDirectoryList = (props) => (<DirectoryListContainer url={`/usercp/webspace/browse.json?folder=${encodeURIComponent(props.current_directory)}`} key_name="contents" {...props} />)

LoadingDirectoryList.propTypes = {
  current_directory: PropTypes.string.isRequired
}

LoadingDirectoryList.defaultProps = {
  current_directory: ""
}

class WebspacePathInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {directory: "", selected_item: null};
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem(e, path) {
    this.setState({selected_item: path});
    e.preventDefault();
    this.context.formDispatch('value_changed', e, {name: this.props.name, value: path});
  }

  render() {
    const n = [this.context.namespace, this.props.name].filter((n) => (n != undefined)).join('_');

    return <ControlGroup name={n} labelFor={n} required label={this.props.label}>
        <p>Bitte markiere ein Verzeichnis:</p>
        <div className="file-browser mb-3">
          {this.props.rootChoosable ?
            <DirectoryList data={[{text: '', type: 'root'}]} selected_item={this.state.selected_item} select_item={this.selectItem} />
          : <LoadingDirectoryList selected_item={this.state.selected_item} select_item={this.selectItem} />
          }
        </div>
        <ControlledErrors names={[this.props.name]} />

        {this.context.formData && this.context.formData[this.props.name] && <p>Ausgewählt: {this.context.formData[this.props.name]}</p>}
      </ControlGroup>;
  }
}

WebspacePathInput.contextTypes = {
  namespace: PropTypes.string,
  formData: PropTypes.object,
  formDispatch: PropTypes.func
};

export default WebspacePathInput;
