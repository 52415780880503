import Callout from 'client/js/util/callout';
import { PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';

const Status = ({item}) => {
  if(item.certificate_present && item.www_certificate_present)
    return <div><span className="badge badge-success">aktiv</span> <span className="badge badge-success">+ www</span></div>
  else if(item.certificate_present)
    return <span className="badge badge-success">aktiv</span>

  return <span className="badge badge-secondary">On Demand</span>
}

const Index = () => {
  const { data, component } = useResource(['lets-encrypt'], `/usercp/lets-encrypt`, {
    header: <PageHeader text="Let's Encrypt" />,
  })

  if (component) {
    return component;
  }

  return (
    <>
      <PageHeader text="Let's Encrypt" />

      <Callout calloutClass="primary" title="SSL on demand">
        <p>Alle Webseiten mit dem Status <span className="badge badge-secondary">On Demand</span> haben zur Zeit kein SSL-Zertifikat. Sobald die Webseiten das erste Mal aufgerufen werden stellt das System automatisch kostenlose SSL-Zertifikate über Let&quot;s Encrypt aus.</p>
      </Callout>

      <table className="table table-striped table-wide">
        <thead>
          <tr><th>Webseite</th><th>Status</th></tr>
        </thead>
        <tbody>
          {data.ssl_domains.length > 0 ? (
            data.ssl_domains.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.domain}
                </td>
                <td>
                  <Status item={item} />
                </td>
              </tr>
            )
          )) : (
           <tr>
            <td colSpan="2" className="text-center">
              Es wurden keine Webseiten gefunden, für welche Let&quot;s Encrypt verfügbar ist.
            </td>
          </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default Index;
