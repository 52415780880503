
import { Adjustment, CartItem } from 'client/js/cart/app';
import { DetailsRow, DetailsSection, PageHeader, SkeletonLoading } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import { Actions, Status } from './lib';

const Show = (props) => {
  const id = props.match.params.id;

  const { data, component, refetch } = useResource(['orders', id], `/usercp/orders/${id}`, {
    keepPreviousData: true,
    header: <PageHeader text="Bestellung ..." back_url="/orders">Bestellung <SkeletonLoading /></PageHeader>
  });

  if(component) {
    return component;
  }

  const isPaymentPage = props.location.pathname.endsWith('/pay');

  const order = data.order;

  return (
    <>
      <PageHeader text={`Bestellung ${order.reference_code || order.id}`} buttons={order.status == 'pending' && <Actions order={order} autoStartPayment={isPaymentPage} refetch={refetch} /> } back_url="/orders" />

      <DetailsSection title="Übersicht">
        <DetailsRow title="Nr.">{order.reference_code || order.id}</DetailsRow>
        <DetailsRow title="Name">{order.title}</DetailsRow>
        <DetailsRow title="Betrag">{format_fractional_price(order.amount)}</DetailsRow>
        <DetailsRow title="Status">{<Status status={order.status} />}</DetailsRow>
        {order.checked_out_at && <DetailsRow title="Datum">{dayjs(order.checked_out_at).format('L LT')}</DetailsRow>}
        {order.paid_by && <DetailsRow title="Zahlungsmethode">{i18n_t(`resources.orders.payment_method.${order.paid_by}`)}</DetailsRow>}
      </DetailsSection>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Produkt</th>
            <th>Anzahl</th>
            <th>Einrichtungsgebühr</th>
            <th>Preis</th>
            <th>Folgepreis</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item) => (
            <>
              <CartItem
                key={item.id}
                {...item}
              />
              {item.adjustments.map((adjustment) => (
                <Adjustment key={adjustment.text} {...adjustment} />
              ))}
            </>
          ))}
        </tbody>
      </table>

    </>
  )
}

export default Show;
