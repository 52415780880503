import { useQuery } from '@tanstack/react-query';
import { BasicDropdown } from 'client/js/usercp/table_utils';
import Callout from 'client/js/util/callout';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import CopyableField from 'client/js/util/copyable_field';
import ExternalLink from 'client/js/util/external_link';
import { BooleanInput, FormActions, GenericReactForm, StringInput, SubmitButton } from 'client/js/util/form_utils';
import { DetailsRow, DetailsSection, FaIcon, HelpTooltip, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { AnimatedNavItem, AnimatedTabs } from 'client/js/util/nav_tabs';
import { errorToComponent } from 'client/js/util/rest_utils';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';

const ImportState = ({ state }) => {
  switch(state) {
    case 'created':
      return <span className="badge badge-info">Erstellt</span>;
    case 'running':
      return <>
        <span className="badge badge-info">Läuft</span>
        <div className="ml-3 css-spinloader inline" style={{"margin-bottom": "-3px"}}></div>
      </>;
    case 'completed':
      return <span className="badge badge-success">Abgeschlossen</span>;
    case 'failed':
      return <span className="badge badge-danger">Fehlgeschlagen</span>;
    default:
      return <span className="badge badge-secondary">{state}</span>;
  }
}

const ImportsTab = (props) => {
  const { data, isLoading, isError, error } = useQuery(['mailbox_imports', props.data.id], async () => {
    return await $.ajax({url: `/usercp/mailboxes/${props.data.id}/imports`, dataType: 'json'});
  }, {
    keepPreviousData: true,
    refetchInterval: (data) => data?.mailbox_imports?.some((i) => i.state == 'running' || i.state == "created") ? 5000 : false
  });

  if(isLoading) {
    return <LoadingTableSpinner />;
  }

  if(isError) {
    return errorToComponent(error);
  }

  if(data.mailbox_imports.length == 0) {
    return <Callout title="Keine Importe" text="Es wurden noch keine E-Mail-Importe für dieses Postfach durchgeführt." />;
  }

  return (
    <table className="table table-striped table-wide">
      <thead>
        <tr>
          <th>Quell-Postfach</th>
          <th>Quell-Server</th>
          <th>Erstellt</th>
          <th>Importiert</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data.mailbox_imports.map((mailbox_import) => (
          <tr key={mailbox_import.id}>
            <td><CopyableField value={mailbox_import.username} /></td>
            <td><CopyableField value={mailbox_import.hostname} /></td>
            <td>{format_date(mailbox_import.created_at)}</td>
            <td>
              {mailbox_import.messages_total ? (
                <>{number_with_delimiter(mailbox_import.messages_imported)} von {number_with_delimiter(mailbox_import.messages_total)}</>
              ) : (
                <>&mdash;</>
              )}
            </td>
            <td><ImportState state={mailbox_import.state} /></td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const OverviewTab = (props) => {
  if(!props.data.quota_usage_kb || !props.data.quota_updated_at)
    return <Callout title="Keine Daten verfügbar" text="Derzeit sind keine Statistiken zu dem Postfach verfügbar." />;

  return (
    <DetailsSection title="Speicherplatz & Nutzung">
      <DetailsRow title="belegter Speicherplatz" text={format_disk_space(props.data.quota_usage_kb * 1024)} />
      <DetailsRow title={<>maximal verfügbarer Speicherplatz <HelpTooltip content='Der tariflich vereinbarte Speicherplatz abzüglich des Speichers, der durch andere Postfächer belegt ist, ergibt den für dieses Postfach maximal verfügbaren Speicherplatz. Wenn ein Limit eingestellt ist wird dies hier ebenfalls wirksam.' /></>} text={props.data.quota_limit_kb ? format_disk_space(props.data.quota_limit_kb * 1024) : 'unbegrenzt'} />
      <DetailsRow title="Speicherplatz-Begrenzung" text={props.data.max_quota_limit_kb ? format_disk_space(props.data.max_quota_limit_kb * 1024) : 'keine'} />
      {props.data.messages ? <DetailsRow title="Nachrichten" text={number_with_delimiter(props.data.messages)} /> : null}
      <DetailsRow title="zuletzt aktualisiert" text={dayjs(props.data.quota_updated_at).format('l')} />
    </DetailsSection>
  );
}

const SettingsTab = (props) => {
  const defaults = {
    relaxed_helo: props.data.relaxed_helo,
    password_api_enabled: props.data.password_api_enabled,
    max_quota_limit_mb: props.data.max_quota_limit_kb ? (props.data.max_quota_limit_kb / 1024) : null
  };
  const dispatch = (action) => {
    if(action == 'form:success')
      props.refreshCb();
  }

  return <GenericReactForm namespace="mailbox" verb="put" url={`/usercp/mailboxes/${props.data.id}`} defaults={defaults} dispatch={dispatch} >
      <BooleanInput name="password_api_enabled" label="Passwort-Änderung durch Benutzer" hint={<span>Erlaubt dem Benutzer, das Passwort für das Postfach unter der Adresse <ExternalLink href="https://www.lima-city.de/postoffice/password" text="https://www.lima-city.de/postoffice/password" /> selbstständig zu ändern.</span>} />
      <StringInput name="max_quota_limit_mb" type="number" label="Speicherplatz-Begrenzung in Megabyte" hint="Begrenzt die maximale Speicherplatz-Belegung von diesem Postfach, selbst wenn mehr Speicherplatz zur Verfügung steht." />

      <FormActions>
        <SubmitButton icon="pencil" text="Einstellungen ändern" />
      </FormActions>
    </GenericReactForm>
}

SettingsTab.propTypes = {
  data: PropTypes.shape({
    relaxed_helo: PropTypes.bool.isRequired,
    password_api_enabled: PropTypes.bool.isRequired,
    max_quota_limit_kb: PropTypes.number,
  }).isRequired,
  refreshCb: PropTypes.func.isRequired
};

const CredentialsTab = (props) => {
  return <>
        <DetailsSection title="Zugangsdaten">
          <DetailsRow title="IMAP-Server:"><CopyableField value="mail.lima-city.de" /></DetailsRow>
          <DetailsRow title="IMAP-Port:" text="993 (SSL), 143 (TLS)" />
          <DetailsRow title="POP3-Server:"><CopyableField value="mail.lima-city.de" /></DetailsRow>
          <DetailsRow title="POP3-Port:" text="995 (SSL), 110 (TLS)" />
          <DetailsRow title="SMTP-Server:"><CopyableField value="mail.lima-city.de" /></DetailsRow>
          <DetailsRow title="SMTP-Port:" text="465 (SSL), 587 (TLS)" />
          <DetailsRow title="Benutzername:"><CopyableField value={props.data.email} /></DetailsRow>
          <DetailsRow title="Passwort:" text="Das von Dir gesetzte Passwort" />
        </DetailsSection>

        <Callout title="Anleitungen">
          <p>Das Postfach kann ohne Einrichtung eines Programms im <ExternalLink href="https://mail.lima-city.de">lima-city Webmail</ExternalLink> abgerufen werden (<ExternalLink href="http://cbhp.lima-city.de/support/email/webmail1.html">detaillierte Anleitung</ExternalLink>).</p>
          <p>Für folgende <strong>Smartphones</strong> haben wir bebilderte Anleitungen zur Einrichtung:</p>
          <ul>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/ios7.html">iPhone iOS 7</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/android4.html">Android E-Mail 4</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/windowsphone8.html">Windows Phone 8</ExternalLink></li>
          </ul>
          <p>
          Für folgende <strong>PC-Programme</strong> haben wir bebilderte Anleitungen zur Einrichtung:
          </p>
          <ul>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/outlook2013.html">Outlook 2013</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/thunderbird24.html">Thunderbird</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/applemail7.html">Apple Mail</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/windowsmail17.html">Windows Mail</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/windowslivemail2012.html">Windows Live Mail 2012</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/operamail1.html">Opera Mail 1</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/emclient6.html">emClient 6</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/incredimail2.html">IncrediMail 2</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/evolution3.html">Evolution 3</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/clawsmail3.html">Claws Mail 3</ExternalLink></li>
            <li><ExternalLink href="http://cbhp.lima-city.de/support/email/kmail4.html">KMail 4</ExternalLink></li>
          </ul>
          <p>
            Bei <strong>Speedport-Routern der Telekom</strong> ist <ExternalLink href="http://cbhp.lima-city.de/support/email/router-speedport.html">eine weitere Konfigurationseinstellung nötig</ExternalLink>.
          </p>
          <p>
            Bitte beachte, dass jedes Postfach ein eigenes Passwort hat. Das Passwort zum Login in die Verwaltung kann nicht zum Login in ein E-Mail-Postfach verwendet werden.
          </p>
        </Callout>
      </>;
}

// TZhis is a list of the e-mail addresses that are forwarded to this mailbox.
// The user can only see the aliases but not add or remove them.
// the data.aliases object is an array of objects with the following keys:
// - id: the ID of the alias
// - email: the e-mail address that is forwarded to this mailbox
// the table should have the following columns:
// - E-Mail-Adresse

const AliasesTab = ({data}) => {
  if (!data.aliases || data.aliases.length == 0) {
    return <Callout calloutClass="info" text="Aliase sind E-Mail-Weiterleitungen, welche dieses Postfach als Ziel haben. Es sind derzeit keine Aliase für dieses Postfach vorhanden." />;
  }

  return (
    <>
      <Callout calloutClass="info" text="Die Aliase sind E-Mail-Weiterleitungen, welche dieses Postfach als Ziel haben." />

      <table className="table table-striped table-wide">
        <thead>
          <tr>
            <th>E-Mail-Adresse</th>
          </tr>
        </thead>
        <tbody>
          {data.aliases.map((alias) => (
            <tr key={alias.id}>
              <td>{alias.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

const ImportStatusRow = ({ mailbox_id, id, state: initial_state, messages_imported, messages_total }) => {
  const { data, isLoading, isError, error } = useQuery(['mailbox_import', mailbox_id, id], async () => {
    const data = await $.ajax({url: `/usercp/mailboxes/${mailbox_id}/imports/${id}`, dataType: 'json'});

    return data.import;
  }, {
    enabled: initial_state === 'running',
    keepPreviousData: true,
    refetchInterval: (data) => data?.state == 'running' ? 5000 : false
  });

  const state = isLoading ? initial_state : data.state;
  const messagesImported = isLoading ? messages_imported : data.messages_imported;
  const messagesTotal = isLoading ? messages_total : data.messages_total;

  const progress = Math.round((messagesImported / messagesTotal) * 100);

  if(isError) {
    return <Callout calloutClass='alert' title='Fehler'>
      <p>Beim Abrufen des Import-Status ist ein Fehler aufgetreten: {error.message}</p>
    </Callout>;
  }

  if(state == 'created') {
    return <Callout calloutClass='info' title={false}>
      <p>Der E-Mail-Import für dieses Postfach wurde erstellt und wird in Kürze gestartet.</p>
    </Callout>;
  }

  if(state == 'completed') {
    return <Callout calloutClass='success' title={false}>
      <p>Der E-Mail-Import für dieses Postfach wurde erfolgreich abgeschlossen. Es wurden {messagesImported} E-Mails importiert.</p>
    </Callout>;
  }

  return (
    <Callout calloutClass={'info'} title={false}>
      <p>Derzeit wird ein E-Mail-Import für dieses Postfach durchgeführt. Dieser Vorgang kann einige Zeit in Anspruch nehmen.</p>

      {!!progress && (
        <div className='progress'>
          <div className='progress-bar' style={{ width: `${progress}%` }}>
            {number_with_delimiter(messagesImported)} von {number_with_delimiter(messagesTotal)} importiert
          </div>
        </div>
      )}
    </Callout>
  );
};

class TabContainer extends React.Component {
  render() {
    return <>
      <AnimatedTabs>
        <AnimatedNavItem to={`/mailboxes/${this.props.id}`} exact>Übersicht</AnimatedNavItem>
        <AnimatedNavItem to={`/mailboxes/${this.props.id}/credentials`}>Zugangsdaten</AnimatedNavItem>
        <AnimatedNavItem to={`/mailboxes/${this.props.id}/settings`}>Einstellungen</AnimatedNavItem>
        <AnimatedNavItem to={`/mailboxes/${this.props.id}/imports`}>Imports</AnimatedNavItem>
        <AnimatedNavItem to={`/mailboxes/${this.props.id}/aliases`}>Aliase</AnimatedNavItem>
      </AnimatedTabs>

      <Switch>
        <Route path={`/mailboxes/${this.props.id}/credentials`} render={() => (<CredentialsTab data={this.props.data} />)} />
        <Route path={`/mailboxes/${this.props.id}/imports`} render={() => (<ImportsTab data={this.props.data} refreshCb={this.props.refreshCb} />)} />
        <Route path={`/mailboxes/${this.props.id}/settings`} render={() => (<SettingsTab data={this.props.data} refreshCb={this.props.refreshCb} />)} />
        <Route path={`/mailboxes/${this.props.id}/aliases`} render={() => (<AliasesTab data={this.props.data} refreshCb={this.props.refreshCb} />)} />
        <Route path={`/mailboxes/${this.props.id}`} exact render={() => (<OverviewTab data={this.props.data} />)} />
      </Switch>
    </>;
  }
}

const Show = (props) => {

  const id = props.match.params.id;
  const { data, isLoading, isError, error, refetch } = useQuery(['mailbox', id], async () => {
    return await $.ajax({url: `/usercp/mailboxes/${id}`, dataType: 'json'});
  }, {
    keepPreviousData: true
  });

  const buttons = (
    <>
      <BasicDropdown button={<Link className="btn btn-light" to={`/mailboxes/${id}/password`}><FaIcon name="key" /> Passwort ändern</Link>}>
        <Link to={`/mailboxes/${id}/imports/new`} className="dropdown-item"><FaIcon name="download" /> E-Mail importieren</Link>

        <ConfirmedDelete
          namespace="usercp"
          resource="mailboxes"
          id={id}
          link
          redirectUrl="/email"
          button_class="dropdown-item text-danger"
          button_text={<><FaIcon name="trash" /> Postfach löschen</>}
          text="Dies wird das Postfach und die darin vorhandenen E-Mails löschen. Bitte beachte die gesetzlichen Aufbewahrungsfristen, sofern es sich um E-Mails handelt, für welche Aufbewahrungspflichten gelten!" />
      </BasicDropdown>
    </>
  );

  if(isLoading) {
    return (
      <>
        <PageHeader text="Postfach" back_url="/email" buttons={buttons} />

        <LoadingTableSpinner />
      </>
    );
  }

  if(isError) {
    return (
      <>
        <PageHeader text="Postfach" back_url="/email" buttons={buttons} />

        {errorToComponent(error)}
      </>
    );
  }

  return (
    <>
      <PageHeader text={`Postfach ${data.mailbox.email}`} back_url="/email" buttons={buttons} />

      {data.mailbox.import_running ? <ImportStatusRow mailbox_id={id} {...data.running_import} /> : null}

      <TabContainer id={id} data={data.mailbox} refreshCb={refetch} />
    </>
  );
}

export default Show;
