import PropTypes from "prop-types";
import {SelectCollectionInput} from 'client/js/util/form_utils';
import {withLoading} from 'client/js/util/rest_utils';

const WebsitesSelect = (props) => {
  let websites = props.data.map((item) => ([item.document_root, item.hostname]));

  return <SelectCollectionInput name="content_path"
                                hint="Die Webseite zeigt das gleiche Webspace-Verzeichnis wie die ausgewählte Webseite. Es werden keine Dateien kopiert."
                                label="Webseite"
                                required
                                options={websites} />;
}

WebsitesSelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ document_root: PropTypes.string, hostname: PropTypes.string})).isRequired
}

const WebsitesSelectContainer = withLoading(WebsitesSelect);

const WebsitePathInput = (props) => {
  return <WebsitesSelectContainer url="/usercp/websites.json" key_name="vhosts" fields={props.fields} />;
}

export default WebsitePathInput;
