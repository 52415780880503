import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import WebsiteContentSection from './website_content_section';

const Form = () => (
  <WebsiteContentSection name="name" label="Webseiten-Inhalt" required />
)

const Page = (props) => {
  return (
    <GenericInteraction
      namespace="usercp"
      resource_name="websites"
      action="update"
      method="PATCH"
      icon_name='pencil'
      form_namespace="website"
      redirectAfter="index"
      back_url="/websites"
      form_url={`/usercp/websites/${props.match.params.id}`}
      id={props.match.params.id}
      form_component={() => (<Form />)}
    />
  )
};

export default Page;
