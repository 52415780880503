import {PageHeader, Card, FaIcon} from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';
import {Link} from 'react-router-dom';
import {ScreenshotContainer} from './lib';
import {useQuery} from '@tanstack/react-query';
import {errorToComponent} from 'client/js/util/rest_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

const InfoCardBody = (props) => {
  let vhost = props.data.websites[0];

  return <div className="card-body">
      <h5 className="card-title">{vhost ? vhost.domain : 'WordPress ohne Domain'}</h5>

      <div className="card-text">
        <div className="btn-group" role="group" aria-label="Aktionen">
          <Link to={`/wordpress/${props.data.id}`} className="btn btn-secondary"><FaIcon name="wrench" /> Verwalten</Link>
          {!!vhost && <a href={vhost.url} target="_blank" rel="noopener noreferrer" className="btn btn-light">öffnen <FaIcon name="external-link" /></a>}
        </div>
      </div>
    </div>;
}

const WordpressItem = (props) => {
  return <Card cardClass={props.item.issues.malicious_files > 0 ? 'card mb-3 border-danger' : "card mb-3"} title={false}>
      {!!props.item.websites.length && <ScreenshotContainer app_id={props.item.id} url={props.item.websites[0].url} />}

      <InfoCardBody data={props.item} />

      <div className="card-footer">
        <div className="d-flex">
          <small className='mr-auto'>Verzeichnis: {props.item.path}</small>
          <small className="text-muted">v{props.item.wordpress.version}</small>
        </div>
        {props.item.issues.malicious_files > 0 && <div className="text-danger">{props.item.issues.malicious_files} Datei(en) mit Infektionen</div>}
      </div>
    </Card>;
}

const WordpressList = ({data}) => {
  if(data.wordpress.length === 0) {
    return (
      <Callout calloutClass="info" title="Keine WordPress Installationen vorhanden...">
        <p>Importiere ein WordPress aus einer Archiv-Datei oder installiere eins!</p>
      </Callout>
    );
  }

  return (
    <div className="card-columns card-columns-2">
      {data.wordpress.map((item) => <WordpressItem key={item.path} item={item} />)}
    </div>
  );
}

const fetchData = async () => {
  const response = await $.ajax({url: `/usercp/wordpress`, dataType: 'json'});

  return response;
}

const Index = () => {
  const { data, isLoading, isError, error } = useQuery(['wordpress_sites'], fetchData, {
    keepPreviousData: true,
  });

  if (isLoading) {
    return <>
      <PageHeader text="WordPress" buttons={<><Link to="/wordpress/import" className="btn btn-light"><FaIcon name="upload" /> WordPress importieren</Link> <a href="/usercp/softwares/1/install" className="btn btn-light"><FaIcon name="plus" /> WordPress installieren</a></>} />

      <LoadingTableSpinner />
    </>
  }

  if (isError) {
    return errorToComponent(error);
  }

  return (
    <>
      <PageHeader text="WordPress" buttons={<><Link to="/wordpress/import" className="btn btn-light"><FaIcon name="upload" /> WordPress importieren</Link> <a href="/usercp/softwares/1/install" className="btn btn-light"><FaIcon name="plus" /> WordPress installieren</a></>} />

      <WordpressList data={data} />
    </>
  )
}

export default Index;
