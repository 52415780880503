import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import Callout from 'client/js/util/callout';
import SnapshotCreateToolbar from 'client/js/usercp/cloud/snapshots/snapshot_create_toolbar';
import SnapshotTable from 'client/js/usercp/cloud/snapshots/snapshot_table';
import { toast } from 'react-toastify';

const SnapshotMenu = (props)=> {
  if(props.snapshotsDisabled)
    return <Callout title="Snapshots nicht möglich"
                    text="Zu diesem Zeitpunkt können noch keine Snapshots von diesem Cloud-VPS erstellt werden. Bitte habe einen Moment Geduld..." />;

  if(props.snapshots == null)
    return <LoadingTableSpinner />;

  const onSnapshotCreate = () => {
    toast.success('Snapshot wird erstellt...');
    props.changePage(1);
    props.refreshCb();
  }

  if(props.snapshots.length) {
    return (
      <>
        {!props.noCreate && <div className="mb-3">
          <SnapshotCreateToolbar url={props.snapshots_url} snapshotCreatedCb={onSnapshotCreate} virtual_machine={props.virtual_machine} />
          {props.virtual_machine.included_snapshots > 0 && <span className='ml-1 text-muted'>{props.virtual_machine.used_snapshots}/{props.virtual_machine.included_snapshots} kostenlose Snapshots genutzt.</span>}
        </div>}

        <SnapshotTable
          dispatch={props.dispatch}
          selectSnapshot={props.selectSnapshot}
          renderOrigin={props.renderOrigin}
          snapshots={props.snapshots}
          fetchSnapshots={props.refreshCb}
          virtual_machine_id={props.id}
          restore_enabled={props.restore_enabled}
          pagination_data={props.pagination_data}
          changePage={props.changePage}
          />
      </>
    );
  }
  return (
    <Callout calloutClass="primary" title="Noch keine Snapshots">
      <p>Bisher sind noch keine Snapshots für diese VM vorhanden.</p>

      {props.virtual_machine.included_snapshots > 0 && <p>In dem Tarif dieser virtuellen Maschine sind {props.virtual_machine.included_snapshots} kostenlose Snapshots enthalten.</p>}

      <SnapshotCreateToolbar virtual_machine={props.virtual_machine} url={props.snapshots_url} snapshotCreatedCb={props.refreshCb} />
    </Callout>
  );
}

export default SnapshotMenu;
