// credit_card

import MaskedInput from 'react-text-mask';

class CreditCardNumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: props.value};

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler(e) {
    const original_value = e.target.value;
    const NON_NUM = /[^0-9]/g, EVERY_FOUR_CHARS =/(.{4})(?!$)/g;

    const new_val = original_value.replace(NON_NUM, '').replace(EVERY_FOUR_CHARS, "$1 ");
    this.setState({value: new_val});
    this.props.dispatch('value_changed', e, {name: 'number', value: new_val});
  }

  render() {
    const props = this.props;
    let passThroughProps = this.props;

    const n = [this.context.namespace, props.name].filter((n) => (n != undefined)).join('_');
    const field_name = this.context.namespace ? `${this.context.namespace}[${props.name}]` : props.name

    return (
        <input type="tel" value={this.state.value} onKeyPress={this.restrictor} onPaste={this.changeHandler} onChange={this.changeHandler}
        name="number" data-stripe='number' maxLength="32" placeholder='••••     ••••     ••••     ••••' size="20" autoComplete='cc-number' className="form-control cc-num" aria-required required
        {...passThroughProps} />);
  }
}

class CreditCardExpiryInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: props.value};

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler(e) {
    const original_value = e.target.value;

    this.setState({value: original_value});
    this.props.dispatch('value_changed', e, {name: 'exp', value: original_value});
  }

  render() {
    const props = this.props;

    const n = [this.context.namespace, props.name].filter((n) => (n != undefined)).join('_');
    const field_name = this.context.namespace ? `${this.context.namespace}[${props.name}]` : props.name

    return (<MaskedInput mask={[/\d/, /\d/, ' ', '/', ' ', /\d/, /\d{1,3}/]} guide={true}
                type="tel" onChange={this.changeHandler} onFocus={this.props.onFocus}
                name="exp" data-stripe='exp' placeholder='MM / JJ' autoComplete='cc-exp' className="form-control cc-exp" aria-required required />);
  }
}

export {CreditCardNumberInput, CreditCardExpiryInput};
