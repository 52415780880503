import { Link } from "react-router-dom";
import { FaIcon, PageHeader, OverviewBoxLine, OverviewBoxLineItem } from "../util/layout_utils";

const SettingsLink = ({to, icon, title, children}) => (
  <div className="col-md-3">
    <Link to={to} className="d-flex align-items-start link">
      <div className="icon icon-primary btn btn-light">
        <FaIcon name={icon} />
      </div>

      <div className="ml-3">
        <h5>{title}</h5>

        {children}
      </div>
    </Link>
  </div>
)

const Settings = () => {
  return (
    <>
      <PageHeader text="Einstellungen" />

      <OverviewBoxLine>
        <OverviewBoxLineItem label="Kundennummer">
          {document.lima_id}
        </OverviewBoxLineItem>
        <OverviewBoxLineItem label="Benutzername">
          {document.lima_username}
        </OverviewBoxLineItem>
      </OverviewBoxLine>

      <hr/>

      <h5>Profil und Zugänge</h5>

      <div className="row settings-row my-3">
        <SettingsLink
          to="/email-adresse"
          icon="envelope"
          title="E-Mail-Adresse"
        >
          <p>E-Mail-Adresse ändern, welche für die Kommunikation von uns verwendet wird.</p>
        </SettingsLink>

        <SettingsLink
          to="/password/edit"
          icon="key"
          title="Passwort ändern"
        >
          <p>Passwort für die lima-city Verwaltung ändern.</p>
        </SettingsLink>

        <SettingsLink
          to="/security"
          icon="shield"
          title="Account-Sicherheit"
        >
          <p>2-Faktor-Authentifizierung und aktuelle Login-Sitzungen verwalten.</p>
        </SettingsLink>
      </div>

      <div className="row settings-row mb-3">
        <SettingsLink
          to="/api_keys"
          icon="key"
          title="API-Keys"
        >
          <p>API-Schlüssel für die lima-city API verwalten.</p>
        </SettingsLink>

        <SettingsLink
          to="/ssh_keys"
          icon="key"
          title="SSH-Keys"
        >
          <p>SSH-Keys für den Zugang zum Webhosting und virtuellen Maschinen verwalten.</p>
        </SettingsLink>
      </div>

      <hr />

      <h5>Account und Abrechnung</h5>

      <div className="row settings-row my-3">
        <SettingsLink
          to="/payment_methods"
          icon="credit-card"
          title="Zahlungsmethoden"
        >
          <p>Automatische Zahlungsmethoden für wiederkehrende Zahlungen verwalten.</p>
        </SettingsLink>

        <SettingsLink
          to="/handles"
          icon="address-card"
          title="Adressen"
        >
          <p>Rechnungsadressen und Adressen für Domain-Inhaber verwalten.</p>
        </SettingsLink>

        <SettingsLink
          to="/invoices"
          icon="euro"
          title="Rechnungen"
        >
          <p>Rechnungen herunterladen und Kontostand einsehen.</p>
        </SettingsLink>
      </div>

      <div className="row settings-row mb-3">
        <SettingsLink
            to="/data_processing_contracts"
            icon="certificate"
            title="AV-Verträge"
        >
          <p>Verträge zur Auftragsdatenverarbeitung verwalten.</p>
        </SettingsLink>

        <SettingsLink
          to="/commissions"
          icon="thumbs-up"
          title="Kunden werben"
        >
          <p>Kunden werben und Provisionen erhalten.</p>
        </SettingsLink>
      </div>

      <hr/>

      <h5>Kundenkonto schließen</h5>

      <div className="row settings-row my-3">
        <SettingsLink
          to="/delete-account"
          icon="close"
          title="Account löschen"
        >
          <p>Den Account unwiderruflich löschen.</p>
        </SettingsLink>
      </div>
    </>
  )
}

export default Settings;
