import GenericIndex from 'client/js/resellercp/generic_resource/index';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon } from 'client/js/util/layout_utils';
import Callout from '../../util/callout';
import ExternalLink from '../../util/external_link';

const download_fn = (id, _f, refresh_cb) => {
  return <div>
      <a href={`/usercp/data_processing_contracts/${id}.pdf`} className="mr-1"><FaIcon name="file-pdf-o" /></a>
      <ConfirmedDelete link namespace="usercp" resource="data_processing_contracts" id={id} button_class="btn-link" button_text={<FaIcon name="trash" />} success_cb={refresh_cb} />
    </div>;
}

const error_handler = (status_code) => {
  if (status_code === 'premium_or_payment_required') {
    return <Callout title="Webhosting-Paket notwendig">
      <p>Für den Abschluss eines "Vertrags zu Auftragsdatenverarbeitung" entstehen uns Kosten und Aufwand. Deshalb ist ein kostenpflichtiges <ExternalLink text='Webhosting-Paket' href='/webhosting' /> notwendig. Alternativ kannst Du den Vertrag - um unseren Aufwand dafür abzudecken gegen eine Gebühr von 10 €/Jahr - auch ohne Webhosting-Paket abschließen. Dazu kannst Du den <ExternalLink text='Vertragsabschluss über unseren Shop bestellen' href='/cart/add_item?item=data_processing_contract&quantity=1' />.</p>
      <p>Du kannst vorher den <ExternalLink text="Vertragsentwurf" href="/usercp/data_processing_contracts/model_contract.pdf" /> und die <ExternalLink text='Liste der Subunternehmer' href='/usercp/data_processing_contracts/contractors.pdf' /> einsehen.</p>


    </Callout>;
  }
}

const fields = [{name: 'contract_revision', type: 'text'},
                {name: "created_at", type: "datetime"},
                {name: "id", type: "component", fn: download_fn}]

const Index = () => (<GenericIndex namespace="usercp"
  back_url="/settings"
                                   resource_name="data_processing_contracts"
                                   error_handler={error_handler}
                                   fields={fields} />);

export default Index;
