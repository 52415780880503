import {FaIcon} from 'client/js/util/layout_utils';
import Modal from 'react-modal';
import {GenericReactForm, SubmitButton, StringInput} from 'client/js/util/form_utils';
import PropTypes from 'prop-types';

const CreateModal = (props) => (
  <Modal isOpen={true} className="modal fade in show d-block" contentLabel="Snapshot erstellen" centered>
    <GenericReactForm url={`/usercp/virtual_machines/${props.virtual_machine_id}/snapshots`} onSuccess={props.onSuccess} resetOnSuccess={false}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className='modal-title'>Snapshot erstellen</h5>
            <button type="button" className="close" onClick={props.handleAbort} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {!props.freeSnapshot && <p>Snapshots werden mit 2 Cent pro Gigabyte und Monat berechnet.</p>}

            <StringInput name="name" label="Name (optional)" />
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-light" onClick={props.handleAbort}><FaIcon name="times" /> abbrechen</button>

            {props.freeSnapshot ? <SubmitButton icon="plus" text="Snapshot erstellen" /> : <SubmitButton icon="plus" text="Snapshot zahlungspflichtig erstellen" />}
          </div>
        </div>
      </div>
    </GenericReactForm>
  </Modal>
)

const SnapshotCreateToolbar = (props) => {
  const [open, setOpen] = React.useState(false);

  const onOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
  }

  const onSuccess = () => {
    props.snapshotCreatedCb();
    setOpen(false);
  }

  return (
    <>
      <button className="btn btn-secondary" onClick={onOpen}>
        <FaIcon name="camera" /> Snapshot erstellen
      </button>
      {open && (
        <CreateModal
          virtual_machine_id={props.virtual_machine.id}
          freeSnapshot={props.virtual_machine.included_snapshots > 0}
          onSuccess={onSuccess}
          snapshotCreatedCb={props.snapshotCreatedCb}
          handleAbort={onClose}
        />
      )}
    </>
  )
}

SnapshotCreateToolbar.propTypes = {
  virtual_machine: PropTypes.object.isRequired,
  snapshotCreatedCb: PropTypes.func.isRequired,
}

export default SnapshotCreateToolbar;
