import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import {PageHeader} from 'client/js/util/layout_utils';
import {PasswordInput} from 'client/js/util/form_utils';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import Callout from 'client/js/util/callout';

const Form = () => {
  return <div>
      <Callout title="Passwort-Änderung" text="Wenn Du das Passwort für dieses Postfach änderst, müssen die Passwörter auch auf allen Geräten (Smartphones, PC-Programme), welche dieses Postfach verwenden, angepasst werden!" />

      <PasswordInput required name="password" label="Passwort" />
    </div>;
}

Form.propTypes = {
  data: PropTypes.shape({
    domains: PropTypes.array.isRequired
  }).isRequired
};

const SuccessCallout = (props) => (<div>
    <PageHeader text="Passwort ändern" />

    <Callout calloutClass="success" title="Passwort geändert">
      <p>Das Passwort wurde erfolgreich geändert. Bitte vergewissere Dich, dass auf allen Geräten, die das Postfach verwenden, das neue Passwort eingestellt ist.</p>

      <p>
        <Link className="btn btn-primary" to={`/mailboxes/${props.id}`}>zurück zum Postfach</Link>
        {' '}
        <Link className="btn btn-secondary" to={`/mailboxes/${props.id}/credentials`}>Zugangsdaten & Anleitung</Link>
        </p>
    </Callout>
  </div>)

const Password = (props) => (<GenericInteraction namespace="usercp"
                                    resource_name="mailboxes"
                                    display_resource_name="mailboxes"
                                    action="password"
                                    method="put"
                                    id={props.match.params.id}
                                    success_splash={() => (<SuccessCallout id={props.match.params.id} />)}
                                    form_component={() => (<Form />)} />);

export default Password;
