import {FaIcon, Card} from 'client/js/util/layout_utils';
import InlineSpinner from 'client/js/util/inline_spinner';

class Subscribe extends React.Component {
  state = {}

  componentDidMount() {
    $.get('/usercp/webhosting/migration_status').done((data) => {
      this.setData(data);
    })
  }

  load = () => {
    $.get('/usercp/webhosting/migration_status').done((data) => {
      this.setData(data);
    })
  }

  setData = (data) => {
    this.setState({data: data.migration});
      if(!data.migration || (data.migration
                              && data.migration.state != 'failed'
                              && data.migration.state != 'completed')) {
        window.setTimeout(this.load, 1000);
      }
  }

  render() {
    if(!this.state.data) {
      return <Card title={false}>
        <div className="card-body">
          <h4 className="mb-0">
            <span className="css-spinloader mr-1 ml-0"></span> Warte auf Beginn der Migration...
          </h4>
        </div>
      </Card>
    }

    if(this.state.data.state == 'initial_sync') {
      return <Card title={false}>
        <div className="card-body">
          <h4 className="mb-0">
            <span className="css-spinloader mr-1 ml-0"></span> Bereite den Transfer des Accounts vor...
          </h4>
        </div>
      </Card>
    }

    if(this.state.data.state == 'completed') {
      return <Card title={false}>
        <div className="card-body">
          <h4 className="text-success mb-0">
            <FaIcon name="thumbs-o-up fa-1x" /> Account erfolgreich umgezogen!
          </h4>
        </div>
      </Card>
    }

    if(this.state.data.state == 'failed') {
      return <Card title={false}>
        <div className="card-body">
          <h4 className="text-warning mb-0">
            <FaIcon name="flash fa-1x" /> Fehler beim Umzug. Wir versuchen es später noch einmal!
          </h4>
          <small>Deine Webseite und Datenbanken funktionieren wie gewohnt weiter. Ein Techniker wird sich das Problem ansehen und den Server-Umzug durchführen.</small>
        </div>
      </Card>
    }

    return <Card title={false}>
        <div className="card-body">
          <h4 className="mb-0">
            <span className="css-spinloader mr-1 ml-0"></span> {i18n_t(`subscription_migration_status.${this.state.data.state}`)}
          </h4>
        </div>
      </Card>
  }
}

export default Subscribe;
