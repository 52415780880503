import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import {StringInput, TextInput} from 'client/js/util/form_utils';
import {PageHeader} from 'client/js/util/layout_utils';
import {withLoading} from 'client/js/util/rest_utils';
import Callout from 'client/js/util/callout';

const SuccessCallout = () => (<div>
    <PageHeader text="Webhosting-Paket kündigen" />

    <Callout calloutClass="success" title="Webhosting-Paket gekündigt">
      <p>Die Kündigung für das Webhosting-Paket wurde eingetragen. Eine Bestätigung wird zusätzlich per E-Mail versendet.</p>
    </Callout>
  </div>)

const Info = (props) => (<div>
  <div className="row">
    <label className="col-md-3 col-form-label">Kündigungszeitpunkt:</label>
    <div className="col-md-9">
      <span className="form-control-plaintext">{dayjs(props.data.cancellation_date).format('L')}</span>
    </div>
  </div>

  {!!(props.data.used_domains && props.data.used_domains.length) && <div className="row">
    <label className="col-md-3 col-form-label">verbundene Domains, die gekündigt werden:</label>
    <div className="col-md-9">
      <ul>
        {props.data.used_domains.map((i, index) => (<li key={index}>{i.unicode_fqdn}</li>))}
      </ul>
    </div>
  </div>}
</div>)

const InfoContainer = withLoading(Info);

const Form = () => (<div>
    <InfoContainer url="/usercp/webhosting.json" key_name="subscription" />

    <StringInput required type="password" name="password" label="Kunden-Passwort" />

    <TextInput name="feedback" label="Feedback" hint="Unzufrieden? Woanders bessere Konditionen erhalten? Sag uns, was Dich stört, wir möchten unser Angebot verbessern!" />
  </div>)

const Page = () => (<GenericInteraction namespace="usercp"
                                             resource_name="webhosting"
                                             form_namespace="cancel"
                                             form_url="/usercp/webhosting/cancel"
                                             action="cancel"
                                             method="post"
                                             success_splash={() => (<SuccessCallout />)}
                                             form_component={() => (<Form />)} />);

export default Page;
