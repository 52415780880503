import GenericIndex from 'client/js/resellercp/generic_resource/index';
import {AnimatedTabs, AnimatedNavItem as NavItem} from 'client/js/util/nav_tabs';
import { Link } from 'react-router-dom';
import { Actions, Status } from './lib';

const Header = () => {
  return (
    <tr>
      <th>Nr.</th>
      <th>Name</th>
      <th>Betrag</th>
      <th>Status</th>
      <th>Datum</th>
      <th>Zahlungsmethode</th>
      <th></th>
    </tr>
  )
}

const TableRow = ({item: order, refreshCb: refetch}) => {
  return (
    <tr key={order.id}>
      <td><Link to={`/orders/${order.id}`}>{order.reference_code || order.id}</Link></td>
      <td>{order.title}</td>
      <td>{format_fractional_price(order.amount)}</td>
      <td><Status status={order.status} /></td>
      <td>{dayjs(order.checked_out_at).format('L LT')}</td>
      <td>{order.paid_by && i18n_t(`resources.orders.payment_method.${order.paid_by}`)}</td>
      <td>
        {order.status == 'pending' && <Actions order={order} refetch={refetch} />}
      </td>
    </tr>
  );
}

const tabs = (
  <AnimatedTabs>
    <NavItem to="/invoices">Rechnungen</NavItem>
    <NavItem to="/orders">Bestellungen</NavItem>
  </AnimatedTabs>
)

const Index = () => (
  <GenericIndex
    namespace="usercp"
    resource_name="orders"
    tabs={tabs}
    back_url="/settings"
    table_header={Header}
    row={TableRow}
    no_create
  />
);

export default Index;
