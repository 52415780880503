import {Route, Switch} from 'react-router-dom';
import Index from './index';
import Create from './create';

const ResourceRouter = () => (
    <Switch>
      <Route path="/data_processing_contracts/new" exact component={Create} />
      <Route path="/data_processing_contracts" exact component={Index} />
    </Switch>);

export default ResourceRouter;
