import ExternalLink from 'client/js/util/external_link';
import { FormActions, GenericReactForm, SubmitButton } from 'client/js/util/form_utils';
import AuthorizedKeysInput from 'client/js/util/inputs/authorized_keys_input';
import { PageHeader } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';
import { Redirect } from 'react-router-dom';

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {success: false};

    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action, original_event, data) {
    if(action == "form:success")
      this.setState({success: true})
  }

  render() {
    if(this.state.success)
      return <Redirect to="/ssh_accounts" />;

    let authorized_key_defaults = [];
    if(this.props.data.authorized_keys)
      authorized_key_defaults = this.props.data.authorized_keys.map((item) => (item.id));

    return <>
      <PageHeader back_url="/ssh_accounts" text="SSH-Keys bearbeiten" />

      <GenericReactForm namespace="authorized_keys"
                        defaults={{authorized_key_ids: authorized_key_defaults}}
                        url={`/usercp/ssh_accounts/authorized_keys`}
                        dispatch={this.dispatch}>

        <AuthorizedKeysInput required name="authorized_key_ids" label="SSH-Keys" hint={<span>Für die Authentifizierung erlauben wir aus Sicherheitsgründen ausschließlich SSH-Keys und keine Passwörter. Bitte beachte auch unsere <ExternalLink href='/hilfe/ssh-keys-erzeugen'>Hilfe zu SSH-Keys</ExternalLink>.</span>} />

        <FormActions>
          <SubmitButton icon="save" text="SSH-Keys speichern" />
        </FormActions>
      </GenericReactForm>
    </>;
  }
}

const ShowLoading = withLoading(Edit);
const ShowPage = () => (<ShowLoading url={`/usercp/ssh_accounts.json`} key_name="ssh_account" resource_name="ssh_accounts" />);

export default ShowPage;
