import SnapshotTableRow from './snapshot_table_row';
import { Pagination } from 'client/js/util/pagination';

const SnapshotTable = (props) => {
  let classes = "table table-striped table-wide table-with-buttons table-snapshots";

  if (props.selectSnapshot)
    classes += " table-with-radios"

  let pagination = null;
  if(props.pagination_data && props.pagination_data.total_pages > 1) {
    pagination = <Pagination currentPage={props.pagination_data.current_page} totalPages={props.pagination_data.total_pages} onPageChange={props.changePage} />;
  }

  return (
    <>
    {pagination}

    <table className={classes}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>erstellt</th>
          <th>{props.selectSnapshot ? 'minimale SSD-Größe' : 'Größe'}</th>
          {!props.selectSnapshot ? <th></th> : null}
        </tr>
      </thead>
      <tbody>
        {props.snapshots.map((item) => {
          return (<SnapshotTableRow dispatch={props.dispatch} selectSnapshot={props.selectSnapshot} key={item.id} renderOrigin={props.renderOrigin} snapshot={item} fetchSnapshots={props.refreshCb} restore_enabled={props.restore_enabled} virtual_machine_id={props.virtual_machine_id} />);
        })}
      </tbody>
    </table>

    {pagination}
    </>
  );
};

export default SnapshotTable;
