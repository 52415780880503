import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import { RadioCollectionInput, CheckboxCollectionInput } from 'client/js/util/form_utils';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';
import Callout from 'client/js/util/callout';

class SuccessCallout extends React.Component {
  render() {
    return (
      <React.Fragment>
        <PageHeader text="Zusatzpaket gebucht" />

        <Callout calloutClass="success" title="Vielen Dank für die Buchung!">
          <p>Das Zusatzpaket wurde erfolgreich gebucht und ist bereits aktiv!</p>
        </Callout>
      </React.Fragment>
    )
  }
}

const Form = (props) => {
  const addons = props.data.addons.map((item) => (
    [item.id, <div key={item.id} className="mb-2"><h5 className="mb-1">{item.title} ({format_fractional_price(item.price_per_month)}/Monat)</h5><small>Für den aktuellen Abrechnungszeitraum werden {format_fractional_price(item.remaining_price)} in Rechnung gestellt.</small></div>]
  ));

  const replaceable_addons = props.data.addon_subscriptions.map((item) => (
    [item.id, <div key={item.id} className="mb-2"><h5 className="mb-1">{item.title} ({format_fractional_price(item.price_per_month)}/Monat)</h5><small>Es werden bereits bezahlte {format_fractional_price(item.remaining_price)} verrechnet.</small></div>]
  ));

  return <React.Fragment>
    <Callout title={false}>
      <p>
        Das ausgewählte Zusatzpaket wird sofort aktiv. Für den aktuellen Leistungszeitraum des Webhosting-Pakets wird der anteilige Preis berechnet. Bei jeder weiteren Verlängerung des Webhosting-Pakets wird der Preis pro Monat verrechnet.
      </p>
    </Callout>
    <RadioCollectionInput required name="addon_id" label="Zusatzpaket" options={addons} />

    {replaceable_addons.length > 0 &&
      <CheckboxCollectionInput name="replace_subscriptions_id" label="Zu ersetzende Zusatzpakete" hint="Ermöglicht es, ein bestehendes Zusatzpaket durch ein neues Paket zu ersetzen. Der bereits bezahlte Leistungszeitraum des Zusatzpakets wird anteilig erstattet." options={replaceable_addons} />}
  </React.Fragment>
}

const FormContainer = withLoading(Form, {url: '/usercp/webhosting/addons.json'});

const Page = () => (<GenericInteraction namespace="usercp"
                                             resource_name="webhosting"
                                             form_namespace="addon"
                                             form_url="/usercp/webhosting/addons"
                                             action="create_addon"
                                             method="post"
                                             button_text="Zahlungspflichtig bestellen"
                                             success_splash={() => (<SuccessCallout />)}
                                             form_component={() => (<FormContainer />)} />);

export default Page;
