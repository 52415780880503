import StandaloneSnapshotMenu from 'client/js/usercp/cloud/snapshots/standalone_snapshot_menu';
import Callout from 'client/js/util/callout';
import ExternalLink from 'client/js/util/external_link';
import {ControlGroup, StringInput, TextInput, GenericReactForm, SubmitButton} from 'client/js/util/form_utils';
import Modal from 'react-modal';

var VmTemplate = function(props) {
  const n = `usercp_create_vm_template_id_${props.template.id}`;

  const selectTemplate = (e) => {
    props.dispatch('template_select', e, props.template.id);
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={props.template.id == props.selectedTemplate ? "vm-template selected" : "vm-template"} onClick={selectTemplate}>
      <input
        onChange={selectTemplate}
        checked={props.template.id == props.selectedTemplate}
        className="css-checkbox"
        id={n}
        name="usercp_create_vm[template_id]"
        type="radio"
        required={true}
        value={props.template.id} />

      <label className="css-label" htmlFor={n}>
        <h4>{props.template.title}</h4>
        <div className="version">{props.template.version}</div>

        <img src={`/assets/icons/distro/${props.template.icon}.png`} alt={`Logo für ${props.template.title}`} />
      </label>
    </div>
  );
}

var RootPasswordInput = function(props) {
  const n = `usercp_create_vm_${props.name}`

  return (<ControlGroup errors={props.errors} type="password_with_strength" name={n} label="Root-Passwort"
            required={true}
            hint={<span>Das Root-Passwort ist nur für den Login per VNC-Konsole nötig.<br/>Ein Login als Root per SSH ist aus Sicherheitsgründen nicht aktiv.</span>}>
              <input value={props.value} type="password"
                     onChange={e => props.dispatch(`${props.name}_changed`, e)}
                     aria-required={props.required}
                     className="string required"
                     id={n}
                     name={`usercp_create_vm[${props.name}]`}
                     required={props.required} />

            <div className="feedback"></div>
            <div className="strengthometer">
              Stärke:
                <div className="progress">
                  <div className="progress-bar"></div>
                  <div className="progress-bar"></div>
                  <div className="progress-bar"></div>
                  <div className="progress-bar"></div>
                </div>
                <span className="text-strength"></span>
            </div>
          </ControlGroup>);
}

var RadioCollectionInput = function(props) {
  const n = `usercp_create_vm_${props.name}`;

  return (
    <ControlGroup errors={props.errors} name="usercp_create_vm_ip_assignment" type="collection" labelFor={n} required={props.required} label={props.label}>
        {props.options.map((v, index) => {
          let n = `usercp_create_vm_${props.name}_${v[0]}`;

          return <div key={v[0]} className="custom-control custom-checkbox checkbox-option">
            <input defaultChecked={props.selectedOption == v[0]}
                   onChange={e => props.dispatch(`${props.name}_select`, e, v[0])}
                   className="custom-control-input collection"
                   id={n}
                   name={`usercp_create_vm[${props.name}]`}
                   type="checkbox"
                   value={v[0]} />
            <label className="custom-control-label" htmlFor={n}>{v[1]}</label>
          </div>;
        })}
    </ControlGroup>);
}

var BooleanInput = function(props) {
  const n = `usercp_create_vm_${props.name}`;
  const type = props.required ? "string required" : "string"

      // <ControlLabel type={type} n={n} required={props.required} label={props.label} />
  return (
    <div className={`row form-group boolean required usercp_create_vm_${props.name}`}>

      <div className="col-md-9 offset-md-3">
        <div className="custom-control custom-checkbox">
          <input aria-required="true"
                 defaultChecked={!!props.defaultChecked}
                 onChange={e => props.dispatch(`${props.name}_changed`, e)}
                 className="custom-control-input boolean"
                 id={n}
                 name={`usercp_create_vm[${props.name}]`}
                 type="checkbox"
                 value="1" />
          <label className="custom-control-label" htmlFor={n}>{props.label}</label>
        </div>
      </div>
    </div>);
}


var TemplateSelect = function(props) {
  return (<div>
    {props.templates.map((template, i) => {
      return <VmTemplate key={template.id} template={template} dispatch={props.dispatch} selectedTemplate={props.selectedTemplate} />
    })}
    </div>);
}

var NavPills = function(props) {
  return <ul className="nav nav-pills mb-3">
    {props.items.map((v, i) => {
      return <li key={v[0]} className="nav-item">
        <a href="#" className={props.selectedItem == v[0] ? 'nav-link active' : 'nav-link'} onClick={e => props.dispatch('change_template_type', e, v[0])}>{v[1]}</a>
      </li>
    })}
  </ul>;
}

var InnerStep = function(props) {
  const types = [
    ['image', 'Images'],
    ['iso', 'ISO-Bibliothek'],
    ['snapshots', 'Snapshots']
  ];

  const typesMap = {
    iso_installer: "image",
    image: "image",
    iso: "iso",
    snapshots: "snapshot"
  }

  let tabContent = null;
  const ft = props.templates.filter((i) => { return i.template_type == props.type });

  if(props.type == 'image')
    tabContent = <div>
                  <TemplateSelect templates={ft} selectedTemplate={props.selectedTemplate} dispatch={props.dispatch} />

                  <Callout title="Zugriff auf das Cloud-VPS">
                    <p><strong>Das gewählte Image benötigt SSH-Keys</strong> zum Herstellen einer Verbindung per SSH. Es ist aus Sicherheitsgründen nicht möglich, sich per SSH mit einem Passwort als User "root" zu verbinden. Um sich als "root" zu verbinden muss ein SSH-Key verwendet werden. Das Root-Passwort wird ausschließlich für den Zugriff per VNC-Konsole benötigt. Ein Zugriff per VNC-Konsole ist im Regelfall nicht nötig, daher ist es in 99% der Fälle nicht nötig, ein Root-Passwort zu setzen.</p>
                    <p>Mehr Informationen findest Du in der <ExternalLink href="/hilfe/zugriff-auf-cloud-vps" text="Hilfe zum Zugriff auf Cloud-VPS" />.</p>
                  </Callout>

                  <RadioCollectionInput name="ssh_key_ids" label="SSH-Keys" required={true} options={props.ssh_keys} errors={props.serverErrors.ssh_key_ids} defaultChecked={props.setRootPassword} dispatch={props.dispatch} />

                  <Modal isOpen={props.sshKeyFormOpen} className="modal fade in show d-block" contentLabel="SSH-Key anlegen">
                    <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                      <h3>SSH-Key anlegen</h3>
                      <button type="button" className="close" onClick={(e) => props.dispatch('abort_new_ssh_key', e)} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <GenericReactForm namespace="ssh_key" url="/usercp/ssh_keys" dispatch={props.dispatch}>
                      <div className="modal-body">
                        <StringInput name="name" label="Name (optional)" />
                        <TextInput name="text_key" label="SSH-Key in Textform" rows={8} extraClass="input-xxlarge"
                                   required={true}
                                    placeholder="Beginnt mit 'ssh-rsa', 'ssh-dss', 'ssh-ed25519', 'ecdsa-sha2-nistp256', 'ecdsa-sha2-nistp384' oder 'ecdsa-sha2-nistp521'"
                                    hint={<span>Rufe unsere <ExternalLink href="/hilfe/ssh-keys-erzeugen">Hilfe für die Generierung von SSH-Keys</ExternalLink> auf.</span>} />
                      </div>

                      <div className="modal-footer">
                        <SubmitButton icon="plus" text="SSH-Key erstellen" />
                      </div>
                    </GenericReactForm>
                    </div>
                    </div>
                  </Modal>

                    <div className="row form-group">
                      <div className="col-md-9 offset-md-3">
                        <a href="/usercp/ssh_keys/new" onClick={(e) => props.dispatch('new_ssh_key', e)}>Neuen SSH-Key hinzufügen</a>
                      </div>
                    </div>

                  <BooleanInput name="set_root_password" label="Root-Passwort setzen" defaultChecked={props.setRootPassword} dispatch={props.dispatch} />
                  {props.setRootPassword && <RootPasswordInput name="root_password" errors={props.serverErrors.root_password} label="Root-Passwort" required={true} dispatch={props.dispatch} />}
                </div>;
  else if(props.type == 'snapshots')
    tabContent = <div>
      <Callout title="Snapshot-Restore" text="Dies wird ein neues Cloud-VPS aus dem gewählten Snapshot erstellen. Bitte beachte, dass die IP-Adressen des neuen VPS anders sein können und es daher hilfreich ist, wenn das Cloud-VPS seine IP-Adressen via DHCP bezieht." />

      <StandaloneSnapshotMenu noReload={true} snapshots_url="/usercp/virtual_machines/snapshots?filter=completed" dispatch={props.dispatch} selectSnapshot={true} />
      </div>;
  else if(props.type == 'iso')
    tabContent = <TemplateSelect templates={ft} selectedTemplate={props.selectedTemplate} dispatch={props.dispatch} />;

  return (<fieldset>
  <legend>2. Betriebssystem/Software</legend>
    <NavPills items={types} selectedItem={props.type} dispatch={props.dispatch} />

    <input type="hidden" name="usercp_create_vm[template_type]" value={typesMap[props.type]} />

    {tabContent}
  </fieldset>);
}

class VmTemplateStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {sshKeys: props.ssh_keys};
    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action, original_event, data) {
    console.log(`Got action ${action} with data`, data);

    switch (action) {
      case "new_ssh_key":
        this.setState({sshKeyFormOpen: true})
        original_event.preventDefault();
        break;
      case "form:success":
        this.setState((prevState, props) => {
          let k = prevState.sshKeys;
          k.push([data.ssh_key.id, data.ssh_key.name]);
          return {sshKeyFormOpen: false, sshKeys: k};
        })
        break;
      case "abort_new_ssh_key":
        this.setState({sshKeyFormOpen: false})
        original_event.preventDefault();
        break;
      default:
        this.props.dispatch(action, original_event, data);
    }
  }

  render() {
    return (<InnerStep type={this.props.type}
                        ssh_keys={this.state.sshKeys}
                        templates={this.props.templates}
                        setRootPassword={this.props.setRootPassword}
                        selectedTemplate={this.props.selectedTemplate}
                        selectedSnapshot={this.props.selectedSnapshot}
                        serverErrors={this.props.serverErrors}
                        sshKeyFormOpen={this.state.sshKeyFormOpen}
                        dispatch={this.dispatch} />);
  }
}

export default VmTemplateStep;
