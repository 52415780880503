import {FaIcon} from 'client/js/util/layout_utils';
import {CheckboxCollectionInput} from 'client/js/util/form_utils';

class AddableCheckboxCollectionInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {input: false, options: props.options};

    this.clickAdd = this.clickAdd.bind(this);
    this.abort = this.abort.bind(this);
    this.submit = this.submit.bind(this);
  }

  clickAdd(e) {
    e.preventDefault();
    this.setState({input: true});
  }

  abort(e) {
    e.preventDefault();
    this.setState({input: false, new_name: ""});
  }

  submit(e) {
    e.stopPropagation();
    if(!this.state.new_name || !this.state.new_name.length)
      return;

    let new_options = this.state.options;
    new_options[this.state.new_name] = this.state.new_name;
    this.setState({input: false, new_name: "", options: new_options});
  }

  render() {
    let hint = <a className="mt-1" onClick={this.clickAdd} href="#"><FaIcon name="plus" /> hinzufügen</a>;

    if(this.state.input) {
      hint = <form className="form-inline mt-1" action="#" onSubmit={this.submit}>
        <input autoFocus className="form-control mr-1" type="form-control" required name="new_option" value={this.state.new_name} onChange={(e) => this.setState({new_name: e.target.value})} onEnter={this.submit} />
        <button className="btn btn-secondary submit mr-1" onClick={this.submit}><FaIcon name="plus" /></button>
        <a href="#" className="btn" onClick={this.abort}><FaIcon name="times" /></a>
      </form>;
    }

    return <CheckboxCollectionInput options={this.state.options} hint={hint} {...this.props} />;
  }
}

export default AddableCheckboxCollectionInput;
