import VmModelStep from "client/js/usercp/cloud/wizard/model_step";
import VmTemplateStep from "client/js/usercp/cloud/wizard/template_step";
import VmNetworkStep from "client/js/usercp/cloud/wizard/network_step";
import VirtualMachinesProvisioningProgress from "client/js/usercp/cloud/virtual_machines/virtual_machine_provisioning_progress";
import {ReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';

class VirtualMachineWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {serverErrors: {}, networkModel: 'dual-stack', templateType: 'image'};

    this.dispatch = this.dispatch.bind(this);
  }

  componentDidMount() {
    window.document.title = "Cloud-VPS erstellen";
  }

  dispatch(action, original_event, data) {
    console.log(`Got action ${action} with data`, data);

    switch (action) {
      case "model_select":
        this.setState({selectedModel: data.id, diskSize: data.disk_size});
        break;
      case "template_select":
        this.setState({selectedTemplate: data, selectedSnapshot: null});
        break;
      case "snapshot_selected":
        this.setState({selectedSnapshot: data.id, minDiskSize: data.disk_size_gb, selectedTemplate: null});
        break;
      case "set_root_password_changed":
        this.setState({setRootPassword: original_event.target.checked});
        break;
      case "ssh_key_ids_select":
        original_event.persist();

        this.setState((prevState) => {
          let keys = prevState.sshKeys || [];

          if(original_event.target.checked) {
            keys = keys.concat(original_event.target.value);
          } else {
            const p = keys.indexOf(original_event.target.value);
            if(p !== -1)
              keys.splice(p, 1);
          }
          return {sshKeys: keys};
        });

        break;
      case "network_select":
        this.setState({networkModel: data});
        break;
      case "change_template_type":
        this.setState({templateType: data, selectedTemplate: null, selectedSnapshot: null});
        original_event.preventDefault();
        break;
      case "hostname_change":
        this.setState({hostname: original_event.target.value});
        break;
      case "submit":
        original_event.preventDefault();
        var form = original_event.target;
        this.setState({submitting: true});

        $.post("/usercp/virtual_machines.json", $(form).serialize())
          .done((data, x, y) => {
            if(y.status == 201) {
              // successfully created the new VPS
              this.setState({creatingVm: data.virtual_machine.id, submitting: false})
              $('.page-header')[0].scrollIntoView();
            } else {
              // got some error here
              this.setState({serverErrors: data.errors, submitting: false})
            }
            console.error("POSTed data");
          })
          .fail(() => {
            this.setState({serverErrors: {}, submitting: false})
            console.error("Failed to POST data");
          })

        break;
    }
  }

  render() {
    if(this.state.creatingVm)
      return <VirtualMachinesProvisioningProgress url={`/usercp/virtual_machines/${this.state.creatingVm}.json`} />;

    return (
      <ReactForm dispatch={this.dispatch}>
        <VmModelStep serverErrors={this.state.serverErrors} models={this.props.models} selectedModel={this.state.selectedModel} dispatch={this.dispatch} />
        <VmTemplateStep type={this.state.templateType}
                        ssh_keys={this.props.ssh_keys.map((key) => ([key.id, key.name]))}
                        templates={this.props.templates}
                        setRootPassword={this.state.setRootPassword}
                        selectedTemplate={this.state.selectedTemplate}
                        selectedSnapshot={this.state.selectedSnapshot}
                        serverErrors={this.state.serverErrors}
                        dispatch={this.dispatch} />
        <VmNetworkStep serverErrors={this.state.serverErrors} hostname={this.state.hostname} networkModel={this.state.networkModel} dispatch={this.dispatch} />

        <input type='hidden' name='authenticity_token' value={this.props.authenticity_token} />

        <FormActions>
          <SubmitButton submitting={this.state.submitting} text="Cloud-VPS erstellen" /> <small className="muted">Dies ist eine zahlungspflichtige Aktion! Das Cloud-VPS wird ab dem Zeitpunkt der Bereitstellung und bis zur Löschung abgerechnet. Das Stoppen unterbricht die Abrechnung nicht.</small>
        </FormActions>

        <div className="tax_footnote">Preisangaben verstehen sich inklusive Umsatzsteuer. Der Umsatzsteuer-Satz ist nach EU-Richtline 2008/8/EG abhängig vom Land des Dienstleistungsempfängers.</div>
      </ReactForm>);
  }
}

export default VirtualMachineWizard;
