import Callout from 'client/js/util/callout';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';

var ApiKeysTable = function(props) {
  if(props.data.length == 0)
    return <Callout calloutClass="primary" title="Keine API-Keys vorhanden" text="Es sind für Deinen Account keine API-Keys vorhanden..." />;

  // return <tr key={(item.id || index)}><td><Link to={`/api_keys/${item.id}`}>{item.api_key}</Link></td><td>{item.permissions}</td><td><Link to={`/api_keys/${item.id}/edit`} className="btn btn-secondary"><FaIcon name="pencil" /> bearbeiten</Link></td></tr>;
  return (<table className="table table-striped table-wide">
            <thead>
              <tr><th>API-Key</th><th>Erstellt</th><th>Zuletzt verwendet</th></tr>
            </thead>
            <tbody>
             {props.data.map((item, index) => {
                return <tr key={(item.id || index)}>
                        <td><Link to={`/api_keys/${item.id}`}>{item.name || 'Unbenannter API-Key'}</Link></td>
                        <td>{dayjs(item.created_at).format('L')}</td>
                        <td>{item.last_used_at ? dayjs(item.last_used_at).format('l') : '-'}</td>
                       </tr>;
              })}
            </tbody>
          </table>);
}

const ApiKeysTableContainer = withLoading(ApiKeysTable);

var ApiKeysIndex = function() {
  return <>
      <PageHeader back_url="/settings" text="API-Keys" buttons={[<Link key="0" to={`/api_keys/new`} className="btn btn-light"><FaIcon name="plus" /> Neuer API-Key</Link>]} />

      <ApiKeysTableContainer url="/usercp/api_keys.json" key_name="api_keys" />
    </>;
}

export default ApiKeysIndex;
