import PropTypes from 'prop-types';
import { PageHeader, FaIcon } from 'client/js/util/layout_utils';
import { Link, Redirect } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import Callout from 'client/js/util/callout';
import { errorToComponent } from 'client/js/util/rest_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { GenericReactForm, StringInput, FormActions, SubmitButton, SelectCollectionInput, BooleanInput } from 'client/js/util/form_utils';
import { toast } from 'react-toastify';
import SingleSignIn from './single_sign_in';
import { useQuery } from '@tanstack/react-query';
import { errorToToast } from '../../util/rest_utils';

const make_blog_name = (domain) => {
  return domain.replace(/[^a-zA-Z0-9-]/g, '_').toLowerCase();
}

const Progress = ({data}) => {
  if(data.state == 'successful') {
    return (
      <>
        <PageHeader text="WordPress Import" />

        <Callout title={<>Der Import wurde erfolgreich abgeschlossen! 🎉</>} calloutClass="success">
          <p>Dein WordPress wurde erfolgreich importiert. Du kannst es jetzt aufrufen, über unsere Admin-Konsole verwalten oder Dich direkt als Admin einloggen!</p>

          <div className="mb-1">
            <a href={data.installation_url} target="_blank" className="btn btn-light mr-1" rel="noreferrer">{data.installation_host} <FaIcon name="external-link" /></a>
            <Link to={`/wordpress/${data.wordpress_installation_id}`} className="btn btn-light mr-1"><FaIcon name="wrench" /> Verwalten</Link>
            <SingleSignIn app_id={data.wordpress_installation_id} />
          </div>

          <pre className="mb-0">
            <code className="d-block">{data.log}</code>
          </pre>
        </Callout>
      </>
    );
  }

  if(data.state == 'failed') {
    return (
      <>
        <PageHeader text="WordPress Import" />

        <Callout title={<>Der Import ist fehlgeschlagen! 😢</>} calloutClass="danger">
          <p>Der Import des WordPress ist fehlgeschlagen. Bitte überprüfe die Log-Ausgabe und wende Dich ggf. an den Support.</p>

          <pre className="mb-0">
            <code className="d-block">{data.log}</code>
          </pre>
        </Callout>
      </>
    );
  }

  return (
    <>
      <PageHeader text="WordPress Import" />

      <Callout title={false}>
        <p>Dein WordPress wird importiert. Dieser Vorgang kann einige Minuten dauern. Bitte warte, bis der Vorgang abgeschlossen ist.</p>

        <pre className="mb-0">
          <code className="d-block">{data.log}</code>
        </pre>
      </Callout>
    </>
  );
}

const LimitOverwriteInstallation = (props, context) => {
  if (context.serverErrors && context.serverErrors.overwrite_existing_installation)
    return <fieldset>{props.children}</fieldset>;

  return null;
}

LimitOverwriteInstallation.contextTypes = {
  serverErrors: PropTypes.object
}

const fetchData = async (id) => {
  const response = $.ajax({
    url: `/usercp/wordpress/import/${id}.json`,
    dataType: 'json',
  });

  return response;
}

const ImportForm = (props) => {
  const id = props.match.params.id;
  // we want to do the fetchiung using tanstack-query / useQuery now
  const { data, error, isError, isLoading, refetch } = useQuery(['wordpress_import', id], () => fetchData(id), {
    refetchInterval: (data) => (data?.state == 'running') ? 1000 : false,
  });

  if(isLoading) {
    return (
      <>
        <PageHeader text="WordPress Import" />

        <LoadingTableSpinner />
      </>
    );
  }

  if(isError) {
    return (
      <>
        <PageHeader text="WordPress Import" />

        {errorToComponent(error)}
      </>
    );
  }

  if(data.state != 'uploaded') {
    return <Progress data={data} />;
  }

  const defaults = {
    directory: make_blog_name(data.blog_name),
    install_core_updates: data.wordpress_update_available,
    create_cronjob: data.cronjobs_available,
    use_memcached_object_cache: data.memcached_available,
    vhost_id: data.original_domain_vhost_id
  }

  const dispatch = (action) => {
    if(action == 'form:success')
      refetch();
  }

  return (
    <>
      <PageHeader text="WordPress Import" />

      <Callout title={false}>
        <p>
          Zur Überprüfung hier eine Zusammenfassung der WordPress-Installation, die importiert wird.

          Bitte wähle die Domain oder Subdomain aus, welche als Adresse des WordPress genutzt werden soll (die Adresse lässt sich später noch ändern) und gib das Verzeichnis an, in welchem die Installation auf dem Webspace gespeichert wird.
        </p>

        <p>
          Wenn das Verzeichnis nicht existiert wird eine neue Datenbank für die WordPress-Installation erstellt. Sollte das Verzeichnis bereits existieren fragen wir Dich, ob wir das Verzeichnis mit dem hochgeladenen WordPress überschreiben sollen. Wenn bereits ein WordPress in dem Verzeichnis installiert ist werden wir außerdem die Datenbank, welche für die WordPress-Installation in diesem Verzeichnis genutzt wird, erneut verwenden und auch diese überschreiben.
        </p>
      </Callout>

      <table className="table table-striped">
        <tbody>
          <tr>
            <td>Archiv-Typ</td>
            <td>{data.type}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{data.blog_name}</td>
          </tr>
          <tr>
            <td>Domain</td>
            <td>
              {data.original_domain}
            </td>
          </tr>
          <tr>
            <td>WordPress-Version</td>
            <td>
              {data.wordpress_version}
              {data.wordpress_update_available && <span className="text-danger"> (Update auf {data.current_wordpress_version} verfügbar)</span>}
            </td>
          </tr>
        </tbody>
      </table>

      <GenericReactForm defaults={defaults} namespace={null} url={`/usercp/wordpress/import/${id}`} verb="POST" dispatch={dispatch}>
        <SelectCollectionInput name="vhost_id" label="Domain" options={data.vhosts.map((v) => ([v.id, v.fqdn]))} required />

        <StringInput name="directory" label="Verzeichnis" required />
        <LimitOverwriteInstallation>
          <BooleanInput name="overwrite_existing_installation" label="Bestehende Installation überschreiben" hint="Wenn eine bestehende Installation überschrieben wird werden _alle_ Daten in dem Verzeichnis gelöscht ud überschrieben. Die Datenbank und die zugehörigen Datenbank-Zugangsdaten dieser Installation werden erneut verwendet, es wird keine neue Datenbank erstellt!" />
        </LimitOverwriteInstallation>

        <BooleanInput name="install_core_updates" label="WordPress-Updates installieren (empfohlen)" disabled={!data.wordpress_update_available} />
        <BooleanInput name="create_cronjob" label="Shell-Cronjob einrichten (empfohlen)" disabled={!data.cronjobs_available} />
        <BooleanInput name="use_memcached_object_cache" label="Memcached als Object Cache einrichten (empfohlen, ab Business-Paket verfügbar)" disabled={!data.memcached_available} />

        <FormActions>
          <SubmitButton icon="upload" text="Import starten" />
        </FormActions>
      </GenericReactForm>
    </>
  );
}

/*
  A page with only a file upload form (a big drag and drop area
    where a click opens the select file dialog).

  The allowed file types are *.zip and *.wpress. The form should
  upload the file to /usercp/wordpress/import.json using jquery
  and display the upload progress with a progress bar. It
  receives an upload  ID, then redirect to /usercp/wordpress/import/:id
  by rendering a <Redirect> component.
 */

class ImportUpload extends React.Component {
  state = {
    file: null,
    progress: null,
    upload_id: null,
    uploading: false
  }

  upload = () => {
    let formData = new FormData();
    formData.append('file', this.state.file);

    $.ajax({
      xhr: () => {
        const xhr = new window.XMLHttpRequest();

        xhr.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            this.setState({ progress: parseInt(event.loaded / event.total * 100) });
          }
        }, false);

        return xhr;
      },
      url: '/usercp/wordpress/upload.json',
      type: 'POST',
      dataType: 'json',
      data: formData,
      contentType: false,
      processData: false
    }).done((data) => {
      if(data.status == 'success')
        return this.setState({upload_id: data.id});

      this.setState({ uploading: false, file: null, progress: null, error: data.message });
    }).fail((xhr) => {
      errorToToast(xhr, 'beim Upload der Datei');
      this.setState({ uploading: false, file: null, progress: null, error: null });
    });
  }

  onDrop = (accepted_files, rejected_files) => {
    rejected_files.map((file) => {
      toast.error(`Die Datei ${file.name} kann nicht hochgeladen werden!`, { autoClose: false });
    });

    if(accepted_files.length === 0) {
      return;
    }

    const file = accepted_files[0];

    // check file extension
    const extension = file.name.split('.').pop();
    if(!['zip'].includes(extension)) {
      alert('Ungültige Dateiendung');
      return;
    }

    this.setState({ file: file, uploading: true, error: null }, () => {
      this.upload();
    });
  }

  render() {
    if(this.state.upload_id) {
      return <Redirect to={`/wordpress/import/${this.state.upload_id}`} />;
    }

    const max_size = 1024 * 1024 * 1024;

    return (
      <>
        <PageHeader text="WordPress Import" back_url="/wordpress" />

        <Callout title={false}>
          <p>Importiere eine WordPress-Installation auf Deinem Webspace. Das Archiv kann mit den WordPress-Plugins Duplicator erstellt werden. Im ersten Schritt lade bitte das Archiv hoch, im zweiten Schritt werden wir einige Einstellungen abfragen. Alle notwendigen Installationsschritte werden für Dich automatisch durchgeführt.</p>
        </Callout>

        <div className="uploader">
          <Dropzone disabled={this.state.uploading} className={this.state.uploading ? "upload-dropzone uploading mb-3" : "upload-dropzone mb-3"} onDrop={this.onDrop} maxFiles="1" maxSize={max_size} multiple={false}>
            <FaIcon name="upload fa-4x mt-2 mb-3" />

            {this.state.error && <div className="alert alert-danger mb-0">{this.state.error}</div>}

            <br />
            <p>Dateien hierhin ziehen oder klicken zum Hinzufügen</p>

            <span className="text-muted">maximal {format_disk_space(max_size, 0)}, erlaubte Dateitypen: .zip</span>
          </Dropzone>

          {!!this.state.progress &&
            <div className="upload-progress">
              <div className="css-spinloader fullpage-spinner mb-3"></div>

              <div className="progress" style={{ height: '1.5rem' }}>
                <div className="progress-bar" role="progressbar" style={{ width: `${this.state.progress}%` }} aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">{this.state.progress}%</div>
              </div>
            </div>}
        </div>
      </>
    );
  }
}

export { ImportForm as ImportRun, ImportUpload };
