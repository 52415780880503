import PropTypes from "prop-types";
import {BreadcrumbItem} from 'client/js/util/layout_utils';
import {RadioCollectionInput, SelectCollectionInput, TextInput} from 'client/js/util/form_utils';
import DomainPrefixInput from "client/js/util/inputs/domain_prefix_input";
import GenericCreate from 'client/js/resellercp/generic_resource/create';
import {withLoading} from 'client/js/util/rest_utils';

const LimitVisibility = (props, context) => {
  if(context.formData && context.formData['type'] == props.visible_value)
    return props.children;

  return null;
}

LimitVisibility.propTypes = {
  visible_value: PropTypes.string.isRequired
};

LimitVisibility.contextTypes = {
  formData: PropTypes.object
};

const Form = (props) => {
  return <div>
      <DomainPrefixInput prefix_name="local_part" hint="Benutze * (Stern) für eine Catch-All/Wildcard-Adresse" domains={props.data.domains.map((i) => ([i.label, i.id]))}
                         label="E-Mail-Adresse" separator="@"/>

      <RadioCollectionInput required name="type"
                            label="Ziel"
                            options={[['internal', `Postfach`], ['external', 'E-Mail-Adresse(n)']]} />

      <LimitVisibility visible_value='internal'>
        <SelectCollectionInput required name="mailbox" label="Postfach" options={props.data.mailboxes.map((i) => ([i.label, i.id]))} />
      </LimitVisibility>

      <LimitVisibility visible_value='external'>
        <TextInput required name="email_address" label="E-Mail-Adressen" hint="Empfänger-Adresse(n), eine je Zeile oder getrennt mit Komma, max. 20 E-Mail-Adressen" />
      </LimitVisibility>
    </div>;
}

Form.propTypes = {
  data: PropTypes.shape({
    mailboxes: PropTypes.array.isRequired,
    domains: PropTypes.array.isRequired
  }).isRequired
};

const FormContainer = withLoading(Form);

const Create = () => (<GenericCreate namespace="usercp"
                                    resource_name="aliases"
                                    back_url="/email"
                                    display_resource_name="mail_aliases"
                                    defaults={{type: 'internal'}}
                                    redirectAfter="url"
                                    redirect_url="/email"
                                    form_component={() => (<FormContainer url="/usercp/aliases/new.json" key_name="new_alias" />)} />);

export default Create;
