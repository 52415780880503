import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';
import ApiKeysRouter from 'client/js/api_keys/router';
import DomainVerificationPage from 'client/js/domains/verify_domain';
import NewMfaSession from 'client/js/new_mfa_session';
import PaymentMethodsRouter from 'client/js/payment_methods/router';
import AffiliatePayoutsIndex from 'client/js/usercp/affiliate_payouts/index';
import AffiliatePayoutsNew from 'client/js/usercp/affiliate_payouts/new';
import Archive from 'client/js/usercp/archive/index';
import CreateOtp from 'client/js/usercp/authentication_factors/create_otp';
import CreateWebauthn from 'client/js/usercp/authentication_factors/create_webauthn';
import Security from 'client/js/usercp/authentication_factors/index';
import CloudRouter from 'client/js/usercp/cloud/router';
import CommissionsIndex from 'client/js/usercp/commissions/index';
import ContingentsIndex from 'client/js/usercp/contingents/index';
import AuthorizePaymentIntent from 'client/js/usercp/credit_cards/authorize_payment_intent';
import CronjobsRouter from 'client/js/usercp/cronjobs/router';
import Dashboard from 'client/js/usercp/dashboard';
import DataProcessingContractsRouter from 'client/js/usercp/data_processing_contracts/router';
import DatabaseBackupIndex from 'client/js/usercp/database_backups/index';
import DatabaseBackupRestore from 'client/js/usercp/database_backups/restore';
import DatabaseUsersRouter from 'client/js/usercp/database_users/router';
import DatabasesRouter from 'client/js/usercp/databases/router';
import DeleteAccount from 'client/js/usercp/delete_account';
import DnsRecordsIndex from 'client/js/usercp/dns_records/index';
import DnsRecordsNew from 'client/js/usercp/dns_records/new';
import DnsRecordUpdate from 'client/js/usercp/dns_records/update';
import DomainAuthInfo from 'client/js/usercp/domains/auth_info';
import DnsServerUpdate from 'client/js/usercp/domains/dns_servers';
import DomainsIndex from 'client/js/usercp/domains/index';
import DomainsShow from 'client/js/usercp/domains/show';
import DomainTransferOutPage from 'client/js/usercp/domains/transfer_out';
import EmailRouter from 'client/js/usercp/email/router';
import FtpAccountsRouter from 'client/js/usercp/ftp_accounts/router';
import HandlesRouter from 'client/js/usercp/handles/router';
import InstallationShow from 'client/js/usercp/installations/show';
import InvoicesIndex from 'client/js/usercp/invoices/index';
import LetsEncryptIndex from 'client/js/usercp/lets_encrypt/index';
import MailAliasRouter from 'client/js/usercp/mail_alias/router';
import MailboxImportsRouter from 'client/js/usercp/mailbox_imports/router';
import MailboxesRouter from 'client/js/usercp/mailboxes/router';
import MemcachedRouter from 'client/js/usercp/memcached/router';
import OrdersIndex from 'client/js/usercp/orders/index';
import OrdersShow from 'client/js/usercp/orders/show';
import UpdatePassword from 'client/js/usercp/password/update';
import ErrorLogIndex from 'client/js/usercp/php_error_log/index';
import PhpmailsIndex from 'client/js/usercp/php_mails/index';
import PhpmailLimitUpdate from 'client/js/usercp/php_mails/update_limit';
import PhpVersionUpdate from 'client/js/usercp/php_version/update';
import PhpmyadminIndex from 'client/js/usercp/phpmyadmin/index';
import RestoresIndex from 'client/js/usercp/restores/index';
import RestoresShow from 'client/js/usercp/restores/show';
import Settings from 'client/js/usercp/settings';
import SoftwaresIndex from 'client/js/usercp/softwares/index';
import SoftwaresInstall from 'client/js/usercp/softwares/install';
import SshAccountsRouter from 'client/js/usercp/ssh_accounts/router';
import SshKeysRouter from 'client/js/usercp/ssh_keys/router';
import TicketsRouter from 'client/js/usercp/tickets/router';
import UpdateEmail from 'client/js/usercp/update_email';
import WebhostingRouter from 'client/js/usercp/webhosting/router';
import WebsitesRouter from 'client/js/usercp/websites/router';
import WebspaceBackupIndex from 'client/js/usercp/webspace_backups/index';
import WebspaceBackupShow from 'client/js/usercp/webspace_backups/show';
import WordpressRouter from 'client/js/usercp/wordpress/router';
import Callout from 'client/js/util/callout';
import { Alert, FaIcon } from 'client/js/util/layout_utils';
import { parseXHRError, useResource } from 'client/js/util/rest_utils';
import WebspaceRouter from 'client/js/webspace/router';
import Modal from 'react-modal';
import { BrowserRouter, Link, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { NextUsercpMenu as Navigation } from './usercp/navigation';
import ScrollToTop from './util/scroll_to_top';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

// Extend dayjs with the plugins
dayjs.extend(localizedFormat);
dayjs.extend(calendar);
dayjs.extend(relativeTime);

// Set the locale
dayjs.locale('de');

if(typeof window !== 'undefined')
  window.dayjs = dayjs;

// if #appRoot exists,use it as the modal approot
if (document.getElementById('appRoot')) {
  Modal.setAppElement('#appRoot');
}

const Content = () => {
  const location = useLocation();

  return (
    <ErrorBoundary location={location}>
      <Switch>
        <Route path="/data_processing_contracts" component={DataProcessingContractsRouter} />
        <Route path="/handles" component={HandlesRouter} />
        <Route path="/database_users" component={DatabaseUsersRouter} />
        <Route path="/databases" component={DatabasesRouter} />
        <Route path="/database_backups/:id/restore" component={DatabaseBackupRestore} />
        <Route path="/database_backups" component={DatabaseBackupIndex} />
        <Route path="/webspace_backups/:id" component={WebspaceBackupShow} />
        <Route path="/webspace_backups" component={WebspaceBackupIndex} />
        <Route path="/restores/:id" component={RestoresShow} />
        <Route path="/restores" component={RestoresIndex} />
        <Route path="/api_keys" component={ApiKeysRouter} />
        <Route path="/virtual_machines" component={CloudRouter} />
        <Route path="/webspace" component={WebspaceRouter} />
        <Route path="/payment_methods" component={PaymentMethodsRouter} />
        <Route path="/credit_cards/authorize/:intent_id" component={AuthorizePaymentIntent} />
        <Route path="/domains/:domain_id/records/:id/edit" component={DnsRecordUpdate} />
        <Route path="/domains/:domain_id/records/new" component={DnsRecordsNew} />
        <Route path="/domains/:domain_id/records" component={DnsRecordsIndex} />
        <Route path="/domains/:domain_id/dns_servers" component={DnsServerUpdate} />
        <Route path="/domains/verifizierung" component={DomainVerificationPage} />
        <Route path="/domains/:id/transfer_out" component={DomainTransferOutPage} />
        <Route path="/domains/:id/auth_info" component={DomainAuthInfo} />
        <Route path="/websites" component={WebsitesRouter} />
        <Route path="/mailboxes/:mailbox/imports/new" component={MailboxImportsRouter} />
        <Route path="/mailboxes/:mailbox/imports/:import_id" component={MailboxImportsRouter} />
        <Route path="/aliases" component={MailAliasRouter} />
        <Route path="/phpmyadmin" component={PhpmyadminIndex} />
        <Route path="/email" component={EmailRouter} />
        <Route path="/email_domains" component={EmailRouter} />
        <Route path="/mailbox_aliases" component={EmailRouter} />
        <Route path="/mailboxes" component={MailboxesRouter} />
        <Route path="/ssh_accounts" component={SshAccountsRouter} />
        <Route path="/ssh_keys" component={SshKeysRouter} />
        <Route path="/ftp_accounts" component={FtpAccountsRouter} />
        <Route path="/cronjobs" component={CronjobsRouter} />
        <Route path="/memcached" component={MemcachedRouter} />
        <Route path="/wordpress" component={WordpressRouter} />
        <Route path="/tickets" component={TicketsRouter} />
        <Route path="/phpmails/limit" component={PhpmailLimitUpdate} />
        <Route path="/phpmails" component={PhpmailsIndex} />
        <Route path="/php_version" component={PhpVersionUpdate} />
        <Route path="/php_error_log" component={ErrorLogIndex} />
        <Route path="/webhosting" component={WebhostingRouter} />
        <Route path="/lets-encrypt" component={LetsEncryptIndex} />
        <Route path="/domains/:id" component={DomainsShow} />
        <Route path="/domains" component={DomainsIndex} />
        <Route path="/password" component={UpdatePassword} />
        <Route path="/email-adresse" component={UpdateEmail} />
        <Route path="/invoices" component={InvoicesIndex} />
        <Route path="/contingents" component={ContingentsIndex} />
        <Route path="/installations/:id" component={InstallationShow} />
        <Route path="/softwares/:id/install" component={SoftwaresInstall} />
        <Route path="/softwares" component={SoftwaresIndex} />
        <Route path="/orders/:id" component={OrdersShow} />
        <Route path="/orders" component={OrdersIndex} />
        <Route path="/affiliate_payouts/new" component={AffiliatePayoutsNew} />
        <Route path="/affiliate_payouts" component={AffiliatePayoutsIndex} />
        <Route path="/commissions" component={CommissionsIndex} />
        <Route path="/authentication_factors/otp/new" component={CreateOtp} />
        <Route path="/authentication_factors/webauthn/new" component={CreateWebauthn} />
        <Route path="/security" component={Security} />
        <Route path="/delete-account" component={DeleteAccount} />
        <Route path="/settings" component={Settings} />
        <Route path="/archive" component={Archive} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </ErrorBoundary>
  )
}

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  componentDidUpdate(prevProps) {
    // Reset error state when location changes
    if (prevProps.location !== this.props.location) {
      this.setState({ hasError: false, error: null, info: null });
    }
  }

  componentDidCatch(error, info) {
    Rollbar.error(info, error);
    this.setState({hasError: true, error: error, info: info});
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <h1>Ups, da ist etwas schiefgelaufen!</h1>

          <Callout title={this.state.error.toString()} calloutClass="danger">
            <p>Sorry, hier ist gerade der folgende Fehler aufgetreten. Bitte versuche es später noch einmal oder wende Dich an den Support.</p>

            <pre><code>{this.state.error.toString()}{this.state.info.componentStack}</code></pre>
          </Callout>
        </React.Fragment>
      )
    }

    return this.props.children;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // do not retry if the error is a client error
        if (error.status >= 400 && error.status < 500) {
          return false;
        }

        return failureCount < 3;
      },
      refetchOnWindowFocus: false,
    },
  }
});

const StatusAlert = ({ account_status }) => {
  const { mutate, isLoading } = useMutation(() => {
    return $.ajax({
      url: '/usercp/reseller_sign_out',
      dataType: 'json',
      type: 'POST',
    });
  }, {
    onSuccess: () => {
      window.location = '/resellercp';
    }
  });

  if(account_status.status == 'unconfirmed') {
    return (
      <Alert alert_class="danger attention-banner">
        Deine E-Mail-Adresse ist noch nicht bestätigt. Wir haben Dir eine E-Mail gesendet. <Link to="/email-adresse">Es ist keine angekommen</Link>?
      </Alert>
    );
  }

  if(account_status.status == 'email_unreachable') {
    return (
      <Alert alert_class="danger attention-banner">
        Leider konnten wir Dich unter Deiner hinterlegten E-Mail-Adresse nicht erreichen. <Link to="/email-adresse">Bitte ändere Deine Adresse in der Verwaltung</Link>!
      </Alert>
    );
  }

  if(account_status.balance < 0 && account_status.dunning_level > 0) {
    if(account_status.http_blocked) {
      return (
        <Alert alert_class="danger attention-banner">
          Auf Deinem Account sind <Link to="/invoices">Rechnungen</Link> in Höhe von {format_fractional_price(Math.abs(account_status.balance))} offen (Mahnstufe {account_status.dunning_level}), der Account ist aktuell gesperrt.
        </Alert>
      );
    }

    return (
      <Alert alert_class="danger attention-banner">
        Auf Deinem Account sind <Link to="/invoices">Rechnungen</Link> in Höhe von {format_fractional_price(Math.abs(account_status.balance))} offen (Mahnstufe {account_status.dunning_level}).
      </Alert>
    );
  }

  if(account_status.status == 'archived') {
    return (
      <Alert alert_class="danger attention-banner">
        Die Webspace-Inhalte Deines Accounts sind derzeit archiviert. Du kannst sie <Link to="/archive">jederzeit wiederherstellen</Link>.
      </Alert>
    );
  }

  if(account_status.impersonating) {
    return (
      <Alert alert_class="info attention-banner">
        Du bist derzeit per Reseller-Account als Kunde <strong>{account_status.impersonating_user}</strong> eingeloggt. <button type="button" className="btn btn-link" onClick={mutate}><FaIcon name="sign-out" loading={isLoading} /> Logout</button>
      </Alert>
    );
  }

  // if(account_status.status == 'verification_required') {
  //   return (
  //     <Alert alert_class="danger attention-banner">
  //       Verifizierung nötig
  //     </Alert>
  //   );
  // }

  return null;
}


const ContentStatusWrapper = ({ children }) => {
  const { data, component, isError, error, refetch } = useResource(['account_status'], '/usercp/account_status', {
    keepPreviousData: true,
  });

  if(isError) {
    const { extendedStatus } = parseXHRError(error);

    // check if the error is a 401, then we need to show the MFA form
    if (error.status == 401 && extendedStatus == '2fa_required') {
      return (
        <div className="text-center p-5">
          <NewMfaSession onSuccess={refetch} />
        </div>
      );
    }
  }

  if(component) {
    return (
      <div className="text-center p-5">
        {component}
      </div>
    );
  }

  return (
    <>
      <StatusAlert account_status={data.account_status} />
      <div className="row flex-md-nowrap">
        {children}
      </div>
    </>
  )
}

const UsercpApp = () => (
  <div id="usercp-content">
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/usercp">
        <ContentStatusWrapper>
          <Navigation />

          <main className="col-12 col-md-9 col-xl-9 py-md-3 pl-md-4 bd-content">
            <ScrollToTop>
              <Content />
            </ScrollToTop>

            <div id="action-menu-portal"></div>
          </main>
        </ContentStatusWrapper>

      </BrowserRouter>
    </QueryClientProvider>
    <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
  </div>
);

export { ErrorBoundary };
export default UsercpApp;
