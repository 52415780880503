import GenericCreate from 'client/js/resellercp/generic_resource/create';
import {BooleanInput} from 'client/js/util/form_utils';
import HandleInput from 'client/js/util/inputs/handle_input';
import ExternalLink from 'client/js/util/external_link';
import StringArrayInput from 'client/js/util/inputs/string_array_input';

const default_types = {"Personenstammdaten": "Personenstammdaten", "Kommunikationsdaten": "Kommunikationsdaten", "Vertragsstammdaten": "Vertragsstammdaten", "Protokolldaten": "Protokolldaten"};
const default_subjects = {"Kunden und Interessenten": "Kunden und Interessenten", "Mitarbeiter": "Mitarbeiter", "Lieferanten": "Lieferanten"};

const Form = () => (<div>
    <HandleInput name="handle_id" input_label="Vertragspartner" resource_name="Vertragspartner"
                     manage_link
                     url="/usercp/handles.json" />

        <StringArrayInput required name="data_types" label="Daten-Art" options={default_types} />
        <StringArrayInput required name="data_subjects" label="Betroffene" options={default_subjects} />

        <BooleanInput required name='terms_accepted' label="Ich stimme den folgenden Vertragsbedingungen zu:" />
        <div className="row form-group">
          <div className="col-md-9 offset-md-3">
            <ul>
              <li><ExternalLink href="/usercp/data_processing_contracts/model_contract.pdf">Vertrag zur Auftragsdatenverarbeitung (Muster)</ExternalLink></li>
              <li><ExternalLink href="/usercp/data_processing_contracts/contractors.pdf">Liste der Subunternehmer</ExternalLink></li>
            </ul>
          </div>
        </div>
  </div>);

const CreateCustomerPage = () => (<GenericCreate namespace="usercp" resource_name="data_processing_contracts"
                                                 form_component={() => (<Form />)}
                                                 redirectAfter="collection" />);

export default CreateCustomerPage;
