import { RadioCollectionInput, StringInput } from 'client/js/util/form_utils';
import PropTypes from 'prop-types';

const LimitVisibility = ({ children, visibleValue, visibleField }, {formData}) => {
  if (formData && formData[visibleField] == visibleValue)
    return children;

  return null;
}

LimitVisibility.propTypes = {
  visibleField: PropTypes.string.isRequired,
  visibleValue: PropTypes.string.isRequired
};

LimitVisibility.contextTypes = {
  formData: PropTypes.object
};

const Form = () => {
  return (
    <>
      <RadioCollectionInput label="Typ" name="cronjob_type" options={{ 'url': 'URL-Cronjob', 'shell': 'Shell-Cronjob' }}  />
      <StringInput required label="Ausführungszeitpunkte" name="exec_times" placeholder="z.B. * * * * *" className="cron_exec_times" />

      <LimitVisibility visibleField="cronjob_type" visibleValue="url">
        <StringInput required type="url" label="URL" name="command_or_url" placeholder="z.B. https://example.com/wp-cron.php" className="input-xxlarge" hint="Es kann eine beliebige URL verwendet werden, die Aufrufe sind nicht auf Webseiten bei lima-city beschränkt." />
      </LimitVisibility>

      <LimitVisibility visibleField="cronjob_type" visibleValue="shell">
        <StringInput required label="Kommando" name="command_or_url" placeholder="z.B. cd wp-123 && php wp-cron.php" className="input-xxlarge" hint="Das Shell-Kommando, so wie es in das crontab-File eingetragen werden soll" />
      </LimitVisibility>

      <StringInput label="Bemerkung" name="description" className="input-xxlarge" hint="Notiz zum Cronjob" />
    </>
  )
}

export default Form;
