import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { Dropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon } from 'client/js/util/layout_utils';
import { Link } from 'react-router-dom';
import { state_to_badge } from './lib';

const name_fn = (id, db) => {
  if(db.comment && db.comment.length)
    return (
      <>
        <Link key={0} to={`/databases/${db.id}`}>{db.name}</Link>
        <br />
        <small key={1} className="text-muted">{db.comment}</small>
      </>
    )

  return <Link to={`/databases/${db.id}`}>{db.name}</Link>
}

const state_fn = (id, db) => {
  return state_to_badge(db.state);
}

const opts_fn = (id, database) => {
  return <Dropdown>
    <Link to={`/databases/${database.id}/edit`} className="dropdown-item"><FaIcon name="pencil" /> Kommentar bearbeiten</Link>

    <ConfirmedDelete id={database.id} disabled={database.state != 'operational'} resource="databases" button_text={<><FaIcon key={0} name="trash" /> löschen</>}
      button_class="dropdown-item text-danger" text="Dies wird die Datenbank und alle Inhalte dieser Datenbank unwiderruflich löschen." />
  </Dropdown>
}

const fields = [{name: "name", type: "component", fn: name_fn},
                {name: "state", type: "component", fn: state_fn},
                {name: "created_at", type: "datetime"},
                {name: "options", type: "component", fn: opts_fn}]

const Index = () => (<GenericIndex namespace="usercp"
                                   resource_name="databases"
                                   filter_transform={(item) => ([item.name, item.comment].join(' '))}
                                   usage_url="/usercp/databases/usage.json"
                                   fields={fields} />);

export default Index;
