import { FaIcon } from 'client/js/util/layout_utils';
import { useState } from 'react';

function fallbackCopyTextToClipboard(text) {
  if (!document.queryCommandSupported('copy')) {
    return;
  }

  const input = document.createElement('input');
  input.value = text;
  // Avoid scrolling to bottom
  input.style.position = 'fixed';
  input.style.top = 0;
  input.style.left = 0;
  input.style.width = '2em';
  input.style.height = '2em';
  input.style.padding = 0;
  input.style.border = 'none';
  input.style.outline = 'none';
  input.style.boxShadow = 'none';
  input.style.background = 'transparent';
  document.body.appendChild(input);
  input.focus();
  input.select();
  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(input);
}

const CopyableField = ({ value, className, children }) => {
  const [hovered, setHovered] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(value);
      setCopied(true);
      return;
    }

    navigator.clipboard.writeText(value).
      then(() => setCopied(true)).
      catch(() => {
        fallbackCopyTextToClipboard(value)
        setCopied(true);
      });
  }

  return (
    <span className="copyable-field" onMouseEnter={() => { setCopied(false); setHovered(true) }} onMouseLeave={() => setHovered(false)}>
      <span onClick={handleClick} className={className}>{value || children}</span>

      {hovered && <span className={copied ? "badge badge-success" : "badge badge-light"}>{copied ? <><FaIcon name="check" /> kopiert!</> : 'kopieren'}</span>}
    </span>
  );
}

CopyableField.defaultProps = {
  className: 'value'
}

export default CopyableField;
