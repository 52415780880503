import { PageHeader } from 'client/js/util/layout_utils';
import { Redirect } from 'react-router-dom';
import { StringInput, GenericReactForm, SubmitButton, FormActions } from 'client/js/util/form_utils';

class Page extends React.Component {
  state = { updated: false };

  dispatch = (action) => {
    if (action == 'form:success') {
      this.setState({ updated: true })
    }
  }

  render() {
    if (this.state.updated)
      return <Redirect to="/payment_methods" />;

    return <>
      <PageHeader text="Ablaufdatum aktualisieren" back_url="/payment_methods" />

      <GenericReactForm namespace="payment_method"
        verb="put"
        dispatch={this.dispatch}
        url={`/usercp/payment_methods/${this.props.match.params.id}.json`}>

        <StringInput name="expiration_date" placeholder="MM/JJ, z.B. 07/29" label="Ablaufdatum (MM/JJ)" />

        <FormActions>
          <SubmitButton icon="pencil" text="Ablaufdatum aktualisieren" />
        </FormActions>
      </GenericReactForm>
    </>;
  }
}

export default Page;
