import { FaIcon } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';

const Progress = (props) => {
  const mailbox_id = props.match.params.mailbox_id;
  const import_id = props.match.params.import_id;

  const { data, component } = useResource(['mailbox', mailbox_id, 'import', import_id], `/usercp/mailboxes/${mailbox_id}/imports/${import_id}`, {
    refetchInterval: (data) => data?.import?.state != 'completed' && data?.import?.state != 'failed' ? 2000 : false
  });

  if(component)
    return component;

  const mailboxImport = data.import;

  let progress = 0;
  if(mailboxImport.messages_imported && mailboxImport.messages_total)
    progress = mailboxImport.messages_imported / mailboxImport.messages_total * 100;

  var progressClasses = 'mb-3 progress';
  if(progress < 100)
    progressClasses += " progress-striped active"

  return (<div className="creating-cloud-vps">
            <div className="text-center mb-3">
              <h2 className="install-status">{i18n_t(`mailbox_imports.progress.state.${mailboxImport.state}`)}</h2>
            </div>

            <div className={progressClasses}><div className="progress-bar" style={{ width: `${progress}%`}}></div></div>

            <div className="text-center">
              {progress > 0 &&
                <p>{number_with_delimiter(mailboxImport.messages_imported)} von {number_with_delimiter(mailboxImport.messages_total)} E-Mails importiert ({Math.round(progress, 0)} %)</p>}

                <p><a href={`/usercp/mailboxes/${mailbox_id}`}>&rarr; zum Postfach</a></p>

                <p className="text-muted"><FaIcon name="lightbulb-o" /> Der Import läuft auch beim Verlassen dieser Seite weiter. Der Status kann über die Postfach-Liste oder mit einem Klick auf das Postfach im Tab &quot;Imports&quot; geprüft werden.</p>
            </div>
          </div>);
}

export default Progress;
