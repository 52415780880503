import {PageHeader, FaIcon} from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';
import {FlexMenuItem, SwitchInput} from '../menu';
import {Status, AutoRenewStatus} from './status';
import {Link} from 'react-router-dom';
import {errorToComponent} from 'client/js/util/rest_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import {useQuery} from '@tanstack/react-query';

const get_safe = (key, default_value) => {
  try {
    const v = localStorage.getItem("domains:" + key);

    if(v === null)
      return default_value;

    return JSON.parse(v);
  } catch(e) {
    return null;
  }
}

const set_safe = (key, value) => {
  try {
    return localStorage.setItem("domains:" + key, JSON.stringify(value));
  } catch(e) {
    return null;
  }
}

const SettingsMenu = (props) => {
  const clickHandler = (e) => {
    props.changeSetting('show_inactive', e.target.checked);
  }

  return <div className="list-group mb-3">
      <FlexMenuItem title="Inaktive Domains anzeigen" subtitle="Zeigt gelöschte, umgezogene und stornierte Domains an">
        <SwitchInput defaultChecked={props.show_inactive} onChange={clickHandler} value="1" />
      </FlexMenuItem>
    </div>;
}

const fetchData = async () => {
  const response = await $.ajax({url: '/usercp/domains', dataType: 'json'});

  return response;
}

const Index = () => {
  const [showInactive, setShowInactive] = React.useState(get_safe('show_inactive', true));
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [filterQuery, setFilterQuery] = React.useState('');

  const { data, isLoading, isError, error, refetch, isPreviousData } = useQuery(['domains'], fetchData,
    {keepPreviousData: true}
  );

  const changeSetting = (setting, value) => {
    setShowInactive(value);
    set_safe('show_inactive', value);
  }

  const toggleSettings = (e) => {
    e.preventDefault();
    setSettingsOpen(!settingsOpen);
  }

  const changeFilter = (e) => {
    setFilterQuery(e.target.value);
  }

  const buttons = (
    <>
      <input className="search-query form-control" type="search" placeholder='Schnellsuche' onChange={changeFilter} value={filterQuery} />
      <a href="/domain-check" className="btn btn-light"><FaIcon name="globe" /> Domain registrieren oder umziehen</a>
      <a href='#' onClick={toggleSettings} className="btn btn-light"><FaIcon name="filter" /></a>
    </>
  );

  if (isLoading) {
    return (
      <>
        <PageHeader text="Domains" buttons={buttons} />

        {settingsOpen && <SettingsMenu changeSetting={changeSetting} show_inactive={showInactive} />}

        <LoadingTableSpinner />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PageHeader text="Domains" buttons={buttons} />

        {settingsOpen && <SettingsMenu changeSetting={changeSetting} show_inactive={showInactive} />}

        {errorToComponent(error)}
      </>
    );
  }

  const list = data.domains.filter((item) => {
    if(!showInactive && (item.status == 'deleted' || item.status == 'transferred_out' || item.status == 'cancelled' || item.status == 'deleted'))
      return false;

    if (filterQuery != '') {
      return item.unicode_fqdn.toLowerCase().indexOf(filterQuery.toLowerCase()) !== -1;
    }

    return true;
  });

  if(!list.length) {
    return (
      <>
        <PageHeader text="Domains" buttons={buttons} />

        {settingsOpen && <SettingsMenu changeSetting={changeSetting} show_inactive={showInactive} />}

        <Callout calloutClass="success" title="Nichts gefunden">
          <p>Es sind keine Domains vorhanden, die angezeigt werden können.</p>
        </Callout>
      </>
    );
  }

  return (
    <>
      <PageHeader text="Domains" buttons={buttons} />

      {settingsOpen && <SettingsMenu changeSetting={changeSetting} show_inactive={showInactive} />}

      <table className={isPreviousData ? "table table-striped table-wide skeleton-loading" : "table table-striped table-wide"}>
        <thead>
          <tr><th>Domain</th><th>Status</th><th>autom. Verlängerung</th><th>Ablaufdatum</th></tr>
        </thead>
        <tbody>
          {list.map((item) => {
            return (
              <tr key={item.id}>
                <td><Link to={`/domains/${item.id}`}>{item.unicode_fqdn}</Link></td>
                <td><Status item={item} refreshCb={refetch} /></td>
                <td><AutoRenewStatus auto_renew={item.auto_renew} in_subscription={item.in_subscription} /></td>
                <td>{item.registered_until ? dayjs(item.registered_until).format('l') : '-'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default Index;
