import GenericCreate from 'client/js/resellercp/generic_resource/create';
import ExternalLink from 'client/js/util/external_link';
import { StringInput, TextInput } from 'client/js/util/form_utils';

const Form = () => {
  return (
    <>
      <StringInput name="name" label="Name (optional)" />

      <TextInput
      name="text_key" label="SSH-Key in Textform" rows={8} extraClass="input-xxlarge"
        required={true}
        placeholder="Beginnt mit 'ssh-rsa', 'ssh-dss', 'ssh-ed25519', 'ecdsa-sha2-nistp256', 'ecdsa-sha2-nistp384' oder 'ecdsa-sha2-nistp521'"
        hint={<span>Rufe unsere <ExternalLink href="/hilfe/ssh-keys-erzeugen">Hilfe für die Generierung von SSH-Keys</ExternalLink> auf.</span>}
      />
    </>
  )
}

const Page = () => (<GenericCreate namespace="usercp" resource_name="ssh_keys" redirectAfter="index" form_component={() => (<Form />)} />);

export default Page;
