import PropTypes from 'prop-types';
import {PageHeader, Progress} from 'client/js/util/layout_utils';
import {withLoading} from 'client/js/util/rest_utils';
import {GenericReactForm, SelectCollectionInput, RadioCollectionInput, SubmitButton, FormActions} from 'client/js/util/form_utils';
import {PaymentMethodSelect, PaymentMethodConfig} from './payment_lib';
import AddFundsButton from '../lib/add_funds_button';
import ExternalLink from 'client/js/util/external_link';
import {Link, Redirect} from 'react-router-dom';

const InfoRow = (props) => {
  return <tr><td>{props.title}:</td><td>{props.children}</td></tr>;
}

const StepOne = (props) => {

  return <div>
      <PageHeader text="Webhosting-Paket wechseln" />

      <p className="text-danger">UNFERTIG</p>

      <p>Du kannst entweder ein Webhosting-Paket kostenlos testen (1x) oder direkt buchen:</p>

      <a onClick={props.handleTestClick} href="#" className="btn btn-light btn-block mb-2">Testen</a>
      <a onClick={props.handleSubscribeClick} href="#" className="btn btn-light btn-block">Buchen</a>
  </div>;
}

StepOne.propTypes = {
  handleTestClick: PropTypes.func,
  handleSubscribeClick: PropTypes.func,
}

class SelectPaymentMethod extends React.Component {
  state = {}

  handleClick = (e, payment_method_type) => {
    e.preventDefault();
    this.setState({payment_method_type: payment_method_type});
  }

  handleReturn = (e) => {
    e.preventDefault();
    this.setState({payment_method_type: null});
  }

  render() {
    return <div>
      <PageHeader text="Webhosting-Paket buchen" />

      <Progress steps={['Preis und Abrechnungszeitraum', 'Zahlungsmethode']} current={1} />

      {this.state.payment_method_type ?
        <PaymentMethodConfig next={this.props.next} payment_method={this.state.payment_method_type} handle_success={this.props.next} handle_return={this.handleReturn} /> :
        <PaymentMethodSelect next={this.props.next} handleClick={this.handleClick} />}
    </div>;
  }
}

const AddFundsBanner = (props) => {
  if(props.type == 'direct_debit' && props.payment_method && props.payment_method.verification_required)
    return <p className="mb-3">Du hast eine neue Bankverbindung hinzugefügt. Diese muss vor der ersten Abrechnung am {dayjs(props.first_billing_date).format('L')} bestätigt werden.<br/>Bitte gib dazu den Code, den wir Dir per Überweisung mitgeteilt haben, bei uns wieder ein.</p>;

  if(props.type != 'prepaid')
    return null;

  return <form className="mb-3">
        <p>Du hast die Prepaid-Zahlung per Guthaben ausgewählt.<br/>Bitte lade <strong>vor dem {dayjs(props.first_billing_date).format('L')}</strong> einen Betrag von {format_fractional_price(props.next_amount)} auf.</p>

        <AddFundsButton amount={props.next_amount} />
      </form>;
}

const SuccessBanner = (props) => {
  const subscription = props.subscription;

  return <div>
      <PageHeader text="Webhosting-Paket gebucht!" />

      <Progress steps={['Preis und Abrechnungszeitraum', 'Zahlungsmethode']} current={2} />

      <div className="text-center">
        <h4 className="text-success">
          <video className="success-video mb-3" id="video-manager" muted preload autoPlay>
            <source src="/assets/ani/success.mp4" type="video/mp4" />
          </video>

          <br/>
          Vielen Dank für Deine Buchung!
        </h4>

        <p className="mb-3"><strong>Willkommen bei lima-city!</strong><br/>Solltest Du Fragen haben oder Hilfe brauchen, ist unser freundlicher <a href="/usercp/tickets/new">Support</a> gerne für Dich da!</p>

        <AddFundsBanner type={props.payment_method_type}
                        payment_method={props.payment_method}
                        next_amount={subscription.next_amount}
                        first_billing_date={subscription.first_billing_date} />

        <div>
          <Link to="/webhosting" className="btn">zurück zum Webhosting-Paket</Link>
        </div>
      </div>
    </div>
}

SuccessBanner.propTypes = {
  subscription: PropTypes.object.isRequired,
  payment_method_type: PropTypes.string.isRequired,
  payment_method: PropTypes.object
};


const TestSubscription = (props) => {
  const dispatch = (action) => {
    if(action == 'form:success')
      props.handleTestCreated();
  }

  const subscriptions = props.test_subscriptions.map((item) => ([item.offer_code, <div key={item.offer_code}><h5>{item.name} ({i18n_c("month", item.contract_period)} Laufzeit) <small className="text-muted">{item.offer_code}</small></h5><p>{item.disk_space_gb} GB SSD, WordPress-Tools, SSH, tägl. Backup (90 Tage Aufbewahrung)</p></div>]))

  return <div>
      <PageHeader text="Webhosting-Test starten" />

      <GenericReactForm verb="post" namespace="test_subscription"
                        url="/usercp/webhosting/create_test" dispatch={dispatch}>

        <RadioCollectionInput required name="offer_code" label="Webhosting-Paket" options={subscriptions} />

        <FormActions>
          <SubmitButton icon="rocket" text="kostenlosen Test starten" />
        </FormActions>
      </GenericReactForm>
    </div>;
}

class ShowContainer extends React.Component {
  state = {}

  stepOneDispatch = (action) => {
    if(action == 'form:success') {
      this.setState({step: 1});
    }
  }

  handleNext = (payment_method_type, payment_method) => {
    this.setState({step: 2, payment_method_type: payment_method_type, payment_method: payment_method})
  }

  handleSubscribeClick = (e) => {
    e.preventDefault();
    this.setState({type: 'subscribe'})
  }

  handleTestCreated = () => {
    this.setState({redirect_back: true});
  }

  handleTestClick = (e) => {
    e.preventDefault();
    this.setState({type: 'test'})
  }

  render() {
    if(this.state.redirect_back)
      return <Redirect to="/webhosting" />;

    if(this.props.data.can_test) {
      if(!this.state.type)
        return <StepOne data={this.props.data}
                        handleTestClick={this.handleTestClick}
                        handleSubscribeClick={this.handleSubscribeClick} />

      if(this.state.type == 'test')
        return <TestSubscription test_subscriptions={this.props.data.test_subscriptions} />;

    } else {
      // no test allowed
    }

    if(this.state.step == 1)
      return <SelectPaymentMethod next={this.handleNext} />

    if(this.state.step == 2) {
      return <SuccessBanner subscription={this.props.data}
                            payment_method_type={this.state.payment_method_type}
                            payment_method={this.state.payment_method} />
    }
  }
}

const ShowLoading = withLoading(ShowContainer);
const Subscribe = () => (<ShowLoading url="/usercp/webhosting/upgrade.json" />);

export default Subscribe;
