import PropTypes from "prop-types";
import {ControlledInput, ControlledSelect, ControlledErrors} from "client/js/util/form_tags";
import {ControlGroup} from "client/js/util/form_utils";

class DomainPrefixInput extends React.Component {
  componentDidMount() {
    if((!this.context.formData || !this.context.formData.hasOwnProperty(this.props.domain_name))
        && this.props.domains.length) {
      this.context.formDispatch('value_changed', null, {name: this.props.domain_name, value: this.props.domains[0][0]})
    }
  }

  render () {
    const n = [this.context.namespace, "domain_prefix"].filter((n) => (n != undefined)).join('_');

    return (
      <ControlGroup name={n} labelFor={n} required label={this.props.label} hint={this.props.hint}>
        <div className="input-group">
          <ControlledInput value={this.props.value} required name={this.props.prefix_name} type="text" />
          <div className="input-group-prepend input-group-append"><span className="input-group-text">{this.props.separator}</span></div>
          <ControlledSelect required name={this.props.domain_name}>
            {this.props.domains.map((item) => (<option key={item[0]} value={item[0]}>{item[1]}</option>))}
          </ControlledSelect>
        </div>
        <ControlledErrors names={[this.props.prefix_name, this.props.domain_name]} />
      </ControlGroup>);
  }
}

DomainPrefixInput.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  prefix_name: PropTypes.string.isRequired,
  domain_name: PropTypes.string.isRequired,
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
};

DomainPrefixInput.defaultProps = {
  prefix_name: 'prefix',
  domain_name: 'domain',
  separator: '.',
  label: 'Subdomain'
};

DomainPrefixInput.contextTypes = {
  namespace: PropTypes.string,
  formDispatch: PropTypes.func.isRequired,
  formData: PropTypes.object,
};

export default DomainPrefixInput;
