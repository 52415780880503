import React, { useState } from 'react';
import CopyableField from './copyable_field';
import { FaIcon } from './layout_utils';

const RevealableField = ({ value }) => {
  const [revealed, setRevealed] = useState(false);

  if (revealed) {
    return <CopyableField value={value} />;
  }

  return (
    <button className="btn btn-light" onClick={() => setRevealed(true)}>
      <FaIcon name="eye" /> anzeigen
    </button>
  );
}

export default RevealableField;
