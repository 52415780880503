import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import {StringInput, TextInput} from 'client/js/util/form_utils';
import {PageHeader} from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';

const SuccessCallout = () => (<>
    <PageHeader text="AuthInfo anfordern" />

    <Callout calloutClass="success" title="AuthInfo verschickt">
      <p>Die AuthInfo für die Domain wurde an die im Account hinterlegte E-Mail-Adresse verschickt.</p>
    </Callout>
  </>)

const Form = () => (<>
    <Callout calloutClass="warning" title="Ausgehenden Domain-Transfer einleiten">
      <p>Wenn Du eine Domain von lima-city zu einem anderen Provider umziehen möchtest, benötigst Du dafür eine AuthInfo (auch AuthCode genannt). Die AuthInfo senden wir an die im Account hinterlegte E-Mail-Adresse. Um den Transfer einzuleiten gib bitte unten Dein Account-Passwort zur Verifizierung ein.</p>

      <p>Bitte beachte, dass mit dem Umzug alle Restlaufzeiten für diese Domain verfallen, Vorauszahlungen werden nicht erstattet. Sobald der Transfer bei der Registry durchgeführt wird, werden auch die mit der Domain verbundenen Services bei lima-city (DNS, Webhosting, E-Mail) <u>sofort</u> deaktiviert.</p>
    </Callout>

    <StringInput required type="password" name="password" label="Passwort" autoComplete="current-password" hint="wird zur Bestätigung benötigt" />

    <TextInput name="feedback" label="Feedback" hint="Unzufrieden? Woanders bessere Konditionen erhalten? Sag uns, was Dich stört, wir möchten unser Angebot verbessern!" />
  </>)


const Page = (props) => (<GenericInteraction namespace="usercp"
                                             resource_name="domains"
                                             form_namespace="request"
                                             action="transfer_out"
                                             method="post"
                                             success_splash={() => (<SuccessCallout />)}
                                             id={props.match.params.id}
                                             form_component={() => (<Form />)} />);

export default Page;
