import GenericCreate from 'client/js/resellercp/generic_resource/create';
import { PasswordInput, StringInput } from 'client/js/util/form_utils';
import WebspacePathInput from 'client/js/util/inputs/webspace_path_input';

const Form = () => (<>
    <PasswordInput name="password" required label="Passwort" autoComplete="off" />

    <WebspacePathInput name="directory" label="Webspace-Verzeichnis" required />

    <StringInput name="comment" label="Kommentar (optional)" />
  </>);


const Page = () => (<GenericCreate namespace="usercp" resource_name="ftp_accounts" redirectAfter="index" form_component={() => (<Form />)} />);

export default Page;
