import ExternalLink from 'client/js/util/external_link';
import {Redirect} from 'react-router-dom';
import {GenericReactForm, StringInput, SubmitButton, FormActions, RadioCollectionInput, BooleanInput} from 'client/js/util/form_utils';
import {PageHeader} from 'client/js/util/layout_utils';
import PropTypes from 'prop-types';

const ShowOnSslError = ({children}, {formData, serverErrors}) => {
  if((!formData || !formData.ssl_ignore_errors) && (!serverErrors || !serverErrors.hostname || !serverErrors.hostname.length))
    return null;

  return children;
}

ShowOnSslError.contextTypes = {
  formData: PropTypes.object,
  serverErrors: PropTypes.object
};

class Form extends React.Component {
  state = {}

  dispatch = (action, original_event, data) => {
    if(action == 'form:success')
      this.setState({import_id: data.object.id});
  }

  render() {
    if(this.state.import_id)
      return <Redirect to={`/mailboxes/${this.props.mailbox_id}/imports/${this.state.import_id}`} />;

    return <>
      <PageHeader text="E-Mail importieren" back_url={`/mailboxes/${this.props.mailbox_id}`} />

      <p>Mit diesem Werkzeug kannst Du die E-Mails und E-Mail-Ordner von einem fremden Server in das Postfach importieren. Es werden dazu alle E-Mails von dem unten angegebenen Server heruntergeladen und in das Postfach übertragen. Es werden keine Nachrichten gelöscht, weder auf dem Quell-, noch auf dem Ziel-Postfach. Obwohl wir das Passwort für das Quell-Postfach nach dem Import löschen empfehlen wir, ein neues Passwort für den Import anzulegen. <ExternalLink href="/hilfe/email-import-hinweise">Tipps zum E-Mail-Import</ExternalLink></p>

      <p><strong>Bitte beachte dass Zugriff auf das Postfach auf unserem Server während des laufenden E-Mail-Imports nicht möglich ist! Clients (Webmail, iPhone, Outlook etc.) werden während des laufenden Imports fehlerhafte Zugangsdaten melden.</strong></p>

      <GenericReactForm namespace="import" defaults={{port: 993, encryption: 'ssl'}} url={`/usercp/mailboxes/${this.props.mailbox_id}/imports`} dispatch={this.dispatch}>
        <StringInput name="hostname" label="Server-Name" required />
        <RadioCollectionInput required name="encryption" label="Verschlüsselung" options={{ "tls": "TLS/STARTTLS", "ssl": "SSL" }} />
        <ShowOnSslError>
          <BooleanInput name="ssl_ignore_errors" label="Ungültiges SSL-Zertifikat akzeptieren" hint="Wenn der Server im SSL-Zertifikat einem anderen Namen ausweist, ein selbstsigniertes Zertifikat verwendet wird oder das Zertifikat abgelaufen ist, kannst Du diese Option aktivieren." />
        </ShowOnSslError>
        <StringInput name="port" type="number" label="Port" required hint="Für TLS/STARTTLS typischerweise 143, für SSL 993" />
        <StringInput name="username" label="Benutzername" required />
        <StringInput name="password" type="password" label="Passwort" required />

        <FormActions>
          <SubmitButton icon="plus" text="E-Mail importieren" />
        </FormActions>
      </GenericReactForm>
      </>;
  }
}

const Page = (props) => (<Form mailbox_id={props.match.params.mailbox_id} />);

export default Page;
