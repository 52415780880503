import {ErrorCallout} from 'client/js/util/form_utils';
import VmModel from 'client/js/usercp/cloud/virtual_machines/model';
import ExternalLink from 'client/js/util/external_link';

var VmModelStep = function(props) {
  return (<fieldset>
  <legend>1. Modell des Cloud-VPS</legend>
    <ErrorCallout errors={props.serverErrors.virtual_machine_model_id} />

    {props.models.map((model) => {
      return <VmModel key={model.id} id={model.id}
                namespace='usercp_create_vm' name="virtual_machine_model_id"
                title={model.title}
                vcores={model.vcores}
                memory_gb={model.memory_mb / 1024}
                ssd_gb={model.ssd_storage_gb}
                traffic_gb={model.traffic_per_month_gb}
                price_per_hour={model.price_per_hour_in_cents}
                price_per_month={model.price_per_month_in_cents}
                dispatch={props.dispatch}
                selectedModel={props.selectedModel} />
    })}

    <p><small>*Es wird nur ausgehender Traffic berechnet, eingehender Traffic ist kostenfrei! Übertraffic wird mit 0,01 €/Gibibyte berechnet. Mehr <ExternalLink href="https://www.lima-city.de/hilfe/cloud-abrechnung" text="Informationen zur Abrechnung in der Hilfe" />.</small></p>
  </fieldset>);
}

export default VmModelStep;
