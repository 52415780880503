import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { Link } from 'react-router-dom';
import {AnimatedTabs, AnimatedNavItem as NavItem} from 'client/js/util/nav_tabs';

const Header = () => {
  return (
    <tr>
      <th>Backup vom</th>
      <th>Status</th>
      <th>Größe</th>
      <th>Optionen</th>
    </tr>
  )
}

const TableRow = (props) => {
  const backup = props.item;

  return (
    <tr key={backup.id}>
      <td>{dayjs(backup.finished_at).format('L LT')}</td>
      <td>{i18n_t(`resources.webspace_backups.state.${backup.state}`)}</td>
      <td>{format_disk_space(backup.size)}</td>
      <td><Link to={`/webspace_backups/${backup.id}`} className="btn btn-light">Inhalt einsehen</Link></td>
    </tr>
  );
}

const tabs = (
    <AnimatedTabs>
      <NavItem to="/webspace_backups">Webspace-Backups</NavItem>
      <NavItem to="/database_backups">Datenbank-Backups</NavItem>
      <NavItem to="/restores">Restores</NavItem>
    </AnimatedTabs>
  )

const Index = () => (<GenericIndex namespace="usercp"
  resource_name="webspace_backups"
  tabs={tabs}
  table_header={Header}
  row={TableRow}
  no_create />);

export default Index;
