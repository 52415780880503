import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { PageHeader } from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';

const SuccessMessage = () => {
  return <Callout title="Fertig!"
                  calloutClass="success"
                  text="Die Zahlungsmethode wurde erfolgreich erstellt und kann nun von uns belastet werden!" />
}

const ErrorMessage = () => {
  return <Callout
    calloutClass="error"
    title="Fehler"
    text="Beim Erstellen der PayPal-Zahlungsmethode ist leider ein Fehler aufgetreten. Bitte versuche es erneut oder wende Dich an den Support."
    />
}

class Page extends React.Component {
  state = {}

  componentDidMount() {
    const token = location.search.match(/token=(EC-[^&]*)(&|$)/)[1];

    $.post('/usercp/payment_methods/paypal_ba_confirm', {token: token}).done(() => {
      this.setState({ result: "ok" });
    }).fail(() => {
      this.setState({ result: "error" });
    });
  }

  render() {
    return <div>
      <PageHeader text="PayPal hinzufügen" back_url="/payment_methods" />

      {this.state.result == "ok" ?
        <SuccessMessage /> :
        (this.state.result == "error" ? <ErrorMessage /> :
        <LoadingTableSpinner text="Erstelle Zahlungsmethode..." />)}
    </div>;
  }
}

export default Page;
