import ExternalLink from 'client/js/util/external_link';
import {Link} from 'react-router-dom';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import {BooleanInput, RadioCollectionInput, TextInput} from 'client/js/util/form_utils';
import {PageHeader} from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';

const SuccessCallout = () => (<div>
    <PageHeader text="Anmeldung abgesendet" />

    <Callout calloutClass="success" text="Wir haben die Anmeldung für den kostenlosen Webspace erhalten und melden uns per E-Mail, sobald wir eine Entscheidung getroffen haben." />
  </div>)

const usage = [['personal', 'privat'], ['business', 'geschäftlich'], ['club', '(eingetragener) Verein'], ['education', 'Bildungszwecke (Schule, Studium, Lernzwecke)'], ['other', 'sonstiges']];

const Form = () => (<div>
    <Callout>
      <p>Für den kostenlosen Webspace prüfen wir jede Anmeldung einzeln, da der kostenlose Webspace nicht für alle Zwecke gedacht ist. Bitte fülle das folgende Formular aus, damit wir Deine Anmeldung prüfen und den kostenlosen Webspace für Dich freischalten können.</p>
      <p>Die Freischaltung erfolg typischerweise innerhalb eines Werktags.</p>
    </Callout>


    <RadioCollectionInput required name="use_case" label="Verwendungszweck" options={usage} />
    <TextInput required name="use_case_description" label="Beschreibung" hint="Bitte erläutere kurz, um was für eine Webseite es sich handelt oder welchen Zweck der kostenlose Webspace erfüllen soll." />

    <BooleanInput required name="no_backups" label={<span>Ich habe verstanden, dass vom kostenlosen Webspace <strong>keine Backups erzeugt werden</strong>. Eine Datensicherung werde ich, sofern nötig, selber vornehmen.</span>} />
    <BooleanInput required name="freespace_restrictions" label={<span>Ich habe verstanden, dass auf dem kostenlosen Webspace <strong>Audio-, Video- und Archiv-Dateien</strong> sowie Dateien größer als 20 MB <strong>nicht erlaubt</strong> sind. Die <ExternalLink href="https://www.lima-city.de/hilfe/welche-dateiformate-sind-auf-dem-webspace-verboten">Liste von Einschränkungen</ExternalLink> habe ich gelesen.</span>} />
  </div>)

const ApplicationForm = () => (<GenericInteraction namespace="usercp"
                                             resource_name="webhosting"
                                             form_namespace="application"
                                             form_url="/usercp/webhosting/apply_for_freespace"
                                             action="apply_for_freespace"
                                             method="post"
                                             button_text={i18n_t("resources.webhosting.interactions.apply_for_freespace_submit")}
                                             success_splash={() => (<SuccessCallout />)}
                                             form_component={() => (<Form />)} />);

export default ApplicationForm;
