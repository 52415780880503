import {FaIcon} from 'client/js/util/layout_utils';

const actionLabels = {
  stop: 'stoppen',
  start: 'starten',
  shutdown: 'herunterfahren',
  reset: 'Reset',
}

const DropdownToggle = (props) => {
  const show_spinner = props.action_running || ['created', 'provisioned', 'installed'].includes(props.status);
  const disabled = props.disabled;

  return (
    <button disabled={disabled} className={show_spinner ? "btn btn-light btn-lg text-nowrap" : "btn btn-light btn-lg dropdown-toggle text-nowrap"} data-toggle="dropdown">
      {show_spinner ? <span className="css-spinloader"></span> : null}
    </button>
  );
}

class Button extends React.Component {
  state = { actionRunning: false }

  handleConsole = (e) => {
    e.preventDefault();

    if(!this.actionAllowed('console'))
      return false;

    var strWindowFeatures = "menubar=no,location=no,resizable=yes,scrollbars=no,status=no";
    window.open(`/usercp/virtual_machines/${this.props.id}/console`, `console-${this.props.id}`, strWindowFeatures);
  }

  handleAction = (e, action) => {
    e.preventDefault();

    if(!this.actionAllowed(action))
      return false;

    this.setState({actionRunning: true});

    $.post(`/usercp/virtual_machines/${this.props.id}/${action}`)
      .done(this.handleActionSuccess)
      .fail(this.handleActionFailed);
  }

  handleActionFailed = () => {
    this.setState({actionRunning: false});
  }

  handleActionSuccess = () => {
    this.setState({actionRunning: false});

    if(this.props.refreshCallback)
      this.props.refreshCallback();
  }

  vmManageable = () => {
    return (this.props.status == 'running' || this.props.status == 'stopped');
  }

  actionAllowed = (action) => {
    if(this.state.actionRunning)
      return false;

    var when_stopped = action == 'start';

    return this.vmManageable() && ((this.props.status == 'stopped' && when_stopped)
                                    || (this.props.status == 'running' && !when_stopped));
  }

  renderActionItem = (action, icon) => {
    var allowed = this.actionAllowed(action);

    return <a href="#" className={!allowed ? 'disabled dropdown-item' : 'dropdown-item'} disabled={!allowed} onClick={el => this.handleAction(el, action)}><FaIcon name={icon}/> {actionLabels[action]} </a>;
  }

  renderBigButton = () => {
    if(this.props.status == "stopped")
      return (<button disabled={this.state.actionRunning || !this.actionAllowed('start')} className="btn btn-light btn-lg" onClick={e => this.handleAction(e, 'start')} target="_blank"><FaIcon name="play" /> Starten </button>)
    else
      return (<button disabled={!this.actionAllowed('console')} className="btn btn-light btn-lg" onClick={this.handleConsole} target="_blank"><FaIcon name="keyboard-o" /> Konsole öffnen </button>)
  }

  render = () => {
    return (
      <div className="btn-group virtual-machine-action-button">
        {this.renderBigButton()}
        <DropdownToggle disabled={this.state.actionRunning || !this.vmManageable()} status={this.props.status} />

        <div className="dropdown-menu">
          {this.renderActionItem('start', 'play')}
          {this.renderActionItem('shutdown', 'stop')}
          {this.renderActionItem('stop', 'stop')}
          {this.renderActionItem('reset', 'repeat')}
        </div>
      </div>
    )
  }
}

export default Button;
