import { binToStr, strToBin } from 'client/js/util/base64';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { stringify_error } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';
import { ControlGroup, FormActions } from 'client/js/util/form_utils';

const Error = (props) => (<div className="text-center">
    <h5 className="text-danger">
      <FaIcon name="exclamation-circle fa-4x mb-2" />
      <br />
      Es ist ein Fehler aufgreteten
    </h5>

    <p>Die Fehlermeldung lautet: <br/> {props.error}</p>

    {!!props.retry && <button className="btn-secondary btn" onClick={props.retry}><FaIcon name="refresh" /> Erneut versuchen</button>}
  </div>)

const Success = () => (<div className="text-center">
    <h5 className="text-success">
      <FaIcon name="check-circle fa-4x mb-2" />
      <br />
      Zweiter Faktor erfolgreich hinzugefügt
    </h5>

    <p>Der zweite Faktor wurde erfolgreich hinzugefügt und wird in Zukunft beim Login und zur Bestätigung verwendet.</p>

    <p><Link to="/security">zurück zu Account-Sicherheit</Link></p>
  </div>)

class Registration extends React.Component {
  state = {}

  componentDidMount() {
    if(!navigator.credentials)
      this.setState({credentials_unavailable: true});
  }

  retry = (e) => {
    e.preventDefault();
    this.setState({error: null, attestation: null, success: null});
  }

  startProvisioning = (e) => {
    e.preventDefault();
    this.setState({provisioning: true});

    $.get('/usercp/authentication_factors/provision_webauthn.json').done((data) => {
      data.webauthn["challenge"] = strToBin(data.webauthn["challenge"]);
      data.webauthn["user"]["id"] = strToBin(data.webauthn["user"]["id"]);
      data.webauthn["excludeCredentials"] = data.webauthn["excludeCredentials"].map((cred) => {
        cred["id"] = strToBin(cred["id"]);
        return cred;
      });

      this.setState({provisioning_data: data.webauthn}, () => this.startRegistration());
    }).fail(this.handleXhrFail);
  }

  startRegistration = () => {
    navigator.credentials.create({publicKey: this.state.provisioning_data}).then((attestation) => {
      const response = {
        id: attestation.id,
        client_data_json: binToStr(attestation.response.clientDataJSON),
        attestation_object: binToStr(attestation.response.attestationObject),
        name: this.state.name
      }
      this.setState({attestation: response, provisioning: null, provisioning_data: null});

      $.post("/usercp/authentication_factors/webauthn", {authentication_factor: response}).done(() => {
        this.setState({success: true});
        this.props.registerChange();
      }).fail(this.handleXhrFail);
    }).catch((error) => {
      this.setState({provisioning: null, provisioning_data: null});
      if(error)
        this.setState({error: error.toLocaleString("de-DE")});
      else
        this.setState({error: "Fehler bei der Registrierung"});
    })
  }

  handleXhrFail = (x, y, z) => {
    console.log('XHR failed:', x, y, z)
    this.setState({error: stringify_error(x, y, z)});
  }

  updateName = (e) => {
    this.setState({name: e.target.value});
  }

  render() {
    if(this.state.credentials_unavailable)
      return <Error error="Der Browser oder die Umgebung unterstützt keine Sicherheitsschlüssel" />;

    if(this.state.error)
      return <Error error={this.state.error} retry={this.retry} />;

    if(this.state.attestation) {
      if(this.state.success)
        return <Success />;
      else
        return <LoadingTableSpinner text="Registriere Schlüssel..." />;
    }

    if(this.state.provisioning) {
      if(this.state.provisioning_data) {
        return <div className="text-center">
          <p>Drücke nun - sofern vorhanden - die Taste zur Bestätigung auf dem Sicherheitsschlüssel.</p>

          <LoadingTableSpinner text="Warte auf den Schlüssel..." />
        </div>

       } else {
        return <LoadingTableSpinner text="Registrierung vorbereiten..." />;
       }
    }

    return <React.Fragment>
        <p>Um Deinen Sicherheitsschlüssel zu registrieren, stecke ihn bitte in den USB-Port und starte die Registrierung mit dem &quot;Start&quot;-Button. Wenn Du möchtest kannst Du zusätzlich einen Namen für den Faktor angeben:</p>

        <ControlGroup label="Name">
          <input type="text" className="form-control" name="authentication_factor[name]" onChange={this.updateName} />
        </ControlGroup>

        <FormActions>
          <a href="#" className="btn btn-primary btn-lg" onClick={this.startProvisioning}>
            <FaIcon name="key" />
            Start
          </a>
        </FormActions>
    </React.Fragment>;
  }
}

class CreateModal extends React.Component {
  state = {open: true}

  hide = (e) => {
    e && e.preventDefault();

    this.setState({open: false});
    this.props.onClose(this.state.changed);
  }

  registerChange = () => {
    this.setState({changed: true});
  }

  render() {
    return (
      <div>
        <PageHeader back_url="/security" text="WebAuthn hinzufügen" />

        <Registration registerChange={this.registerChange} />
      </div>
      );
  }
}

export default CreateModal;
