import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { Dropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon } from 'client/js/util/layout_utils';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <tr>
      <th>Benutzername</th>
      <th>Webspace-Verzeichnis</th>
      <th>Optionen</th>
    </tr>
  )
}

const TableRow = ({item: account, refreshCb}) => {
  return (
    <tr>
      <td>
        <Link to={`/ftp_accounts/${account.id}`}>{account.username}</Link>
        {account.comment && account.comment != "" && (<>
            <br />
            <small className="text-muted">{account.comment}</small>
          </>
        )}
        </td>
      <td>{account.directory ? account.directory : '/'}</td>
      <td>
        <Dropdown>
          <Link to={`/ftp_accounts/${account.id}/edit`} className="dropdown-item"><FaIcon name="pencil" /> Verzeichnis bearbeiten</Link>
          <Link to={`/ftp_accounts/${account.id}/password`} className="dropdown-item"><FaIcon name="key" /> Passwort ändern</Link>
          <Link to={`/ftp_accounts/${account.id}/comment`} className="dropdown-item"><FaIcon name="comment" /> Kommentar bearbeiten</Link>

          {account.deleteable && (
            <ConfirmedDelete
              namespace="usercp"
              onSuccess={refreshCb}
              redirect_back={false}
              resource="ftp_accounts"
              id={account.id}
              button_text={<><FaIcon name="trash" /> löschen</>}
              button_class="dropdown-item text-danger"
              text="Dies wird den FTP-Zugang sofort löschen, der Zugriff ist nicht mehr möglich."
            />
          )}
        </Dropdown>
        </td>
    </tr>
  );
}

const Index = () => (<GenericIndex namespace="usercp" resource_name="ftp_accounts" row={TableRow} table_header={Header} />);

export default Index;
