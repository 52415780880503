import {ControlGroup, ControlLabel, ControlErrors} from 'client/js/util/form_utils';
import ExternalLink from 'client/js/util/external_link';

var VmNetworkAddresses = function(props) {
  const addressOptions = [
    ["dual-stack", "Dual-Stack (IPv4 und IPv6, empfohlen)"],
    ["ipv4", "nur IPv4"],
    ["ipv6", "nur IPv6"]
  ];

  return (
  <div className="row form-group large_radio_buttons required usercp_create_vm_ip_assignment">
    <label className="large_radio_buttons required col-form-label col-md-3">
      <abbr title="required">*</abbr> Netzwerk-Adressen</label>
      <div className="col-md-9">
        {addressOptions.map((v, index) => {
          let n = `usercp_create_vm_ip_assignment_${v[0]}`;

          return <div key={v[0]} className="custom-control custom-radio checkbox-option">
            <input aria-required="true"
                   checked={props.selectedOption == v[0]}
                   onChange={e => props.dispatch('network_select', e, v[0])}
                   className="custom-control-input large_radio_buttons required"
                   id={n}
                   name="usercp_create_vm[ip_assignment]"
                   required="required"
                   type="radio"
                   value={v[0]} />
            <label className="custom-control-label" htmlFor={n}>{v[1]}</label>
          </div>;
        })}
      </div>
    </div>);
}

var VmHostname = function(props) {
  const type = "string required";
  const hint = <span>Als Hostname kann ein Label wie "web1" oder ein gültiger Hostname wie "db.example.com" (der nicht zwingend vorhanden sein muss) eingegeben werden. Der Hostname kann später jederzeit geändert werden. <ExternalLink href="https://www.lima-city.de/hilfe/reverse-hostname-ptr-record-setzen" text="Mehr Informationen" />.</span>;

  return (
    <ControlGroup type={type} name="usercp_create_vm_hostname" labelFor="usercp_create_vm_hostname" required={props.required} errors={props.errors} label="Hostname" hint={hint}>
      <input value={props.value} onChange={e => props.dispatch('hostname_change', e)} aria-required="true" className="form-control string required" id="usercp_create_vm_hostname" name="usercp_create_vm[hostname]" required="required" type="text" />
    </ControlGroup>);
}

var VmNetworkStep = function(props) {
  return (<fieldset>
  <legend>3. Netzwerk</legend>
    <VmNetworkAddresses options={props.networkOptions} selectedOption={props.networkModel} dispatch={props.dispatch} />
    <VmHostname errors={props.serverErrors.hostname} label="Hostname" required={true} currentValue={props.hostname} dispatch={props.dispatch} />
  </fieldset>);
}

export default VmNetworkStep;
