import PropTypes from "prop-types";
import GenericCreate from 'client/js/resellercp/generic_resource/create';
import {BooleanInput, StringInput, SelectCollectionInput, RadioCollectionInput, PasswordInput} from 'client/js/util/form_utils';
import PrependStringInput from 'client/js/util/inputs/prepend_string_input';
import ExternalLink from 'client/js/util/external_link';

const charsets = ['utf8mb4_general_ci', 'latin1_swedish_ci', 'utf8_general_ci', 'big5_chinese_ci', 'dec8_swedish_ci', 'cp850_general_ci', 'hp8_english_ci', 'koi8r_general_ci', 'latin2_general_ci', 'swe7_swedish_ci', 'ascii_general_ci', 'ujis_japanese_ci', 'sjis_japanese_ci', 'hebrew_general_ci', 'tis620_thai_ci', 'euckr_korean_ci', 'koi8u_general_ci', 'gb2312_chinese_ci', 'greek_general_ci', 'cp1250_general_ci', 'gbk_chinese_ci', 'latin5_turkish_ci', 'armscii8_general_ci', 'ucs2_general_ci', 'cp866_general_ci', 'keybcs2_general_ci', 'macce_general_ci', 'macroman_general_ci', 'cp852_general_ci', 'latin7_general_ci', 'cp1251_general_ci', 'utf16_general_ci', 'utf16le_general_ci', 'cp1256_general_ci', 'cp1257_general_ci', 'utf32_general_ci', 'binary', 'geostd8_general_ci', 'cp932_japanese_ci', 'eucjpms_japanese_ci'];
const options = {"localhost": "localhost (vom eigenen Webspace)", "%": "% (vom eigenen Webspace und Internet)"}
const access_options = {"admin": "Admin-Zugriff", "readonly": "Nur-Lesen", "readwrite": "Lese- und Schreibzugriff, keine Schema-Änderungen"}

const CreateUser = (props, context) => {
  if(context.formData && context.formData['create_user'] == '1')
    return <fieldset>{props.children}</fieldset>;

  return null;
}

CreateUser.contextTypes = {
  formData: PropTypes.object
};

const Form = () => {
  return <div>
      <StringInput name="comment" label="Kommentar (optional)" />

      <SelectCollectionInput name="collation" label="Collation (Zeichensatz)"
                             hint={<span>optional: die <ExternalLink text="Datenbank-Kollation (Zeichensatz)" href="https://dev.mysql.com/doc/refman/5.6/en/charset-charsets.html" /></span>}
                             options={charsets.map((i) => [i, i])} />

      <BooleanInput name="create_user" label="Benutzer erstellen" />

      <CreateUser>
        <legend>Benutzer</legend>
        <PrependStringInput prepend_text={`USER${document.lima_id}_`} name="user_suffix" label="Benutzername" required maxLength={6} />
        <RadioCollectionInput name="user_hostname" label="Zugriff" options={options} required />
        <RadioCollectionInput name="user_simple_access" label="Rechte" options={access_options} required hint="Die Rechte werden nur für die neu erstellte Datenbank vergeben und können später geändert werden." />

        <PasswordInput name="user_password" label="Passwort" required />
      </CreateUser>
    </div>;
}

const transform = (original) => {
  let data = {comment: original.comment, collation: original.collation,
              create_user: (original.create_user == '1')};

  if(original.create_user == '1')
    data.database_user = {suffix: original.user_suffix, hostname: original.user_hostname,
                          password: original.user_password, simple_access: original.user_simple_access};

  return data;
}

const Create = () => (<GenericCreate namespace="usercp"
                                    resource_name="databases"
                                    form_resource_name="database"
                                    redirectAfter="collection"
                                    transform_data={transform}
                                    form_component={() => (<Form />)} />);

export default Create;
