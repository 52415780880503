import { Route, Switch } from 'react-router-dom';
import CreateExternalDomain from './create_external_domain';
import CreateFreeSubdomain from './create_free_subdomain';
import CreateSubdomain from './create_subdomain';
import Index from './index';
import Statistics from './statistics';
import Update from './update';

const Router = () => (
    <Switch>
      <Route path="/websites/lima-subdomain/new" component={CreateFreeSubdomain} />
      <Route path="/websites/statistics" component={Statistics} />
      <Route path="/websites/subdomain/new" component={CreateSubdomain} />
      <Route path="/websites/external-domain/new" component={CreateExternalDomain} />
      <Route path="/websites/:id/edit" component={Update} />
      <Route path="/websites" component={Index} />
    </Switch>);

export default Router;
