import {Route, Switch} from 'react-router-dom';
import Index from './index';
import Show from './show';
import Create from './create';
import Update from './update';

const ResourceRouter = () => (
    <Switch>
      <Route path="/databases/new" exact component={Create} />
      <Route path="/databases/:id/edit" exact component={Update} />
      <Route path="/databases/:id" component={Show} />
      <Route path="/databases" exact component={Index} />
    </Switch>);

export default ResourceRouter;
