import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { Dropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon } from 'client/js/util/layout_utils';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Header = () => {
  return (
    <tr>
      <th>Typ</th>
      <th>URL oder Kommando</th>
      <th>Ausführungszeiten</th>
      <th>Optionen</th>
    </tr>
  )
}

const types = {
  "url": "URL",
  "shell": "Shell"
}

const TableRow = ({item: cronjob, refreshCb: refetch}) => {
  const onSuccess = () => {
    toast.success("Cronjob wurde gelöscht.");
    refetch();
  }

  return (
    <tr>
      <td>{types[cronjob.type]}</td>
      <td>
        <Link to={`/cronjobs/${cronjob.id}`} title={cronjob.url || cronjob.command}>{cronjob.url || cronjob.command}</Link>

        {!!cronjob.description && (
          <>
            <br />
            <small className="d-block text-muted">{cronjob.description}</small>
          </>
        )}
      </td>
      <td>{cronjob.exec_times}</td>
      <td>
        <Dropdown>
          <Link to={`/cronjobs/${cronjob.id}/edit`} className="dropdown-item"><FaIcon name="pencil" /> bearbeiten</Link>

          <ConfirmedDelete
            namespace="usercp"
            onSuccess={onSuccess}
            redirect_back={false}
            resource="cronjobs"
            id={cronjob.id}
            button_text={<><FaIcon name="trash" /> löschen</>}
            button_class="dropdown-item text-danger"
            text="Dies wird den Cronjob sofort löschen. Aktuell laufende Instanzen dieses Cronjobs werden nicht beendet."
          />
        </Dropdown>
        </td>
    </tr>
  );
}

const Index = () => (<GenericIndex filter_transform={(item) => [item.url || '', item.command || '', item.description || ''].join(' ')} namespace="usercp" resource_name="cronjobs" row={TableRow} table_header={Header} />);

export default Index;
