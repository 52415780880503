import Callout from 'client/js/util/callout';
import {Redirect} from 'react-router-dom';
import {FaIcon} from 'client/js/util/layout_utils';
import {FormActions} from 'client/js/util/form_utils';

class Form extends React.Component {
  state = { formValue: '', deleteSnapshots: false, actionRunning: false }

  handleTyping = (e) => {
    this.setState({formValue: e.target.value });
  }

  handleCheck = (e) => {
    this.setState({deleteSnapshots: e.target.checked });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if(!this.actionAllowed())
      return false;

    this.setState({ actionRunning: true });

    $.ajax({url: this.props.delete_url, type: 'DELETE', data: {destroy_vm: {destroy_snapshots: this.state.deleteSnapshots}}})
      .done(() => this.handleVmDeleted())
      .fail(() => this.handleVmDeleteFailed());
  }

  handleVmDeleteFailed = () => {
    this.setState({ actionRunning: false });
  }

  handleVmDeleted = () => {
    this.setState({ deleted: true });
  }

  actionAllowed = () => {
    return this.state.formValue == this.props.hostname;
  }

  renderIcon = () => {
    if(this.state.actionRunning)
      return <span className="css-spinloader"></span>;
    else
      return <FaIcon name="trash" />;
  }

  renderSubmit = () => {
    if(this.state.actionRunning)
      return (<FaIcon name="spinner icon-spin fa-fw" />);
    else
      return (<FaIcon name="trash fa-fw" />);
  }

  deleteSnapshotsControl = () => {
    if(!this.props.hasSnapshots)
      return null;

    return (
      <div className="form-group row optional destroy_vm_destroy_snapshots">
        <div className="col-md-9 offset-md-3">
          <div className="custom-control custom-checkbox">
            <input className="custom-control-input" id="destroy_vm_destroy_snapshots" name="destroy_vm[destroy_snapshots]" type="checkbox" value="1" />
            <label className="custom-control-label" onChange={this.handleCheck} htmlFor="destroy_vm_destroy_snapshots">Snapshots dieser VM löschen</label>
          </div>
        </div>
      </div>
      );
  }

  render(){
    if(this.state.deleted)
      return <Redirect to='/virtual_machines' />;

    return <div>
        <Callout calloutClass="danger" title="Achtung!" text="Das VPS wird sofort gestoppt und vollständig gelöscht. Diese Aktion kann nicht rückgängig gemacht werden." />

        <form className="simple_form form-large-inputs form-horizontal" onSubmit={this.handleSubmit}>
          {this.deleteSnapshotsControl()}
          <div className="form-group row string required destroy_vm_vm_name">
            <div className="col-md-9 offset-md-3">
              <input aria-required="true" className="form-control string required" aria-describedby="snapshotHelpBlock" onChange={this.handleTyping} required="required" type="text" />
              <small id="snapshotHelpBlock" className="form-text text-muted">Bitte gib hier den Hostnamen "{this.props.hostname}" zur Bestätigung ein.</small>
            </div>
          </div>
          <FormActions>
            <button className="btn btn btn-danger" disabled={!this.actionAllowed() || this.state.actionRunning} name="button" type="submit">
              {this.renderIcon()} Löschen
            </button>
          </FormActions>
        </form>
      </div>;
  }
}

export default Form;
