import { useMutation } from '@tanstack/react-query';
import { DetailsRow, DetailsSection, FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { Modal, ModalHeader } from 'client/js/util/modal';
import { Pagination, usePages } from 'client/js/util/pagination';
import { errorToToast, useResource } from 'client/js/util/rest_utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const StatusBadge = ({ sending_state }) => {
  if(sending_state == 'blocked') {
    return <span className="badge badge-danger">gesperrt</span>
  } else if (sending_state == 'paused') {
    return <span className="badge badge-warning">pausiert</span>
  } else {
    return <span className="badge badge-success">aktiv</span>
  }
}

const StatusDot = ({ state }) => {
  if(state == 'delivered') {
    return <span className="status-dot status-success ml-2" title="zugestellt"></span>
  } else if (state == 'hardfail') {
    return <span className="status-dot status-danger ml-2" title="nicht zugestellt"></span>
  } else if (state == 'softfail') {
    return <span className="status-dot status-warning ml-2" title="temporär nicht zugestellt"></span>
  } else {
    return <span className="status-dot ml-2" title="in Warteschlange"></span>
  }
}

const EmailStatus = ({ delivered_at, state, smtp_status }) => {
  return (
    <>
      {i18n_t(`phpmails.state.${state}`)} {delivered_at && <>am {dayjs(delivered_at).format('L LT')}</>}
      {' '}
      {smtp_status?.message && smtp_status?.relay && (
        <i className="fa fa-question-circle tooltipped" title={`Relay: ${smtp_status.relay} - SMTP-Status: ${smtp_status.code} - Message: ${smtp_status.message}`}></i>
      )}

      {smtp_status?.message && !smtp_status?.relay && (
        <i className="fa fa-question-circle tooltipped" title={`Nachricht: ${smtp_status.message}`}></i>
      )}
    </>
  );
}

const TableRow = ({ item }) => {
  return (
    <tr>
      <td>
        <StatusDot state={item.state} />
      </td>
      <td>
        <a href={`/usercp/phpmails/${item.id}`}>{item.subject}</a>
        <br />
        <small>{item.from} &rarr; {item.to}</small>

      </td>
      <td>{dayjs(item.queued_at).format('L LT')}</td>
      <td>
        <EmailStatus delivered_at={item.delivered_at} state={item.state} smtp_status={item.smtp_status} />
      </td>
    </tr>
  )
}

const EmptyQueueModal = ({onConfirm, closeModal, isLoading }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onConfirm();
  }

  return (
    <Modal isOpen onRequestClose={closeModal} className="modal fade in show d-block modal-no-borders" contentLabel="Warteschlange leeren">
      <ModalHeader title="Warteschlange leeren" onRequestClose={closeModal} />

      <div className="modal-body">
        Möchtest du die Warteschlange wirklich leeren? Alle noch nicht versendeten E-Mails werden gelöscht.
      </div>

      <div className="modal-footer">
        <button className="btn btn-danger" onClick={handleClick} disabled={isLoading}><FaIcon name='times' loading={isLoading} /> Warteschlange leeren</button>
      </div>
    </Modal>
  )
}

const EmptyQueueButton = ({ refetch }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { isLoading, mutate } = useMutation(() => {
    return $.ajax({
      url: `/usercp/phpmails/empty_queue`,
      type: 'POST',
      dataType: 'json'
    });
  }, {
    onSuccess: () => refetch(),
    onError: (error) => {
      errorToToast(error, "beim Leeren der Warteschlange");
    }
  });

  const handleClick = (e) => {
    e.preventDefault();
    setModalOpen(true);
  }

  return (
    <>
      <button className="btn btn-danger" onClick={handleClick} disabled={isLoading}>
        <FaIcon name="times" loading={isLoading} /> Warteschlange leeren
      </button>

      {modalOpen && <EmptyQueueModal isOpen={modalOpen} onConfirm={mutate} closeModal={() => setModalOpen(false)} isLoading={isLoading} />}
    </>
  )
}

const Index = () => {
  const [page, changePage] = usePages();

  const { data, component, isPreviousData, refetch } = useResource(['phpmails', {page: page}], `/usercp/phpmails?page=${page}`,  {header:
    () => <PageHeader text="PHP-Mailversand" />,
    keepPreviousData: true
  });

  if(component) {
    return component;
  }

  let pagination = null;

  if (data?.pagination && data?.pagination.total_pages > 1) {
    pagination = <Pagination currentPage={page} totalPages={data?.pagination.total_pages} onPageChange={changePage} />;
  }

  return (
    <>
      <PageHeader text="PHP-Mailversand" buttons={data.queued_mails > 0 && <EmptyQueueButton refetch={refetch} />} />

      <DetailsSection
        title="Statistik"
        intro={
          <p>
            E-Mails, die mit der PHP-Funktion mail() versendet werden, werden hier mit dem Ergebnis der Zustellung aufgelistet und nach 7 Tagen gelöscht. Werden mehr als {data.current_limit} E-Mails pro Tag <strong> über PHP (Postfächer/SMTP-Versand sind ausgenommen)</strong> versendet, sammelt das System die E-Mails und sendet sie am nächsten Tag.
          </p>
        }
      >
        <DetailsRow title="Versand-Status:">
          <StatusBadge sending_state={data.sending_state} />
        </DetailsRow>

        <DetailsRow title="Gesendet heute:">
          {number_with_delimiter(data.sent_today)}
        </DetailsRow>

        <DetailsRow title="Gesendet gestern:">
          {number_with_delimiter(data.sent_yesterday)}
        </DetailsRow>

        <DetailsRow title="E-Mails in der Warteschlange:">
          {number_with_delimiter(data.queued_mails)}
        </DetailsRow>

        <DetailsRow title="Limit pro Tag:">
          {data.current_limit} (<Link to="/phpmails/limit">anpassen</Link>)
        </DetailsRow>

        {data.delivery_percentage && !isNaN(data.delivery_percentage) && (
          <DetailsRow title="Zustellrate letzte 7 Tage:">
            {format_percent(data.delivery_percentage)}
          </DetailsRow>
        )}
      </DetailsSection>

      {pagination}

      <table className={isPreviousData ? "table table-striped skeleton-loading table-wide table-php-mails" : "table table-striped table-wide table-php-mails"}>
        <thead>
          <tr>
            <th></th>
            <th>E-Mail</th>
            <th>Erstellt</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.emails.map((item) => (
            <TableRow key={item.id} item={item} />
          ))}

          {data.emails.length === 0 && (
            <tr>
              <td colSpan="4" className="text-center">Keine E-Mails gefunden</td>
            </tr>
          )}
        </tbody>
      </table>

      {pagination}
    </>
  )
}

export default Index;
