import {Route, Switch} from 'react-router-dom';
import Index from './index';
// import Create from './create';
import EditAuthorizedKeys from './edit_authorized_keys';
// import Show from './show';

const ResourceRouter = () => (
    <Switch>
      {/* <Route path="/customers/new" exact component={Create} />
      // <Route path="/customers/:id/edit" component={Edit} />
      // <Route path="/customers/:id" component={Show} />*/}
      <Route path="/ssh_accounts/authorized_keys/edit" exact component={EditAuthorizedKeys} />
      <Route path="/ssh_accounts" exact component={Index} />
    </Switch>);

export default ResourceRouter;
