import {Route, Switch} from 'react-router-dom';
import Index from './index';
import Show from './show';
import Create from './create';
import UpdateGrants from './update_grants';
import UpdatePassword from './update_password';

const ResourceRouter = () => (
    <Switch>
      <Route path="/database_users/new" exact component={Create} />
      <Route path="/database_users/:id/password" exact component={UpdatePassword} />
      <Route path="/database_users/:id/grants/edit" exact component={UpdateGrants} />
      <Route path="/database_users/:id" component={Show} />
      <Route path="/database_users" exact component={Index} />
    </Switch>);

export default ResourceRouter;
