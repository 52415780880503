const transform_db_user = (original) => {
  let data = {...original};

  if(original.access != 'complex') {
    data.access = 'simple';
    data.simple_access = original.access;
    data.grants = null;
  }

  return data;
}

export {transform_db_user};
