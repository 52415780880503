import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { Dropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon } from 'client/js/util/layout_utils';
import { toast } from 'react-toastify';

const Header = () => {
  return (
    <tr>
      <th>Key</th>
      <th>Erstellt</th>
      <th></th>
    </tr>
  )
}

const TableRow = ({item: key, refreshCb: refetch}) => {
  const onSuccess = () => {
    toast.success("SSH-Key wurde gelöscht.");
    refetch();
  }

  return (
    <tr>
      <td>
        <strong>{key.friendly_name}</strong>
        <br/>
        <span className="badge badge-secondary" style={{textTransform: 'none'}}>
          MD5: {key.fingerprint}
        </span>
        {' '}
        <span className="badge badge-secondary" style={{textTransform: 'none'}}>
          SHA256: {key.sha256_fingerprint}
        </span>
      </td>
      <td>
        {dayjs(key.created_at).format('L LT')}
      </td>
      <td>
        <Dropdown>
          <ConfirmedDelete
            namespace="usercp"
            onSuccess={onSuccess}
            redirect_back={false}
            resource="ssh_keys"
            id={key.id}
            button_text={<><FaIcon name="trash" /> löschen</>}
            button_class="dropdown-item text-danger"
            text="Dies wird den SSH-Key sofort löschen. Sofern er für den SSH-Zugriff auf das Webhosting verwendet wird, wird der Key dort entfernt. Für vServer und Cloud-VPS bleibt der Server aktiv."
          />
        </Dropdown>
      </td>
    </tr>
  );
}

const Index = () => (<GenericIndex back_url="/settings" namespace="usercp" resource_name="ssh_keys" row={TableRow} table_header={Header} />);

export default Index;
