import Callout from 'client/js/util/callout';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import CopyableField from 'client/js/util/copyable_field';
import { DetailsRow, DetailsSection, FaIcon, HelpTooltip, OverviewBoxLine, OverviewBoxLineItem, PageHeader } from 'client/js/util/layout_utils';
import { withLoading } from 'client/js/util/rest_utils';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

const Overview = (props) => {
  return (
    <DetailsSection title="MySQL-Zugangsdaten">
      <DetailsRow title={<>MySQL-Server/Host (lokal): <HelpTooltip content='Verwende den Hostnamen "localhost" bei Verbindungen von Deinem eigenen Webspace' /></>}>
        <CopyableField value="localhost" />
      </DetailsRow>

      <DetailsRow title={<>MySQL-Server/Host (extern): <HelpTooltip content="Bitte verwende diesen Hostnamen bei Verbindungen von Fremd-Systemen (eigener Server, anderer Webspace)" /></>}>
        {props.data.hostname == 'localhost' ? <div className="fake-credential"><FaIcon name="minus" title="Der Zugriff von extern ist nicht erlaubt. Bitte ändere den Datenbank-Benutzer, um den Zugriff von extern freizugeben." /></div> : <CopyableField value={`${document.lima_username}.lima-db.de`} />}
      </DetailsRow>
      <DetailsRow title="Port:">
        <CopyableField value={3306} />
      </DetailsRow>
      <DetailsRow title="Benutzername:">
        <CopyableField value={props.data.username} />
      </DetailsRow>
      <DetailsRow title="Passwort:">
        <em className="fake-credential">das von Dir gewählte Passwort</em>
      </DetailsRow>
    </DetailsSection>
  );
}

const Grants = (props) => {
  if(props.data.simple_access == 'admin')
    return <Callout calloutClass="info" title="Admin-Rechte">
      <p>Der Datenbank-Zugang hat <strong>Administrator-Rechte</strong> für alle Datenbanken.</p>
      <p><small>Dieser Datenbank-Zugang benutzt den "Simple-Mode" für Rechtevergabe. Die gewählten Rechte gelten für alle Datenbanken, auch für in Zukunft angelegte Datenbanken.</small></p>
    </Callout>;

  if(props.data.simple_access == 'readonly')
    return <Callout calloutClass="info" title="Lese-Zugriff">
      <p>Der Datenbank-Zugang hat <strong>Lese-Zugriff</strong> für alle Datenbanken. Dies enthält die Privilegien SELECT und SHOW CREATE.</p>
      <p><small>Dieser Datenbank-Zugang benutzt den "Simple-Mode" für Rechtevergabe. Die gewählten Rechte gelten für alle Datenbanken, auch für in Zukunft angelegte Datenbanken.</small></p>
    </Callout>;

  if(props.data.simple_access == 'readwrite')
    return <Callout calloutClass="info" title="Lese-Schreib-Zugriff">
      <p>Der Datenbank-Zugang hat <strong>Lese-Schreib-Zugriff</strong> für alle Datenbanken. Dies enthält die Privilegien SELECT, SHOW CREATE, UPDATE, INSERT und DELETE.</p>
      <p><small>Dieser Datenbank-Zugang benutzt den "Simple-Mode" für Rechtevergabe. Die gewählten Rechte gelten für alle Datenbanken, auch für in Zukunft angelegte Datenbanken.</small></p>
    </Callout>;

  if(props.data.grants && props.data.grants.length) {
    return <table className="table table-striped table-wide mb-3">
            <thead>
              <tr><th>Datenbank</th><th>Rechte</th></tr>
            </thead>
            <tbody>
             {props.data.grants.map((grant, index) => {
                return <tr key={(grant.id || index)}>
                        <td><Link to={`/databases/${grant.database.id}`}>{grant.database.name}</Link></td>
                        <td>{grant.privileges.length ? grant.privileges.join(', ') : '-'}</td>
                       </tr>;
              })}
            </tbody>
          </table>;
  }

    return <Callout calloutClass="info" title="Keine Rechte">
      <p>Der Datenbank-Zugang hat <strong>keine Rechte</strong>, kann sich aber mit dem Datenbank-Server verbinden. Dies ist für Monitoring-Scripte nützlich, welche nur prüfen, ob der Datenbank-Server erreichbar ist.</p>
    </Callout>;
}

const AccessFrom = ({hostname}) => {
  if (hostname == 'localhost')
    return <span className="badge badge-secondary">Webspace</span>;

  return <span className="badge badge-warning">Webspace & Internet</span>;
}

const ShowHandle = (props) => {
  let buttons = [<Link key="0" to={`/database_users/${props.data.id}/password`} className="btn btn-light">
                   <FaIcon name="pencil" /> Passwort ändern
                 </Link>,
                 <Link key="2" to={`/database_users/${props.data.id}/grants/edit`} className="btn btn-light"><FaIcon name="pencil" /> Rechte ändern</Link>,
                 <ConfirmedDelete key="1" id={props.data.id} resource="database_users" button_text={[<FaIcon key={0} name="trash" />, ' löschen']}
                       button_class="btn btn-danger" text="Dies wird den Datenbank-Benutzer und alle bestehenden Rechte für Datenbanken, die mit ihm verbunden sind, löschen." />,
                 ];

  return <div>
    <PageHeader text={props.data.username} back_url="/database_users" buttons={buttons}>
      {props.data.username}
    </PageHeader>

    <OverviewBoxLine>
      <OverviewBoxLineItem label="Zugriff">
        <AccessFrom hostname={props.data.hostname} />
      </OverviewBoxLineItem>
      <OverviewBoxLineItem label="Kommentar">
        {props.data.comment ? <span className="text-muted">{props.data.comment}</span> : <>&mdash;</>}
      </OverviewBoxLineItem>
    </OverviewBoxLine>

    <Overview data={props.data} />

    <hr />

    <h5>Zugriffsrechte</h5>

    <Grants data={props.data} />
  </div>;
}

const ShowHandleContainer = withLoading(ShowHandle);

ShowHandle.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};

const Page = (props) => (<ShowHandleContainer url={`/usercp/database_users/${props.match.params.id}.json`} id={props.match.params.id} key_name="database_user" />)

Page.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default Page;
