import { PageHeader } from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';

const Page = () => (
  <div>
    <PageHeader text="PayPal hinzufügen" back_url="/payment_methods" />

    <Callout text="Das Hinzufügen der Zahlungsmethode wurde abgebrochen." />
  </div>
)

export default Page;
