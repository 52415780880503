import PropTypes from 'prop-types';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {FaIcon} from 'client/js/util/layout_utils';
import { toast } from "react-toastify";
import { errorToToast } from 'client/js/util/rest_utils';

const SingleSignIn = ({ app_id: appId }) => {
  const [url, setUrl] = useState(null);

  const mutation = useMutation({
    mutationFn: () => {
      return $.ajax({
        url: `/usercp/wordpress/${appId}/sso_link`,
        type: 'POST',
      });
    },
    onSuccess: (data) => {
      if (data.status === 'error') {
        toast.error(`Fehler: ${data.message}`, {
          autoClose: false
        });

        return;
      }

      const popup = window.open(data.url);

      if (!popup || popup.closed) {
        setUrl(data.url);
        toast.warn("Das Popup wurde blockiert, bitte den Button erneut klicken!", {
          autoClose: false
        });
      }
    },
    onError: (xhr) => {
      errorToToast(xhr, "beim Laden des Login-Links");
    }
  });

  const handleClick = (event) => {
    event.preventDefault();
    mutation.mutate();
  };

  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-light"
        onClick={() => setUrl(null)}
      >
        <FaIcon name="external-link" /> WordPress-Admin
      </a>
    );
  }

  return (
    <button
      className="btn btn-light"
      disabled={mutation.isLoading}
      onClick={handleClick}
    >
      <FaIcon name="sign-in" loading={mutation.isLoading} /> Admin-Login
    </button>
  );
};

SingleSignIn.propTypes = {
  app_id: PropTypes.string.isRequired
}

export default SingleSignIn;
