import { default as BaseModal } from 'react-modal';

const ModalHeader = ({ title, onRequestClose }) => {
  return (
    <div className="modal-header">
      <h5 className="modal-title">{title}</h5>
      {!!onRequestClose && <button type="button" className="close" onClick={onRequestClose}>
        <span aria-hidden="true">&times;</span>
      </button>}
    </div>
  );
}

const Modal = ({ isOpen, large, onRequestClose, children, contentLabel, shouldCloseOnOverlayClick, className }) => {
  if (!isOpen)
    return null;

  return (
    <BaseModal
      isOpen
      onRequestClose={onRequestClose}
      overlayClassName={className || "modal fade in show d-block"}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      role="document"
      className={large ? "modal-dialog modal-dialog-centered modal-lg" : "modal-dialog modal-dialog-centered"}
      overlayElement={(props, contentElement) => <><div role="dialog" {...props}>{contentElement}</div><div className="modal-backdrop fade show"></div></>}
    >
      <div className="modal-content">
        {children}
      </div>
    </BaseModal>
  );
}

Modal.defaultProps = {
  shouldCloseOnOverlayClick: true
};

export default Modal;
export { Modal, ModalHeader };

