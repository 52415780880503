import PropTypes from "prop-types";
import {ControlledInput, ControlledErrors} from "client/js/util/form_tags";
import {ControlGroup} from "client/js/util/form_utils";

const PrependStringInput = (props, context) => {
  const n = [context.namespace, props.name].join('_');

  return <ControlGroup name={n} labelFor={n} required label={props.label} hint={props.hint}>
      <div className="input-group">
        <div className="input-group-prepend"><span className="input-group-text">{props.prepend_text}</span></div>
        <ControlledInput required name={props.name} type="text" maxLength={props.maxLength} />
      </div>
      <ControlledErrors names={[props.name]} />
    </ControlGroup>;
}

PrependStringInput.propTypes = {
  prepend_text: PropTypes.node.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string.isRequired
};

PrependStringInput.contextTypes = {
  namespace: PropTypes.string,
  formData: PropTypes.object,
};

export default PrependStringInput;
