import PropTypes from "prop-types";
import {PageHeader} from 'client/js/util/layout_utils';
import {Redirect} from 'react-router-dom';
import {withLoading} from 'client/js/util/rest_utils';
import {StringInput, GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';

class EditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {updated: false};

    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action) {
    if(action == 'form:success') {
      this.setState({updated: true})
    }
  }

  render() {
    if(this.state.updated)
      return <Redirect to={`/databases/${this.props.data.database.id}`} />;

    return <div>
      <PageHeader text={`Datenbank ändern`} back_url={`/databases/${this.props.data.database.id}}`} />

      <GenericReactForm namespace="database"
                        verb="put"
                        dispatch={this.dispatch}
                        defaults={{comment: this.props.data.database.comment}}
                        url={`/usercp/databases/${this.props.data.database.id}.json`}>

        <StringInput name="comment" label="Kommentar" />

        <FormActions>
          <SubmitButton icon="pencil" text="Kommentar aktualisieren" />
        </FormActions>
      </GenericReactForm>
    </div>;
  }
}

EditPage.propTypes = {
  data: PropTypes.shape({
    handle: PropTypes.shape({
      id: PropTypes.number.isRequired,
      comment: PropTypes.string,
    }).isRequired
  }).isRequired
};

const EditHandle = withLoading(EditPage);
const Page = (props) => (<EditHandle url={`/usercp/databases/${props.match.params.id}.json`} />)

Page.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default Page;
