import { Route, Switch } from 'react-router-dom';
import Create from './create';
import Index from './index';

const Router = () => (
    <Switch>
      <Route path="/ssh_keys/new" component={Create} />
      <Route path="/ssh_keys" component={Index} />
    </Switch>);

export default Router;
