import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { Dropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon } from 'client/js/util/layout_utils';
import { Link } from 'react-router-dom';

class SetDefault extends React.Component {
  do = () => {
    $.post(`/usercp/handles/${this.props.id}/default`).done(
      () => this.props.refreshCb()
    )
  }

  render() {
    return <a href="#" className='dropdown-item' onClick={this.do}>als Standard setzen</a>
  }
}
const options_fn = (id, handle, refreshCb) => (
  <Dropdown>
    {!handle.default && <SetDefault id={handle.id} refreshCb={refreshCb} />}
    <Link to={`/handles/${handle.id}/edit`} className='dropdown-item'>bearbeiten</Link>
    <ConfirmedDelete key="del" id={handle.id} disabled={!handle.deletable} resource="handles" button_text={[<FaIcon key={0} name="trash" />, ' löschen']}
      button_class="dropdown-item btn-danger" text="Dies wird die Adresse löschen. Sollten gesetzliche Aufbewahrungspflichten bestehen wird die Adresse als gelöscht markiert und nach Ablauf der Aufbewahrungspflichten gelöscht."
      success_cb={() => { refreshCb() }} />
  </Dropdown>
)

const status_fn = (id, handle) => {
  if(handle.default)
    return <span className="badge badge-primary">Standard</span>;

  return null;
}

const fields = [{name: "friendly_name", type: "text", link: "show"},
                {name: "status", type: "component", fn: status_fn},
                {name: "options", type: "component", fn: options_fn}
                ]

const Index = () => (<GenericIndex namespace="usercp"
                                   back_url="/settings"
                                   resource_name="handles"
                                   fields={fields} />);

export default Index;
