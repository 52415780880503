import { useMutation, useQueryClient } from '@tanstack/react-query';
import Callout from 'client/js/util/callout';
import CopyableField from 'client/js/util/copyable_field';
import ExternalLink from 'client/js/util/external_link';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { useResource, errorToToast } from 'client/js/util/rest_utils';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { BalanceInfo } from '../dashboard';
import {AnimatedTabs, AnimatedNavItem as NavItem} from 'client/js/util/nav_tabs';

const AcceptTos = () => {
  const [tosAccepted, setTosAccepted] = useState(false);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return $.post('/usercp/commissions/accept_tos');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['commissions'] })
      queryClient.invalidateQueries({ queryKey: ['affiliate_payouts'] })
    },
    onError: (xhr) => {
      errorToToast(xhr, "beim Hinzufügen des Produkts zum Warenkorb");
    }
  })

  const handleSubmit = () => {
    if(tosAccepted)
      mutate();
  }

  return (
    <>
      <PageHeader text="Kunden werben" />

      <Callout type="primary">
        <h4>Kunden werben Kunden</h4>

        <p>
          Das lima-city Partnerprogramm ist eine Möglichkeit, an den Umsätzen geworbener Kunden beteiligt zu werden.
        </p>

        <p>
          Wenn sich neue Kunden über Deinen Referal-Link anmelden beteiligen wir Dich mit 10% an allen Umsätzen dieser Kunden! Um an dem Partnerprogramm teilnehmen zu können melde Dich bitte dazu an.
        </p>

        <div className="custom-control custom-checkbox mb-2">

          <input id="tos_accepted" className="custom-control-input" type="checkbox" required onChange={(e) => setTosAccepted(e.target.checked)} checked={tosAccepted} />

          <label htmlFor="tos_accepted" className="custom-control-label">
            Ich akzeptiere die <ExternalLink href="/Teilnahmebedingungen Partnerprogramm.pdf">Bedingungen für das Partnerprogramm</ExternalLink>.
          </label>
        </div>

        <button type="button" className="btn btn-primary" disabled={isLoading || !tosAccepted} onClick={handleSubmit}>
          <FaIcon name="user-plus" loading={isLoading} /> Anmelden zum Partnerprogramm
        </button>
      </Callout>
    </>
  )
}

const MediaList = ({ media }) => {
  return (
    <>
      <p>Dein persönlicher Referral-Link lautet: <CopyableField value={media.text_link} /></p>

      <h2>Textlink</h2>

      <p>
        <a target="_blank" rel="noopener noreferrer" title="Webhosting, Domains &amp; Cloud von lima-city" href={media.text_link}>Hosted by lima-city</a>
      </p>

      HTML: <CopyableField value={media.text_link_html}>
        <code>{media.text_link_html}</code>
      </CopyableField>

      <h2>Banner</h2>

      {media.banners.map((banner, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="media-wrap mb-2">
          <img src={banner.image_url} alt="lima-city: Webhosting, Domains und Cloud" />

          <br />

          <CopyableField value={banner.html} />
        </div>
      ))}

      <h2>Buttons</h2>

      {media.buttons.map((banner, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="media-wrap mb-2">
          <img src={banner.image_url} alt="lima-city: Webhosting, Domains und Cloud" />

          <br/>

          <CopyableField value={banner.html} />
        </div>
      ))}
    </>
  )
}

/* basically the same as this HAML template:

%table.table.table-striped
  %thead
    %tr
      %th Angebots-Code
      %th Webhosting-Paket
      %th Testzeitraum
      %th Einlösungen
      %th aktiv seit
  %tbody
    -current_user.shared_hosting_offer_promotions.each do |promotion|
      %tr
        %td=external_link_to(promotion.offer_code, offer_signup_url(promotion_code: promotion.offer_code, protocol: 'https'))
        %td=promotion.offer.name
        %td=t('day', count: (promotion.trial_days || 14))
        %td #{promotion.usage_count}/∞
        %td=l promotion.created_at

*/
const PromotionsList = ({ promotions }) => {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Angebots-Code</th>
          <th>Webhosting-Paket</th>
          <th>Testzeitraum</th>
          <th>Einlösungen</th>
          <th>aktiv seit</th>
        </tr>
      </thead>
      <tbody>
        {promotions.map((promotion, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={i}>
            <td>
              <ExternalLink href={promotion.offer_url}>
                {promotion.offer_code}
              </ExternalLink>
            </td>
            <td>{promotion.offer_name}</td>
            <td>{promotion.trial_days || 14} Tage</td>
            <td>{number_with_delimiter(promotion.usage_count)}/∞</td>
            <td>{dayjs(promotion.created_at).format('L LT')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

// Have a page that uses useResource to fetch /usercp/commissions
// When tos_accepted is false, display the following callout.
// Then, use a mutation to accept the TOS by POST ing to /commission/accept_tos
// and refetch the commissions.

const Index = () => {
  const { data, component } = useResource(['commissions'], '/usercp/commissions', {
    keepPreviousData: true,
    header: <PageHeader text="Kunden werben" />
  });

  if(component) {
    return component;
  }

  if(!data.tos_accepted) {
    return <AcceptTos />;
  }

  return (
    <>
      <PageHeader text="Kunden werben" />

      <AnimatedTabs>
        <NavItem to="/commissions" exact>Übersicht</NavItem>
        <NavItem to="/commissions/media">Werbemittel</NavItem>
        {!!data.promotions?.length && <NavItem to="/commissions/promotions">Promotions</NavItem>}
        <NavItem to="/affiliate_payouts">Auszahlungen</NavItem>
      </AnimatedTabs>

      <Switch>
        <Route path="/commissions" exact>
          <div className="row">
            <div className="col-md-4">
              <BalanceInfo affiliate={data.statistics} tos_accepted />
            </div>
          </div>
        </Route>
        <Route path="/commissions/media">
          <MediaList media={data.media} />
        </Route>
        <Route path="/commissions/promotions">
          <PromotionsList promotions={data.promotions} />
        </Route>
      </Switch>
    </>
  )
}


export { AcceptTos };

export default Index;
