import {StringInput, GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';
import Callout from 'client/js/util/callout';

const TopCallout = () => (<Callout calloutClass="warning" title="Account-Prüfung erforderlich" text="Für die Registrierung der Domain ist die Verifizierung des Accounts erforderlich. Bitte gib unten eine Telefonnummer ein, an die wir eine SMS mit einem Bestätigungscode senden." />)

class VerifyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {step: 1};

    this.stepOneDispatch = this.stepOneDispatch.bind(this);
    this.stepTwoDispatch = this.stepTwoDispatch.bind(this);
    this.clickBack = this.clickBack.bind(this);
  }

  stepOneDispatch(action, original_event, data) {
    if(action == 'form:success')
      this.setState({step: 2, phone: data.object.phone});
  }

  clickBack(e) {
    this.setState({step: 1});
    e.preventDefault();
  }

  stepTwoDispatch(action) {
    if(action == 'form:success')
      this.setState({step: 3});
  }

  render() {
    return <div>
      {this.state.step == 1 &&
        <div>
          <TopCallout />

          <GenericReactForm namespace="usercp_domain_verification_step1" url="/usercp/domains/verifizierung" dispatch={this.stepOneDispatch}>
            <StringInput name="phone" label="Telefonnummer" required placeholder='+49 172 12345678' hint='Bitte trage auch den Länder-Code mit ein, sofern es keine deutsche Nummer ist!' />

            <FormActions>
              <SubmitButton icon="plus" text="SMS-Code senden" />
            </FormActions>
          </GenericReactForm>
        </div>}

      {this.state.step == 2 &&
        <div>
          <TopCallout />

          <div className="alert alert-success">
            Der Code wurde per SMS an {this.state.phone} gesendet. <a href="#" onClick={this.clickBack}>Telefonnummer ändern</a>
          </div>

          <GenericReactForm namespace="usercp_domain_verification_step2" url="/usercp/domains/verifizierung/code" dispatch={this.stepTwoDispatch}>
            <StringInput name="code" label="Code" required placeholder='123456' autoComplete="off" />

            <FormActions>
              <SubmitButton icon="plus" text="Code prüfen" />
            </FormActions>
          </GenericReactForm>
        </div>}

      {this.state.step == 3 &&
        <Callout calloutClass="success" title="Account-Prüfung erfolgreich">
          Vielen Dank für die Verifizierung des Accounts. Die Domain wird nun registriert.
          <br />
          <a href="/usercp/websites">Websites & Domains</a>
        </Callout>
        }
    </div>;
  }
}

export default VerifyAccount;
