import {Link} from 'react-router-dom';
import { PageHeader, FaIcon } from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import {Dropdown} from 'client/js/usercp/table_utils';
import {AnimatedTabs, AnimatedNavItem as NavItem} from 'client/js/util/nav_tabs';
import { useQuery } from '@tanstack/react-query';
import { errorToComponent } from 'client/js/util/rest_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

// Status is a little component that makes a request to /email_domains/:domain/status.json
// and displays a badge with the status of the domain
const Status = ({id}) => {
  const { data, isLoading, isError } = useQuery(['email_domain', id, 'status'], async () => {
    const response = await $.ajax({url: `/email_domains/${id}/status`, dataType: 'json'});

    return response;
  }, {
    keepPreviousData: true
  })

  if(isLoading || isError) {
    return <span className="css-spinloader inline"></span>;
  }

  const status = data.status;

  // if any of spf_status, dkim_status, dmarc_status, mx_record_status is 'error', we display a red dot
  if(status.spf_status == 'error' || status.dkim_status == 'error' || status.dmarc_status == 'error' || status.mx_record_status == 'error') {
    const messages = [];
    if(status.spf_status == 'error') {
      messages.push('SPF-Record fehlerhaft');
    }

    if(status.dkim_status == 'error') {
      messages.push('DKIM-Schlüssel fehlerhaft');
    }

    if(status.dmarc_status == 'error') {
      messages.push('DMARC-Record fehlerhaft');
    }

    if(status.mx_record_status == 'error') {
      messages.push('MX-Record fehlerhaft');
    }

    return <span className="status-dot status-danger" title={messages.join(', ')}></span>
  }

  // same with status "warning"
  if(status.spf_status == 'warning' || status.dkim_status == 'warning' || status.dmarc_status == 'warning' || status.mx_record_status == 'warning') {
    const messages = [];
    if(status.spf_status == 'warning') {
      messages.push('SPF-Record fehlerhaft');
    }

    if(status.dkim_status == 'warning') {
      messages.push('DKIM-Schlüssel fehlerhaft');
    }

    if(status.dmarc_status == 'warning') {
      messages.push('DMARC-Record fehlerhaft');
    }

    if(status.mx_record_status == 'warning') {
      messages.push('MX-Record fehlerhaft');
    }

    return <span className="status-dot status-warning" title={messages.join(', ')}></span>
  }

  return <span className="status-dot status-success"></span>;
}

const EmailRow = (props) => {
  const domain = props.item;

  return (
    <tr>
      <td>
        <Link to={`/email_domains/${domain.ascii_fqdn}`}><strong>{domain.unicode_fqdn}</strong></Link>
      </td>
      <td>
        <Status id={domain.id} />
      </td>
      <td>
        <Dropdown>
          <Link to={`/mailboxes/${domain.id}/password`} href="#" className="dropdown-item"><FaIcon name="key" /> Passwort ändern</Link>

          <ConfirmedDelete namespace="usercp" resource="mailboxes" id={domain.id}
                         link button_class="dropdown-item" button_text={<><FaIcon name="trash" /> Postfach löschen</>}
                         redirect_back={false} success_cb={props.hide}
                         text="Dies wird das Postfach und die darin vorhandenen E-Mails löschen. Bitte beachte die gesetzlichen Aufbewahrungsfristen, sofern es sich um E-Mails handelt, für welche Aufbewahrungspflichten gelten!" />
        </Dropdown>
      </td>
    </tr>
  )
}

const fetchData = async () => {
  const response = await $.ajax({
    url: '/usercp/email_domains',
    dataType: 'json',
  });

  return response;
}

const NoEmailSplash = () => (<Callout calloutClass="info" title="E-Mail nicht verfügbar">
  <p>Hier kannst Du E-Mail-Postfächer und Weiterleitungen verwalten. Derzeit sind keine Domains verfügbar, für welche Postfächer oder Weiterleitungen verwaltet werden können.</p>
  <p>Bitte registriere oder transferiere eine Domain oder schalte sie mit aktivierter E-Mail-Funktion auf, sofern die Domain bei einem anderen Provider registriert ist.</p>
</Callout>)

const Index = () => {
  const [filterQuery, setFilterQuery] = React.useState('');

  const { data, isLoading, isError, error } = useQuery(['email_domains'], fetchData, {
    keepPreviousData: true,
  });

  const buttons_disabled = !data?.email_available;

  const header_buttons = <>
    <input disabled={buttons_disabled} className="search-query form-control" type="search" placeholder='Schnellsuche' onChange={(e) => setFilterQuery(e.target.value)} />
  </>;

  if(isLoading) {
    return (
      <>
        <PageHeader text="E-Mail-Domains" buttons={header_buttons} />

        <AnimatedTabs>
          <NavItem to="/email">E-Mail-Adressen</NavItem>
          <NavItem to="/email_domains">E-Mail-Domains</NavItem>
        </AnimatedTabs>

        <LoadingTableSpinner />
      </>
    )
  }

  if (isError) {
    return (
      <>
        <PageHeader text="E-Mail-Domains" buttons={header_buttons} />

        <AnimatedTabs>
          <NavItem to="/email">E-Mail-Adressen</NavItem>
          <NavItem to="/email_domains">E-Mail-Domains</NavItem>
        </AnimatedTabs>

        {errorToComponent(error)}
      </>
    )
  }

  if(!data.email_available) {
    return <>
        <PageHeader text="E-Mail-Domains" buttons={header_buttons} />

        <AnimatedTabs>
          <NavItem to="/email">E-Mail-Adressen</NavItem>
          <NavItem to="/email_domains">E-Mail-Domains</NavItem>
        </AnimatedTabs>

        <NoEmailSplash />
      </>
  }

  let email_domains = data.email_domains;

  if(filterQuery && filterQuery !== '') {
    let filter_fn;
    let rex;
    try {
      rex = new RegExp(filterQuery, 'i');

      filter_fn = (item) => (rex.test(item.unicode_fqdn));
    } catch(err) {
      filter_fn = (item) => (item.unicode_fqdn.indexOf(filterQuery) !== -1);
    }

    email_domains = email_domains.filter(filter_fn);
  }

  return (
    <>
      <PageHeader text="E-Mail-Domains" buttons={header_buttons} />

      <AnimatedTabs>
        <NavItem to="/email">E-Mail-Adressen</NavItem>
        <NavItem to="/email_domains">E-Mail-Domains</NavItem>
      </AnimatedTabs>

      <div className="table-responsive">
      <table className="table table-striped table-wide table-email-manager">
        <thead>
          <tr>
            <th>Domain</th>
            <th>Status</th>
            <th>Optionen</th>
          </tr>
        </thead>
        <tbody>
          {email_domains.map((item) => (<EmailRow key={item.id} item={item} />))}

          {email_domains.length == 0 && <tr><td colSpan="3" className="text-center font-weight-bold">Keine E-Mail-Domains {data.email_domains.length == 0 ? 'vorhanden' : 'gefunden'}.</td></tr>}
        </tbody>
      </table>
      </div>
    </>
  );
}

export default Index;
