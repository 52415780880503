import {useResource} from 'client/js/util/rest_utils';
import Callout from 'client/js/util/callout';

/* issues are:

  json.rule_match file.signature_match
  json.filename file.filename
  json.status file.status
  json.offset file.offset
  json.code_match file.code_match

*/

// The issue row is actually two rows, where the
// second row is hidden and can be toggled.
// the second row contains the malicious code that matched
class IssueRow extends React.Component {
  state = { showSecondRow: false }

  toggleSecondRow = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showSecondRow: !prevState.showSecondRow }));
  }

  render() {
    const { issue } = this.props;
    const { showSecondRow } = this.state;

    return (
      <>
        <tr>
          <td>
            <a href="#" onClick={this.toggleSecondRow}><i className={`fa fa-fw ${showSecondRow ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i></a>
            {' '}
            {issue.filename}</td>
          <td>{issue.rule_match}</td>
        </tr>
        {showSecondRow && (
          <tr>
            <td colSpan="2">
              <p>Offset/Position in der Datei: {issue.offset}</p>
              <pre><code>{issue.code_match}</code></pre>
            </td>
          </tr>
        )}
      </>
    );
  }
}

const Issues = ({app_id}) => {
  const { data, component } = useResource(['wordpress', app_id, 'issues'], `/usercp/wordpress/${app_id}/issues`);

  if(component) {
    return component;
  }

  const issues = data.issues;

  if(!issues.length) {
    // display a nice callout
    return <Callout calloutClass="success" title="Keine Probleme erkannt" text="Derzeit sind keine Sicherheitsprobleme bekannt 🎉!" />;
  }

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Datei</th>
          <th scope="col">Signatur</th>
        </tr>
      </thead>
      <tbody>
        {issues.map((issue, i) => (<IssueRow key={i} issue={issue} />))}
      </tbody>
    </table>
  );
}

export default Issues;
