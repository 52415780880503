import PropTypes from "prop-types";
import {PageHeader} from 'client/js/util/layout_utils';
import {Redirect} from 'react-router-dom';
import {PasswordInput, GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';

class EditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {updated: false};

    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action) {
    if(action == 'form:success') {
      this.setState({updated: true})
    }
  }

  render() {
    if(this.state.updated)
      return <Redirect to={`/database_users/${this.props.id}`} />;

    return <div>
      <PageHeader text={`Passwort ändern`} back_url={`/database_users/${this.props.id}`} />

      <p>Die Änderung des Passworts wird sofort aktiv. Bitte denke daran, Deine Zugangsdaten in PHP-Scripten anzupassen!</p>

      <GenericReactForm namespace="database_user"
                        verb="post"
                        dispatch={this.dispatch}
                        url={`/usercp/database_users/${this.props.id}/password`}>

        <PasswordInput label="Passwort" name="password" required />

        <FormActions>
          <SubmitButton icon="pencil" text="Passwort ändern" />
        </FormActions>
      </GenericReactForm>
    </div>;
  }
}

const Page = (props) => (<EditPage id={props.match.params.id} />)

Page.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default Page;
