import {NavLink} from 'react-router-dom';
import GenericIndex from 'client/js/resellercp/generic_resource/index';

const host_fn = (id, user) => {
  if(user.hostname == 'localhost')
    return <span className="badge badge-secondary">Webspace</span>;

  if(user.hostname == '%')
    return <span className="badge badge-warning">Webspace & Internet</span>;

  return user.hostname;
}

const access_fn = (id, user) => {
  if(user.simple_access == 'admin')
    return 'Administrator';

  if(user.simple_access == 'readonly')
    return 'Lese-Zugriff';

  if(user.simple_access == 'readwrite')
    return 'Lese-Schreib-Zugriff';

  if(user.grants && user.grants.length) {
    return `${user.grants.length} Rechte`;
  } else {
    return 'Kein Zugriff';
  }
}

const fields = [{name: "username", type: "text", link: "show"},
                {name: "hostname", type: "component", fn: host_fn},
                {name: "created_at", type: "datetime"},
                {name: "access", type: "component", fn: access_fn}]

const Index = () => (<GenericIndex namespace="usercp"
                                   resource_name="database_users"
                                   filter_transform={(item) => (item.username)}
                                   fields={fields} />);

export default Index;
