import {Route, Switch} from 'react-router-dom';
import UpdatePaymentMethod from './update_payment_method';
import Subscribe from './subscribe';
import Upgrade from './upgrade';
import Cancel from './cancel';
import Order from './order';
import ApplyForFreespace from './apply_for_freespace';
import CreateTest from './create_test';
import Index from './index';
import CreateAddon from './create_addon';

const ResourceRouter = () => (
    <Switch>
      <Route path="/webhosting/upgrade" exact component={Upgrade} />
      <Route path="/webhosting/subscribe" exact component={Subscribe} />
      <Route path="/webhosting/cancel" exact component={Cancel} />
      <Route path="/webhosting/order" exact component={Order} />
      <Route path="/webhosting/apply_for_freespace" exact component={ApplyForFreespace} />
      <Route path="/webhosting/create_test" exact component={CreateTest} />
      <Route path="/webhosting/payment_method/edit" exact component={UpdatePaymentMethod} />
      <Route path="/webhosting/addons/new" exact component={CreateAddon} />
      <Route path="/webhosting" exact component={Index} />
    </Switch>);

export default ResourceRouter;
