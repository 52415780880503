import { GenericReactForm, SubmitButton, FormActions, BooleanInput } from 'client/js/util/form_utils';
import {useResource} from 'client/js/util/rest_utils';
import {toast} from 'react-toastify';

const Settings = ({id}) => {
  const { data, component, refetch } = useResource(['virtual_machines', id], `/usercp/virtual_machines/${id}`, {
    keepPreviousData: true,
    refetchInterval: 5000
  });

  if(component) {
    return component;
  }

  const onSuccess = () => {
    toast.success("Einstellungen gespeichert");
    refetch();
  }

  const hint = data.virtual_machine.type == 'vserver' ? "Erstellt ein tägliches Backup dieser virtuellen Maschine. Die Snapshots werden für 3 Tage aufbewahrt und danach gelöscht. Die automatischen Snapshots eines vServers sind kostenfrei." : "Erstellt ein tägliches Backup dieser virtuellen Maschine. Die Snapshots werden 3 Tage aufbewahrt, der verwendete Speicherplatz wird nach den Preisen für Snapshots abgerechnet."

  return (
    <GenericReactForm
      defaults={{backup: data.virtual_machine.backup}}
      namespace={null}
      url={`/usercp/virtual_machines/${id}`}
      verb="PUT"
      onSuccess={onSuccess}
    >
      <BooleanInput name="backup" sendBoolean label="tägliches Backup" hint={hint} />

      <FormActions>
        <SubmitButton icon="floppy-o" text="Speichern" />
      </FormActions>
    </GenericReactForm>
    );
}

export default Settings;
