import CopyableField from 'client/js/util/copyable_field';

const NetworkTab = function(props) {
  return (
    <div>
      <h2>DNS</h2>

      <p>Anstatt von IP-Adressen kann auch der DNS-Cloud-Hostname verwendet werden, um zum Cloud-VPS zu verbinden. Unter diesem Hostnamen sind die IPv4 und IPv6-Adressen des Cloud-VPS hinterlegt.</p>

      {props.ip_addresses.map((item) => (
        <div className="card mb-3" key={item.address}>
          <h5 className="card-header">IPv{item.version}</h5>

          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              IP-Adresse
              <div className="float-right"><CopyableField value={item.address} /></div>
            </li>

            <li className="list-group-item">
              Netzmaske
              <div className="float-right"><CopyableField value={item.netmask} /></div>
            </li>

            <li className="list-group-item">
              Gateway
              <div className="float-right"><CopyableField value={item.gateway} /></div>
            </li>

            <li className="list-group-item">
              DNS-Cloud-Hostname
              <div className="float-right"><CopyableField value={`${props.id}.trafficplex.cloud`} /></div>
            </li>
          </ul>
        </div>
      ))}
    </div>);
}

export default NetworkTab;
