import {GenericReactForm, SubmitButton, SelectCollectionInput, FormActions} from 'client/js/util/form_utils';
import Callout from 'client/js/util/callout';
import { FaIcon } from 'client/js/util/layout_utils';
import AuthorizedKeysInput from 'client/js/util/inputs/authorized_keys_input';
import ExternalLink from 'client/js/util/external_link';
import { useState } from 'react';
import { useResource } from 'client/js/util/rest_utils';

class SuccessMessage extends React.Component {
  state = {started: false}

  componentDidUpdate(prevProps) {
    if(prevProps.status !== 'rebuilding' && this.props.status === 'rebuilding') {
      this.setState({started: true});
    }
  }

  render() {
    const {status, restore_progress} = this.props;

    if ((status == 'provisioned' || status == 'rebuilding') && restore_progress) {
      return <Callout title="Rebuild läuft...">
        <p>Der Rebuild wurde gestartet und wird in Kürze abgeschlossen sein.</p>

        <div className="progress" style={{height: "1rem"}}><div className="progress-bar" style={{ width: `${restore_progress}%` }}>{restore_progress} %</div></div>
      </Callout>
    }

    if (status == 'running' && this.state.started) {
      return <Callout calloutClass="success" title="Rebuild abgeschlossen!">
        <p className='text-success'><FaIcon name="thumbs-up" /> <strong>Der Rebuild wurde erfolgreich abgeschlossen.</strong></p>

      </Callout>
    }

    return <Callout title="Rebuild gestartet">
        <p>Der Rebuild wurde gestartet und wird in Kürze abgeschlossen sein.</p>
      </Callout>;
  }
}

const RebuildImage = (props) => {
  const [success, setSuccess] = useState(false);
  const { data, component } = useResource(['virtual_machines/templates'], '/usercp/virtual_machines/templates.json');

  if(component) return component;

  if(success || props.status == 'rebuilding')
    return <SuccessMessage status={props.status} restore_progress={props.restore_progress} />;

  return (
    <GenericReactForm url={`/usercp/virtual_machines/${props.id}/rebuild`} onSuccess={() => setSuccess(true)}>
      <Callout calloutClass="danger">
        <div className='d-flex align-items-center'>
          <FaIcon name="exclamation-triangle fa-5x text-muted mr-3" />

          <p>Die virtuelle Maschine wird durch diese Aktion von einem Image neu erstellt. <strong>Alle Daten auf der virtuellen Maschine gehen dabei verloren!</strong></p>
        </div>
      </Callout>

      <SelectCollectionInput clearable name="template_id" label="Image" options={data.templates.filter(t => t.type == 'image').map((t) => [t.id, t.title])} />

      <AuthorizedKeysInput name="ssh_key_ids" label="SSH-Keys" hint={<span>Für die Authentifizierung verwenden unsere Images standardmäßig SSH-Keys und keine Passwörter. Bitte beachte auch unsere <ExternalLink href='/hilfe/ssh-keys-erzeugen'>Hilfe zu SSH-Keys</ExternalLink>.</span>} />

      <FormActions>
        <SubmitButton icon="recycle" text="Rebuild image" />
      </FormActions>
    </GenericReactForm>
  );
}

export default RebuildImage;
