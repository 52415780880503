import Callout from 'client/js/util/callout';
import CopyableField from 'client/js/util/copyable_field';
import { DetailsRow, DetailsSection, FaIcon, HelpTooltip, PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';

class ActivateMemcacheButton extends React.Component {
  state = {loading: false}

  activate = (e) => {
    e.preventDefault();
    this.setState({loading: true});

    $.post(`/usercp/memcached/${this.props.url_action}`).done(() => this.props.refreshCb());
  }

  render() {
    return <button className={`btn btn-${this.props.button_class}`} onClick={this.activate} disabled={this.state.loading}><FaIcon name={this.props.icon} loading={this.state.loading} /> {this.props.button_text}</button>;
  }
}

ActivateMemcacheButton.defaultProps = {
  button_class: 'light'
}

const ActivatePage = (props) => {
  const wait_and_refresh = () => {
    // this is a bit hacky, but we need to give the memcached a bit of time to start up
    setTimeout(() => props.refreshCb(), 2000);
  }

  return (
    <>
      <PageHeader text="Memcached" buttons={<ActivateMemcacheButton key="activate" url_action="enable" button_text="starten" icon="play" refreshCb={wait_and_refresh} />} />

      <Callout title="Memcached gestoppt">
        <p>Derzeit ist Memcached gestoppt.</p>
      </Callout>
    </>
  );
}

const Index = () => {
  const { data, component, refetch, isRefetching } = useResource(['memcached'], '/usercp/memcached', {
    keepPreviousData: true,
    refetchInterval: (data) => data?.enabled ? 5000 : false,
    header: <PageHeader text="Memcached" />
  })

  if(component)
    return component;

  if(!data.available)
    return (
      <>
        <PageHeader text="Memcached" />

        <Callout calloutClass="danger" title="Memcached nicht verfügbar">
          <p>Leider ist Memcached in Deinem Paket nicht enthalten. Wir bieten Memcached ab dem Webhosting-Paket "Business" an.</p>
        </Callout>
      </>
    );

  if(!data.enabled)
    return <ActivatePage refreshCb={refetch} />;

  let usage = data.stats ? (data.stats.bytes / data.stats.limit_maxbytes)*100 : null;
  let hitrate = data.stats ? (data.stats.get_hits / data.stats.cmd_get)*100 : null;
  let write_ratio = data.stats ? (data.stats.cmd_set / data.stats.cmd_get)*100 : null;

  const handleRefresh = (e) => {
    e.preventDefault();
    refetch();
  }

  return (
    <>
      <PageHeader
        text="Memcached"
        buttons={
          <>
            <button className="btn btn-light" onClick={handleRefresh} disabled={isRefetching}><FaIcon name="refresh" loading={isRefetching} /> aktualisieren</button>

            <ActivateMemcacheButton url_action="disable" button_class="danger" button_text="stoppen" icon="power-off" refreshCb={refetch} />
          </>
        }
      />

      <DetailsSection title="Einstellungen">
        <DetailsRow title="Größe:" text={`${data.current_size} MB`} />
        <DetailsRow title="Hostname / Socket:">
          <CopyableField value={`/usr/share/lima/php-${document.lima_username}/memcached.sock`} />
        </DetailsRow>
      </DetailsSection>

      <hr />

      {data.stats ? (
        <DetailsSection title="Statistiken">
          <DetailsRow title="Verbindungen aktuell" text={number_with_delimiter(data.stats.curr_connections)} />
          <DetailsRow title="Verbindungen gesamt" text={number_with_delimiter(data.stats.total_connections)} />
          <DetailsRow title="Traffic ausgehend" text={format_disk_space(data.stats.bytes_written)} />
          <DetailsRow title="Traffic eingehend" text={format_disk_space(data.stats.bytes_read)} />
          <DetailsRow title="Cache-Größe aktuell" text={format_disk_space(data.stats.bytes)} />
          <DetailsRow title={<span>Speicher-Auslastung <HelpTooltip content="Der prozentual belegte Cache. Höher ist besser." /></span>} text={format_percent(usage)} />
          <DetailsRow title="Cache-Einträge aktuell" text={number_with_delimiter(data.stats.curr_items)} />
          <DetailsRow title="Cache-Einträge gesamt" text={number_with_delimiter(data.stats.total_items)} />

          <DetailsRow title={<span>Hitrate <HelpTooltip content="Der Anteil von Lesezugriffen, welche Daten aus dem Cache laden. Höher ist besser." /></span>} text={format_percent(hitrate)} />

          <DetailsRow title="GET (hit)" text={number_with_delimiter(data.stats.get_hits)} />
          <DetailsRow title="GET (miss)" text={number_with_delimiter(data.stats.get_misses)} />
          <DetailsRow title="GET (expired)" text={number_with_delimiter(data.stats.get_expired)} />
          <DetailsRow title="GET (total)" text={number_with_delimiter(data.stats.cmd_get)} />
          <DetailsRow title="SET" text={number_with_delimiter(data.stats.cmd_set)} />

          <DetailsRow title={<span>Schreib-/Lese-Rate <HelpTooltip content="Das Verhältnis von Schreib- zu Lesezugriffen. Sollte im Idealfall kleiner als 100% sein, kleiner ist häufig besser." /></span>} text={format_percent(write_ratio)} />
        </DetailsSection>
      ) : (
        <>
          <h5>Statistiken</h5>

          <Callout text="Derzeit sind keine Statistiken verfügbar. Bitte warte einen Moment..." />
        </>
      )}
    </>
  );
}

export default Index;
