import PropTypes from "prop-types";
import {ControlledInput, ControlledErrors} from "client/js/util/form_tags";
import {ControlGroup} from "client/js/util/form_utils";

var SshUsernameInput = function(props, context) {
  if(context.formData && context.formData.username_schema != 'own')
    return null;

  const n = [context.namespace, "domain_prefix"].filter((n) => (n != undefined)).join('_');
  return (
    <ControlGroup name={n} labelFor={n} required label={props.label} hint={props.hint}>
      <div className="input-group">
        <div className="input-group-prepend"><span className="input-group-text">ssh-</span></div>
        <ControlledInput required name={props.name} type="text" />
      </div>
      <ControlledErrors names={[props.name]} />
    </ControlGroup>);
}

SshUsernameInput.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string.required
};

SshUsernameInput.contextTypes = {
  namespace: PropTypes.string,
  formData: PropTypes.object,
};

export default SshUsernameInput;
