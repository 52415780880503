import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import Callout from 'client/js/util/callout';
import { StringInput } from 'client/js/util/form_utils';
import { PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';

const Form = (props) => {
  return <StringInput required type="number" min="0" max={props.max_limit} name="limit" label="tägliches Limit" hint="Wir empfehlen, immer das geringstmögliche Limit zu verwenden und das Limit nur bei Bedarf zu erhöhen. Bei Problemen mit einem ungewollten Versand (z.B. durch Missbrauch eines Kontaktformulars, Hacking der Webseite o.ä.) wird dadurch der geringste Schaden angerichtet." />;
}

const SuccessCallout = () => (<div>
    <PageHeader text="PHP-Mailversand: Limit ändern" back_url='/phpmails' />

    <Callout calloutClass="success" title="PHP-Mailversand: Limit geändert">
      <p>Das Limit für den PHP-Mailversand wurde erfolgreich geändert. Die Änderung wird in wenigen Sekunden aktiv.</p>
    </Callout>
  </div>)

const Page = () => {
  const { data, component } = useResource(['phpmails', 'limit'], '/usercp/phpmails');

  if(component) {
    return component;
  }

  return <GenericInteraction namespace="usercp"
                                    resource_name="phpmail_limit"
                                    action="change"
                                    method="put"
                                    defaults={{limit: data.current_limit}}
                                    form_url="/usercp/phpmails/limit"
                                    back_url="/phpmails"
                                    breadcrumbs={() => (null)}
                                    success_splash={() => (<SuccessCallout />)}
                                    form_component={() => (<Form max_limit={data.max_limit} />)} />
}

export default Page;
