import Callout from 'client/js/util/callout';
import SnapshotMenu from 'client/js/usercp/cloud/snapshots/snapshot_menu';
import {PageHeader} from 'client/js/util/layout_utils';
import {useResource} from 'client/js/util/rest_utils';
import {usePages} from 'client/js/util/pagination';

const Menu = (props) => {
  const [page, changePage ] = usePages();

  const { data, component, refetch } = useResource(['virtual_machine_snapshots', {page: page}], `/usercp/virtual_machines/snapshots?page=${page}`, {
    refetchInterval: 5000,
    keepPreviousData: true,
  });

  if(component)
    return component;

  if(data.snapshots.length == 0)
    return <Callout title="Keine Snapshots vorhanden" text='Es sind noch keine Snapshots vorhanden. Snapshots von einem Cloud-VPS können über den Tab "Snapshots" auf der Detailseite eines Cloud-VPS erstellt werden.' />;

  return (<SnapshotMenu changePage={changePage} pagination_data={data.pagination} dispatch={props.dispatch} selectSnapshot={props.selectSnapshot} renderOrigin={true} noCreate snapshots={data.snapshots} refreshCb={refetch} />);
}

const MenuPage = () => (<div>
  <PageHeader text="Snapshots" />

  <Menu />
</div>)

export {MenuPage};

export default Menu;
