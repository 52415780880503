import { useMutation, useQuery } from '@tanstack/react-query';
import PayButton from "client/js/usercp/lib/payment";
import { DetailsRow, DetailsSection, FaIcon, PageHeader, SkeletonLoading } from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { Modal, ModalHeader } from 'client/js/util/modal';
import { errorToComponent, errorToToast } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';
import { BasicDropdown } from '../table_utils';
import HandleDetails from './../handles/handle';
import { AutoRenewStatus, Status } from './status';

const fetchData = async (id) => {
  const response = await $.ajax({url: `/usercp/domains/${id}`, dataType: 'json'});

  return response;
}

const Buttons = ({domain, refetch}) => {
  if(!domain) {
    return null;
  }

  if (domain.manageable) {
    let buttons = []

    if(domain.renewable && !domain.in_subscription) {
      if (!domain.auto_renew) {
        buttons.push(<a key="1" href={`/usercp/domains/${domain.id}/renew`} className="btn btn-light"><FaIcon name="shopping-cart" /> verlängern</a>);
        buttons.push(<EnableAutoRenew domain={domain} refetch={refetch} />);
      }
    }

    if(domain.ordered) {
      const dnsButton = domain.custom_dns_servers ? <Link to={`/domains/${domain.id}/dns_servers`} className="btn btn-light"><FaIcon name="globe" /> DNS-Server ändern</Link> : <Link to={`/domains/${domain.id}/records`} className="btn btn-light"><FaIcon name="globe" /> DNS verwalten</Link>;

      return (
        <>
          {buttons}

          <BasicDropdown direction="right" button={dnsButton}>
            <Link to={`/domains/${domain.id}/transfer_out`} className="dropdown-item"><FaIcon name="wrench" /> AuthInfo anfordern</Link>
          </BasicDropdown>
        </>
      )
    }

    return buttons;
  } else if (domain.setup_before_transfer_allowed) {
    if(domain.custom_dns_servers)
      return <Link to={`/domains/${domain.id}/dns_servers`} className="btn btn-light"><FaIcon name="globe" /> DNS-Server ändern</Link>
    else
      return <Link to={`/domains/${domain.id}/records`} className="btn btn-light"><FaIcon name="globe" /> DNS verwalten</Link>;
  }

  return null;
}

const DisableAutoRenew = ({domain, refetch}) => {
  const { isLoading, mutate } = useMutation((id) => {
    return $.ajax({
      url: `/usercp/domains/${id}/auto_renew`,
      type: 'POST',
      data: { enabled: false },
      dataType: 'json'
    });
  }, {
    onSuccess: () => refetch(),
    onError: (error) => {
      errorToToast(error, "beim Deaktivieren der automatischen Verlängerung");
    }
  });

  const handleClick = (e) => {
    e.preventDefault();
    mutate(domain.id);
  }

  return (
    <button className="btn btn-light btn-small" onClick={handleClick} disabled={isLoading}>
      <FaIcon name="times" /> automatische Verlängerung deaktivieren
    </button>
  )
}

const CancelIncludedDomainModal = ({domain, onConfirm, closeModal, isLoading }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onConfirm();
  }

  return (
    <Modal isOpen onRequestClose={closeModal} contentLabel="Inklusiv-Domain kündigen">
      <ModalHeader title="Inklusiv-Domain kündigen" onRequestClose={closeModal} />

      <div className="modal-body">
        <p>
          Möchtest du die Domain wirklich aus dem Paket entfernen? Die Domain wird am {dayjs(domain.registered_until).format('L')} ablaufen oder kann alternativ manuell verlängert werden.
        </p>

        <p className="text-warning">
          Bitte beachte: Du kannst erst nach Ablauf dieser Domain eine neue Domain in Dein Webhosting-Paket aufnehmen.
        </p>

        <table className="table">
          <tbody>
            <tr>
              <td>Domain</td>
              <td>{domain.unicode_fqdn}</td>
            </tr>
            <tr>
              <td>Ablaufdatum</td>
              <td>{dayjs(domain.registered_until).format('L')}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="modal-footer">
        <button className="btn btn-secondary" onClick={handleClick} disabled={isLoading}><FaIcon name='times' loading={isLoading} /> Inklusiv-Domain kündigen</button>
      </div>
    </Modal>
  )
}

const CancelIncludedDomain = ({domain, refetch}) => {
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const { isLoading, mutate } = useMutation((id) => {
    return $.ajax({
      url: `/usercp/domains/${id}`,
      type: 'DELETE',
      dataType: 'json'
    });
  }, {
    onSuccess: () => refetch(),
    onError: (error) => {
      errorToToast(error, "beim Entfernen der Domain aus dem Paket");
    }
  });

  const handleClick = (e) => {
    e.preventDefault();
    setConfirmationOpen(true);
  }

  const onConfirm = () => {
    mutate(domain.id);
  }

  return (
    <>
      <button className="btn btn-light btn-small" onClick={handleClick} disabled={isLoading}>
        <FaIcon name="times" /> Inklusiv-Domain kündigen
      </button>

      {confirmationOpen && <CancelIncludedDomainModal isLoading={isLoading} domain={domain} onConfirm={onConfirm} closeModal={() => setConfirmationOpen(false)} />}
    </>
  )
}

const PaymentMethodSelectModal = ({ closeModal }) => {
  return (
    <Modal isOpen onRequestClose={closeModal} contentLabel="Zahlungsmethode erforderlich">
      <ModalHeader title="Zahlungsmethode erforderlich" onRequestClose={closeModal} />

      <div className="modal-body">
        <p>
          Um die automatische Verlängerung zu aktivieren ist eine Standard-Zahlungsmethode erforderlich. Bitte hinterlege eine SEPA-Bankverbindung, eine Kreditkarte oder ein PayPal-Konto, das wir automatisch belasten können.
        </p>

        <div className="list-group list-group-flush">
          <Link to="/payment_methods/new/credit_card" className="list-group-item">Kreditkarte</Link>
          <Link to="/payment_methods/new/direct_debit" className="list-group-item">SEPA-Lastschrift</Link>
          <Link to="/payment_methods/new/paypal" className="list-group-item">PayPal</Link>
        </div>
      </div>
    </Modal>
  )
}

const ConfirmationModal = ({domain, onConfirm, closeModal }) => {
  const [loading, setLoading] = React.useState(false);

  const handleClick = (e) => {
    setLoading(true);
    e.preventDefault();
    onConfirm();
  }

  return (
    <Modal isOpen onRequestClose={closeModal} contentLabel="Automatische Verlängerung aktivieren">
      <ModalHeader title="Automatische Verlängerung aktivieren" onRequestClose={closeModal} />

      <div className="modal-body">
        <p>
          Mit der Aktivierung der automatischen Verlängerung wird die Domain automatisch 14 Tage vor Ablauf verlängert. Es wird der zum Zeitpunkt der Verlängerung gültige Preis laut unserer regulären Preisliste berechnet und die aktive Standard-Zahlungsmethode des Kundenkontos belastet.
        </p>

        <table className="table">
          <tbody>
            <tr>
              <td>Domain</td>
              <td>{domain.unicode_fqdn}</td>
            </tr>
            <tr>
              <td>Verlängerung</td>
              <td>{dayjs(domain.auto_renew_date).format('L')}</td>
            </tr>
            <tr>
              <td>aktueller Preis</td>
              <td>{format_fractional_price(domain.auto_renew_price)} (inkl. USt)</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="modal-footer">
        <button className="btn btn-secondary" onClick={handleClick} disabled={loading}><FaIcon name='shopping-cart' loading={loading} /> zahlungspflichtig bestellen</button>
      </div>
    </Modal>
  )
}

const EnableAutoRenew = ({ domain, refetch }) => {
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [paymentMethodSelectOpen, setPaymentMethodSelectOpen] = React.useState(false);

  const handleStart = (e) => {
    e.preventDefault();

    if (domain.payment_method_available) {
      setConfirmationOpen(true);
    } else {
      setPaymentMethodSelectOpen(true);
    }
  }

  const { isLoading, mutate } = useMutation((id) => {
    return $.ajax({
      url: `/usercp/domains/${id}/auto_renew`,
      type: 'POST',
      data: { enabled: true },
      dataType: 'json'
    });
  },
  {
    onError: (error) => {
      errorToToast(error, "beim Aktivieren der automatischen Verlängerung");
    },
    onSuccess: () => {
      refetch();
    }
  });

  const onConfirm = () => {
    mutate(domain.id);
  }

  return (
    <>
      <button className="btn btn-light btn-small" onClick={handleStart} disabled={isLoading}>
        <FaIcon name="repeat" /> automatisch verlängern
      </button>
      {confirmationOpen && <ConfirmationModal domain={domain} onConfirm={onConfirm} closeModal={() => setConfirmationOpen(false)} />}
      {paymentMethodSelectOpen && <PaymentMethodSelectModal domain={domain} closeModal={() => setPaymentMethodSelectOpen(false)} />}
    </>
  )
}

const RestoreDomainBanner = ({domain, onRestore}) => {
  // use tanstack react-query useMutation for a POST to /usercp/domains/:id/restore
  // on success, we receive an order_id as data and show the
  // <PayButton order_id={order.id} autostart btnClass="btn btn-light" onSuccess={onPaymentSuccess} successElement={<button className="btn btn-light" disabled><FaIcon name="check text-success" /> bezahlt</button>} />

  const [orderId, setOrderId] = React.useState(null);
  const { isLoading, mutate } = useMutation((id) => {
    return $.ajax({
      url: `/usercp/domains/${id}/restore`,
      type: 'POST',
      dataType: 'json'
    });
  },
  {
    onMutate: () => {
      setOrderId(null);
    },
    onError: (error) => {
      errorToToast(error, "beim Wiederherstellen der Domain");
    },
    onSuccess: (data) => {
      setOrderId(data.order_id);
    }
  });

  return (
    <>
      <div className="alert alert-danger">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <strong>Domain in Redemption Grace Period</strong>
            {' '}
            Die Domain wurde am {dayjs(domain.deleted_at).format('L LT')} gelöscht und ist wiederherstellbar bis zum {dayjs(domain.restorable_until).format('L LT')}. Der Preis für die Wiederherstellung beträgt {format_fractional_price(domain.restore_price)} (inkl. USt) und enthält die Verlängerung der Domain um ein Jahr.
          </div>

          <button type="button" onClick={() => mutate(domain.id)} className="btn btn-light text-nowrap" disabled={isLoading}>{isLoading && <div className="css-spinloader inline"></div>} wiederherstellen</button>
        </div>
      </div>

      {orderId && <PayButton order_id={orderId} autostart btnClass="d-none" onSuccess={onRestore} successElement={<button className="btn btn-light" disabled><FaIcon name="check text-success" /> bezahlt</button>} />}
    </>
  )
}

const Show = (props) => {
  const id = props.match.params.id;
  const [waitingForRestore, setWaitingForRestore] = React.useState(false);

  const { data, isLoading, isError, error, refetch } = useQuery(['domain', id], () => fetchData(id), {
    keepPreviousData: true,
    refetchInterval: (data) => (data?.domain?.status == 'registration_pending' || (waitingForRestore && data.domain?.status == 'deleted') || data?.domain?.status == 'restoring' ? 5000 : false)
  });

  const onRestore = () => {
    setWaitingForRestore(true);
  }

  if(isLoading) {
    return <>
      <PageHeader text="Domain ..." back_url="/domains">Domain <SkeletonLoading /></PageHeader>

      <LoadingTableSpinner />
    </>
  }

  if(isError) {
    return errorToComponent(error);
  }

  const domain = data.domain;

  return (
    <>
      <PageHeader text={`Domain ${domain.unicode_fqdn}`} buttons={<Buttons refetch={refetch} domain={domain} />} back_url="/domains" />

      {domain.in_dns_propagation_phase && (
        <div className="alert alert-info">
          <strong>Hinweis!</strong>
          {' '}
          Die Domain wurde erfolgreich am {dayjs(domain.registered_at).format('L LT')} registriert. Bis die Änderungen auf allen DNS-Servern wirksam werden können bis zu 3 Stunden vergehen, wir bitten daher noch um ein wenig Geduld.
        </div>
      )}

      {domain.backorder_pending && domain.backorder_error && (
        <div className="alert alert-warning">
          <strong>Fehler!</strong>
          {' '}
          Bei der Registrierung der Domain ist ein Fehler aufgetreten, der in Kürze von unserem Team bearbeitet wird. Wir bitten um ein wenig Geduld.
        </div>
      )}

      {domain.backorder_pending && !domain.backorder_error && !domain.need_auth_info && (
        <div className="alert alert-info">
          <strong>Hinweis!</strong>
          {' '}
          Diese Domain wurde gerade erstellt und wird innerhalb der nächsten Minuten automatisch registriert.
        </div>
      )}

      {domain.mode == 'transfer_in' && !domain.auth_info && (
        <div className="alert alert-warning">
          <strong>AuthInfo für Umzug erforderlich!</strong>
          {' '}
          Um den Transfer der Domain zu starten gib bitte den AuthCode ein. <Link to={`/domains/${domain.id}/auth_info`} className="alert-link">AuthInfo eingeben</Link>
        </div>
      )}

      {(waitingForRestore || domain.status == 'restoring') && (
        <div className="alert alert-info">
          Die Domain wird derzeit bei der Registry wiederhergestellt. Dies kann abhängig von der Domain-Endung zwischen mehreren Minuten und einem Arbeitstag dauern.
        </div>
      )}

      {domain.deleted && domain.restorable && !waitingForRestore && (
        <RestoreDomainBanner domain={domain} onRestore={onRestore} />
      )}

      <div className="row">
        <div className='col-md-6'>
          <DetailsSection title="Übersicht">
            <DetailsRow title="Status:">
              {domain.status == 'registration_pending' || waitingForRestore ? <div className="css-spinloader d-inline-block ml-0 mr-1"></div> : null}
              <Status item={domain} refreshCb={() => refetch()} />
            </DetailsRow>

            <DetailsRow title="Registrierung/Transfer:">
              {domain.mode == 'create' ? 'Neuregistrierung' : 'Transfer'}
            </DetailsRow>

            {domain.mode == 'transfer_in' && (
              <DetailsRow title="AuthInfo:">
                {domain.auth_info || '\u2014'}
              </DetailsRow>
            )}

            {domain.ordered && <DetailsRow title="Bestelldatum:">
              {dayjs(domain.ordered_at).format('L LT')}
            </DetailsRow>}

            {domain.registered && <DetailsRow title="Konnektierung:">
              {dayjs(domain.registered_at).format('L LT')}
            </DetailsRow>}

            <DetailsRow title="Verlängerung:">
              <AutoRenewStatus auto_renew={domain.auto_renew} in_subscription={domain.in_subscription} />
            </DetailsRow>

            {domain.in_subscription ? (
              <DetailsRow title={domain.auto_renew_mode == 'inclusive_expire' ? "Ablaufdatum:" : 'Datum der Verlängerung:'}>
                {dayjs(domain.registered_until).format('L')}
              </DetailsRow>
            ) : (
              domain.auto_renew ? (
                <DetailsRow title="Datum der Verlängerung:">
                  {dayjs(domain.auto_renew_date).format('L')}
                </DetailsRow>
              ) : (
                <DetailsRow title="Ablaufdatum:">
                  {dayjs(domain.registered_until).format('L')}
                </DetailsRow>
              )
            )}

            <DetailsRow title="DNS-Server:">
              {domain.custom_dns_servers ? (
                domain.dns_servers.map((item) => (
                  <>
                    {item}
                    <br />
                  </>
                ))
              ) : (
                <span className="badge badge-secondary">lima-city</span>
              )}
            </DetailsRow>

            <DetailsRow title="DNSSEC:">
              {domain.dnssec ? <span className="badge badge-success">aktiv</span> : <span className="badge badge-danger">inaktiv</span>}
            </DetailsRow>
          </DetailsSection>

          {domain.auto_renew && !domain.in_subscription && (
            <DisableAutoRenew domain={domain} refetch={refetch} />
          )}

          {domain.auto_renew && domain.in_subscription && (
            <CancelIncludedDomain domain={domain} refetch={refetch} />
          )}
        </div>

        <div className='col-md-6'>
          {domain.owner_contact?.id && (
            <>
              <HandleDetails handle={domain.owner_contact} />

              <Link to={`/handles/${domain.owner_contact.id}/edit`} className="btn btn-light mb-3"><FaIcon name="address-card" /> Adresse bearbeiten</Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Show;
