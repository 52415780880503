import {FaIcon} from 'client/js/util/layout_utils';
import {toast} from 'react-toastify';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {parseXHRError, errorToToast} from 'client/js/util/rest_utils';
import {ControlGroup} from 'client/js/util/form_utils';
import {Modal, ModalHeader} from 'client/js/util/modal';

const EditableHostname = (props) => {
  const [name, setName] = React.useState(props.hostname);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const queryClient = useQueryClient();

  const requestClose = () => {
    setOpen(false);
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return $.ajax({ url: `/usercp/virtual_machines/${props.id}`, type: 'PUT', data: { hostname: name }, dataType: 'json' });
    },
    onMutate: () => {
      setErrorMessage(null);
    },
    onSuccess: () => {
      toast.success('Hostname aktualisiert');
      setOpen(false);

      queryClient.invalidateQueries({ queryKey: ['virtual_machines', props.id] });

    },
    onError: (xhr) => {
      const {isValidationError, validationErrors} = parseXHRError(xhr);

      if(isValidationError) {
        setErrorMessage(validationErrors['hostname']);
      } else {
        setErrorMessage(null);
        errorToToast(xhr, "beim Aktualisieren des Hostnames");
      }
    }
  })

  return (
    <>
      <a href="#edit" className="inplace-editable" onClick={(e) => { e.preventDefault(); setOpen(true); }}>{props.hostname}</a>

      <Modal isOpen={open} onRequestClose={requestClose} contentLabel="Hostname ändern">
        <form onSubmit={(e) => { e.preventDefault(); mutate(); }}>
          <ModalHeader title="Hostname ändern" onRequestClose={requestClose} />

          <div className="modal-body">
            <p>
              Der Hostname ist der Name, unter welchem der Server geführt wird.
              Wenn der Hostname auf einen Domainnamen eingestellt wird, wird dieser automatisch als Reverse-DNS-Eintrag gesetzt.
            </p>
            <ControlGroup name="name" label="Hostname" required errors={errorMessage}>
              <input
                type="text"
                disabled={isLoading}
                className={errorMessage?.length ? "form-control is-invalid" : "form-control"}
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </ControlGroup>
          </div>

          <div className="modal-footer">
            <button className="btn btn-light" type="button" onClick={requestClose} disabled={isLoading}>abbrechen</button>
            <button className="btn btn-secondary" type="submit" disabled={isLoading}><FaIcon name='floppy-o' loading={isLoading} /> speichern</button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default EditableHostname;
