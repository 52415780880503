import PropTypes from "prop-types";
import {PageHeader} from 'client/js/util/layout_utils';
import {RadioCollectionInput} from 'client/js/util/form_utils';
import {withLoading} from 'client/js/util/rest_utils';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import Callout from 'client/js/util/callout';

const Form = (props) => {
  const options = props.data.map((item) => [item.name, item.label]);

  return <div>
      <RadioCollectionInput required name="version" label="PHP-Version" options={options} />
    </div>;
}

Form.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired
};

const SuccessCallout = () => (<div>
    <PageHeader text="PHP-Version ändern" />

    <Callout calloutClass="success" title="PHP-Version geändert">
      <p>Die PHP-Version wurde erfolgreich geändert. Die Änderung wird in wenigen Sekunden aktiv.</p>

      <p>Bitte beachte, dass nicht alle Skripte mit jeder PHP-Version kompatibel sind. Sollten "Fehler 500 (Internal Server Error)" auftreten prüfe bitte, ob das Ändern der PHP-Version der Grund dafür ist.</p>
    </Callout>
  </div>)

const Page = (props) => {
  let defaults = {};
  let v = props.data.filter((item) => (item.active));

  if(v.length)
    defaults.version = v[0].name;

  return <GenericInteraction namespace="usercp"
                                    resource_name="php_version"
                                    action="change"
                                    method="patch"
                                    defaults={defaults}
                                    form_url="/usercp/php_version"
                                    back_url={false}
                                    breadcrumbs={() => (null)}
                                    success_splash={() => (<SuccessCallout />)}
                                    form_component={() => (<Form data={props.data} />)} />
}

Page.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired
};

const PageContainer = withLoading(Page);
const PagerWrapper = () => (<PageContainer url="/usercp/php_version.json" key_name="versions" />)

export default PagerWrapper;
