import {Route, Switch} from 'react-router-dom';
import Create from './create';
import Password from './password';
import Show from './show';

const ResourceRouter = () => (
    <Switch>
      <Route path="/mailboxes/new" exact component={Create} />
      <Route path="/mailboxes/:id/password" exact component={Password} />
      <Route path="/mailboxes/:id" component={Show} />
    </Switch>);

export default ResourceRouter;
