// This is a simple page that loads /usercp/archive.json to check
// if the user  account is currently archived

// If yes (archived is true), display a message and a button to unarchive
// by POSTing to /usercp/archive/restore

// After that, reload data every X seconds to check if the account is unarchived

import {useResource, errorToToast} from 'client/js/util/rest_utils';
import {FaIcon} from 'client/js/util/layout_utils';
import Callout from "client/js/util/callout";
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

const Index = () => {
  const { data, component, refetch } = useResource(['archived'], '/usercp/archive', {
    refetchInterval: (data) => data?.archived && data?.restore_running_since ? 5000 : false
  })

  // use mutation for logout
  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return $.post('/usercp/archive/restore');
    },
    onSuccess: () => {
      refetch();
      toast.success('Der Account wird jetzt wiederhergestellt!');
    },
    onError: (xhr) => {
      errorToToast(xhr, "beim Wiederherstellen");
    }
  })

  const handleRestore = (e) => {
    e.preventDefault();
    mutate();
  }

  if(component) return component

  if(data.archived && data.restore_running_since) {
    return (
      <>
        <Callout title="Account wird wiederhergestellt..." calloutClass="info">
          <p>Der Account wird gerade wiederhergestellt. Dies kann einige Minuten dauern, je nach Größe Deiner Webspace-Inhalte.</p>

          <button className="btn btn-secondary" disabled={true} onClick={handleRestore}><FaIcon name="download" loading={true} /> Wiederherstellen</button>
        </Callout>
      </>
    )
  }

  if(data.archived && !data.restore_running_since) {
    return (
      <>
        <Callout title="Dein Account ist derzeit archiviert" calloutClass="warning">
          <p>Um Speicherplatz freizugeben ist Dein Account in das Archiv verschoben worden. Bitte blicke den Button, um ihn wieder zu aktivieren. Dies kann einige Minuten dauern, je nach Größe Deiner Webspace-Inhalte.</p>

          <button className="btn btn-secondary" disabled={isLoading} onClick={handleRestore}><FaIcon name="download" loading={isLoading} /> Wiederherstellen</button>
        </Callout>
      </>
    )
  }

  if(!data.archived && data.restore_running_since) {
    return (
      <>
        <Callout title="Account wiederhergestellt" calloutClass="success">
          <p>Dein Account wurde aktiviert und kann wieder normal genutzt werden!</p>
        </Callout>
      </>
    )
  }

  return <Redirect to='/' />
}

export default Index;
