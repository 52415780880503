import {GenericReactForm, SubmitButton, SelectCollectionInput, FormActions} from 'client/js/util/form_utils';
import Callout from 'client/js/util/callout';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

const SuccessMessage = function() {
  return <Callout calloutClass="success" title="CD-ISO geändert">
      <p>Das CD-ISO wurde erfolgreich geändert. Die neue CD steht ab sofort in der virtuellen Maschine zur Verfügung.</p>
    </Callout>;
}

class ResizeForm extends React.Component {
  state = {}

  componentDidMount() {
    this.ajax = $.get(`/usercp/virtual_machines/${this.props.match.params.id}/templates.json`)

    this.ajax.done((data) => {
      this.setState({templates: data.templates, current_template_id: data.current_template_id});
    })
  }

  dispatch = (action) => {
    switch (action) {
      case "form:success":
      this.setState({success: true})
      break;
    }
  }

  render() {
    if(!this.state.templates)
      return <LoadingTableSpinner />;

    if(this.state.success)
      return <SuccessMessage />;

    return (
      <GenericReactForm defaults={{template_id: this.state.current_template_id}} namespace="usercp_cloud_change_iso" url={`/usercp/virtual_machines/${this.props.match.params.id}/change_iso.json`} dispatch={this.dispatch}>
        <SelectCollectionInput clearable name="template_id" label="CD-Image" options={this.state.templates.map((t) => [t.id, t.title])} />

        <FormActions>
          <SubmitButton icon="compact-disc" text="CD-ISO wechseln" />
        </FormActions>
      </GenericReactForm>
      );
  }
}

export default ResizeForm;
