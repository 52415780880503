import GenericIndex from 'client/js/resellercp/generic_resource/index';
import { Dropdown } from 'client/js/usercp/table_utils';
import { FaIcon } from 'client/js/util/layout_utils';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

const PaymentMethodState = ({ item }) => {
  if(item.blocked)
    return <span className="badge badge-danger">gesperrt {!!item.default && '(Standard)'}</span>;

  if(item.type == 'direct_debit' && !item.verified)
    return <Link to={`/payment_methods/${item.id}`} className="badge badge-warning">unbestätigt {!!item.default && '(Standard)'}</Link>;

  if(item.default)
    return <span className="badge badge-primary">Standard</span>;

  return <span className="badge badge-secondary">aktiv</span>;
}

const DestroyModal = (props) => {
  return <Modal isOpen={props.open} className="modal fade in show d-block" contentLabel="Zahlungsmethode löschen">
   <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Zahlungsmethode löschen</h5>
            <button type="button" className="close" onClick={props.abort} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {props.loading ?
              <span className="css-spinloader fullpage-spinner"></span> :
              <p>Soll die Zahlungsmethode wirklich gelöscht werden?</p>}
          </div>

          <div className="modal-footer">
            <button className="btn btn-danger" onClick={props.confirm} disabled={props.loading}><FaIcon name={props.loading ? 'spinner fa-spin' : 'trash-o'}/> löschen</button>
          </div>
        </div>
      </div>
  </Modal>
}

class DestroyRow extends React.Component {
  state = {working: false, open: false}

  startDestroy = (e) => {
    e.preventDefault();
    this.setState({open: true});
  }

  abort = (e) => {
    e.preventDefault();

    if(this.state.working)
      return
    this.setState({open: false});
  }

  destroy = (e) => {
    e.preventDefault();
    this.setState({working: true});

    $.ajax({url: `/usercp/payment_methods/${this.props.id}`, type: 'DELETE'})
      .done(() => {
        this.setState({working: false});
        this.props.refreshCb();
      })
      .fail(() => {
        this.setState({failed: true, working: false});
      });
  }

  render() {
    return (
      <a href="#delete" className="dropdown-item" onClick={this.startDestroy}>
        <span className="text-danger">löschen</span>
        <DestroyModal open={this.state.open}
                      loading={this.state.working}
                      confirm={this.destroy}
                      abort={this.abort} />
      </a>);
  }
}

class SetDefault extends React.Component {
  do = (e) => {
    //e.preventDefault()
    $.post(`/usercp/payment_methods/${this.props.id}/default`).done(
      () => this.props.refreshCb()
    )
  }

  render() {
    return <a href="#" className='dropdown-item' onClick={this.do}>als Standard setzen</a>
  }
}

const PaymentMethodActions = ({ item, refreshCb }) => (
  <Dropdown item={item} refreshCb={refreshCb}>
    {item.type == 'credit_card' && <Link className="dropdown-item" to={`/payment_methods/${item.id}/edit`}>Ablaufdatum aktualisieren</Link>}
    {!item.default && <SetDefault id={item.id} refreshCb={refreshCb} />}
    <DestroyRow id={item.id} refreshCb={refreshCb} />
  </Dropdown>
)

const Row = ({ item, refreshCb }) => {
  return (
    <tr>
      <td>{item.name} {item.type == 'direct_debit' && <> <small className="text-muted">Mandatsreferenz: {item.mandate_id}</small></>}</td>
      <td><PaymentMethodState item={item} /></td>
      <td>{new Date(item.created_at).toLocaleString()}</td>
      <td><PaymentMethodActions item={item} refreshCb={refreshCb} /></td>
    </tr>
  );
};

const Header = () => (
  <tr>
    <th>Name</th>
    <th>Status</th>
    <th>Erstellt am</th>
    <th>Aktionen</th>
  </tr>
);

const dropdown = (
  <Dropdown direction="right" buttonClassName="btn btn-light" text={<>Zahlungsmethode hinzufügen <FaIcon name="chevron-down" /></>}>
    <Link to="/payment_methods/new/credit_card" className="dropdown-item">Kreditkarte hinzufügen</Link>
    <Link to="/payment_methods/new/direct_debit" className="dropdown-item">SEPA-Bankverbindung hinzufügen</Link>
    <Link to="/payment_methods/new/paypal" className="dropdown-item">PayPal hinzufügen</Link>
  </Dropdown>
  )

const Index = () => (
  <GenericIndex
    namespace="usercp"
    back_url="/settings"
    resource_name="payment_methods"
    buttons={dropdown}
    table_header={Header}
    row={Row} />
);

export default Index;
