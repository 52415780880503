import {Card, DetailsSection, DetailsRow, FaIcon} from 'client/js/util/layout_utils';
import {useResource} from 'client/js/util/rest_utils';
import {BooleanInput, GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';
import {FormErrors} from 'client/js/util/form_tags';
import ExternalLink from 'client/js/util/external_link';
import Callout from 'client/js/util/callout';
import {Link} from 'react-router-dom';
import { errorToToast } from '../../util/rest_utils';

class EnableStaging extends React.Component {
  state = {loading: false, update: true}

  dispatch = (action) => {
    if(action == 'form:success') {
      this.setState({creating: true});
      setTimeout(this.tick, 2000);
    }
  }

  tick = () => {
    $.get(`/usercp/wordpress/${this.props.app_id}.json`).done((data) => {
      if(data.wordpress.staging) {
        this.props.refreshCb();
      } else {
        setTimeout(this.tick, 2000);
      }
    }).fail(() => this.setState({loading: false}));
  }

  render() {
    return <Card title="Staging">
        {this.state.loading || this.state.creating ?
          <div className="card-body loading">
          <div className="css-spinloader"></div>
          <div className="text-center"><strong>{this.state.loading ? 'Laden...': 'Warte auf Staging-Umgebung...' }</strong></div>
        </div> :
        <div className="card-body">
          <p className="card-text">Die WordPress-Staging-Umgebung ist nicht aktiv. Du kannst mit der Staging-Funktion eine Kopie Deiner WordPress-Installation (Datenbank und Dateien) erstellen um gefahrlos Änderungen an der Kopie vorzunehmen. Wenn Du mit den Änderungen zufrieden bist, schalte die Staging-Kopie live und überschreibe damit die Live-Webseite mit Deiner Kopie, um die Änderungen zu aktivieren.</p>

          <GenericReactForm namespace="staging" defaults={{update: 1}} url={`/usercp/wordpress/${this.props.app_id}/start_staging`} dispatch={this.dispatch}>
            <BooleanInput name="update" label="WordPress- & Plugins-Updates durchführen, sofern vorhanden"/>

            <FormErrors />

            <FormActions>
              <SubmitButton icon="copy" text="Staging-Kopie erstellen" />
            </FormActions>
          </GenericReactForm>
        </div>}
      </Card>;
  }
}

class StagingInfo extends React.Component {
  state = {loading: false}

  delete = (e) => {
    e.preventDefault();
    this.setState({deleting: true});

    $.post(`/usercp/wordpress/${this.props.app_id}/drop_staging`).done(() => {
      setTimeout(this.tick, 2000);
    }).fail((response) => {
      errorToToast(response, 'beim Löschen der Staging-Kopie');

      this.setState({deleting: false})
    });
  }

  go_live = (e) => {
    e.preventDefault();
    this.setState({going_live: true});

    $.post(`/usercp/wordpress/${this.props.app_id}/go_live`).done(() => {
      setTimeout(this.tick, 2000);
    }).fail((response) => {
      errorToToast(response, 'beim Live-Schalten der Staging-Kopie');

      this.setState({going_live: false});
    });
  }

  tick = () => {
    $.get(`/usercp/wordpress/${this.props.app_id}.json`).done((data) => {
      if(!data.staging) {
        this.props.refreshCb();
      } else {
        setTimeout(this.tick, 2000);
      }
    }).fail(() => this.setState({loading: false}));
  }

  render() {
    let data = this.props.data.staging;

    if(data.is_staging)
      return <Callout calloutClass="info" title="Staging-Kopie">
        <p>Dies ist die Staging-Kopie der WordPress-Installation unter "{data.live_path}". Um die Staging-Kopie zu entfernen oder live zu schalten, öffne bitte die <Link to={`/wordpress/${btoa(data.live_path)}/staging`}>Staging-Verwaltung dieser WordPress-Installation</Link>.</p>
      </Callout>;

    if(this.state.deleting || this.state.creating || this.state.going_live) {
      return (
        <>
          <div className="css-spinloader"></div>
          <div className="text-center"><strong>{this.state.deleting ? 'Löschen...': 'Going live...!' }</strong></div>
        </>
      )
    }

    return (
      <>
        <DetailsSection title="Staging">
          <DetailsRow title="Adresse"><ExternalLink href={data.staging_url} text={data.staging_hostname} /></DetailsRow>
          <DetailsRow title="Kopie erstellt" text={dayjs(data.created_at).format('L LT')} />
          <DetailsRow title="Pfad" text={data.staging_path} />
          <DetailsRow title="Datenbank" text={data.staging_db_name} />
        </DetailsSection>

        <div className="btn-group">
          <button className="btn btn-secondary" onClick={this.go_live}><FaIcon name="thumbs-up" /> live schalten</button>
          <button className="btn btn-light text-danger" onClick={this.delete}><FaIcon name="trash" /> löschen</button>
        </div>
      </>
    );

  }
}

const StagingPage = ({app_id}) => {
  const { data, component, refetch } = useResource(['wordpress', app_id, 'staging'], `/usercp/wordpress/${app_id}/staging`);

  if(component) {
    return component;
  }

  if(data.staging)
    return <StagingInfo app_id={app_id} refreshCb={refetch} data={data} />;

  return <EnableStaging app_id={app_id} refreshCb={refetch} />;
}

export default StagingPage;
