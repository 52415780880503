import {Route, Switch} from 'react-router-dom';
import Index from './index';

const ResourceRouter = () => (
    <Switch>
      {/* <Route path="/customers/new" exact component={Create} />
      // <Route path="/customers/:id/edit" component={Edit} />
      // <Route path="/customers/:id" component={Show} />
      <Route path="/ssh_accounts/authorized_keys/edit" exact component={EditAuthorizedKeys} />*/}
      <Route path="/memcached" exact component={Index} />
    </Switch>);

export default ResourceRouter;
