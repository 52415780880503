import { useQuery } from '@tanstack/react-query';
import Callout from 'client/js/util/callout';
import { ControlGroup, RadioCollectionInput, StringInput } from 'client/js/util/form_utils';
import WebsitePathInput from "client/js/util/inputs/websites_path_input";
import WebspacePathInput from "client/js/util/inputs/webspace_path_input";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

const RedirectSection = function() {
  let hint = <span>Diese Einstellung ist im Wesentlichen nur für Suchmaschinen wichtig. <a href="/hilfe/was-ist-der-unterschied-zwischen-temporaerer-und-permanenter-umleitung" target="_blank">Hilfe</a></span>;
  let url_hint = <span>Benutze $1 als Platzhalter für die "Query URI":<br/>
      <strong>Beispiel 1</strong>: https://www.zieldomain.de/$1<br/>
      https://www.example.com/ => https://www.zieldomain.de/<br/>
      https://www.example.com/foobar.php => https://www.zieldomain.de/foobar.php<br/><br/>
      <strong>Beispiel 2</strong>: https://www.zieldomain.de/<br/>
      https://www.example.com/ => https://www.zieldomain.de/<br/>
      https://www.example.com/foobar.php => https://www.zieldomain.de/'
        </span>;

  return <>
      <RadioCollectionInput name="redirect_status" label="Art der Weiterleitung" hint={hint} required options={[["301", 'permanente Weiterleitung (HTTP 301)'], ['302', 'temporäre Weiterleitung (HTTP 302)']]} />
      <StringInput name="redirect_url" type="url" label="Ziel der Weiterleitung" required hint={url_hint} placeholder="https://www.lima-city.de/" />

    </>
}

const NoSubscriptionCallout = () => {
  return (
    <Callout title="Kein Webhosting-Paket gefunden" calloutClass="warning">
      <p>Es wurde kein Webhosting-Paket gefunden, aus diesem Grund ist es nicht möglich eine Webseite zu hinterlegen. Du kannst zu diesem, Zeitpunkt nur eine Weiterleitung hinterlegen.</p>

      <p>Du kannst <Link to="/webhosting">ein Webhosting-Paket bestellen oder kostenlos testen</Link>.</p>
    </Callout>
  )
}

const fetchData = async () => {
  return await $.ajax({
    url: '/usercp/webhosting',
    method: 'GET',
    dataType: 'json',
  });
}

const WebsiteContentInput = (props, context) => {
  let opts = { 'same_path': 'den gleichen Inhalt anzeigen wie eine andere Webseite',
    'path': 'ein vorhandenes Verzeichnis auf dem Webspace auswählen',
    'redirect': 'Weiterleitung' };

  if(props.installerRedirect) {
    opts['install'] = 'eine neue Software installieren (Weiterleitung zum Software-Installer)';
  }

  let selected_content = null;
  if(context.formData && context.formData['content_strategy'])
    selected_content = context.formData['content_strategy'];

  const { data, isLoading } = useQuery(['webhosting_status'], fetchData, {
    keepPreviousData: true,
  })

  if(isLoading) {
    return (
      <ControlGroup name="content_strategy" type="collection" required label="Webseiten-Inhalt">
        <div className="css-spinloader inline"></div>
      </ControlGroup>
    );
  }

  if(!data.subscription) {
    opts = { 'redirect': 'Weiterleitung' };
  }

  return <>
    {!data.subscription && <NoSubscriptionCallout />}

    <RadioCollectionInput required name="content_strategy" label="Webseiten-Inhalt" options={opts} />

    {selected_content == 'same_path' && <WebsitePathInput />}
    {selected_content == 'path' && <WebspacePathInput name="path" />}
    {selected_content == 'redirect' && <RedirectSection />}
  </>
}

WebsiteContentInput.contextTypes = {
  formData: PropTypes.object,
};

export default WebsiteContentInput;
