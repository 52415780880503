import PropTypes from 'prop-types';
import {FaIcon} from 'client/js/util/layout_utils';
import { toast } from "react-toastify";
import Callout from 'client/js/util/callout';
import {useResource} from 'client/js/util/rest_utils';

const PluginStatus = (props) => {
  if(props.status == 'active')
    return <span className="badge badge-primary">aktiv</span>;

  if(props.status == 'inactive')
    return <span className="badge badge-secondary">deaktiviert</span>;

  if(props.status == 'installed')
    return <span className="badge badge-secondary">deaktiviert</span>;

  return <span className="badge badge-secondary">{props.status}</span>;
}

const Checker = (props) => {
  return <input className="form-control custom-control-input"
                value={props.value} id={`${props.name}_${props.value}`}
                name={props.name} type="checkbox"
                checked={props.checked && props.checked.includes(props.value)}
                onClick={props.clickSelect} />;
}

Checker.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.string),
};

const TaskStatusNotification = (props) => {
  return <div><span>{props.text}</span> <span className="css-spinloader float-right mr-1"></span></div>;
}

class PluginTable extends React.Component {
  state = {
    checked: [],
    loading: false
  };

  refreshTask = () => {
    $.get(`/usercp/wordpress_tasks/${this.state.task_id}`).done((data) => {
      let status = data.task.status;
      let text = `${i18n_t(`wordpress_toolkit.interactions.doing.${this.state.action}`)} ${i18n_c(`wordpress_toolkit.components.${this.props.key_name}`, this.state.checked.length)}...`

      toast.update(this.toastId, { render: <TaskStatusNotification status={status} text={text} />});

      if(status == 'created' || status == 'running' || status == 'refreshing')
        setTimeout(() => this.refreshTask(), 1000);

      if(status == 'success') {
        text = `${i18n_c(`wordpress_toolkit.components.${this.props.key_name}`, this.state.checked.length)} erfolgreich ${i18n_t(`wordpress_toolkit.interactions.done.${this.state.action}`)}!`
        toast.update(this.toastId, { closeButton: true, render: text, type: toast.TYPE.SUCCESS, autoClose: 10000 });
        this.setState({checked: [], loading: false});
        this.props.clickRefresh();
      }

      if(status == 'failed') {
        text = `${i18n_t(`wordpress_toolkit.interactions.${this.state.action}`)} fehlgeschlagen!`
        toast.update(this.toastId, { closeButton: true, render: text, type: toast.TYPE.ERROR });
        this.setState({checked: [], loading: false});
        this.props.clickRefresh();
      }
    }).fail(() => {

    });
  }

  action = (action, e) => {
    e.preventDefault();
    this.setState({loading: true, action: action});
    let text = `${i18n_t(`wordpress_toolkit.interactions.doing.${action}`)} ${i18n_c(`wordpress_toolkit.components.${this.props.key_name}`, this.state.checked.length)}...`
    this.toastId = toast.info(<TaskStatusNotification text={text} />, {
      autoClose: false,
      closeButton: false
    });

    $.post(`/usercp/wordpress/${this.props.app_id}/components/${action}`,
           {[this.props.key_name]: this.state.checked.map((i) => i.id)})
    .done((data) => {
      this.setState({task_id: data.object.task_id}, () => this.refreshTask());
    }).fail(() => {
      toast.update(this.toastId, { closeButton: true, render: "Error", type: toast.TYPE.ERROR });
      this.setState({loading: false});
    });
  }

  clickSelect = (e, actions) => {
    if(this.state.loading) {
      e.preventDefault();
      return false;
    }
    let checked = e.target.checked;
    let checked_id = e.target.value;
    //console.log(e.target.id, "checked", e.target.checked);
    if(checked) {
      this.setState((prevState) => {
        return {checked: [].concat(prevState.checked, [{id: checked_id, actions: actions}])};
      });
    } else {
      this.setState((prevState) => {
        return {checked: prevState.checked.filter((v) => v.id !== checked_id)};
      });
    }
  }

  render() {
    const all_actions = ['update', 'activate', 'deactivate', 'uninstall']

    let item_actions = this.state.checked.map((i) => i.actions);
    let available_actions = all_actions.filter((action) => {
      return item_actions.every((actions_list) => actions_list.includes(action));
    });

    let checked_ids = this.state.checked.map((i) => i.id);

    return <div className="mb-3">
        <h2 className="mb-3 d-flex">
          <div>
            {this.props.name}
          </div>

          {this.state.checked.length ?
            <div className="btn-group ml-auto">
              <button disabled={this.state.loading || !available_actions.includes('update')} title="aktualisieren" onClick={(e) => this.action('update', e)} className="btn btn-secondary"><FaIcon name="file-o fa-lg" /></button>
              <button disabled={this.state.loading || !available_actions.includes('activate') || (this.props.key_name == 'themes' && this.state.checked.length != 1)} title="aktivieren" onClick={(e) => this.action('activate', e)} className="btn btn-secondary"><FaIcon name="check fa-lg" /></button>
              {this.props.key_name == 'plugins' &&
                <button disabled={this.state.loading || !available_actions.includes('deactivate')} title="deaktivieren" onClick={(e) => this.action('deactivate', e)} className="btn btn-secondary"><FaIcon name="ban fa-lg" /></button>}
              <button disabled={this.state.loading || !available_actions.includes('uninstall')} title="löschen" onClick={(e) => this.action('uninstall', e)} className="btn btn-danger"><FaIcon name="trash-o fa-lg" /></button>
            </div>
            :
            <a href="#" className="ml-auto" onClick={this.props.clickRefresh}><FaIcon name="refresh" /></a>
          }
        </h2>

        <table className="table table-striped table-wordpress-plugins">
          <thead>
            <tr>
              <th>
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" name="select_all" id="all_plugins" type="checkbox" />
                  {' '}
                  <label className="custom-control-label" htmlFor="all_plugins">
                  Name
                  </label>
                </div>
              </th>
              <th>Status</th>
              <th>Version</th>
              <th>Update</th>
            </tr>
          </thead>
          <tbody>
            {this.props.components.map((component) => this.props.row(component, this.clickSelect, checked_ids))}
          </tbody>
        </table>
      </div>
  }
}

PluginTable.propTypes = {
  app_id: PropTypes.string,
  key_name: PropTypes.string
}

const PluginRow = (props) => {
  let plugin = props.plugin;

  let actions = ['uninstall'];

  if(plugin.update_version)
    actions.push('update');

  if(plugin.status == 'active')
    actions.push('deactivate');
  else
    actions.push('activate');

  return <tr key={plugin.name} className={plugin.status == 'inactive' || plugin.status == 'dropin' ? 'text-muted' : null}>
      <td>
        <div className="custom-control custom-checkbox">
          <Checker name="plugin" value={plugin.name} clickSelect={(e) => props.clickSelect(e, actions)} checked={props.checked} />

          <label className="custom-control-label" htmlFor={`plugin_${plugin.name}`}>
            {plugin.status != 'dropin' ? plugin.title : plugin.name}
          </label>
          {' '}
          {plugin.update_version ? <span className="badge badge-warning">Update verfügbar</span> : null}
          {plugin.name == 'wp-cli-login-server' && <em className="text-muted">lima-city Plugin für Admin-Login</em>}
        </div>
      </td>
      <td><PluginStatus status={plugin.status} /></td>
      <td>{plugin.status != 'dropin' ? plugin.version : <FaIcon name="minus" />}</td>
      <td>
        {plugin.update_version ? plugin.update_version : <FaIcon name="minus" />}
      </td>
    </tr>
}

const ThemeRow = (props) => {
  let plugin = props.theme;

  let actions = []
  if(plugin.status != 'active') {
    actions.push('uninstall');
    actions.push('activate');
  }

  if(plugin.update_version)
    actions.push('update');

  return <tr key={plugin.name} className={plugin.status == 'inactive' ? 'text-muted' : null}>
      <td>
        <div className="custom-control custom-checkbox">
          <Checker name="theme" value={plugin.name} clickSelect={(e) => props.clickSelect(e, actions)} checked={props.checked} />

          <label className="custom-control-label" htmlFor={`theme_${plugin.name}`}>
            {plugin.title}
          </label>
          {' '}
          {plugin.update_version ? <span className="badge badge-warning">Update verfügbar</span> : null}
        </div>
      </td>
      <td><PluginStatus status={plugin.status} /></td>
      <td>{plugin.version}</td>
      <td>
        {plugin.update_version ? plugin.update_version : <FaIcon name="minus" />}
      </td>
    </tr>
}

class UpdateBanner extends React.Component {
  state = {
    updating: false,
    hidden: false
  };

  refreshTask = () => {
    $.get(`/usercp/wordpress_tasks/${this.state.task_id}`).done((data) => {
      let status = data.task.status;

      if(status == 'created' || status == 'running' || status == 'refreshing')
        setTimeout(() => this.refreshTask(), 1000);

      if(status == 'success') {
        toast.update(this.toastId, { closeButton: true, render: "WordPress erfolgreich aktualisiert", type: toast.TYPE.SUCCESS, autoClose: 10000 });
        this.setState({hidden: true});
      }

      if(status == 'failed') {
        toast.update(this.toastId, { closeButton: true, render: "Aktualisierung fehlgeschlagen!", type: toast.TYPE.ERROR });
        this.setState({updating: false});
      }
    }).fail(() => {
      toast.update(this.toastId, { closeButton: true, render: "Aktualisierung fehlgeschlagen!", type: toast.TYPE.ERROR });
      this.setState({updating: false});
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({updating: true});

    this.toastId = toast.info(<TaskStatusNotification text="Aktualisiere WordPress..." />, {
      autoClose: false,
      closeButton: false
    });

    $.post(`/usercp/wordpress/${this.props.app_id}/update`)
    .done((data) => {
      this.setState({task_id: data.object.task_id}, () => this.refreshTask());
    }).fail(() => {
      toast.update(this.toastId, { closeButton: true, render: "Error", type: toast.TYPE.ERROR });
      this.setState({updating: false});
    });
  }

  render() {
    if(this.state.hidden)
      return null;

    if(this.props.update && this.props.update.length > 0)
      return <div className="alert alert-danger" style={{lineHeight: '2rem'}}>
            Die WordPress-Version {this.props.version} ist veraltet. Bitte führe ein Update durch!

            <div className="float-right"><button className="btn btn-secondary btn-sm" disabled={this.state.updating} onClick={this.handleClick}>aktualisieren</button></div>
        </div>;

    return null;
  }
}

const StatusSection = (props) => {
  if(!props.status)
    return null;

  return <div>
      <UpdateBanner app_id={props.app_id} update={props.status.core.update} version={props.status.core.version} />

      <PluginTable name="Plugins" clickRefresh={props.clickRefresh}
                   app_id={props.app_id}
                   key_name="plugins"
                   components={props.status.plugins}
                   row={(plugin, clickSelect, checked) => <PluginRow key={plugin.name} plugin={plugin} clickSelect={clickSelect} checked={checked} />} />

      <PluginTable name="Themes" clickRefresh={props.clickRefresh}
                   app_id={props.app_id}
                   key_name="themes"
                   components={props.status.themes}
                   row={(theme, clickSelect, checked) => <ThemeRow key={theme.name} theme={theme} clickSelect={clickSelect} checked={checked} />} />
    </div>;
}

StatusSection.propTypes = {
  app_id: PropTypes.string
}

const ComponentsPage = ({app_id}) => {
  const { data, component, refetch, isPreviousData } = useResource(['wordpress', app_id, 'components'], `/usercp/wordpress/${app_id}/components`, {
    keepPreviousData: true
  });

  if(component) {
    return component;
  }

  const handleRefresh = (e) => {
    e.preventDefault();
    refetch();
  }

  const components = { plugins: data.plugins, themes: data.themes, core: data.core }

  if(data.status == 'error') {
    const { command, output, message } = data;

    return (
      <Callout title="Fehler beim Laden der Plugins und Themes">
        <p>Beim Abruf der Plugins und Themes trat der folgende Fehler auf:</p>
        <code>
          ~$ wp {command}<br/>
          {output == '' ? <em>(Das Kommando gab keine Ausgabe zurück)</em> : output}<br /><br />
          (Hinweis zum Return Code: {message})
        </code>
      </Callout>
    );
  }

  return <StatusSection app_id={app_id} status={components} clickRefresh={handleRefresh} />;
}

export default ComponentsPage;
