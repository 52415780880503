import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown } from 'client/js/usercp/table_utils';
import { FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { useResource, errorToToast, parseXHRError } from 'client/js/util/rest_utils';
import { Modal, ModalHeader } from 'client/js/util/modal'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ControlGroup } from 'client/js/util/form_utils';

const type_to_str = (type) => {
  if(type == 'otp')
    return 'Authenticator-App';

  return 'WebAuthn';
}

const UpdateModal = (props) => {
  const [name, setName] = React.useState(props.name);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const requestClose = () => {
    setOpen(false);
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return $.ajax({ url: `/usercp/authentication_factors/${props.id}`, type: 'PUT', data: { display_name: name }, dataType: 'json' });
    },
    onMutate: () => {
      setErrorMessage(null);
    },
    onSuccess: () => {
      toast.success('Authentifizierungsfaktor aktualisiert');
      props.onSuccess();
      setOpen(false);
    },
    onError: (xhr) => {
      const {isValidationError, validationErrors} = parseXHRError(xhr);

      if(isValidationError) {
        setErrorMessage(validationErrors['display_name']);
      } else {
        setErrorMessage(null);
        errorToToast(xhr, "beim Aktualisieren des Authentifizierungsfaktors");
      }
    }
  })

  return (
    <>
      <a href="#edit" className="dropdown-item" onClick={(e) => { e.preventDefault(); setOpen(true); }}>
        Name bearbeiten
      </a>
      <Modal isOpen={open} onRequestClose={requestClose} contentLabel="Authentifizierungsfaktor ändern">
        <form onSubmit={(e) => { e.preventDefault(); mutate(); }}>
          <ModalHeader title="Authentifizierungsfaktor ändern" onRequestClose={requestClose} />

          <div className="modal-body">
            <ControlGroup name="name" label="Name" errors={errorMessage}>
              <input
                type="text"
                disabled={isLoading}
                className={errorMessage?.length ? "form-control is-invalid" : "form-control"}
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </ControlGroup>
          </div>

          <div className="modal-footer">
            <button className="btn btn-light" type="button" onClick={requestClose} disabled={isLoading}>abbrechen</button>
            <button className="btn btn-secondary" type="submit" disabled={isLoading}><FaIcon name='floppy-o' loading={isLoading} /> speichern</button>
          </div>
        </form>
      </Modal>
    </>
  );
}

const DestroyModal = (props) => {
  return <Modal isOpen={props.open} className="modal fade in show d-block" contentLabel="Authentifizierungsfaktor löschen">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Authentifizierungsfaktor löschen</h5>
        </div>

        <div className="modal-body">
          {props.loading ?
            <span className="css-spinloader fullpage-spinner"></span> :
            <p>Soll der Authentifizierungsfaktor wirklich gelöscht werden?</p>}
        </div>

        <div className="modal-footer">
          <button className="btn btn-light" onClick={props.abort} disabled={props.loading}>abbrechen</button>
          <button className="btn btn-danger" onClick={props.confirm} disabled={props.loading}><FaIcon name={props.loading ? 'spinner fa-spin' : 'trash-o'} /> löschen</button>
        </div>
      </div>
    </div>
  </Modal>
}

class DestroyRow extends React.Component {
  state = { working: false, open: false }

  startDestroy = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  }

  abort = (e) => {
    e.preventDefault();

    if (this.state.working)
      return;

    this.setState({ open: false });
  }

  destroy = (e) => {
    e.preventDefault();
    this.setState({ working: true });

    $.ajax({ url: `/usercp/authentication_factors/${this.props.id}`, type: 'DELETE' })
      .done(() => {
        this.setState({ working: false });
        this.props.refreshCb();
      })
      .fail(() => {
        this.setState({ failed: true, working: false });
      });
  }

  render() {
    return (
      <React.Fragment>
        <a href="#delete" className="dropdown-item" onClick={this.startDestroy}>
          <span className="text-danger">löschen</span>
        </a>
        <DestroyModal open={this.state.open}
          loading={this.state.working}
          confirm={this.destroy}
          abort={this.abort} />
      </React.Fragment>
    );
  }
}

const Factor = ({ factor, refreshCb }) => {
  return (
    <tr>
      <td>
        <strong>
          {type_to_str(factor.type)}
        </strong>
        {!!factor.display_name && <br/>}
        {!!factor.display_name && <small className="text-muted">{factor.display_name}</small>}
      </td>
      <td>
        {format_date(factor.created_at)}
      </td>

      <td>
        <Dropdown className="pull-right">
          <UpdateModal id={factor.id} name={factor.display_name} onSuccess={refreshCb} />
          <DestroyRow id={factor.id} refreshCb={refreshCb} />
        </Dropdown>
      </td>
    </tr>
  );
}

// display an image with the flag for the country cde
// or no image if the country code is --
const CountryFlagImage = ({ country_code }) => {
  if (country_code == '--')
    return null;

  return <img src={`/assets/flags/${country_code}.png`} height="24" width="24" className='flag-icon' alt={`Flagge für ${country_code}`} />;
}

// Gerät	letzte Aktivität	zuletzt aktiv von	Ablauf der Sitzung
const Session = (props) => {
  const session = props.session;
  return (
    <tr>
      <td>
        {session.device}
        {' '}
        {session.current_session ? <span className="badge badge-primary" style={{"vertical-align": "text-bottom"}}>aktuelle Sitzung</span> : null}
      </td>
      <td>{format_date(session.updated_at)}</td>
      <td>{session.last_ip} <CountryFlagImage country_code={session.country_code} /></td>
      <td>{format_date(session.expires_at)}</td>
    </tr>
  );
}

const Page = () => {
  const { data, component, refetch } = useResource(["security"], '/usercp/security', {
    keepPreviousData: true,
  });

  const queryClient = useQueryClient();

  // use mutation for logout
  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return $.post('/usercp/security/sign_out.json');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['security'] })
      toast.success('Du wurdest aus allen anderen Sitzungen abgemeldet!');
    },
    onError: (xhr) => {
      errorToToast(xhr, "beim Beenden der Sitzungen");
    }
  })

  const signOut = (e) => {
    e.preventDefault();
    mutate();
  }

  const authentication_factors = data?.authentication_factors;
  const sessions = data?.sessions;

  return (
    <>
      <PageHeader text="Account-Sicherheit" back_url="/settings" />

      <section>
        <div className='section-header mb-3'>
          <div className="d-flex align-items-center justify-content-between">
            <h5 className='mr-2 mb-0'>
              2-Faktor-Authentifizierung
            </h5>

            <div className="resource-btns">
              <Dropdown className="btn-light" text={<React.Fragment><FaIcon name="plus" /> Faktor hinzufügen</React.Fragment>}>
                <Link key="otp" to="/authentication_factors/otp/new" className="dropdown-item">Authenticator-App hinzufügen...</Link>
                <Link key="webauthn" to="/authentication_factors/webauthn/new" className="dropdown-item">WebAuthn hinzufügen...</Link>
              </Dropdown>
            </div>
          </div>
          <p>
            Verbessere die Sicherheit Deines Accounts mit 2-Faktor-Authentifizierung.
          </p>
        </div>

        {component ? component : <table className="table table-striped table-wide">
          <thead>
            <tr>
              <th>Name</th>
              <th>Erstellt</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {authentication_factors.map((factor) => (<Factor key={factor.id} factor={factor} refreshCb={refetch} />))}

            {authentication_factors.length == 0 && (
              <tr>
                <td colSpan="3" className="text-center">Keine Faktoren hinzugefügt.</td>
              </tr>
            )}
          </tbody>
        </table>}
      </section>

      <hr />

      <div className='section-header mb-3'>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className='mr-2 mb-0'>
            Sitzungen
          </h5>

          <div className="resource-btns">
            <button className="btn btn-light" disabled={!sessions?.length || sessions?.length <= 1 || isLoading} onClick={signOut}><FaIcon name="sign-out" /> aus allen anderen Sitzungen abmelden</button>
          </div>
        </div>
        <p>
          Die aktuell auf Deinem Account aktiven Login-Sitzungen.
        </p>
        <p>
          Sitzungen bleiben bis zum Ablauf gültig, auch wenn die Sitzung nicht mehr verwendet wird (z.B. der Browser wird ohne Logout geschlossen, Cookies werden gelöscht).
        </p>
      </div>

      {component ? component : <table className='table table-striped'>
        <thead>
          <tr>
            <th>Gerät</th>
            <th>Letzte Aktivität</th>
            <th>zuletzt aktiv von</th>
            <th>Ablauf der Sitzung</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session) => (<Session key={session.id} session={session} />))}
        </tbody>
      </table>}
    </>
  )
}

export default Page;
