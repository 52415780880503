import PayButton from "client/js/usercp/lib/payment";
import { BasicDropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { FaIcon } from "client/js/util/layout_utils";
import { toast } from "react-toastify";

const badgeColor = {
  pending: 'warning',
  paid: 'success',
  cancelled: 'danger',
  declined: 'secondary',
}

const Status = ({ status }) => {
  return <span className={`badge badge-${badgeColor[status]}`}>{i18n_t(`resources.orders.status.${status}`)}</span>
}

const Actions = ({ order, refetch, autoStartPayment }) => {
  const onDeleteSuccess = () => {
    toast.success('Bestellung wurde erfolgreich widerrufen');
    refetch();
  }

  const onPaymentSuccess = () => {
    toast.success('Die Bestellung wurde bezahlt - bitte beachte dass es je nach Zahlungsmethode einige Minuten dauern kann bis die Bestellung als bezahlt markiert wird');
    refetch();
  }

  return (
    <BasicDropdown button={
      <PayButton order_id={order.id} autostart={autoStartPayment} btnClass="btn btn-light" onSuccess={onPaymentSuccess} successElement={<button className="btn btn-light" disabled><FaIcon name="check text-success" /> bezahlt</button>} />
    }>
      <ConfirmedDelete resource="orders" id={order.id} onSuccess={onDeleteSuccess} title="Bestellung widerrufen" text="Soll die Bestellung wirklich widerrufen werden?" button_class="dropdown-item text-danger" button_text="widerrufen" confirmButtonText="widerrufen" />


    </BasicDropdown>
  )
}

export { Actions, Status };

