import {FaIcon, PageHeader} from 'client/js/util/layout_utils';
import {Link} from 'react-router-dom';
import {withLoading} from 'client/js/util/rest_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import HandleDetails from './handle';
import {useQuery} from '@tanstack/react-query';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import {errorToComponent} from 'client/js/util/rest_utils';

const fetchData = async (id) => {
  const response = await $.ajax({ url: `/usercp/handles/${id}`, dataType: 'json' });

  return response;
}

const Page = (props) => {
  const { data, isLoading, isError, error } = useQuery(['handle', props.match.params.id], () => fetchData(props.match.params.id));

  if(isLoading) {
    return <>
      <PageHeader text="Adresse anzeigen" back_url="/handles" />

      <LoadingTableSpinner />
    </>
  }

  if(isError) {
    return errorToComponent(error);
  }

  const handle = data.handle;

  const buttons = <>
    <Link to={`/handles/${handle.id}/edit`} className="btn btn-light"><FaIcon name="pencil" /> bearbeiten</Link>

    <ConfirmedDelete id={handle.id} disabled={!handle.deletable} resource="handles" button_text={<><FaIcon name="trash" /> löschen</>}
      button_class="btn btn-danger" text="Dies wird die Adresse löschen. Sollten gesetzliche Aufbewahrungspflichten bestehen wird die Adresse als gelöscht markiert und nach Ablauf der Aufbewahrungspflichten gelöscht."
      success_cb={() => { props.history.push('/handles') }} />
  </>;

  return <>
    <PageHeader text="Adresse anzeigen" back_url="/handles" buttons={buttons}>
      Adresse anzeigen
    </PageHeader>

    <div className="row">
      <div className="col-md-6">
          <HandleDetails handle={handle} />
      </div>
    </div>
  </>;
}

export default Page;
