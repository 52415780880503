import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

const ProgressBottom = (props) => {
  if(props.hidden)
    return <LoadingTableSpinner text=" "/>

  const renderKeys = props.host_keys && props.host_keys.length > 0;

  return <div>
      <p><a className="btn btn-secondary" href={`/usercp/virtual_machines/${props.id}`}>Cloud-VPS verwalten</a></p>

      <p>Stelle nun eine Verbindung per SSH her:</p>
      <pre className="code-block">ssh root@{props.id}.trafficplex.cloud</pre>

      { renderKeys &&
        <div>
          <p>Die Host-Keys des VPS lauten:</p>
          <pre className="code-block">{props.host_keys && props.host_keys.join("\n")}</pre>
        </div>
      }
    </div>
}

class VirtualMachinesProvisioningProgress extends React.Component {
  state = {charges: null, loadingInProgress: false}

  componentDidMount() {
    this.fetchData();
    this.interval = setInterval(() => this.fetchData(), 5000);
  }

  componentWillUnmount() {
    if(this.interval)
      clearInterval(this.interval);
  }

  fetchData = () => {
    this.setState({loadingInProgress: true});

    $.get(this.props.url)
      .done((data) => {
        this.setState({status: data.virtual_machine.status,
                                     uptime: data.virtual_machine.uptime,
                                     id: data.virtual_machine.id,
                                     provisioned_before_s: data.virtual_machine.provisioned_before_s,
                                     restore_progress: data.virtual_machine.restore_progress,
                                     host_keys: data.virtual_machine.host_key_ids})
        if(data.status == 'running')
          clearInterval(this.interval);
      }).fail(() => this.setState({loadingInProgress: false}));
  }

  progressPercent = () => {
    var current_progress = 0;
    if(this.state.status == 'created' || this.state.status == 'rebuilding')
      current_progress = this.state.restore_progress || 5;
    else if(this.state.status == 'provisioned') {
      current_progress = 10;
      if(this.state.provisioned_before_s)
        current_progress += Math.min(this.state.provisioned_before_s / 40 * 70, 70);

    } else if(this.state.status == 'installed' || this.state.status == 'stopped')
      current_progress = 90;
    else if(this.state.status == 'running')
      current_progress = 100;

    return current_progress;
  }

  renderStarted = () => {
    if(this.state.uptime)
      return <small>gestartet {dayjs().subtract(this.state.uptime, 'seconds').fromNow()}</small>
    else
      return null
  }

  render() {
    const clamped_cpu_usage = {width: Math.max(Math.min(this.progressPercent(), 100), 0) + '%'};

    var progressClasses = 'progress';
    if(this.progressPercent() < 100)
      progressClasses += " progress-striped active"

    return (<div className="creating-cloud-vps">
              <div className={progressClasses}><div className="progress-bar" style={ clamped_cpu_usage }></div></div>

              <div className="text-center">
                {this.state.status != 'started' &&
                  (<p>Die Installation Deines Cloud-VPS läuft. Dies wird in weniger als einer Minute abgeschlossen sein.</p>)}

                <h2 className="install-status">{I18n_usercp.vms.state[this.state.status]} {this.renderStarted()}</h2>

                <ProgressBottom host_keys={this.state.host_keys} id={this.state.id} hidden={this.state.status != 'running'}/>
              </div>
            </div>);
  }
}

export default VirtualMachinesProvisioningProgress;
