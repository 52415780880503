import PropTypes from "prop-types";
import {Route, Switch} from 'react-router-dom';
import {StringInput, BooleanInput, GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';
import {withLoading} from 'client/js/util/rest_utils';
import {PageHeader} from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';

class VerificationBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {step: 1};

    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action, original_event, data) {
    if(action == 'form:success')
      this.setState({step: 2});
  }

  render() {
    return <div>
      {this.state.step == 1 &&
        <div>
          <Callout calloutClass="warning" title="Unbestätigte Bankverbindung">
            <p>Diese Bankverbindung ist bisher nicht bestätigt und kann bis zur Eingabe des Verifizierungscodes nicht genutzt werden.</p>
            <p>Den <strong>Bestätigungscode findest Du auf Deinem Kontoauszug</strong> im Verwendungszweck einer Überweisung in Höhe von 1 Cent (der Absender lautet "TrafficPlex GmbH").</p>
          </Callout>

          <GenericReactForm namespace="direct_debit_verification" url={`/usercp/payment_methods/${this.props.id}/verify`} dispatch={this.dispatch}>
            <StringInput name="code" label="Code" required placeholder='123456' />

            <div className="form-group row">
              <div className="col-md-9 offset-md-3">
                <p>
                  Ich ermächtige die TrafficPlex GmbH, identifiziert durch die Gläubiger-Identifikationsnummer DE59ZZZ00001163198 (nachfolgend &quot;lima-city&quot; genannt), wiederkehrende Zahlungen von meinem Konto mittels Lastschrift einzuziehen.
                </p>

                <ul>
                  <li>Kontoinhaber: {this.props.account_holder}</li>
                  <li>IBAN: {this.props.iban}</li>
                  <li>Mandatsreferenz: {this.props.mandate_id}</li>
                </ul>

                <p>
                  Zugleich weise ich mein Kreditinstitut an, die von lima-city auf meinem Konto gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.
                </p>

                <p>
                  Ich verpflichte mich, für ausreichende Deckung auf seinem Bankkonto Sorge zu tragen. Kann ein Bankeinzug aufgrund fehlender Deckung, falscher oder fehlender Bankdaten nicht durchgeführt werden oder wird der Bankeinzug meinerseits unberechtigt storniert, ist lima-city berechtigt, mir die angefallenen Rücklastschriftgebühren in Rechnung zu stellen. Gleichzeitig weise ich meine Bank an, lima-city auf Anfrage meine aktuelle Anschrift mitzuteilen, sofern eine Lastschrift fehlschlägt und lima-city keine Anschrift bekannt ist oder diese veraltet ist.
                </p>
              </div>
            </div>

            <BooleanInput name="tos_accepted" label="Ich akzeptiere die oben stehenden Bedingungen und erteile das SEPA-Mandat" required />

            <FormActions>
              <SubmitButton icon="plus" text="Code prüfen" />
            </FormActions>
          </GenericReactForm>
        </div>}

      {this.state.step == 2 &&
        <Callout calloutClass="success" title="Bankverbindung bestätigt">
          Vielen Dank für die Verifizierung der Bankverbindung. Sie kann nun verwendet werden.
          <br />
          <a href="#" onClick={() => location.reload()}>Fortfahren</a>
        </Callout>
        }
    </div>;
  }
}

VerificationBlock.propTypes = {
  id: PropTypes.number.isRequired,
  account_holder: PropTypes.string.isRequired,
  iban: PropTypes.string.isRequired,
  mandate_id: PropTypes.string.isRequired
}

const ShowPage = (props) => {
  return <div>
    <PageHeader text={props.data.friendly_name} back_url="/payment_methods" />

    {props.data.direct_debit_verified === false ?
      <VerificationBlock id={props.data.id} account_holder={props.data.account_holder}
        iban={props.data.iban} mandate_id={props.data.mandate_id} />:

        <p>Hier gibt es derzeit nicht viel zu sehen ;-)</p>}
  </div>;
}

ShowPage.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    friendly_name: PropTypes.string.isRequired,
    account_holder: PropTypes.string.isRequired,
    direct_debit_verified: PropTypes.bool.isRequired,
    iban: PropTypes.string.isRequired,
    mandate_id: PropTypes.string.isRequired
  })
}

const ShowContainer = withLoading(ShowPage);

const ShowWrapper = (props) => {
  return <ShowContainer key_name="payment_method" url={`/usercp/payment_methods/${props.match.params.id}.json`} />
}

export default ShowWrapper;
