import { DetailsRow, DetailsSection } from 'client/js/util/layout_utils';

const HandleAddress = ({ handle }) => {
  return (
    <address className="mb-0">
      {handle.organisation ? <React.Fragment>{handle.organisation} <br /></React.Fragment> : null}
      {handle.firstname} {handle.lastname}<br />
      {handle.street} {handle.house_number}<br />
      {handle.postal} {handle.city}
    </address>
  );
}

const HandleDetails = ({ handle }) => {
  return (
    <DetailsSection title="Inhaber-Daten">
      <DetailsRow title="Anschrift:">
        <HandleAddress handle={handle} />
      </DetailsRow>
      <DetailsRow title="Land:">
        <img src={`/assets/flags/${handle.country}.png`} className="mr-1 align-bottom" alt={`Flagge von ${handle.country_name}`} />
        {handle.country_name}
      </DetailsRow>
      <DetailsRow title="E-Mail:">
        {handle.email}
      </DetailsRow>
      <DetailsRow title="Telefon:">
        {handle.phone_formatted}
      </DetailsRow>
      <DetailsRow title="Fax:">
        {handle.fax ? handle.fax_formatted : '\u2014'}
      </DetailsRow>
    </DetailsSection>
  );
}

export default HandleDetails;
