import {withLoading} from 'client/js/util/rest_utils';
import {FaIcon} from 'client/js/util/layout_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';

const SuccessMessage = () => (
  <div className="text-center">
    <h4 className="text-success">
      <video className="success-video mb-3" id="video-manager" muted preload autoPlay>
        <source src="/assets/ani/success.mp4" type="video/mp4" />
      </video>

      <br/>
      Vielen Dank für die Zahlung!
    </h4>
  </div>
)

class AuthorizePaymentIntentPage extends React.Component {
  state = {}

  componentDidMount() {
    if(typeof(window.Stripe) === 'undefined') {
      $.getScript('https://js.stripe.com/v3/', () => {
        this.setState({stripe: window.Stripe(window.StripePublishableKey)}, () => {
          this.authorizePayment();
        });
      });
    } else {
      this.setState({stripe: window.Stripe(window.StripePublishableKey)}, () => {
        this.authorizePayment();
      })
    }
  }

  authorizePayment = () => {
    this.state.stripe.handleCardPayment(
      this.props.data.secret,
      { payment_method: this.props.data.stripe_payment_method_id }
    ).then((result) => {
      if (result.error) {
        this.setState({error: result.error});
      } else {
        this.setState({success: true});
      }
    });
  }

  restartPayment = (event) => {
    event.preventDefault();
    this.setState({error: null});
    this.authorizePayment();
  }

  render() {
    if(this.props.data.status == 'succeeded' || this.state.success)
      return <SuccessMessage />;

    if(this.state.error)
      return (
        <div className="text-center text-danger">
          <h4>Fehler</h4>
          <p>Es ist ein Fehler aufgetreten:</p>
          <p>{this.state.error.message}</p>
          <button className="btn btn-secondary" onClick={this.restartPayment}>
            <FaIcon name="repeat" /> erneut versuchen
          </button>
        </div>
      );

    return <div><LoadingTableSpinner /></div>;
  }
}

const AuthorizePaymentIntentLoader = withLoading(AuthorizePaymentIntentPage);
const AuthorizePaymentIntent = (props) => (
  <AuthorizePaymentIntentLoader url={`/usercp/credit_cards/authorize/${props.match.params.intent_id}.json`} intent_id={props.match.params.intent_id} />
)

export default AuthorizePaymentIntent;
