import ExternalLink from 'client/js/util/external_link';
import {Link} from 'react-router-dom';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import {RadioCollectionInput} from 'client/js/util/form_utils';
import {FaIcon, PageHeader, BigPrice} from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';
import {withLoading} from 'client/js/util/rest_utils';

const OfferItem = (props) => {
  const offer = props.offer;

  let price = <BigPrice price={offer.price} period='Monat' />;

  if(offer.first_interval)
    price = <span>
              <BigPrice price={offer.first_interval_price} old_price={offer.price} period='Monat' />
              für {i18n_c("month", offer.first_interval_months)}, <br />danach {format_fractional_price(offer.price)} pro Monat
            </span>

  return <a href={`/cart/add_item?item=shared_hosting_offer&offer_code=${offer.offer_code}`} to="/webhosting/create_test"
            className="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
      <div>
        <h5>{offer.name} ({i18n_c("month", offer.contract_period)} Laufzeit) <small className="text-muted">{offer.offer_code}</small></h5>

        <p>{offer.disk_space_gb} GB SSD, WordPress-Tools, SSH, tägl. Backup (90 Tage Aufbewahrung)</p>
      </div>

      <div className="text-center">{price}</div>
    </a>;
}

class FilterBlock extends React.Component {
  state = {months: 12}

  toggleFilter = (e, months) => {
    this.setState({months: months});
    e.preventDefault();
  }

  render() {
    const items = this.props.data.offers.filter((item) => item.contract_period == this.state.months)

    return <div>
      <div className="price-toggle-block mb-3">
        <button onClick={(e) => this.toggleFilter(e, 12)} aria-label="Pakete mit 1 Jahr Laufzeit anzeigen" className={this.state.months == 12 ? "btn btn-link btn-green-gradient" : "btn btn-link"}>
          12 Monate Laufzeit
        </button>
        <button onClick={(e) => this.toggleFilter(e, 1)} aria-label="Pakete mit 1 Monat Laufzeit anzeigen" className={this.state.months == 1 ? "btn btn-link btn-green-gradient" : "btn btn-link"}>
          1 Monat Laufzeit
        </button>
      </div>
      <div className="list-group list-group-flush">
        {items.map((item) => (<OfferItem key={item.offer_code} offer={item} />))}
      </div>
    </div>
  }
}

const FormContainer = withLoading(FilterBlock);

const Page = () => (<div>
  <PageHeader back_url="/webhosting" text="Webhosting-Paket direkt bestellen" />

  <FormContainer url="/usercp/webhosting/offers.json" />
</div>);

export default Page;
