import { useQuery } from '@tanstack/react-query';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';
import { BooleanInput, SelectCollectionInput } from 'client/js/util/form_utils';
import LoadingTableSpinner from 'client/js/util/loading_table_spinner';
import { errorToComponent } from 'client/js/util/rest_utils';

const database_to_select = (d) => {
  if(d.comment == null || d.comment == "") {
    return d.name;
  }

  return `${d.name} (${d.comment})`;
}

const Form = ({databases}) => {
  return (<>
      <p>Die Inhalte aus dem Datenbank-Backup werden in die folgende Datenbank wiederhergestellt:</p>

      <SelectCollectionInput required options={databases.map((item) => ([item.name, database_to_select(item)]))} name="target_database_name" label="Datenbank" />

      <BooleanInput name="confirmation" required label="Ich verstehe, dass die bestehenden Datenbank-Inhalte in der vom mir gewählten Ziel-Datenbank bei der Wiederherstellung überschrieben werden" />
    </>
  )
}

const Page = (props) => {
  const defaults = {};

  const { data: databaseData, isLoading: databasesIsLoading, isError: databasesIsError, error: databasesError } = useQuery(['databases'], async () => {
    const response = await $.ajax({url: '/usercp/databases', dataType: 'json'});

    return response;
  }, {
    keepPreviousData: true
  })

  const { data: backupData, isLoading: backupIsLoading, isError: backupIsError, error: backupError } = useQuery(['database_backups', props.match.params.id], async () => {
    const response = await $.ajax({url: `/usercp/database_backups/${props.match.params.id}`, dataType: 'json'});
    return response;
  }, {
    keepPreviousData: true
  })

  if(databasesIsLoading || backupIsLoading) {
    return <LoadingTableSpinner />;
  }

  if(databasesIsError) {
    return errorToComponent(databasesError);
  }

  if(backupIsError) {
    return errorToComponent(backupError);
  }

  defaults.target_database_name = backupData.database_backup.database_name;

  return <GenericInteraction namespace="usercp" resource_name="database_backups"
    action="restore" method="post" icon_name='server'
    form_namespace="restore"
    redirectAfter="url"
    redirect_url={(restore) => `/restores/${restore.restore_id}`}
    defaults={defaults} id={props.match.params.id} form_component={() => (<Form databases={databaseData.databases} />)} />
};

export default Page;
