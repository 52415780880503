import { BasicDropdown } from 'client/js/usercp/table_utils';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { DetailsRow, DetailsSection, FaIcon, PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import { Link } from 'react-router-dom';
import { SkeletonLoading } from '../../util/layout_utils';
import { state_to_badge } from './lib';

const Show = (props) => {
  const id = props.match.params.id;
  const { data, component } = useResource(['database', id], `/usercp/databases/${id}`, {
    keepPreviousData: true,
    header: <PageHeader text="Datenbank ..." back_url="/databases">Datenbank <SkeletonLoading /></PageHeader>
  })

  if(component)
    return component;

  const database = data.database;

  const buttons = <>
                  <Link to={`/databases/${database.id}/edit`} className="btn btn-light"><FaIcon name="pencil" /> Kommentar bearbeiten</Link>
                  <ConfirmedDelete id={database.id} disabled={database.state != 'operational'} resource="databases" button_text={[<FaIcon key={0} name="trash" />, ' löschen']}
                       button_class="btn btn-danger" text="Dies wird die Datenbank und alle Inhalte dieser Datenbank unwiderruflich löschen." />
                </>

  return <>
    <PageHeader text={database.name} back_url="/databases" buttons={buttons}>
      Datenbank {database.name}
    </PageHeader>

    <DetailsSection title="Übersicht">
      <DetailsRow title="Datenbank-Name:">
        {database.name}
      </DetailsRow>

      <DetailsRow title="Status:">{state_to_badge(database.state)}</DetailsRow>
      <DetailsRow title="Erstellt:">
        {format_date(database.created_at)}
      </DetailsRow>
      <DetailsRow title="Kommentar:">
        {database.comment}
      </DetailsRow>
    </DetailsSection>

    <hr/>

    <h5>Backups</h5>

    <table className="table table-striped table-wide">
      <thead>
        <tr>
          <th>Backup vom</th>
          <th>Status</th>
          <th>Größe</th>
          <th>Optionen</th>
        </tr>
      </thead>
      <tbody>
        {data.backups.map((backup) => {
          const download_button = <Link to={`/database_backups/${backup.id}/restore`} className="btn btn-light"><FaIcon name="recycle" /> wiederherstellen</Link>;

          return <tr key={backup.id}>
            <td>{dayjs(backup.created_at).format('L LT')}</td>
            <td>{i18n_t(`resources.database_backups.state.${backup.state}`)}</td>
            <td>{format_disk_space(backup.size)}</td>
            <td>
              <BasicDropdown button={download_button}>
                <a href={`/usercp/database_backups/${backup.id}/download`} className="dropdown-item">herunterladen</a>
              </BasicDropdown>
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </>;
}


export default Show;
