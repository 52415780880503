
import Callout from 'client/js/util/callout';
import ExternalLink from 'client/js/util/external_link';
import { DetailsRow, DetailsSection, PageHeader } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import RevealableField from 'client/js/util/revealable_field';

const Index = () => {
  const { data, component } = useResource(['contingents'], '/usercp/contingents', {
    keepPreviousData: true,
    header: <PageHeader text="Kontingente" />,
  });

  if(component) {
    return component;
  }

  return (
    <>
      <PageHeader text="Kontingente" />

      <Callout calloutClass="warning">
        <p>Bitte beachte dass Kontingente veraltet sind und nicht mehr unterstützt werden.</p>
      </Callout>

      <DetailsSection title="API-Zugangsdaten">
        <DetailsRow title="User-ID:" text={data.user_id} />
        <DetailsRow title="API-Key:">
          <RevealableField value={data.api_key} />
        </DetailsRow>
      </DetailsSection>

      <p>Eine Anleitung zur API und Beispielcode findest du in der <ExternalLink href="/2008/api">Hilfe zur API</ExternalLink>.</p>

      <hr />

      <table className="table table-striped table-contingents">
        <thead>
          <tr>
            <th>Art</th>
            <th>Gekauft</th>
            <th>Benutzt</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.contingents.length ? (
            data.contingents.map(contingent => (
              <tr key={contingent.id}>
                <td>{i18n_t(`contingent.type.${contingent.type}`)}</td>
                <td>{number_with_delimiter(contingent.amount)}</td>
                <td>{number_with_delimiter(contingent.used)}</td>
                <td>
                  <div className="progress progress-striped">
                    <div className={`progress-bar ${contingent.used_percentage > 90 ? 'bg-danger' : 'bg-success'}`} style={{ width: `${contingent.used_percentage}%` }} />
                  </div>
                </td>
              </tr>
            ))) : (
              <tr>
                <td colSpan="4" className="alert alert-warning">Keine Kontingente gefunden</td>
              </tr>
            )}
        </tbody>
      </table>
    </>
  )
}

export default Index;
