import {Route, Switch} from 'react-router-dom';
import Index from './index';
import EmailDomains from './email_domains';
import EditMailboxAlias from './edit_mailbox_alias';

const ResourceRouter = () => (<Switch>
      <Route path="/mailbox_aliases/:id/edit" component={EditMailboxAlias} />
      {/*<Route path="/email_domain/:id" component={ShowEmailDomain} />*/}
      <Route path="/email_domains" component={EmailDomains} />
      <Route path="/email" exact component={Index} />
    </Switch>);

export default ResourceRouter;
