import { PasswordInput } from 'client/js/util/form_utils';
import GenericInteraction from 'client/js/resellercp/generic_resource/interaction';

const Form = () => (<>
  <PasswordInput name="password" required label="Passwort" autoComplete="off" />
</>);

const UpdatePassword = (props) => (<GenericInteraction namespace="usercp"
  resource_name="ftp_accounts"
  action="password"
  method="patch"
  id={props.match.params.id}
  redirectAfter="index"
  form_component={() => (<Form />)} />);


  export default UpdatePassword;
