import {Route, Switch} from 'react-router-dom';
import ApiKeysIndex from 'client/js/api_keys/index';
import ApiKeysCreate from 'client/js/api_keys/create';
import ApiKeysEdit from 'client/js/api_keys/edit';
import ApiKeysShow from 'client/js/api_keys/show';

const ApiKeysRouter = () => (
    <Switch>
      <Route path="/api_keys/new" component={ApiKeysCreate} />
      <Route path="/api_keys/:id/edit" component={ApiKeysEdit} />
      <Route path="/api_keys/:id" component={ApiKeysShow} />
      <Route path="/api_keys" component={ApiKeysIndex} />
    </Switch>);

export default ApiKeysRouter;
