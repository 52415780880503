import Callout from 'client/js/util/callout';
import ConfirmedDelete from 'client/js/util/confirmed_delete';
import { DetailsRow, DetailsSection, FaIcon, PageHeader, SkeletonLoading } from 'client/js/util/layout_utils';
import { useResource } from 'client/js/util/rest_utils';
import RevealableField from 'client/js/util/revealable_field';
import { Link } from 'react-router-dom';

var ApiKeyPermissions = (props) => {
  if(props.data.roles.length == 0)
    return (
      <Callout title={false} calloutClass="warning">
        <p><strong>Dieser API-Key hat keine Berechtigungen!</strong></p>
      </Callout>
    );

  return <>
        {props.data.roles.map((item, index) => (
          <DetailsRow key={index} title={item} />
        ))}
      </>;
}

var ApiKeyInfo = (props) => (
  <>
    <DetailsSection title="Allgemeines">
        <DetailsRow title="Name:" text={props.data.name ? props.data.name : '-'} />
        <DetailsRow title="Key:">
          <RevealableField value={props.data.api_key} />
        </DetailsRow>
        <DetailsRow title="Erstellt" text={dayjs(props.data.created_at).format('L')} />
        <DetailsRow title="Zuletzt verwendet" text={props.data.last_used_at ? dayjs(props.data.last_used_at).format('l') : 'noch nie'} />
    </DetailsSection>

    <hr />

    <DetailsSection title="Rollen" className="table-api-roles">
      <ApiKeyPermissions data={props.data} />
    </DetailsSection>
  </>
)

const Show = (props) => {
  const id = props.match.params.id;

  const { data, component } = useResource(['api_key', id], `/usercp/api_keys/${id}`, {
    keepPreviousData: true,
    header: <PageHeader text="API-Key ..." back_url="/api_keys">API-Key <SkeletonLoading /></PageHeader>
  })

  if(component)
    return component;

  const buttons = (
    <>
      <Link to={`/api_keys/${id}/edit`} className="btn btn-light"><FaIcon name="pencil" /> bearbeiten</Link>
      <ConfirmedDelete namespace="usercp" resource="api_keys" id={id} button_text={<><FaIcon name="trash" /> löschen</>}
                             button_class="btn btn-danger" text="Dies wird den API-Key sofort löschen, der Zugriff ist nicht mehr möglich." />
    </>
  )

  const key = data.api_key;

  return (
    <>
      <PageHeader text={`API-Key ${key.name || id}`} back_url="/api_keys" buttons={buttons} />

      <ApiKeyInfo data={key} />
    </>
  );
}

export default Show;
